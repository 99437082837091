export const EmbedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V5H20C21.6569 5 23 6.34315 23 8V9C23 9.55228 22.5523 10 22 10C21.4477 10 21 9.55228 21 9V8C21 7.44772 20.5523 7 20 7H19V16C19 17.6569 17.6569 19 16 19H7V20C7 20.5523 7.44772 21 8 21H9C9.55228 21 10 21.4477 10 22C10 22.5523 9.55228 23 9 23H8C6.34315 23 5 21.6569 5 20V19H4C2.34315 19 1 17.6569 1 16V4ZM4 3C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V4C17 3.44772 16.5523 3 16 3H4ZM5 9C5 8.44772 5.44772 8 6 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10H6C5.44772 10 5 9.55228 5 9ZM5 13C5 12.4477 5.44772 12 6 12H14C14.5523 12 15 12.4477 15 13C15 13.5523 14.5523 14 14 14H6C5.44772 14 5 13.5523 5 13ZM22 12C22.5523 12 23 12.4477 23 13V15C23 15.5523 22.5523 16 22 16C21.4477 16 21 15.5523 21 15V13C21 12.4477 21.4477 12 22 12ZM22 18C22.5523 18 23 18.4477 23 19V20C23 21.6569 21.6569 23 20 23H19C18.4477 23 18 22.5523 18 22C18 21.4477 18.4477 21 19 21H20C20.5523 21 21 20.5523 21 20V19C21 18.4477 21.4477 18 22 18ZM12 22C12 21.4477 12.4477 21 13 21H15C15.5523 21 16 21.4477 16 22C16 22.5523 15.5523 23 15 23H13C12.4477 23 12 22.5523 12 22Z"
        fill="#002c4b"
      />
    </svg>
  );
};
