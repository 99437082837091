export const InstagramIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "48px",
        height: "48px",
      }}
    >
      <g>
        <g id="Background_1_">
          <radialGradient
            cx="51.9924"
            cy="13.5086"
            gradientTransform="matrix(5.660332e-002 -1.08 0.918 4.811314e-002 -5.988 520.5566)"
            gradientUnits="userSpaceOnUse"
            id="SVGID_1_"
            r="684.4449"
          >
            <stop offset="0" style={{ stopColor: "#FED576" }} />

            <stop offset="0.2634" style={{ stopColor: "#F47133" }} />
            <stop offset="0.6091" style={{ stopColor: "#BC3081" }} />
            <stop offset="1" style={{ stopColor: "#4C63D2" }} />
          </radialGradient>
          <path
            d="M0,249.5v13.8C3.6,395.7,110.2,502,237.1,512h39C403.7,501.9,508.5,394.7,512,263.1v-13.6    C508.5,115.6,400.1,7,269.4,0.3C128.8-6.8,3.9,106,0,249.5z"
            fill="url(#SVGID_1_)"
          />
        </g>
        <g id="Symbol">
          <g>
            <path
              d="M257.2,128.6c40.8,0,45.6,0.1,61.8,0.9c14.9,0.6,22.9,3.2,28.3,5.3c7.1,2.8,12.2,6.2,17.5,11.6     c5.3,5.4,8.7,10.6,11.5,17.8c2.1,5.5,4.5,13.7,5.3,28.9c0.8,16.4,0.9,21.3,0.9,62.8s-0.1,46.4-0.9,62.8     c-0.6,15.1-3.1,23.4-5.3,28.9c-2.8,7.2-6.1,12.4-11.5,17.8c-5.3,5.4-10.4,8.8-17.5,11.6c-5.4,2.2-13.5,4.7-28.3,5.3     c-16.1,0.8-21,0.9-61.8,0.9s-45.6-0.1-61.8-0.9c-14.9-0.6-22.9-3.2-28.3-5.3c-7.1-2.8-12.2-6.2-17.5-11.6s-8.7-10.6-11.5-17.8     c-2.1-5.5-4.5-13.7-5.3-28.9c-0.8-16.4-0.9-21.3-0.9-62.8s0.1-46.4,0.9-62.8c0.6-15.1,3.1-23.4,5.3-28.9     c2.8-7.2,6.1-12.4,11.5-17.8c5.3-5.4,10.4-8.8,17.5-11.6c5.4-2.2,13.5-4.7,28.3-5.3C211.5,128.8,216.4,128.6,257.2,128.6      M257.2,100.6c-41.5,0-46.7,0.2-62.9,1s-27.4,3.4-37.1,7.2c-10.1,4-18.6,9.3-27,18c-8.5,8.7-13.7,17.3-17.6,27.6     c-3.8,9.8-6.4,21.2-7.1,37.7c-0.8,16.5-0.9,21.8-0.9,64s0.2,47.5,0.9,64c0.8,16.5,3.4,27.8,7.1,37.7c3.9,10.2,9.1,18.9,17.6,27.6     s17,14,27,18c9.7,3.9,20.9,6.5,37.1,7.2c16.3,0.8,21.5,1,62.9,1s46.7-0.2,62.9-1c16.2-0.8,27.4-3.4,37.1-7.2     c10.1-4,18.6-9.3,27-18c8.5-8.7,13.7-17.3,17.6-27.6c3.8-9.8,6.4-21.2,7.1-37.7c0.8-16.5,0.9-21.8,0.9-64s-0.2-47.5-0.9-64     c-0.8-16.5-3.4-27.8-7.1-37.7c-3.9-10.2-9.1-18.9-17.6-27.6s-17-14-27-18c-9.7-3.9-20.9-6.5-37.1-7.2     C303.9,100.8,298.6,100.6,257.2,100.6L257.2,100.6z"
              fill="#FFFFFF"
            />
            <path
              d="M257.2,176.2c-43.4,0-78.4,35.7-78.4,79.8s35.1,79.8,78.4,79.8s78.4-35.7,78.4-79.8     S300.5,176.2,257.2,176.2z M257.2,307.8c-28.1,0-50.9-23.1-50.9-51.8s22.8-51.8,50.9-51.8s50.9,23.1,50.9,51.8     C308.1,284.7,285.3,307.8,257.2,307.8z"
              fill="#FFFFFF"
            />
            <ellipse cx="338.7" cy="173" fill="#FFFFFF" rx="18.3" ry="18.6" />
          </g>
        </g>
      </g>
    </svg>
  );
};
