import { Box, Text } from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";

import { KoFiSettings } from "./settings";
import { useLocation } from "react-router-dom";

type ButtonProps = {
  kofiUsername?: string;
};

export const KoFi: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { kofiUsername } = props;

  const renderHelpLabel = () => {
    if (enabled && isEditorView && !kofiUsername) {
      return (
        <Text
          p="1rem"
          my="1rem"
          fontSize="xs"
          textAlign="center"
          textShadow="0 1px 5px black"
          style={{
            color: "#eee",
            cursor: "pointer",
          }}
        >
          <i
            className="uil uil-exclamation-triangle"
            style={{
              color: "#f74949",
            }}
          />{" "}
          Ko-fi username missing
        </Text>
      );
    }

    if (enabled && isEditorView && kofiUsername) {
      return (
        <Text
          py="0.25rem"
          fontSize="xs"
          style={{
            color: "#eee",
            cursor: "pointer",
            textShadow: "0px 0px 10px #000000",
          }}
          textAlign="center"
        >
          Edit Ko-fi username
        </Text>
      );
    }

    return null;
  };

  return (
    <Box ref={(ref) => connect(ref)} textAlign="center" width="100%">
      {kofiUsername && (
        <Box width="100%">
          <iframe
            style={{
              borderRadius: "8px",
              border: "none",
              width: "100%",
              display: "block",

              height: "273px",
              pointerEvents: enabled ? "none" : "auto",
            }}
            src={`https://ko-fi.com/${kofiUsername}/?compact=true&hidefeed=true&widget=true&embed=false`}
            frameBorder="0"
            allowTransparency
            allow="encrypted-media"
            title="KoFiIframe"
          />
        </Box>
      )}
      {renderHelpLabel()}
    </Box>
  );
};

KoFi.craft = {
  displayName: "Ko-fi",

  props: {
    kofiUsername: "",
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: KoFiSettings,
  },
};
