import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { formatReferrers } from "utils";
import styled from "styled-components";

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  display: inline-block;
  padding-top: 0;
  padding: 0;
  //margin-left: -28px;
`;

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#ff428a",
  "#6e7dff",
  "#6ed6ff",
  "#fdff70",
  "#70ff88",
  "#ff709b",
  "#a3741b",
  "#00743e",
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  data,
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const radiuss = innerRadius + (outerRadius - innerRadius) * 0.5;
  const xx = cx + radiuss * Math.cos(-midAngle * RADIAN);
  const yy = cy + radiuss * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        fontSize={11}
        x={xx}
        y={yy}
        fill="#fff"
        textAnchor={xx > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>

      <text
        fontSize={9}
        x={x}
        y={y}
        fill={COLORS[index % COLORS.length]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {data[index] && data[index].name === ""
          ? "Direct"
          : data[index].name.toUpperCase()}
      </text>
    </>
  );
};
export const TopCountsPieChart = ({
  data,
  isReferrerPie,
}: {
  data: any;
  isReferrerPie?: boolean;
}) => {
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");

  if (data.length === 0 || !data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p="2rem">
        <Text>No data to display</Text>
      </Box>
    );
  }

  const formattedReferrers = isReferrerPie ? formatReferrers(data) : data;

  return (
    <StyledResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={formattedReferrers}
          //cx={isSmallerThan768 ? 144 : 200}
          isAnimationActive={false}
          //cy={150}
          labelLine={true}
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            percent,
            index,
          }) =>
            renderCustomizedLabel({
              data: formattedReferrers,
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              percent,
              index,
            })
          }
          outerRadius={isSmallerThan768 ? 88 : 112}
          fill="#17109b"
          dataKey="pageView"
        >
          {formattedReferrers.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </StyledResponsiveContainer>
  );
};
