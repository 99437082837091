import {
  Box,
  Fade as CFade,
  Heading,
  Image,
  SlideFade,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import styled from "styled-components";
import { useState } from "react";
import { cloudfrontURL } from "utils/urlBuilder.js";

const CardBpx = styled(Box)<{ selected?: boolean }>`
  border-radius: 6px;
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  transition: all 400ms ease 0s;

  :hover {
    h4 {
      text-decoration: underline;
    }
  }

  ${({ selected }) =>
    selected &&
    `
      text-decoration: none;
      border-bottom: 2px;
      border-style: solid;
      border-color: #d50d4e;
      box-shadow: #d9d9d9 0px 4px 20px 0px;
      opacity: 1;
      transform: translateY(-7px);

    :hover, :active, :focus {
      box-shadow: #d9d9d9 0px 4px 20px 0px !important;

    }
  `};
`;

const FeatureList = [
  {
    name: "ppLink",
    title: "Password Protected Link",
    description:
      "You can make your links private and password protected. Only users with correct password are able to get redirected to the link",
  },

  {
    name: "header",
    title: "Customize profile header",
    description:
      "Along with freedom to add a background to your Alfalink header, you can change layout of it and add decorations. You can make the header sticky on scroll, add shadow and much more",
  },

  {
    name: "socialHandles",
    title: "Social handles",
    description:
      "You can quickly add and group all your social handlers in once place. Layout and background of icons are editable!",
  },

  {
    name: "hideLink",
    title: "Hide Link",
    description:
      "Ability to hide or show specific links. Especially useful when you need to hide the link temporary",
  },

  {
    name: "bulkUpdate",
    title: "Bulk links update",
    description:
      "Ability to update colors, spacing, font and typography and decoration of all links at once",
  },

  {
    name: "preview",
    title: "Rich preview",
    description:
      "Ability to preview your Alfalink while you're editing it right in the editor, in both mobile and desktop",
  },
];
const Features2 = () => {
  const [selectedFeat, setSelectedFeat] = useState("ppLink");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const awsFileLinks = {
    header: cloudfrontURL("/headerlayouts.jpg"),
    socialHandles: cloudfrontURL("/socials.jpg"),
    ppLink: cloudfrontURL("/pplink.jpg"),
    hideLink: cloudfrontURL("/hidelink.jpg"),
    preview: cloudfrontURL("/preview.mov"),
    bulkUpdate: cloudfrontURL("/bulk.jpg"),
  };

  const featureMap = (feature) => {
    switch (feature) {
      case "header":
        return <Image src={awsFileLinks.header} alt={feature} />;

      case "socialHandles":
        return <Image src={awsFileLinks.socialHandles} alt={feature} />;

      case "ppLink":
        return <Image src={awsFileLinks.ppLink} alt={feature} />;

      case "hideLink":
        return <Image src={awsFileLinks.hideLink} alt={feature} />;

      case "bulkUpdate":
        return <Image src={awsFileLinks.bulkUpdate} alt={feature} />;

      case "preview":
        return <video autoPlay loop muted src={awsFileLinks.preview} />;

      default:
        return (
          <Image
            src="https://www.passiveairbnb.com/wp-content/uploads/2018/03/blog-46.jpg"
            alt={feature}
          />
        );
    }
  };
  return (
    <>
      {isLargerThan768 ? (
        <>
          <Box display="flex" flexDirection="row-reverse" pt="2rem">
            <Box
              width="55%"
              height="100%"
              maxWidth="550px"
              m="auto"
              py="0.5rem"
              pl="1rem"
            >
              {FeatureList.map(
                (f) =>
                  selectedFeat === f.name && (
                    <SlideFade
                      key={f.name}
                      offsetY="20px"
                      in={selectedFeat === f.name}
                    >
                      {featureMap(selectedFeat)}
                    </SlideFade>
                  )
              )}
            </Box>

            <Box width="50%" pr="0.5rem" borderRight="1px solid #ebebeb">
              <Stack
                spacing="1rem"
                p="0.75rem 0.75rem 0.75rem 0"
                alignItems="flex-start"
              >
                {FeatureList.map((f) => (
                  <CardBpx
                    key={f.name}
                    as="button"
                    selected={selectedFeat === f.name}
                    onClick={() => setSelectedFeat(f.name)}
                  >
                    <Stack spacing="0.5rem" p="1rem">
                      <Heading as="h4" fontSize="lg">
                        {f.title}
                      </Heading>

                      {selectedFeat === f.name && (
                        <CFade in={selectedFeat === f.name}>
                          <Text fontSize="sm" color="gray.600">
                            {f.description}
                          </Text>
                        </CFade>
                      )}
                    </Stack>
                  </CardBpx>
                ))}
              </Stack>
            </Box>
          </Box>
        </>
      ) : (
        <Stack
          spacing="5rem"
          width={{ sm: "100%", md: "55%" }}
          height="100%"
          minHeight={{ base: "300px", md: "560px" }}
          py={{ base: "0.5rem", md: "0.5rem" }}
          pr={{ base: "0", md: "1rem" }}
        >
          {FeatureList.map((f) => (
            <SlideFade key={f.name} offsetY="20px" in>
              <CFade in>
                <Heading as="h4" fontSize={{ base: "sm", md: "lg" }}>
                  {f.title}
                </Heading>

                <Text fontSize="sm" color="gray.500" p="4px">
                  {f.description}
                </Text>
              </CFade>
              <br />
              {featureMap(f.name)}
            </SlideFade>
          ))}
        </Stack>
      )}
    </>
  );
};

export default Features2;
