import "./styles.css";

import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import { ROOT_NODE, useEditor, useNode } from "@craftjs/core";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Delete from "@iconscout/react-unicons/icons/uil-trash-alt";
import Move from "@iconscout/react-unicons/icons/uil-expand-arrows-alt";
import ReactDOM from "react-dom";
import UilArrowMoveDown from "@iconscout/react-unicons/icons/uil-arrow-down";
import UilArrowMoveUp from "@iconscout/react-unicons/icons/uil-arrow-up";
import UilArrowUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilToBottomArrowDown from "@iconscout/react-unicons/icons/uil-arrow-to-bottom";
import UilToTopArrowUp from "@iconscout/react-unicons/icons/uil-top-arrow-to-top";
import styled from "styled-components";
import { useTracked } from "hooks/useTracked";

const IndicatorDiv = styled(Box)`
  height: 32px;
  margin-top: -32px;
  font-size: 12px;
  line-height: 14px;
  background: #111111 !important;
  border-radius: 6px;
  z-index: 1117 !important;

  svg {
    fill: #fff;
    width: 20px;
    height: 20px;

    @media (max-width: 600px) {
      width: 22px;
      height: 22px;
    }
  }
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  margin: 0 6px !important;
  font-weight: bold;

  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

// Add new component names to this array
const linkAndEmbedNames = [
  "Link",
  "Label",
  "Ko-fi",
  "Music",
  "Video",
  "Image",
  "BrandAd",
  "MintMobile",
  "Divider",
  "Twitter",
  "Buy Me A Coffee",
  "Support",
];

export const RenderNode = ({ render }: any) => {
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");

  const [numberOfLinks, setNumberOfLinks] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [mainContainerChildNodesLength, setMainContainerChildNodesLength] =
    useState(0);

  const { actions, query } = useEditor();
  const {
    id,
    isActive,
    isHover,
    dom,
    name,
    moveable,
    parent,
    deletable,

    connectors: { drag },
  } = useNode((node) => ({
    isActive: node.events.selected,
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useTracked();

  const currentRef = useRef<HTMLDivElement>();

  const findAllByKey = (obj, keyToFind) => {
    if (obj === null) {
      return null;
    }

    return Object.entries(obj)?.reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(value)
          : typeof value === "object"
          ? acc.concat(findAllByKey(value, keyToFind))
          : acc,
      []
    );
  };

  useEffect(() => {
    if (dom) {
      isActive || isHover
        ? dom.classList.add("component-selected")
        : dom.classList.remove("component-selected");
    }
  }, [dom, isActive, isHover]);

  useEffect(() => {
    if (dom && isActive && linkAndEmbedNames.includes(name)) {
      const linkNodes = query.node(state.linksContainerId).childNodes();
      const index = linkNodes.indexOf(id);

      setNumberOfLinks(linkNodes.length - 1);
      setSelectedItemIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom, isActive, selectedItemIndex, name]);

  useEffect(() => {
    if (dom && isActive && name === "Social handles") {
      const mainContainerChildNodes = query.node(ROOT_NODE).childNodes();

      const index = mainContainerChildNodes.indexOf(id);
      setSelectedItemIndex(index);

      setMainContainerChildNodesLength(mainContainerChildNodes.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom, isActive, name]);

  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top : bottom}px`,
      left: `${left}px`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;
    const { top, left } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos]);

  useEffect(() => {
    let doc = document.querySelector(".viewport");
    if (doc) {
      doc.addEventListener("scroll", scroll);
    }

    return () => {
      if (doc) {
        doc.removeEventListener("scroll", scroll);
      }
    };
  }, [scroll]);

  const handleMoveLinkUp = () => {
    actions.move(id, parent, selectedItemIndex - 1);
    actions.clearEvents();
  };

  const handleMoveLinkDown = () => {
    actions.move(id, parent, selectedItemIndex + 2);
    actions.clearEvents();
  };

  const handleMoveSocialHandlesToTop = () => {
    const acc = findAllByKey(query.getSerializedNodes(), "resolvedName");

    setTimeout(() => {
      actions.move(id, parent, acc?.includes("ContainerHeader") ? 1 : 0);
    }, 150);

    actions.clearEvents();
  };

  const handleMoveSocialHandlesToBottom = () => {
    actions.move(id, parent, mainContainerChildNodesLength);
    actions.clearEvents();
  };

  // Link/label is movable and not on top
  const shouldShowMoveLinkUpHandler =
    moveable &&
    selectedItemIndex !== -1 &&
    selectedItemIndex > 0 &&
    linkAndEmbedNames.includes(name);

  // Link/label is movable and not in bottom
  const shouldShowMoveLinkDownHandler =
    moveable &&
    selectedItemIndex !== -1 &&
    selectedItemIndex !== numberOfLinks &&
    linkAndEmbedNames.includes(name);

  // when social handles is NOT in top
  const shouldShowMoveSocialHandlesToTopHandler =
    name === "Social handles" && selectedItemIndex !== 1;

  // when social handles is in the top
  const shouldShowMoveSocialHandlesToBottomHandler =
    name === "Social handles" && selectedItemIndex === 1;

  return (
    <>
      {isActive
        ? ReactDOM.createPortal(
            isSmallerThan768 ? (
              <IndicatorDiv
                ref={currentRef}
                position="fixed"
                display="flex"
                alignItems="center"
                justifyContent="center"
                boxShadow="sm"
                color="#fff"
                pl="10px"
                pr={name === "Main Container" ? "10px" : "2px"}
                style={{
                  left: getPos(dom).left,
                  top: getPos(dom).top,
                  zIndex: 101,
                }}
              >
                {(name === "Links Container" || name === "Main Container") && (
                  <>
                    <Text
                      fontStyle="italic"
                      display="flex"
                      mr={moveable || deletable ? "8px" : "2px"}
                    >
                      {name}
                    </Text>
                    <Box
                      height="1rem"
                      borderRight="1px solid #ffffff33"
                      mx="0.25rem"
                    ></Box>
                  </>
                )}

                {shouldShowMoveLinkUpHandler && (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveLinkUp}
                  >
                    <UilArrowMoveUp />
                  </Btn>
                )}

                {shouldShowMoveLinkDownHandler && (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveLinkDown}
                  >
                    <UilArrowMoveDown />
                  </Btn>
                )}

                {shouldShowMoveSocialHandlesToTopHandler ? (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveSocialHandlesToTop}
                  >
                    <UilToTopArrowUp />
                  </Btn>
                ) : null}

                {shouldShowMoveSocialHandlesToBottomHandler ? (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveSocialHandlesToBottom}
                  >
                    <UilToBottomArrowDown />
                  </Btn>
                ) : null}

                {name === "Social handles" ||
                linkAndEmbedNames.includes(name) ? (
                  <Box
                    height="1rem"
                    borderRight="1px solid #ffffff33"
                    mx="0.25rem"
                  ></Box>
                ) : null}

                {deletable &&
                name !== "Main Container" &&
                name !== "Links Container" ? (
                  <>
                    <Btn
                      style={{ cursor: "pointer" }}
                      onMouseDown={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        actions.delete(id);
                      }}
                    >
                      <Delete />
                    </Btn>
                    <Box
                      height="1rem"
                      borderRight="1px solid #ffffff33"
                      mx="0.25rem"
                    ></Box>
                  </>
                ) : null}

                {id !== ROOT_NODE && (
                  <Btn
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      actions.selectNode(parent);
                    }}
                  >
                    <UilArrowUp size="38px" />
                  </Btn>
                )}
              </IndicatorDiv>
            ) : (
              <IndicatorDiv
                ref={currentRef}
                position="fixed"
                display="flex"
                alignItems="center"
                justifyContent="center"
                boxShadow="sm"
                color="#fff"
                p="12px"
                style={{
                  left: getPos(dom).left,
                  top: getPos(dom).top,
                  zIndex: 101,
                }}
              >
                {(name === "Links Container" || name === "Main Container") && (
                  <>
                    <Text
                      fontStyle="italic"
                      display="flex"
                      mr={moveable || deletable ? "8px" : "2px"}
                    >
                      {name}
                    </Text>
                    <Box
                      height="1rem"
                      borderRight="1px solid #ffffff33"
                      mx="0.25rem"
                    ></Box>
                  </>
                )}

                {moveable && name !== "Social handles" ? (
                  <Btn
                    style={{
                      marginRight: "4px",
                      cursor: "move",
                    }}
                    ref={drag}
                  >
                    <Move />
                  </Btn>
                ) : null}

                {linkAndEmbedNames.includes(name) ? (
                  <Box
                    height="1rem"
                    borderRight="1px solid #ffffff33"
                    mx="0.25rem"
                  />
                ) : null}

                {shouldShowMoveLinkUpHandler && (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveLinkUp}
                  >
                    <UilArrowMoveUp />
                  </Btn>
                )}

                {shouldShowMoveLinkDownHandler && (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveLinkDown}
                  >
                    <UilArrowMoveDown />
                  </Btn>
                )}

                {shouldShowMoveSocialHandlesToTopHandler ? (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveSocialHandlesToTop}
                  >
                    <UilToTopArrowUp />
                  </Btn>
                ) : null}

                {shouldShowMoveSocialHandlesToBottomHandler ? (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={handleMoveSocialHandlesToBottom}
                  >
                    <UilToBottomArrowDown />
                  </Btn>
                ) : null}

                {linkAndEmbedNames.includes(name) ? (
                  <Box
                    height="1rem"
                    borderRight="1px solid #ffffff33"
                    mx="0.25rem"
                  />
                ) : null}

                {deletable &&
                name !== "Main Container" &&
                name !== "Links Container" ? (
                  <Btn
                    style={{ cursor: "pointer" }}
                    onMouseDown={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      actions.delete(id);
                    }}
                  >
                    <Delete />
                  </Btn>
                ) : null}

                {name === "Social handles" ||
                linkAndEmbedNames.includes(name) ? (
                  <Box
                    height="1rem"
                    borderRight="1px solid #ffffff33"
                    mx="0.25rem"
                  ></Box>
                ) : null}

                {id !== ROOT_NODE && (
                  <Btn
                    style={{ marginRight: "4px", cursor: "pointer" }}
                    onClick={() => {
                      actions.selectNode(parent);
                    }}
                  >
                    <UilArrowUp size="40px" />
                  </Btn>
                )}
              </IndicatorDiv>
            ),
            document.body
          )
        : null}
      {render}
    </>
  );
};
