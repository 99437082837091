import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useState } from "react";

import SearchList from "./SearchList";
import UilSearchIcon from "@iconscout/react-unicons/icons/uil-search";

export const Search = ({ questions }: { questions: any }) => {
  const [searchField, setSearchField] = useState("");

  const filteredQuestions = questions.filter((question) => {
    return question.title.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  function searchList() {
    return <SearchList filteredQuestions={filteredQuestions} />;
  }

  return (
    <>
      <Box
        p={{ base: "3rem 1rem", md: "4rem 9rem" }}
        my="1rem"
        background="linear-gradient(344deg, #8748f3 0%, #bf61f9 100%)"
        borderRadius="10px"
      >
        <Box>
          <Heading
            as="h2"
            size="xl"
            textAlign="center"
            mb="3rem"
            color="#ffffff"
          >
            Hi, how can we help?
          </Heading>

          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<UilSearchIcon size="1rem" color="##002c4b" />}
            />
            <Input
              type="search"
              bg="#f7f7f7"
              placeholder="Enter your search terms.."
              onChange={handleChange}
            />
          </InputGroup>
        </Box>
      </Box>
      <Box py="2rem">{searchList()}</Box>
    </>
  );
};
