import { Box, Center, SimpleGrid, Stack } from "@chakra-ui/react";

import Fade from "react-reveal/Fade";
import Features from "components/Features";
import GetStartedInput from "components/GetStartedInput";
import GradientText from "components/GradientText";
import { Helmet } from "react-helmet";
import Hero from "components/Hero";
import IntroOneLink from "components/OneLinkToEverywhere";
import Line from "./Line";
import Monetize from "components/MonetizeLanding";
import Page from "components/Page";
import Privacy from "components/PrivacyAndSecurity";
import StartWithTemplate from "components/TemplateLanding";
import styled from "styled-components";
import { alfalinkURL, s3URL } from "utils/urlBuilder.js";

const LineContainer = styled(Box)`
  transform: rotateX(140deg);
`;

const ImageWithCircle = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(1turn);
    }
  }

  .image-circle {
    width: 124px;
    height: 124px;
    border-radius: 50%;
    border: 3px dashed #eda8cd;
    border-right: 3px dashed #eda8cd;
    border-left: 3px dashed #eda8cd;
    border-bottom: 3px dashed #eda8cd;
    padding: 8px;
    animation: spin 13s infinite linear;
  }

  .image-circle img {
    animation: spin 13s infinite reverse linear;
    width: 100px;
    height: 104px;
    border-radius: 50%;
  }
`;
const LandingScreen = () => {
  return (
    <>
      <Helmet>
        <title>Alfalink | Home</title>
      </Helmet>

      <Page pt={{ base: "2rem", md: "4rem" }}>
        <Hero />
        <LineContainer m="3rem 0 0rem 0">
          <Line primary={"#002c4b"} secondary={"#ececec"} />
        </LineContainer>

        <Box>
          <Center py="1rem">
            <GradientText
              text="Examples to inspire you"
              fontSize={{ base: "1.5rem", md: "2rem" }}
            />
          </Center>

          <Center>
            <SimpleGrid
              columns={[2, null, 4]}
              spacing={{ base: "4rem", md: "6rem" }}
            >
              <ImageWithCircle className="container">
                <div className="image-circle">
                  <a
                    href={ alfalinkURL('/hey') }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ s3URL('/1658784763815') } alt="alfalink demo" />
                  </a>
                </div>
              </ImageWithCircle>

              <ImageWithCircle className="container">
                <div className="image-circle">
                  <a
                    href={ alfalinkURL('/aras') }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ s3URL('/1634941883309') } alt="alfalink demo"  />
                  </a>
                </div>
              </ImageWithCircle>

              <ImageWithCircle className="container">
                <div className="image-circle">
                  <a
                    href={ alfalinkURL('/demo1') }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ s3URL('/1641570693642') } alt="alfalink demo"  />
                  </a>
                </div>
              </ImageWithCircle>

              <ImageWithCircle className="container">
                <div className="image-circle">
                  <a
                    href={ alfalinkURL('/demo3') }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ s3URL('/1641735649963') } alt="alfalink demo"  />
                  </a>
                </div>
              </ImageWithCircle>
            </SimpleGrid>
          </Center>
        </Box>

        <LineContainer m="1rem 0">
          <Line primary={"#002c4b"} secondary={"#ececec"} />
        </LineContainer>

        <Stack spacing={{ base: "6rem", lg: "8rem" }}>
          <Fade bottom>
            <StartWithTemplate />
          </Fade>

          <Fade bottom>
            <IntroOneLink />
          </Fade>

          <Fade bottom>
            <Monetize />
          </Fade>

          <Fade bottom>
            <Features />
          </Fade>

          <Fade bottom>
            <Privacy />
          </Fade>

          <Fade bottom>
            <Stack
              spacing="1rem"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              fontWeight="bold"
              mb="5rem"
            >
              <GradientText
                fontSize={{ base: "1.825rem", md: "2.5rem" }}
                text="Start making the most of every link"
              />
              <GetStartedInput showInput />
            </Stack>
          </Fade>
        </Stack>
      </Page>
    </>
  );
};

export default LandingScreen;
