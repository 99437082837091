import {
  Alert,
  AlertDescription,
  Button,
  CloseButton,
  Collapse,
  Divider,
  Drawer,
  Fade,
  HStack,
  Heading,
  Radio,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import {
  FontSizeItem,
  FontWeightRadios,
  LinkStylesItem,
  TextAlignRadios,
} from "../commons";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useCallback, useState } from "react";
import { TabHeaderButton, TabList } from "components/TabList";
import { useEditor, useNode } from "@craftjs/core";

import { AnimationIcon } from "assets/svgs/animationIcon";
import { CloneIcon } from "assets/svgs/cloneIcon";
import Cookies from "js-cookie";
import Delete from "@iconscout/react-unicons/icons/uil-trash-alt";
import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { EDITOR_LINK_CONTAINER_TIP } from "utils/constants";
import { FontColorIcon } from "assets/svgs/fontColorIcon";
import { FontFormatIcon } from "assets/svgs/fontFormatIcon";
import { FontTypeIcon } from "assets/svgs/fontTypeIcon";
import { LinkDecorationsIcon } from "assets/svgs/linkDecorationsIcon";
import { LinkShieldIcon } from "assets/svgs/linkShieldIcon";
import { LinkStylesIcon } from "assets/svgs/linkStylesIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";
import UilHideLink from "@iconscout/react-unicons/icons/uil-eye-slash";
import UilInfo from "@iconscout/react-unicons/icons/uil-info-circle";
import UilMargins from "@iconscout/react-unicons/icons/uil-line-spacing";
import UilSmile from "@iconscout/react-unicons/icons/uil-smile-beam";
import { getRandomId } from "@craftjs/utils";

export const LinkSettings = () => {
  const { id, propValue, parent } = useNode((node) => ({
    propValue: node.data.props,
    parent: node.data.parent,
  }));

  const { actions, query } = useEditor();

  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const toast = useToast();

  const showInfoCookie = Cookies.get(EDITOR_LINK_CONTAINER_TIP);

  const handleOnCloseTipClick = () => {
    Cookies.set(EDITOR_LINK_CONTAINER_TIP, "true", { expires: 1000 });
    setShowLinkContainerInfoMessage(false);
  };

  const [showLinkContainerInfoMessage, setShowLinkContainerInfoMessage] =
    useState(showInfoCookie !== "true");

  const getCloneTree = useCallback((idToClone) => {
    const tree = query.node(idToClone).toNodeTree();
    const newNodes = {};

    const changeNodeId = (node, newParentId) => {
      const newRandomNodeId = getRandomId();

      const childNodes = node.data.nodes.map((childId) =>
        changeNodeId(tree.nodes[childId], newRandomNodeId)
      );
      const linkedNodes = Object.keys(node.data.linkedNodes).reduce(
        (accum, id) => {
          const newNodeId = changeNodeId(
            tree.nodes[node.data.linkedNodes[id]],
            newRandomNodeId
          );
          return {
            ...accum,
            [id]: newNodeId,
          };
        },
        {}
      );

      let tmpNode = {
        ...node,
        id: newRandomNodeId,
        data: {
          ...node.data,
          parent: newParentId || node.data.parent,
          nodes: childNodes,
          linkedNodes,
        },
      };
      let freshnode = query.parseFreshNode(tmpNode).toNode();
      newNodes[newRandomNodeId] = freshnode;
      return newRandomNodeId;
    };

    const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId], parent);
    return {
      rootNodeId,
      nodes: newNodes,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClone = (e: any) => {
    e.preventDefault();
    const theNode = query.node(id).get();
    const parentNode = query.node(theNode.data.parent).get();
    const indexToAdd = parentNode.data.nodes.indexOf(id);
    const tree = getCloneTree(id);
    actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);

    toast({
      title: "Link cloned",
      status: "success",
      variant: "subtle",
      isClosable: true,
      position: "top",
    });

    actions.clearEvents();
  };

  const MobileInfoSettingItems = (
    <>
      <SettingsItem
        full
        propKey="text"
        type="text"
        label="Name"
        placeholder="Title of the link"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="linkUrl"
        type="text"
        label="Link URL"
        isUrlInput
        placeholder="Add a valid url"
      />
    </>
  );

  const MobileLinkGuardsSettingItems = (
    <>
      <Heading as="h6" size="xs" pl="2px">
        Link to sensitive content
      </Heading>
      <Text color="gray.500" fontSize="xs">
        When active, visitors will be prompted with an popover before they are
        directed to the link
      </Text>

      <SettingsItem full={true} propKey="isSensitiveContent" type="switch" />

      {propValue.isSensitiveContent ? (
        <Fade in={propValue && propValue.isSensitiveContent}>
          <SettingsItem
            full={true}
            propKey="sensitiveContentMessage"
            type="textArea"
            label="Prompt message"
            placeholder="Alert visitors about the content of link.."
          />
        </Fade>
      ) : null}

      <Divider my="1em" />

      <Heading as="h6" size="xs" pl="2px">
        Password protect link
      </Heading>
      <Text color="gray.500" fontSize="xs">
        Visitors will be asked to enter the password to access and visit the
        link.
      </Text>

      <SettingsItem full={true} propKey="isPasswordProtected" type="switch" />

      {propValue.isPasswordProtected ? (
        <Fade in={propValue && propValue.isPasswordProtected}>
          <SettingsItem
            full={true}
            propKey="linkAccessPassword"
            type="text"
            label="Password"
            placeholder="Password"
          />
        </Fade>
      ) : null}
    </>
  );

  const MobileHideLinkSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Hide Link
      </Heading>
      <Text color="gray.500" fontSize="xs">
        When active, this link will be hidden from your public profile
      </Text>
      <SettingsItem full={true} propKey="hideLink" type="switch" />
    </>
  );

  const InfoSettingItems = (
    <>
      {MobileInfoSettingItems}

      <Divider my="1em" />

      {MobileLinkGuardsSettingItems}

      <Divider my="1em" />

      {MobileHideLinkSettingItem}
    </>
  );

  const MobileButtonStylesSettingItems = (
    <>
      {LinkStylesItem({
        buttonStyle: propValue.buttonStyle,
        background: propValue.background,
      })}
    </>
  );

  const MobileLinkMarginSettingItems = (
    <>
      <SettingsItem
        full
        propKey="marginTop"
        type="slider"
        sliderMin={4}
        sliderMax={64}
        label="Margin from top"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="marginBottom"
        type="slider"
        sliderMin={4}
        sliderMax={64}
        label="Margin from bottom"
      />
    </>
  );

  const MobileDecorationSettingItems = (
    <>
      <SettingsItem
        full
        propKey="borderRadius"
        type="slider"
        sliderMin={0}
        sliderMax={60}
        label="Corner smoothing"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="shadow"
        type="slider"
        sliderMin={0}
        sliderMax={28}
        label="Shadow"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="py"
        type="slider"
        sliderMin={8}
        sliderMax={32}
        label="Padding"
      />
    </>
  );

  const DecorationSettingItems = (
    <>
      {MobileButtonStylesSettingItems}

      <Divider my="1em" />

      {MobileLinkMarginSettingItems}

      <Divider my="1em" />

      {MobileDecorationSettingItems}
    </>
  );

  const MobileTextColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Text color
      </Heading>

      <SettingsItem full propKey="color" type="gradient" useGradient={false} />
    </>
  );

  const MobileBackgroundColorSettingItem = (
    <>
      {propValue.buttonStyle === "ghost" ? (
        <>
          <Heading as="h6" size="xs" pl="2px">
            Cannot add background color to a 'Ghost' link style. It's called
            'Ghost' for a reason &#128521;
          </Heading>

          <Button
            mt="1.5rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("styles")}
          >
            Change link style <UilArrowRight size="20px" />
          </Button>
        </>
      ) : (
        <>
          <Heading
            display={{ base: "none", md: "block" }}
            as="h6"
            size="xs"
            pl="2px"
          >
            Background color
          </Heading>

          <SettingsItem
            full
            propKey="background"
            type="gradient"
            useGradient={propValue.buttonStyle === "solid"}
          />
        </>
      )}
    </>
  );

  const ColorSettingItems = (
    <>
      {MobileBackgroundColorSettingItem}

      <Divider my="1.5em" />

      {MobileTextColorSettingItem}
    </>
  );
  const AnimationSettingItem = (
    <SettingsItem full propKey="animationType" type="buttonRadio">
      <Stack spacing="1rem">
        <Radio value="none">None</Radio>
        <Radio value="shaky">Shaky</Radio>
        <Radio value="bounce">Bounce</Radio>
        <Radio value="pulse">Pulse</Radio>
      </Stack>
    </SettingsItem>
  );

  const MobileTextFormatSettingItem = (
    <>
      <SettingsItem
        full
        propKey="textAlign"
        type="buttonRadio"
        label="Align text"
      >
        {TextAlignRadios}
      </SettingsItem>

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="fontWeight"
        type="buttonRadio"
        label="Font weight"
      >
        {FontWeightRadios}
      </SettingsItem>
    </>
  );
  const TypographySettingItems = (
    <div>
      {FontSizeItem}

      <Divider my="1em" />

      {MobileTextFormatSettingItem}
    </div>
  );

  const FontSettingItem = (
    <SettingsItem
      full
      propKey="fontFamily"
      type="selectfont"
      label="Font family"
    />
  );

  const EmojiSettingItem = (
    <>
      <SettingsItem propKey="showEmoji" type="switch" label="Add emojis" />
      <Collapse in={propValue.showEmoji} animateOpacity>
        {propValue.showEmoji ? (
          <SettingsItem full propKey="emoji" type="selectemoji" label="Emoji" />
        ) : null}

        <Divider my="1em" />

        <SettingsItem
          full
          propKey="emojiSize"
          type="slider"
          sliderMin={14}
          sliderMax={50}
          label="Emoji size"
        />

        <Divider my="1rem" />

        <SettingsItem
          full
          propKey="alignEmoji"
          type="buttonRadio"
          label="Emoji placement"
        >
          <HStack spacing="1rem">
            <Radio value="left">Left</Radio>
            <Radio value="right">Right </Radio>
            <Radio value="both">Both sides</Radio>
          </HStack>
        </SettingsItem>
      </Collapse>
    </>
  );

  const hideIconWrapper =
    drawerOpen === "info" ||
    drawerOpen === "decoration" ||
    drawerOpen === "hide" ||
    drawerOpen === "styles" ||
    drawerOpen === "margin" ||
    drawerOpen === "typography" ||
    drawerOpen === "textColor" ||
    drawerOpen === "backgroundColor" ||
    drawerOpen === "animation" ||
    drawerOpen === "guards" ||
    drawerOpen === "font" ||
    drawerOpen === "emoji";

  return isLargerThan768 ? (
    <React.Fragment>
      <Tabs variant="line" isFitted>
        <TabList>
          <TabHeaderButton>Info</TabHeaderButton>
          <TabHeaderButton>Decoration</TabHeaderButton>
          <TabHeaderButton>Colors</TabHeaderButton>
          <TabHeaderButton>Animation</TabHeaderButton>
          <TabHeaderButton>Typography</TabHeaderButton>
          <TabHeaderButton>Font</TabHeaderButton>
          <TabHeaderButton>Emoji</TabHeaderButton>
        </TabList>
        <TabPanels>
          <TabPanel>
            {InfoSettingItems}

            <Divider my="1rem" />

            <Button mb="1.5rem" variant="outline" onClick={handleClone}>
              <CloneIcon /> <Text pl="0.25rem"> Clone Link</Text>
            </Button>
          </TabPanel>

          <TabPanel>{DecorationSettingItems}</TabPanel>

          <TabPanel>{ColorSettingItems}</TabPanel>

          <TabPanel>{AnimationSettingItem}</TabPanel>
          <TabPanel> {TypographySettingItems} </TabPanel>

          <TabPanel>{FontSettingItem}</TabPanel>

          <TabPanel>{EmojiSettingItem}</TabPanel>
        </TabPanels>
      </Tabs>
    </React.Fragment>
  ) : (
    <>
      {showLinkContainerInfoMessage && !hideIconWrapper && (
        <Alert
          width="98%"
          left="2px"
          right="2px"
          position="relative"
          bottom="5.75rem"
          status="info"
          borderRadius="4px"
          fontSize="11px"
          p="0.25rem"
          zIndex={1111}
        >
          <UilInfo size="20px" />
          <AlertDescription pl="6px">
            You can bulk update appearances of unstyled links in{" "}
            <button
              style={{
                textDecoration: "underline",
              }}
              onClick={() => {
                actions.selectNode(parent);
              }}
            >
              Links Container
            </button>
          </AlertDescription>
          <CloseButton
            size="sm"
            position="relative"
            onClick={handleOnCloseTipClick}
          />
        </Alert>
      )}

      <IconsWrapper hidden={hideIconWrapper}>
        <SettingIconButton onClick={() => setDrawer("info")}>
          <UilEditKeyboard />
          <Text>Info</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("decoration")}>
          <LinkDecorationsIcon />
          <Text>Decorations</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("textColor")}>
          <FontColorIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.color ? propValue.color : "#eee",
            }}
          />

          <Text>Color</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("backgroundColor")}>
          <PaintBucketIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.background ? propValue.background : "#111",
            }}
          />

          <Text>Background</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("styles")}>
          <LinkStylesIcon />
          <Text>Styles</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("margin")}>
          <UilMargins />
          <Text>Margins</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("animation")}>
          <AnimationIcon />
          <Text>Animation</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("typography")}>
          <FontFormatIcon />
          <Text>Typography</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("font")}>
          <FontTypeIcon />
          <Text>Font</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("guards")}>
          <LinkShieldIcon />
          <Text>Guards</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("emoji")} color="red">
          <UilSmile />
          <Text>Emoji</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("hide")}>
          <UilHideLink />
          <Text>Hide</Text>
        </SettingIconButton>

        <SettingIconButton onClick={handleClone}>
          <CloneIcon />

          <Text>Clone</Text>
        </SettingIconButton>

        <SettingIconButton
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            actions.delete(id);
          }}
        >
          <Delete />
          <Text>Delete</Text>
        </SettingIconButton>
      </IconsWrapper>

      {
        // DRAWERS
      }
      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "info"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Link information">
          {MobileInfoSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "guards"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Link guards">
          {MobileLinkGuardsSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "hide"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="20vh" title="Hide link">
          {MobileHideLinkSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "styles"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Link styles">
          {MobileButtonStylesSettingItems}

          <Button
            mt="1.5rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("backgroundColor")}
          >
            Change background color <UilArrowRight size="20px" />
          </Button>
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "margin"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="27vh" title="Spacing margins">
          {MobileLinkMarginSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "decoration"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="37vh" title="Decorations">
          {MobileDecorationSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "textColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Text color">
          {MobileTextColorSettingItem}

          <Button
            mt="1.5rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("backgroundColor")}
          >
            Change background color <UilArrowRight size="20px" />
          </Button>
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "backgroundColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Background color">
          {propValue.buttonStyle === "" ? (
            <>
              <Heading as="h6" size="xs" pl="2px">
                Select a link style before changing background color.
              </Heading>
              <Button
                mt="1rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("styles")}
              >
                Change Link style
              </Button>
            </>
          ) : (
            <>
              {MobileBackgroundColorSettingItem}

              <Button
                mt="1.5rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("textColor")}
              >
                Change text color <UilArrowRight size="20px" />
              </Button>
            </>
          )}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "animation"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="34vh" title="Animations">
          {AnimationSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "typography"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Typography">
          {TypographySettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "font"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Font">{FontSettingItem}</DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "emoji"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Emoji">{EmojiSettingItem}</DrawerContent>
      </Drawer>
    </>
  );
};
