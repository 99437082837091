import {
  Alert,
  Box,
  Collapse,
  Divider,
  Drawer,
  Heading,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";
import { TabHeaderButton, TabList } from "components/TabList";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { FontTypeIcon } from "assets/svgs/fontTypeIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SelectFontItem } from "../commons";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilImage from "@iconscout/react-unicons/icons/uil-image";
import UilInfo from "@iconscout/react-unicons/icons/uil-info-circle";
import UilVideo from "@iconscout/react-unicons/icons/uil-video";
import { useNode } from "@craftjs/core";

export const ContainerSettings = () => {
  const { propValue } = useNode((node) => ({
    propValue: node.data.props,
  }));

  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const BackgroundColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Background color
      </Heading>
      <SettingsItem full propKey="background" type="gradient" />
    </>
  );

  const BackgroundImageSettingItem = (
    <>
      <SettingsItem full propKey="useImage" type="switch" label="Use image" />

      <Collapse in={propValue.useImage} animateOpacity>
        <Box>
          <SettingsItem
            full
            propKey="backgroundImageUrl"
            type="imageGallery"
            withUnsplash
          />

          {propValue.backgroundImageUrl && (
            <>
              <Divider my="0.75rem" borderColor="#ececec" />

              <Stack spacing="1rem" mb={{ base: "1rem", md: "2rem" }}>
                <SettingsItem
                  full
                  propKey="positionX"
                  type="slider"
                  subLabel="Horizontal positioning"
                  sliderMin={0}
                  sliderMax={100}
                />
                <SettingsItem
                  full
                  propKey="positionY"
                  type="slider"
                  subLabel="Vertical positioning"
                  sliderMin={0}
                  sliderMax={100}
                />
              </Stack>
            </>
          )}

          <Divider my="1.25rem" borderColor="#ececec" />

          <SettingsItem
            full
            propKey="backgroundImageUrl"
            type="text"
            placeholder="Image URL address"
            label="Image URL address"
          />
        </Box>
        <Text
          color="gray.400"
          fontSize="xs"
          mt="1.5rem"
          display="flex"
          alignItems="center"
        >
          <UilInfo size="1rem" /> You can also use/paste your own online image
          URL.
        </Text>
      </Collapse>
    </>
  );

  const BackgroundVideoSettingItem = (
    <>
      <SettingsItem full propKey="useVideo" type="switch" label="Use video" />

      <Collapse in={propValue.useVideo} animateOpacity>
        <Box mt="1rem">
          <SettingsItem
            full
            propKey="backgroundVideoUrl"
            type="text"
            label="Video URL address"
            placeholder="Video URL address"
          />

          <SettingsItem full propKey="backgroundVideoUrl" type="videoGallery" />
        </Box>

        <Text
          color="gray.400"
          fontSize="xs"
          mt="1.5rem"
          display="flex"
          alignItems="center"
        >
          <UilInfo size="1rem" /> You can also use/paste your own online video
          URL.
        </Text>
      </Collapse>
    </>
  );

  const MessageIfVideoOrImageIsActive =
    propValue.useImage || propValue.useVideo ? (
      <Box bg="gray.200" p="0.25rem" mb="1rem">
        <Alert
          borderRadius="4px"
          status="info"
          fontSize={isLargerThan768 ? "sm" : "xs"}
          p="0.25rem"
          mb={isLargerThan768 ? "0.25rem" : "1rem"}
        >
          <UilInfo />

          <Text ml="0.5rem">
            An image or video is already set as background. <br />
            Please disable them before using color as background.
          </Text>
        </Alert>

        {propValue.useImage && (
          <>
            <SettingsItem full propKey="useImage" type="switch" label="Image" />

            {propValue.useVideo && <Divider my="0.5rem" />}
          </>
        )}

        {propValue.useVideo && (
          <SettingsItem full propKey="useVideo" type="switch" label="Video" />
        )}
      </Box>
    ) : null;

  const MessageIfVideoIsActive = propValue.useVideo ? (
    <Box
      bg="gray.200"
      p="0.25rem"
      mb={isLargerThan768 ? "0.5rem" : "1rem"}
      borderRadius="4px"
    >
      <Alert
        borderRadius="4px"
        status="info"
        fontSize="xs"
        p="0.25rem"
        mb={isLargerThan768 ? "0.5rem" : "0.5rem"}
      >
        <UilInfo />

        <Text ml="0.5rem">
          A video is already set as background. <br /> Please disable that to
          use image background.
        </Text>
      </Alert>

      <SettingsItem full propKey="useVideo" type="switch" label="Video" />
    </Box>
  ) : null;

  const InfoMessageOnChangesHere = (
    <Alert
      borderRadius="4px"
      status="info"
      fontSize="xs"
      p="0.25rem"
      mb={isLargerThan768 ? "1.5rem" : "1rem"}
      mt={isLargerThan768 ? "-1rem" : "-0.5rem"}
    >
      <UilInfo />

      <Text ml="0.5rem">
        Font style change will be applied to the unstyled texts and labels.{" "}
        <br />
        You can specify individual font style for each component when editing
        them.
      </Text>
    </Alert>
  );
  return isLargerThan768 ? (
    <React.Fragment>
      <Tabs variant="line" isFitted>
        <TabList mb="1em">
          <TabHeaderButton>Color</TabHeaderButton>
          <TabHeaderButton>Image</TabHeaderButton>
          <TabHeaderButton>Video</TabHeaderButton>
          <TabHeaderButton>Font</TabHeaderButton>
        </TabList>
        <TabPanels>
          <TabPanel mb="2rem">
            {MessageIfVideoOrImageIsActive}

            {BackgroundColorSettingItem}
          </TabPanel>
          <TabPanel mb="2rem">
            {MessageIfVideoIsActive}

            {BackgroundImageSettingItem}
          </TabPanel>
          <TabPanel mb="2rem">{BackgroundVideoSettingItem}</TabPanel>

          <TabPanel>
            {InfoMessageOnChangesHere}
            {SelectFontItem}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </React.Fragment>
  ) : (
    <>
      <IconsWrapper
        hidden={
          drawerOpen === "color" ||
          drawerOpen === "image" ||
          drawerOpen === "video" ||
          drawerOpen === "font"
        }
      >
        <SettingIconButton onClick={() => setDrawer("color")}>
          <PaintBucketIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.background
                ? propValue.background
                : "#f3f3f3",
            }}
          />

          <Text>Color</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("image")}>
          <UilImage />
          <Text>Image</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("video")}>
          <UilVideo />
          <Text>Video</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("font")}>
          <FontTypeIcon />
          <Text>Font</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "color"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Background color">
          {MessageIfVideoOrImageIsActive}

          {BackgroundColorSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "image"}
        blockScrollOnMount={false}
      >
        <DrawerContent pb="3rem" title="Background image">
          {MessageIfVideoIsActive}

          {BackgroundImageSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "video"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="12vh" pb="3rem" title="Background video">
          {BackgroundVideoSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "font"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Font style">
          {InfoMessageOnChangesHere}
          {SelectFontItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
