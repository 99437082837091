import {
  Drawer,
  HStack,
  Image,
  Radio,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";
import { useNode } from "@craftjs/core";
import { cloudfrontURL } from "utils/urlBuilder.js";

export const MusicSettings = () => {
  const { propValue } = useNode((node) => ({
    propValue: node.data.props,
    parent: node.data.parent,
  }));

  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const MusicDisplayOptionsSettingItem = (
    <>
      <SettingsItem
        full={true}
        propKey="url"
        type="text"
        label="Music URL or Embed code"
        subLabel="Embed music or podcast from Spotify, Apple Music, SoundCloud or Mixcloud"
        placeholder="URL or embed code"
      />

      {propValue &&
        propValue.url &&
        propValue.url.includes("music.apple.com") &&
        !propValue.url.startsWith("<iframe") && (
          <>
            <Text fontSize="xs" color="gray.500" py="0.25rem">
              <i className="uil uil-info-circle" /> Please copy and then paste
              the Embed Code from Apple Music:{" "}
              <Image
                pt={"0.25rem"}
                src={ cloudfrontURL("/embedApplemusic.jpg") }
                alt="Embed Apple music or podcast"
              />
            </Text>
          </>
        )}

      {propValue && propValue.url && propValue.url.includes("spotify") && (
        <>
          <Text fontSize="sm" pl="2px" mt="1.5rem" mb="0.5rem">
            Preview style
          </Text>
          <SettingsItem propKey="previewSpotifyType" type="buttonRadio">
            <HStack spacing="1.25rem">
              <Radio value="compact">Compact</Radio>
              <Radio value="large">Card</Radio>
            </HStack>
          </SettingsItem>
        </>
      )}
    </>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Info</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{MusicDisplayOptionsSettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "edit"}>
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="15vh" title="Info" pb="6rem">
          {MusicDisplayOptionsSettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
