import { Box, Text, Tooltip } from "@chakra-ui/react";
import { Element, useNode } from "@craftjs/core";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useLocation, useParams } from "react-router-dom";

import { HeaderContainerSettings } from "./settings";
import { ProfileImage } from "../ProfileImage";
import { VerifiedBadge } from "assets/svgs/verifiedBadge";
import { getAuthToken } from "utils";
import { getIsAccountVerified } from "api/queries";
import jwt_decode from "jwt-decode";
import { useQuery } from "react-query";
import { useTracked } from "hooks/useTracked";

const ImageContainer = styled(Box)<{
  backgroundimageurl: string;
  positiony: string;
  positionx: string;
  shadow: number;
}>`
  position: absolute;
  background-image: ${({ backgroundimageurl }) =>
    `url(${backgroundimageurl})`} !important;

  background-size: cover !important;

  background-position-y: ${({ positiony }) => `${positiony}%`} !important;
  background-position-x: ${({ positionx }) => `${positionx}%`} !important;

  top: 0;
  left: 0;
  object-fit: cover;
  height: 99%;
  width: 100%;
  z-index: -1000;
  border-bottom-left-radius: ${({ imageBorderLeftRadius }) =>
    `${imageBorderLeftRadius}%`} !important;
  border-bottom-right-radius: ${({ imageBorderRightRadius }) =>
    `${imageBorderRightRadius}%`} !important;

  box-shadow: ${({ shadow }) =>
    `${
      shadow === 0 ? "none" : `0px 4px ${shadow}px 2px  rgba(0, 0, 0, 0.13)`
    }`};
`;

export type Container = {
  background: string;
  color?: string;
  shadow: number;
  children: React.ReactNode;
  useBackground: boolean;
  borderBottomLeftRadius: number;
  borderBottomRightRadius?: number;
  imageBorderLeftRadius?: number;
  imageBorderRightRadius?: number;
  isSticky?: boolean;
  hideUsername?: boolean;
  hideProfileImage?: boolean;
  headerStyle?: string;
  positionX?: string;
  positionY?: string;
  backgroundImageUrl?: string;
  useImage?: boolean;
};

const defaultProps = {
  background: "",
  isSticky: false,
  shadow: 0,
  useBackground: true,
  hideUsername: false,
  borderBottomLeftRadius: 2,
  borderBottomRightRadius: 2,
  imageBorderLeftRadius: 4,
  imageBorderRightRadius: 4,
  color: "#fff",
  headerStyle: "vertical",
  hideProfileImage: false,
  backgroundImageUrl: "",
  positionX: "auto",
  positionY: "auto",
  useImage: false,
};

const StyledBox = styled(Box)`
  ${({ issticky }) =>
    issticky &&
    css`
      position: sticky !important;
      top: 0 !important;
      z-index: 2 !important;
    `};
`;

const StyledBioContainer = styled(Box)<{ isrow?: boolean }>`
  && div {
    width: ${({ isrow }) => (isrow ? "220px" : "auto")} !important;
    ${({ isrow }) =>
      isrow &&
      css`
        width: 212px !important;
        padding: 4px 2px !important;t;
        text-align: left !important;
      `};
  }
`;

const StyledImageContainer = styled(Box)`
  > div {
    ${({ isrow }) =>
      isrow &&
      css`
        margin: 16px 0 !important;
      `};
  }
`;

export const ContainerHeader = (props: Partial<Container>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { username } = useParams();
  const [displayUsername, setDisplayUsername] = useState(username);

  const { data } = useQuery(
    ["getIsAccountVerified", displayUsername],
    () => getIsAccountVerified(displayUsername ?? ""),
    {
      enabled: Boolean(displayUsername),
    }
  );

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useTracked();

  const { nodeId } = useNode((node) => ({
    nodeId: node.id,
  }));

  useEffect(() => {
    const token = getAuthToken();

    if (token) {
      const decoded = jwt_decode(token) as any;
      setDisplayUsername(isEditorView ? decoded.username : username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken()]);

  useEffect(() => {
    setState((prev) => ({ ...prev, headerContainerId: nodeId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);

  const {
    background,
    shadow,
    useBackground,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    color,
    isSticky,
    hideUsername,
    headerStyle,
    hideProfileImage,
    useImage,
    backgroundImageUrl,
    positionX,
    positionY,
    imageBorderLeftRadius,
    imageBorderRightRadius,
    children,
  } = props;

  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  return (
    <StyledBox
      className="header_wrapper"
      position="relative"
      issticky={isSticky ? 1 : 0}
      mb={headerStyle === "middle" && !hideProfileImage ? "120px" : "0"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={headerStyle === "middle" ? "80px" : "auto"}
      flexDirection={headerStyle === "row" ? "row" : "column"}
      ref={connect}
      style={{
        width: "100%",

        maxHeight: "auto",
        background: useImage || !useBackground ? "transparent" : background,

        boxShadow:
          shadow === 0 || useImage
            ? "none"
            : `0px 4px ${shadow}px 2px  rgba(0, 0, 0, 0.13)`,
        borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${borderBottomRightRadius}px`,
        color: "#fff",
        flex: 1,
      }}
      color={`${color} !important`}
    >
      {hideProfileImage ? null : (
        <StyledImageContainer
          isrow={headerStyle === "row" ? 1 : 0}
          mt={headerStyle === "middle" && "132px"}
        >
          <Element
            canvas
            is={ProfileImage}
            username={username}
            id="profileImage"
          ></Element>
        </StyledImageContainer>
      )}

      <Box
        zIndex="2"
        ml={headerStyle === "row" ? "10px" : "0"}
        textAlign={headerStyle === "row" ? "left" : "center"}
        width={headerStyle === "row" ? "auto" : "100%"}
      >
        <Box
          mb={headerStyle === "row" ? "0px" : "6px"}
          mt={headerStyle === "row" ? "8px" : "0px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {hideUsername ? null : (
            <Text
              color={color}
              fontSize="1rem"
              fontWeight="bold"
              cursor="default"
            >
              @{displayUsername}
            </Text>
          )}

          {data?.accountVerified ? (
            <Tooltip label="Account verified by Alfalink" fontSize="xs">
              <i>
                <VerifiedBadge />
              </i>
            </Tooltip>
          ) : null}
        </Box>

        {useImage ? (
          <ImageContainer
            backgroundimageurl={backgroundImageUrl}
            positionx={positionX}
            positiony={positionY}
            imageBorderLeftRadius={imageBorderLeftRadius}
            imageBorderRightRadius={imageBorderRightRadius}
            shadow={shadow}
          />
        ) : null}

        <StyledBioContainer isrow={headerStyle === "row" ? 1 : 0}>
          {children}
        </StyledBioContainer>
      </Box>
    </StyledBox>
  );
};

ContainerHeader.craft = {
  displayName: "Header",
  name: "Header",
  props: defaultProps,
  related: {
    settings: HeaderContainerSettings,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => false,
  },
};

/*
useBackground was used to activate background color for header. Don't remove
*/
