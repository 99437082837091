import { Box, Button, Text } from "@chakra-ui/react";
import { formatReferrers, secondsToHms } from "utils";

import DataTable from "react-data-table-component";
import UilArrowDown from "@iconscout/react-unicons/icons/uil-arrow-down";
import { useMemo } from "react";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    grow: 3,

    cell: (row) => (
      <Text display="flex" alignItems="center" textAlign="left">
        {row.name === "" || row.name === null ? "Direct" : row.name}
      </Text> // handle referrer name
    ),
  },
  {
    name: "Views",
    selector: (row) => row.pageView,
    sortable: true,
  },
  {
    name: "Clicks",
    selector: (row) => row.linkClick,
    sortable: true,
  },
  {
    name: "CTR",
    selector: (row) => row.linkClick,
    sortable: true,
    cell: (row) => (
      <Text>
        {row?.linkClick
          ? ((row.linkClick / row.pageView) * 100).toFixed(1)
          : 0.0}
        %
      </Text>
    ),
  },
  {
    name: "Avg. TTC",
    grow: 3,
    selector: (row) => row.avgTtc,
    sortable: true,
    cell: (row) => <Text>{secondsToHms(parseInt(row.avgTtc))}</Text>,
  },
];

const columnsLink = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    grow: 3,
    cell: (row) => (
      <Text display="flex" alignItems="center" textAlign="center">
        {row.name === "" || row.name === null ? "Direct" : row.name}
      </Text>
    ),
  },

  {
    name: "Clicks",
    selector: (row) => row.linkClick,
    sortable: true,
  },

  {
    name: "Avg. TTC",
    grow: 3,
    selector: (row) => row.avgTtc,
    sortable: true,
    cell: (row) => <Text>{secondsToHms(parseInt(row.avgTtc))}</Text>,
  },
];

const customStyles = {
  table: {
    style: {
      padding: "0.5px",
      border: `1px solid #f1f1f1`,
      borderRadius: "6px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
  tableWrapper: {
    style: {
      display: "table",
    },
  },
  rows: {
    style: {
      minHeight: "50px",
      border: "none",
      borderBottomWidth: "0 !important",
    },
    denseStyle: {
      minHeight: "42px",
    },
    stripedStyle: {
      backgroundColor: "#f7f7f7",
    },
  },

  headCells: {
    style: {
      minHeight: "48px",
      fontSize: "14px",
      color: "#111",
    },
  },

  pagination: {
    style: {
      fontSize: "11px",
      border: `1px solid #f1f1f1`,
      color: "#777777",
      borderRadius: "6px",
      marginTop: "0px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
    },
  },
};

const convertArrayOfObjectsToCSV = (array) => {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

const downloadCSV = (array) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "alfalink.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

const Export = ({ onExport }) => (
  <Button size="sm" onClick={(e: any) => onExport(e.target.value)}>
    Export CSV
  </Button>
);

export const TopReferrerTable = ({
  data,
  isLink,
}: {
  data?: any;
  isLink?: boolean;
}) => {
  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    [data]
  );

  if (data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p="2rem">
        <Text>No data to display</Text>
      </Box>
    );
  }

  const formattedReferrers = formatReferrers(data);

  return (
    <DataTable
      customStyles={customStyles}
      striped
      // @ts-ignore
      columns={isLink ? columnsLink : columns}
      data={formattedReferrers}
      defaultSortFieldId={0}
      sortIcon={<UilArrowDown />}
      actions={actionsMemo}
      pagination={formattedReferrers.length > 12}
      paginationPerPage={12}
    />
  );
};
