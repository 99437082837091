import { UserComponent, useNode } from "@craftjs/core";
// import { UserComponent, useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from "react";

// import ContentEditable from "react-contenteditable";
import { TextSettings } from "./settings";
// import TextareaAutosize from "react-textarea-autosize";
// import { useMediaQuery } from "@chakra-ui/react";
// import useAdData from "hooks/useAdData";
import {
  Spinner,
} from "@chakra-ui/react";

import lz from "lzutf8";
import api from "api";
import axios from "axios";

import styled, { css } from "styled-components";
// import { useUserContext } from "HoCs/UserProvider";

import {
  BioContainer,
  BuyMeACoffee,
  ContainerHeader,
  Divider,
  KoFi,
  Label,
  Link,
  Links,
  MainContainer,
  Music,
  ProfileImage,
  SocialLinks,
  Support,
  Text,
  Twitter,
  Video,
  Image,
  BrandAdContainer,
  MintMobile,
} from "components/Selectors";
import { Box } from "@chakra-ui/react";
import { Editor, Element, Frame } from "@craftjs/core";
import { useParams } from "react-router-dom";

const StyledBox = styled(Box)`
  margin: 0px;
  padding: 0px;
  ${({ issticky }) =>
    issticky &&
    css`
      position: sticky !important;
      top: 0 !important;
      z-index: 2 !important;
    `};
`;

export type BrandAdTypes = {
  brandAdId?: string,
  fontSize: string;
  text1?: string;
  text2?: string;
  text3?: string;
  buttonText?: string;
  title?: string;
  description?: string;
};

export const BrandAd: UserComponent = (props: Partial<BrandAdTypes>) => {
  const {
    brandAdId,
    // fontSize,
    text1,
    text2,
    text3,
    buttonText,
    // title,
    // description,
  } = props;

  const { username } = useParams();
  // const { userData } = useUserContext();

  const {
    connectors: { connect },
    actions: {setCustom},
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // const { enabled, actions: {setProp}, query } = useEditor((state) => ({
  //   enabled: state.options.enabled,
  // }));
  // const { actions: {setProp} } = useEditor((state) => ({
  //   enabled: state.options.enabled,
  // }));

  // const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  // let brandAdId = '';
  // if (text && !brandAdId) {
  //   brandAdId = text;
  // }

  const [frameData, setFrameData] = useState<any>(undefined);
  const [frameDataLoaded, setFrameDataLoaded] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  // const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);

  // const [frameDataExists, setFrameDataExists] = useState(false);

  const makeTagReplaces = (inObject, link)=>{
    for (let key in inObject) {
      if (inObject[key].props) {
        if (text1) {
          inObject[key].props.text1 = ''+text1;
        }
        if (text2) {
          inObject[key].props.text2 = ''+text2;
        }
        if (text3) {
          inObject[key].props.text3 = ''+text3;
        }
        if (buttonText) {
          inObject[key].props.buttonText = ''+buttonText;
        }
      }

      if (username) {
        for (let propKey in inObject[key].props) {
          if (inObject[key].props[propKey]) {
            // eslint-disable-next-line no-useless-concat
            if (inObject[key].props[propKey].indexOf && inObject[key].props[propKey].indexOf('${'+'username}') !== -1) {
            // eslint-disable-next-line no-useless-concat
              inObject[key].props[propKey] = inObject[key].props[propKey].split('${'+'username}').join(username);
            }
            // eslint-disable-next-line no-useless-concat
            if (inObject[key].props[propKey].indexOf && inObject[key].props[propKey].indexOf('${'+'link}') !== -1) {
            // eslint-disable-next-line no-useless-concat
              inObject[key].props[propKey] = inObject[key].props[propKey].split('${'+'link}').join(link);
            }
          }
        }
      }
    }

  };

  useEffect(()=>{
    if (!loading) {
      setLoading(true);
      setFrameData(null);
    }

    if (frameDataLoaded) {
      const obj = JSON.parse(frameDataLoaded);
      makeTagReplaces(obj, '#');
      setTimeout(()=>{
        console.log('updateinngng', text1, obj);
        setFrameData(obj);
        setLoading(false);
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text1, text2, text3, buttonText]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await new Promise((res)=>setTimeout(res, 200));
      try {
        const { data } = (await api.post(
          `api/private/ad/frame-data-with-url/${brandAdId}`,
          { username: username },
        )) as any;

        // const { frameData, title, description } = data;
        const { frameData, url } = data;

        // if (title) {
        //   setProp((prop) => (prop.title = title));
        // }
        // if (description) {
        //   setProp((prop) => (prop.description = description));
        // }

        if (frameData) {
          // setFrameDataExists(true);
          const updatedUint8array = lz.decodeBase64(frameData);

          const decompressed = lz.decompress(updatedUint8array);
          const updatedJson = JSON.parse(decompressed);

          /// filter out children DisplayAd
          for (let key in updatedJson) {
            console.log('component in BrandAd', updatedJson[key].displayName, updatedJson[key].type, updatedJson[key]);

            if (updatedJson[key] && updatedJson[key].displayName && updatedJson[key].displayName === 'Main Container') {
              updatedJson[key].displayName = 'BrandAd Container';
              updatedJson[key].type.resolvedName = 'BrandAdContainer';
            }
            // if (updatedJson[key] && updatedJson[key].displayName && updatedJson[key].displayName === 'Image') {
            //   updatedJson[key].displayName = 'BrandAd Container';
            //   updatedJson[key].type.resolvedName = 'MintMobile';
            // }
            if (updatedJson[key] && updatedJson[key].displayName && updatedJson[key].displayName === 'BrandAd') {
              delete updatedJson[key];

              for (let anotherKey in updatedJson) {
                const item = updatedJson[anotherKey];
                if (item.nodes && item.nodes.indexOf(key) !== -1) {
                  item.nodes = item.nodes.filter(element => element !== key);
                }
              }
            }

            // do tags replaces

            // if (doExtraReplacesIn.indexOf(updatedJson[key].displayName) !== -1) {
            //   // need to overwrite extra values
            //   if (text1) {
            //     updatedJson[key].props.text1 = text1;
            //   }
            // }

            // if (!enabled && username && updatedJson[key].props) {
            //   if (updatedJson[key].props.linkUrl) {
            //     // eslint-disable-next-line no-template-curly-in-string
            //     updatedJson[key].props.linkUrl = updatedJson[key].props.linkUrl.split('${username}').join(username);
            //   }
            //   if (updatedJson[key].props.text) {
            //     // eslint-disable-next-line no-template-curly-in-string
            //     updatedJson[key].props.text = updatedJson[key].props.text.split('${username}').join(username);
            //   }
            // }
          }

          makeTagReplaces(updatedJson, url);

          setCustom(props => {
            props.innerData = updatedJson;
          }, 500);
          setFrameDataLoaded(JSON.stringify(updatedJson));
          console.error('updatedJson in BrandAd', updatedJson);

          setLoading(false);

          return setFrameData(updatedJson);
        }

        setFrameData("");
        // setFrameDataExists(false);
      } catch (error: any) {
        if (error && error.response && error.response.status === 404) {
          setLoading(false);

          // return setIsUsernameAvailable(true);
        }

        if (error && error.response && error.response.status === 429) {
          setLoading(false);

          return setError(429);
        }

        setError(error);
        console.error("error ", error);

        setFrameData("");
        if (axios.isCancel(error)) {
          console.warn("Request canceled", error.message);
        }
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandAdId]);


  // const handleOnTextChange = (e: any) => {
  //   setProp((prop) => (prop.text = e.target.value));
  // };

  if (!frameData || loading || error) {
    return (<>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection="column"
        minHeight="100px"
      >
        <Spinner color="gray.500" />
      </Box>
    </>);
  }

    return (<StyledBox
        style={{
          width: "100%",
        }}
        >

      <Box
        ref={(ref) => connect(ref)}
        style={{
          width: "100%",
        }}
        >
        <Editor
          resolver={{
            MainContainer,
            BrandAdContainer,
            Text,
            ContainerHeader,
            ProfileImage,
            BioContainer,
            Label,
            Links,
            Link,
            KoFi,
            Twitter,
            BuyMeACoffee,
            Music,
            Video,
            SocialLinks,
            Divider,
            Support,
            Image,
            BrandAd,
            MintMobile,
          }}
          enabled={false}
        >
          <Frame data={frameData}>
            <Element canvas is={MainContainer}>
              <Element canvas is={ContainerHeader}>
                <BioContainer />
              </Element>

              <Element canvas is={Label}></Element>

              <Element canvas is={Links}>
                <Element canvas is={Link}>
                  <Link />
                </Element>
              </Element>

              <Element canvas is={SocialLinks}></Element>
            </Element>
          </Frame>
        </Editor>
      </Box>


    </StyledBox>);


  // return useTextArea ? (
  //   <>
  //     <TextareaAutosize
  //       maxRows={5}
  //       maxLength={120}
  //       ref={connect}
  //       readOnly={!enabled || isSmallerThan600}
  //       value={text || ""}
  //       onChange={handleOnTextChange}
  //       style={{
  //         width: "100%",
  //         background: background ? background : "transparent",
  //         wordBreak: "break-word",
  //         color: color ?? "red",
  //         fontSize: `${fontSize}px`,
  //         textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
  //         fontFamily: fontFamily,
  //         border: "none",
  //         height: 100,
  //         maxLines: 4,
  //         textAlign: textAlign as any,
  //         borderRadius: "3px",
  //         fontWeight: fontWeight as any,
  //         padding: padding ? `${padding}px` : "0px",
  //         cursor: enabled ? "text" : "default",
  //       }}
  //     />
  //   </>
  // ) : (
  //   <ContentEditable
  //     innerRef={connect}
  //     html={text ?? ""}
  //     disabled={!enabled || isSmallerThan600}
  //     defaultValue={text || ""}
  //     onChange={handleOnTextChange}
  //     tagName={background ? "span" : "div"}
  //     style={{
  //       background: background ?? "transparent",
  //       width: "100%",
  //       wordBreak: "break-word",
  //       whiteSpace: "normal",
  //       borderRadius: "3px",
  //       color: color ?? "red",
  //       fontSize: `${fontSize}px`,
  //       textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
  //       fontWeight,
  //       textAlign,
  //       fontFamily: fontFamily,
  //       paddingTop: "1px",
  //       padding: padding ? `${padding}px` : "0px",
  //     }}
  //   />
  // );
};

BrandAd.craft = {
  displayName: "BrandAd",
  props: {
    fontSize: "20",
    textAlign: "center",
    fontWeight: "",
    fontFamily: "",
    color: "",
    background: "transparent",
    shadow: 0,
    text: "BrandAd",
  },
  related: {
    settings: TextSettings,
  },
};
