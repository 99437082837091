import {
  DrawerContent as CDrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { Divider, Heading } from "@chakra-ui/react";

interface DrawerTypes {
  children: React.ReactNode;
  maxHeight?: string;
  py?: string;
  pt?: string;
  pb?: string;
  title?: string;
}
export const DrawerContent = ({
  children,
  maxHeight = "39vh",
  title,
  py,
  pb = "3rem",
  pt = "0.75rem",
}: DrawerTypes) => {
  return (
    <>
      <DrawerOverlay
        position="relative"
        maxHeight={maxHeight}
        backgroundColor="gray.100"
      />

      <CDrawerContent
        boxShadow="0 2px 4px 0px #111"
        borderTopLeftRadius="0.5rem"
        borderTopRightRadius="0.5rem"
      >
        <DrawerCloseButton
          size="sm"
          backgroundColor="gray.300"
          zIndex="112"
          borderRadius="3rem"
        />

        <DrawerBody py={py} pb={pb} maxHeight="39vh" pt={pt}>
          {title ? (
            <>
              <Heading as="h6" size="xs" mb="0.5rem">
                {title}
              </Heading>
              <Divider mt="4px" mb="1rem" borderColor="#111" />
            </>
          ) : null}
          {children}
        </DrawerBody>
      </CDrawerContent>
    </>
  );
};
