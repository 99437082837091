import {
  Drawer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  // HStack,
  // Radio,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";

export const Settings = () => {
  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const SettingItem = (
    <>
      <SettingsItem
        full={true}
        propKey="text1"
        type="textOnBlur"
        label="Text Line 1"
        placeholder="Unlimited Premium Wireless"
      />
      <SettingsItem
        full={true}
        propKey="text2"
        type="textOnBlur"
        label="Text Line 2"
        // eslint-disable-next-line no-useless-concat
        placeholder={"Exclusive offer for ${"+"username} followers"}
      />
      <SettingsItem
        full={true}
        propKey="text3"
        type="textOnBlur"
        label="Text Line 3"
        placeholder="Nationwide Coverage For Less"
      />
      <SettingsItem
        full={true}
        propKey="url"
        type="textOnBlur"
        label="Link"
        placeholder="https://google.com"
      />
      <SettingsItem
        full={true}
        propKey="buttonText"
        type="textOnBlur"
        label="buttonText"
        placeholder="Start Saving"
      />

{/*
      <SettingsItem
        full
        propKey="defaultOpen"
        type="buttonRadio"
        label="defaultOpen"
      >
        <HStack spacing="2rem">
          <Radio value="1">Yes</Radio>
          <Radio value="0">No</Radio>
        </HStack>
      </SettingsItem>*/}
    </>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Info</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{SettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "edit"}>
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Info" pb="4rem">
          {SettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
