import {
  Drawer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";

export const TwitterSettings = () => {
  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const TwitterSettingItem = (
    <Stack spacing="2rem">
      <SettingsItem
        full={true}
        propKey="url"
        type="textOnBlur"
        isUrlInput
        label="Tweet URL or Twitter username"
        subLabel="Embed a tweet or Twitter profile by adding a Twitter username or Tweet URL."
        placeholder="Tweet URL or Twitter username"
      />

      <SettingsItem
        full
        propKey="height"
        type="slider"
        sliderMin={200}
        sliderMax={1000}
        label="Panel height"
      />
    </Stack>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Info</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{TwitterSettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "edit"}>
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Info" pb="4rem">
          {TwitterSettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
