import {
  Button as AButton,
  Text as CText,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";
import styled, { css, keyframes } from "styled-components";
import { useLocation, useParams } from "react-router-dom";

import Cookies from "js-cookie";
import { EVENT_LINK_CLICK } from "utils/events";
import { HAS_ALREADY_REGISTERED_TTC } from "utils/constants";
import { LinkSettings } from "./settings";
import { Text } from "../Text";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilEyeSlash from "@iconscout/react-unicons/icons/uil-eye-slash";
import UilLock from "@iconscout/react-unicons/icons/uil-keyhole-circle";
import { registerEvent } from "api/queries";

type ButtonProps = {
  background?: string;
  color?: string;
  buttonStyle?: string;
  fontFamily?: string;
  fontSize?: string;
  text?: string;
  textComponent?: any;
  buttonType?: string;
  linkUrl?: string;
  borderRadius?: string;
  icon?: any;
  emoji?: any;
  alignIcon?: string;
  alignEmoji?: string;
  showIcon?: boolean;
  showEmoji?: boolean;
  hideLink?: boolean;
  isSensitiveContent?: boolean;
  isPasswordProtected?: boolean;
  useImage?: boolean;
  alignImage?: string;
  imageUrl?: string;
  fontWeight?: string;
  textAlign?: string;
  py?: number;
  animationType?: string;
  previewSpotify?: boolean;
  previewSpotifyType?: string;
  previewVideo?: boolean;
  autoplayVideo?: boolean;
  muteVideo?: boolean;
  sensitiveContentMessage?: string;
  linkAccessPassword?: string | undefined;
  marginTop?: string;
  marginBottom?: string;
  owncolor?: string;
  buttonbackground?: string;
  emojiSize?: number;
};

const bounce = keyframes`
 0% {
    top: 0em;
  }
  40% {
    top: 0em;
  }
  43% {
    top: -0.9em;
  }
  46% {
    top: 0em;
  }
  48% {
    top: -0.4em;
  }
  50% {
    top: 0em;
  }
  100% {
    top: 0em;
  }
`;

const pulse = (color: any) => {
  const alphaTo0 = color
    ? color.replace(")", ", 0)").replace("rgb", "rgba")
    : "rgb(255, 255, 255)".replace(")", ", 0)").replace("rgb", "rgba");
  const alphaTo7 = color
    ? color.replace(")", ", 0.7)").replace("rgb", "rgba")
    : "rgb(255, 255, 255)".replace(")", ", 0.7)").replace("rgb", "rgba");

  return keyframes`
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 ${alphaTo7};

  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px ${alphaTo0};
  }
  
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 ${alphaTo0};

  }
  `;
};

const shake = keyframes`
 % {
    transform: translateX(3px) rotate(1deg);
}
2.5% {
    transform: translateX(-3px) rotate(-1deg);
}
5% {
    transform: translateX(3px) rotate(1deg);
}
7.5% {
  transform: translateX(-3px) rotate(-1deg);
}
10% {
    transform: translateX(2px) rotate(1deg);
}
12.5% {
    transform: translateX(-2px) rotate(-1deg);
}
15% {
    transform: translateX(2px) rotate(1deg);
}
17.5% {
    transform: translateX(-2px) rotate(-1deg);
}
20% {
    transform: translateX(1px) rotate(1deg);
}
22.5% {
    transform: translateX(-1px) rotate(-1deg);
}
25% {
    transform: translateX(0) rotate(0);
}
`;

const handleAnimationType = (animation: string, color: string) => {
  switch (animation) {
    case "none":
      return css`
        animation: none;
      `;
    case "shaky":
      return css`
        animation: ${shake} 3s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97)
          1.5s;
      `;
    case "bounce":
      return css`
        animation: ${bounce} 3s infinite linear 1s;
      `;

    case "pulse":
      return css`
        animation: ${pulse(color)} 3s infinite;
      `;

    default:
      return "none";
  }
};

const handleButtonStyle = (style: string, background: string) => {
  switch (style) {
    case "solid":
      return css`
        background: ${background} !important;
        border: ${background && background.startsWith("linear-gradient")
          ? "none"
          : "2px solid transparent"} !important;
      `;
    case "outline":
      return css`
        background: transparent !important;
        border: 2px solid ${background} !important;
      `;
    case "ghost":
      return css`
        background: transparent !important;
        border: 2px solid transparent !important;
      `;
    case "shadow":
      return css`
        background: ${background} !important;
        border: 2px solid transparent !important;
        box-shadow: 6px 8px 0
          ${background.replace(")", ", 0.5)").replace("rgb", "rgba")} !important;
      `;

    default:
      return css``;
  }
};

const StyledButton = styled(AButton)<ButtonProps>`
  width: 100% !important;
  height: unset !important;

  border-radius: ${({ borderRadius }) => borderRadius}px !important;

  font-family: ${({ ownfontfamily }) => `${ownfontfamily}`};

  box-shadow: ${({ shadow }) =>
    shadow === 0
      ? "none"
      : `0px 4px ${shadow}px 2px  rgba(0, 0, 0, 0.178)`} !important;

  color: ${({ owncolor }) => `${owncolor}`} !important;

  font-weight: ${({ ownfontweight }) => `${ownfontweight}`};

  span,
  div {
    color: ${({ owncolor }) => `${owncolor}`} !important;
    font-family: ${({ ownfontfamily }) => `${ownfontfamily}`} !important;
    font-weight: ${({ ownfontweight }) => `${ownfontweight}`};
  }

  ${({ useImage, alignImage, imageUrl }) =>
    useImage &&
    `
      background-image: url( ${imageUrl || ""}) !important;
      background-attachment: scroll !important;
      background-size: 50px 50px !important;
      background-position: ${
        alignImage === "left" ? "1%" : "98% 50%"
      } !important;

      background-repeat: no-repeat !important;
      `}

  ${({ animationtype, buttonbackground }) =>
    handleAnimationType(animationtype, buttonbackground)};

  ${({ buttonstyle, buttonbackground }) =>
    handleButtonStyle(buttonstyle, buttonbackground)};

  ${({ enabled }) =>
    !enabled &&
    css`
      -webkit-backface-visibility: hidden !important;
      cursor: pointer !important;
      transition: all 0.2s ease-in-out;

      :hover {
        transform: scale(1.022) !important;
        filter: brightness(85%) !important;
      }
    `};

  :disabled {
    cursor: pointer;
  }
`;

export const Link: UserComponent<ButtonProps> = (props: any) => {
  const { username } = useParams();

  const [userInputPassword, setUserInputPassword] = useState(null);
  const [pageLoadedDate, setPageLoadedDate] = useState(undefined);

  const {
    isOpen: isSensitiveContentModalOpen,
    onOpen: onSensitiveContentOpen,
    onClose: onSensitiveContentClose,
  } = useDisclosure();
  const {
    isOpen: isVIPopen,
    onOpen: onPPopen,
    onClose: onVIPclose,
  } = useDisclosure();

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const {
    nodeId,
    connectors: { connect, drag },
  } = useNode((node) => ({
    nodeId: node.id,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    text,
    textComponent,
    color,
    buttonStyle,
    background,
    linkUrl,
    borderRadius,
    shadow,
    showEmoji,
    emoji,
    alignEmoji,
    fontFamily,
    hideLink,
    fontSize,
    py,
    textAlign,
    fontWeight,
    animationType,
    isSensitiveContent,
    isPasswordProtected,
    sensitiveContentMessage,
    linkAccessPassword,
    marginTop,
    marginBottom,
    emojiSize,
  } = props;

  useEffect(() => {
    if (username) {
      setPageLoadedDate(new Date());
    }
  }, [username]);

  useEffect(() => {
    const onbeforeunloadFn = () => {
      Cookies.remove(HAS_ALREADY_REGISTERED_TTC);
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
  }, []);

  const goToLink = async () => {
    if (!enabled) {
      const url = linkUrl.match(/^https?:/) ? linkUrl : "//" + linkUrl;

      window.open(url, "_blank");

      if (username) {
        try {
          await handleRegisterLinkClickEvent();
        } catch (error: any) {
          console.error("Error ", error);
        }
      }
      onVIPclose();
      return onSensitiveContentClose();
    }

    return () => {};
  };

  const handleOnClick = () => {
    if (!enabled && isPasswordProtected) {
      return onPPopen();
    }

    if (!enabled && isSensitiveContent) {
      return onSensitiveContentOpen();
    }

    goToLink();
  };

  const handlePasswordProtectedLinkRedirect = () => {
    if (!enabled && linkAccessPassword === userInputPassword) {
      return isSensitiveContent ? onSensitiveContentOpen() : goToLink();
    }

    alert("Wrong password. Ask profile owner for link password.");
  };

  const handleRegisterLinkClickEvent = async () => {
    const cookieHasAlreadyRegisteredLinkTTC = Cookies.get(
      HAS_ALREADY_REGISTERED_TTC
    );

    let secondsTimeToClick = 0;
    if (
      !cookieHasAlreadyRegisteredLinkTTC ||
      cookieHasAlreadyRegisteredLinkTTC === "false"
    ) {
      let clickDate = new Date();

      secondsTimeToClick =
        (clickDate.getTime() - pageLoadedDate.getTime()) / 1000;
    }

    try {
      await registerEvent({
        username,
        eventType: EVENT_LINK_CLICK,
        linkInfo: {
          linkId: nodeId ?? "",
          name: text,
          linkUrl: linkUrl,
        },
        timeToClick:
          cookieHasAlreadyRegisteredLinkTTC === "true" ? 0 : secondsTimeToClick,
      });

      Cookies.set(HAS_ALREADY_REGISTERED_TTC, "true"); // session cookie
    } catch (error) {
      console.log("Err event ", error);
    }
  };

  const emojiItem = emoji && showEmoji && (
    <span style={{ fontSize: emojiSize }}>{emoji}</span>
  );

  const isLinkUrlEmpty = linkUrl === "";

  return (!isEditorView && !hideLink && !isLinkUrlEmpty) ||
    (isEditorView && (enabled || !hideLink)) ? (
    <>
      <StyledButton
        className="link_component_wrapper"
        enabled={enabled ? 1 : 0}
        leftIcon={
          showEmoji && (alignEmoji === "left" || alignEmoji === "both")
            ? emojiItem
            : null
        }
        rightIcon={
          showEmoji && (alignEmoji === "right" || alignEmoji === "both")
            ? emojiItem
            : null
        }
        borderRadius={borderRadius}
        buttonstyle={buttonStyle}
        ownfontfamily={fontFamily}
        ownfontweight={fontWeight}
        animationtype={animationType}
        onClick={handleOnClick}
        _hover={{ bg: `${background} !important` }}
        buttonbackground={background}
        owncolor={color}
        shadow={shadow}
        mt={`${marginTop}px !important`}
        mb={`${marginBottom}px !important`}
        //py={py}

        style={{
          paddingTop: `${py}px`,
          paddingBottom: `${py}px`,
        }}
        ref={(ref) => connect(drag(ref))}
        cursor={enabled ? "text" : "default"}
      >
        <Text
          {...textComponent}
          text={text}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textAlign={textAlign}
        />

        {isLinkUrlEmpty && (
          <Tooltip label="Link URL not set" aria-label="Link URL is not set">
            <i
              className="uil uil-exclamation-triangle"
              style={{
                color: "#f74949",
              }}
            />
          </Tooltip>
        )}
        {hideLink && (
          <Tooltip
            label="Link is hidden"
            aria-label="Link is hidden"
            zIndex={1002}
          >
            <i
              className="uil uil-eye-slash"
              style={{
                color: "#f74949",
                marginLeft: "0.25rem",
              }}
            />
          </Tooltip>
        )}
      </StyledButton>

      <Modal
        onClose={onSensitiveContentClose}
        isOpen={isSensitiveContentModalOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p="0.5rem" bg="#16151a" color="#fbfbfe">
          <ModalCloseButton />
          <ModalBody
            pt="2.5rem"
            textAlign="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            fontSize="0.825rem"
          >
            <UilEyeSlash size="46px" color="#ececec" />

            <Heading as="h5" size="sm" my="0.25rem">
              Sensitive content
            </Heading>
            <br />

            <CText color="gray.300" fontWeight="300">
              {sensitiveContentMessage}
            </CText>
          </ModalBody>

          <ModalFooter>
            <AButton
              variant="outline"
              colorScheme="purple"
              fontWeight="300"
              isFullWidth
              onClick={goToLink}
            >
              Continue to link <UilArrowRight size="20px" />
            </AButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal onClose={onVIPclose} isOpen={isVIPopen} isCentered size="lg">
        <ModalOverlay />
        <ModalContent p="0.5rem" bg="#16151a" color="#fbfbfe" fontWeight="300">
          <ModalCloseButton />
          <ModalBody
            pt="2.5rem"
            textAlign="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <UilLock size="48px" color="#e0e0e0" />

            <Heading as="h4" size="md" my="0.5rem">
              This link is password protected
            </Heading>

            <CText color="gray.400" fontSize="xs">
              Please enter the link password to continue
            </CText>

            <br />

            <Input
              size="lg"
              placeholder="Enter link password.."
              onChange={(e) => setUserInputPassword(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <AButton
              variant="outline"
              fontWeight="300"
              colorScheme="purple"
              isFullWidth
              onClick={handlePasswordProtectedLinkRedirect}
            >
              Continue to link <UilArrowRight size="20px" />
            </AButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};

Link.craft = {
  displayName: "Link",

  props: {
    background: "",
    color: "",
    buttonStyle: "",
    text: "Example Link",
    linkUrl: "",
    alignIcon: "left",
    alignEmoji: "left",
    showIcon: false,
    showEmoji: false,
    animationType: "none",
    hideLink: false,
    isSensitiveContent: false,
    isPasswordProtected: false,
    linkAccessPassword: undefined,
    sensitiveContentMessage:
      "This link contains sensitive content, which some people may find offensive or disturbing",
    useImage: false,
    alignImage: "left",
    imageUrl: "",
    fontFamily: "",
    fontSize: "",
    emojiSize: 20,
    py: 16,
    marginTop: "",
    marginBottom: "",
    fontWeight: "",
    textAlign: "",
    previewSpotify: false,
    previewSpotifyType: "compact",
    previewVideo: false,
    autoplayVideo: true,
    muteVideo: true,
    textComponent: {
      ...Text.craft.props,
      textAlign: "center",
    },
  },

  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: LinkSettings,
  },
};
