import { Box, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";

import { MusicSettings } from "./settings";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const StyledReactPlayer = styled(ReactPlayer)<{ iseditorview?: boolean }>`
  z-index: 0 !important;
  max-width: ${({ iseditorview, enabled }) =>
    iseditorview && enabled ? "-webkit-fill-available" : "unset"} !important;

  max-height: ${({ iseditorview, enabled }) =>
    iseditorview && enabled ? "280px" : "unset"} !important;

  margin-top: 4px;

  & iframe {
    z-index: 0 !important;

    border-radius: 20px;
  }
`;
type ButtonProps = {
  url?: any;
  previewSpotifyType?: string;
};

export const Music: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { previewSpotifyType, url } = props;

  const getSpotifyEmbedUrl = (url) => {
    const suffix = url.split(".com").pop();
    const res = `https://open.spotify.com/embed${suffix}`;

    return res;
  };

  const renderComponent = () => {
    const isSpotifyLink = url.includes("spotify");
    const isEmbedCode = url.startsWith("<iframe");
    const isSoundcloudLink =
      url.includes("soundcloud") || url.includes("mixcloud");

    if (isEmbedCode) {
      return (
        <div
          style={{
            pointerEvents: enabled ? "none" : "auto",
          }}
          dangerouslySetInnerHTML={{
            __html: url,
          }}
        ></div>
      );
    }

    if (isSpotifyLink) {
      return (
        <iframe
          title="spotify"
          style={{ borderRadius: "8px" }}
          src={getSpotifyEmbedUrl(url)}
          height={previewSpotifyType === "compact" ? "80" : "300"}
          width="100%"
          frameBorder="0"
          allowTransparency
          allow="encrypted-media"
        />
      );
    }

    if (isSoundcloudLink) {
      return (
        <StyledReactPlayer
          url={url}
          controls
          fallback={<Spinner />}
          height={isSmallerThan600 ? "216px" : "304px"}
          width={isSmallerThan600 ? "auto" : "640px"}
          iseditorview={isEditorView ? 1 : 0}
          enabled={enabled ? 1 : 0}
        />
      );
    }

    return enabled ? (
      <Text
        fontSize="xs"
        style={{
          color: "#f74949",
          cursor: "pointer",
          textShadow: "0px 0px 10px #1a1a1a",
        }}
        textAlign="center"
      >
        <i className="uil uil-exclamation-triangle" /> Invalid music URL or
        embed code.
      </Text>
    ) : null;
  };

  const renderHelpLabel = () => {
    if (enabled && isEditorView && !url) {
      return (
        <Text
          p="1rem"
          my="1rem"
          fontSize="xs"
          textAlign="center"
          textShadow="0 1px 5px black"
          style={{
            color: "#eee",
            cursor: "pointer",
          }}
        >
          <i
            className="uil uil-exclamation-triangle"
            style={{
              color: "#f74949",
            }}
          />{" "}
          Music URL missing
        </Text>
      );
    }

    if (enabled && isEditorView && url) {
      return (
        <Text
          py="0.25rem"
          textShadow="0 1px 5px black"
          fontSize="xs"
          style={{
            color: "#eee",
            cursor: "pointer",
            textShadow: "0px 0px 10px #000000",
          }}
          textAlign="center"
        >
          Edit music URL
        </Text>
      );
    }

    return null;
  };

  return (
    <Box ref={(ref) => connect(ref)} textAlign="center" p="0" width="100%">
      {url && (
        <Box
          borderRadius="8x"
          width="100%"
          pointerEvents={enabled ? "none" : "auto"}
          //px="1rem"

          mb="1rem"
        >
          {renderComponent()}
        </Box>
      )}

      {renderHelpLabel()}
    </Box>
  );
};

Music.craft = {
  displayName: "Music",

  props: {
    url: "",
    previewSpotifyType: "compact",
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: MusicSettings,
  },
};
