import {
  Box,
  Slider as CSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Select,
} from "@chakra-ui/react";
import {
  RadioGroup as CRadioGroup,
  Divider,
  Heading,
  Switch,
} from "@chakra-ui/react";

import EmojiSelectorSettingsItem from "./EmojiSelectorSettingsItem";
import FontSelectorSettingsItem from "./FontSelectorSettingsItem";
import ImageGallery from "./ImageGallery";
import ProfileImageSelector from "./ProfileImageSelector";
import React from "react";
import ReactGPicker from "react-gcolor-picker";
import { SettingsOnBlurTextInputItem } from "./SettingsOnBlurTextInput";
import { SettingsTextInputItem } from "./SettingsTextInputItem";
import UnsplashImages from "./UnsplashSettingsItem";
import VideoGallery from "./VideoGallery";
import debounce from "lodash/debounce";
import styled from "styled-components";
import { useNode } from "@craftjs/core";

const StyledSliderThumb = styled(SliderThumb)`
  border-color: #abc3d7 !important;
  width: 19px;
  height: 19px;
`;

const StyledSliderFilledTrack = styled(SliderFilledTrack)`
  background: #7792a5;
`;

const StyledReactGPicker = styled.div`
  margin-top: 0.5rem;
  > * {
    width: 100% !important;
    min-width: 100% !important;
  }

  & div {
    width: 100% important;
    min-width: 100% important;
  }

  &.popup_tabs {
    width: 100% !important;
    min-width: 100% !important;
  }
`;

const Label = styled(({ size, ...rest }) => (
  <Heading
    as="h3"
    size={size ?? "md"}
    mb="0.25rem"
    pl="2px"
    pb="2px"
    {...rest}
  />
))``;

export type SettingsItemTypes = {
  prefix?: string;
  label?: string;
  subLabel?: string;
  full?: boolean;
  propKey?: string;
  index?: number;
  children?: React.ReactNode;
  type: string;
  placeholder?: string;
  sliderMin?: number;
  sliderMax?: number;
  textAreaLength?: number;
  inputMaxLength?: number;
  useGradient?: boolean;
  isUrlInput?: boolean;
  withUnsplash?: boolean;
  onChange?: (value: any) => any;
};

export const SettingsItem = ({
  full = false,
  propKey,
  type,
  onChange,
  index,
  isUrlInput,
  sliderMin = 0,
  sliderMax = 100,
  placeholder,
  useGradient = true,
  withUnsplash = false,
  label,
  textAreaLength = 120,
  inputMaxLength,
  subLabel,
  ...props
}: SettingsItemTypes) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => ({
    propValue: node.data.props[propKey],
  }));
  const value = Array.isArray(propValue) ? propValue[index] : propValue;

  const onGradientChange = (value) => {
    setProp((props: any) => {
      if (Array.isArray(propValue)) {
        props[propKey][index] = onChange ? onChange(value) : value;
      } else {
        props[propKey] = onChange ? onChange(value) : value;
      }
    }, 500);
  };

  const updateSliderValue = (value: number) =>
    setProp((props: any) => {
      if (Array.isArray(propValue)) {
        props[propKey][index] = onChange ? onChange(value) : value;
      } else {
        props[propKey] = onChange ? onChange(value) : value;
      }
    }, 1000);

  const debouncedSliderChange = debounce(updateSliderValue, 250);

  return (
    <Box width={full ? "100%" : "50%"} mb="0.5rem">
      {["text", "number", "textArea"].includes(type) ? (
        <>
          {label ? <Label size="xs">{label}</Label> : null}
          {subLabel ? (
            <Label fontSize="xs" fontWeight="none" color="gray.500">
              {subLabel}
            </Label>
          ) : null}
          <SettingsTextInputItem
            {...props}
            type={type}
            textAreaLength={textAreaLength}
            inputMaxLength={inputMaxLength}
            value={value}
            isUrlInput={isUrlInput}
            placeholder={placeholder}
            required={type === "text"}
            onChange={(value) => {
              setProp((props: any) => {
                if (Array.isArray(propValue)) {
                  props[propKey][index] = onChange ? onChange(value) : value;
                } else {
                  props[propKey] = onChange ? onChange(value) : value;
                }
              }, 500);
            }}
          />
        </>
      ) : type === "textOnBlur" ? (
        <>
          {label ? <Label size="xs">{label}</Label> : null}
          {subLabel ? (
            <Label fontSize="xs" fontWeight="none" color="gray.500">
              {subLabel}
            </Label>
          ) : null}
          <SettingsOnBlurTextInputItem
            {...props}
            value={value}
            placeholder={placeholder}
            onChange={(value) => {
              setProp((props: any) => {
                if (Array.isArray(propValue)) {
                  props[propKey][index] = onChange ? onChange(value) : value;
                } else {
                  props[propKey] = onChange ? onChange(value) : value;
                }
              }, 500);
            }}
          />
        </>
      ) : type === "fontSize" ? (
        <>
          {label ? <Label size="xs">{label}</Label> : null}
          <Select placeholder='Select font size'
            value={value}
            onChange={(e) => {
              console.error(e, 'e');
              const value = e.currentTarget.value;
              setProp((props: any) => {
                props[propKey] = onChange ? onChange(value) : value;
              });
            }}>
            <option value='10px'>10px</option>
            <option value='11px'>11px</option>
            <option value='12px'>12px</option>
            <option value='14px'>14px</option>
            <option value='16px'>16px</option>
            <option value='18px'>18px</option>
            <option value='20px'>20px</option>
            <option value='24px'>24px</option>
            <option value='28px'>28px</option>
            <option value='32px'>32px</option>
            <option value='36px'>36px</option>
            <option value='40px'>40px</option>
            <option value='48px'>48px</option>
            <option value='56px'>56px</option>
            <option value='64px'>64px</option>
            <option value='80px'>80px</option>
            <option value='96px'>96px</option>
            <option value='112px'>112px</option>
          </Select>
        </>
      ) : type === "slider" ? (
        <>
          {label ? <Label size="xs">{label}</Label> : null}
          {subLabel ? (
            <Label fontSize="xs" fontWeight="none" color="gray.500">
              {subLabel}
            </Label>
          ) : null}

          <CSlider
            aria-label="slider"
            width={{ base: "76%", md: "64%" }}
            ml={{ base: "2rem", md: "0" }}
            min={sliderMin}
            max={sliderMax}
            defaultValue={parseInt(value) || 0}
            onChange={debouncedSliderChange}
          >
            <SliderTrack>
              <StyledSliderFilledTrack />
            </SliderTrack>
            <StyledSliderThumb />
          </CSlider>
        </>
      ) : type === "buttonRadio" ? (
        <>
          {label ? <Label size="xs">{label}</Label> : null}

          <CRadioGroup
            defaultValue={value}
            onChange={(e) => {
              const value = e;
              setProp((props: any) => {
                props[propKey] = onChange ? onChange(value) : value;
              });
            }}
          >
            {props.children}
          </CRadioGroup>
        </>
      ) : type === "switch" ? (
        <>
          <Label size="xs">{label}</Label>

          <Switch
            pt="0.25rem"
            position="relative"
            colorScheme="whatsapp"
            size="lg"
            isChecked={value || false}
            onChange={(e) => {
              const value = e.target.checked;
              setProp((props: any) => {
                props[propKey] = onChange ? onChange(value) : value;
              });
            }}
          />
        </>
      ) : type === "selectfont" ? (
        <FontSelectorSettingsItem
          selectedFont={value}
          handleFontSelect={(font) => {
            setProp((props: any) => {
              if (Array.isArray(propValue)) {
                props[propKey][index] = onChange ? onChange(font) : font;
              } else {
                props[propKey] = onChange ? onChange(font) : font;
              }
            }, 500);
          }}
        />
      ) : type === "imageGallery" ? (
        <>
          <ImageGallery
            handleImageSelect={(imageUrl) => {
              setProp((props: any) => {
                props[propKey] = onChange ? onChange(imageUrl) : imageUrl;
              }, 500);
            }}
          />

          <Divider my="0.75rem" borderColor="#ececec" />

          {withUnsplash ? (
            <UnsplashImages
              handleImageSelect={(image) => {
                setProp((props: any) => {
                  if (Array.isArray(propValue)) {
                    props[propKey][index] = onChange
                      ? onChange(image)
                      : image.urls.regular;
                  } else {
                    props[propKey] = onChange
                      ? onChange(image)
                      : image.urls.regular;
                  }
                }, 500);
              }}
            />
          ) : null}
        </>
      ) : type === "videoGallery" ? (
        <VideoGallery
          currentVideoUrl={value}
          handleVideoSelect={(videoUrl) => {
            setProp((props: any) => {
              props[propKey] = onChange ? onChange(videoUrl) : videoUrl;
            }, 500);
          }}
        />
      ) : type === "profileImageUrl" ? (
        <>
          <ProfileImageSelector
            currentImageUrl={value}
            handleImageSelect={(imageUrl) => {
              setProp((props: any) => {
                props[propKey] = onChange ? onChange(imageUrl) : imageUrl;
              }, 500);
            }}
          />
        </>
      ) : type === "selectemoji" ? (
        <EmojiSelectorSettingsItem
          //selectedIcon={value}
          handleEmojiSelect={(emoji) => {
            setProp((props: any) => {
              if (Array.isArray(propValue)) {
                props[propKey][index] = onChange ? onChange(emoji) : emoji;
              } else {
                props[propKey] = onChange ? onChange(emoji) : emoji;
              }
            }, 500);
          }}
        />
      ) : type === "gradient" || type === "color" ? (
        <>
          {label ? <Label size="xs">{label}</Label> : null}

          <StyledReactGPicker>
            <ReactGPicker
              gradient={useGradient}
              value={value}
              onChange={onGradientChange}
              showAlpha={true}
              defaultColors={[
                "#000000",
                "#f8f8f8",
                "#b71c1c",
                "#FF6900",
                "#FCB900",
                "#7BDCB5",
                "#00D084",
                "#8ED1FC",
                "#0693E3",
                "#ABB8C3",
                "#607d8b",
                "#EB144C",
                "#F78DA7",
                "#ba68c8",
                "#FF6900",
                "#FCB900",
                "#7BDCB5",
                "#00D084",
                "#8ED1FC",
                "#0693E3",
                "#ABB8C3",
                "#607d8b",
                "#EB144C",
                "#F78DA7",
                "#ba68c8",
                "#9900EF",
                "linear-gradient(0deg, #48099b 0%, rgb(105, 79, 246) 100%)",
                "linear-gradient(0deg, transparent, transparent)",
                "linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%)",
                "linear-gradient(135deg, #5ee7df 0%, #b490ca 100%)",
                "linear-gradient(135deg, #f093fb 0%, #f5576c 100%)",
                "linear-gradient(135deg, #f6d365 0%, #fda085 100%)",
                "linear-gradient(135deg, rgb(0, 100, 80) 0%, rgb(25, 230, 140) 100%)",
                "linear-gradient(0deg, rgb(255, 177, 153) 0%, rgb(255, 8, 68) 100%)",
                "linear-gradient(270deg, rgb(251, 171, 126) 8.00%, rgb(247, 206, 104) 92.00%)",
                "linear-gradient(0deg, rgb(255, 185, 198) 0%, rgb(105, 79, 246) 100%)",
                "linear-gradient(315deg, rgb(150, 230, 161) 8.00%, rgb(212, 252, 121) 92.00%)",
                "linear-gradient(to left, rgb(249, 240, 71) 0%, rgb(15, 216, 80) 100%)",
                "linear-gradient(315deg, rgb(194, 233, 251) 8.00%, rgb(161, 196, 253) 92.00%)",
                "linear-gradient(0deg, rgb(0, 198, 251) 0%, rgb(0, 91, 234) 100%)",
                "linear-gradient(0deg, rgb(167, 166, 203) 0%, rgb(137, 137, 186) 51.00%, rgb(137, 137, 186) 100%)",
                "linear-gradient(0deg, rgb(80, 82, 133) 0%, rgb(88, 94, 146) 15.0%, rgb(101, 104, 159) 28.00%, rgb(116, 116, 176) 43.00%, rgb(126, 126, 187) 57.00%, rgb(131, 137, 199) 71.00%, rgb(151, 149, 212) 82.00%, rgb(162, 161, 220) 92.00%, rgb(181, 174, 228) 100%)",
                "linear-gradient(270deg, rgb(255, 126, 179) 0%, rgb(255, 117, 140) 100%)",
                "linear-gradient(90deg, rgb(120, 115, 245) 0%, rgb(236, 119, 171) 100%)",
                "linear-gradient(0deg, rgb(255, 185, 198), rgb(105, 79, 246)",
                "radial-gradient(circle at center, yellow 0%, #009966 50%, purple 100%)",
              ]}
            />
          </StyledReactGPicker>
        </>
      ) : null}
    </Box>
  );
};
