import { Box, Grid, Heading, Stack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select, Textarea, useToast } from "@chakra-ui/react";

import { Button } from "@chakra-ui/button";
import { Helmet } from "react-helmet";
import { Input } from "@chakra-ui/input";
import Page from "components/Page";
import api from "api";
import { useState } from "react";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const ContactUsScreen = () => {
  const toast = useToast();

  const [isSending, setIsSending] = useState(false);
  const [messageInfo, setMessageInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;

    setMessageInfo({
      ...messageInfo,
      [e.target.name]: value,
    });
  };

  const handleInfoUpdateSubmit = async (e) => {
    e.preventDefault();

    setIsSending(true);

    try {
      await api.post(
        `/api/public/contact-us`,
        {
          name: messageInfo.name,
          email: messageInfo.email,
          subject: messageInfo.subject,
          message: messageInfo.message,
        },
        config
      );

      setIsSending(false);

      toast({
        title: "Message received",
        description:
          "Thank you for your message, we will get back to you shortly!",
        status: "success",
        variant: "subtle",
        isClosable: true,
        duration: 9000,
        position: "top",
      });
    } catch (error) {
      console.error("Err ", error);

      toast({
        title: "Message could not be sent",
        description: "Something went wrong. Please try again in a bit",
        status: "error",
        variant: "subtle",
        isClosable: false,
        position: "top",
      });
    }

    setIsSending(false);
  };

  return (
    <>
      <Helmet>
        <title>Contact us | Alfalink</title>
      </Helmet>

      <Page>
        <Stack
          spacing="2rem"
          display="flex"
          alignItems="center"
          flexDirection="column"
          py={{ base: "1rem", "2xl": "2rem" }}
          px={{ base: "0rem", md: "0rem" }}
        >
          <Box
            p={{ base: "3rem 1rem", md: "5rem 9rem" }}
            width="100%"
            background="linear-gradient(344deg, #8748f3 0%, #bf61f9 100%)"
            borderRadius="0.5rem"
          >
            <Heading as="h2" size="xl" textAlign="center" color="#ffffff">
              Get in touch! &#128075;
            </Heading>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            mr={{ base: "0", md: "2rem" }}
            width={{ base: "50%", md: "24%", lg: "20%" }}
          ></Box>

          <Box width={{ base: "100%", md: "64%" }}>
            <form onSubmit={handleInfoUpdateSubmit}>
              <Stack spacing="1.5rem" my="1rem">
                <FormControl>
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                    }}
                    gap={{ base: "1.5rem", md: "1rem", "2xl": "2rem" }}
                  >
                    <Box>
                      <FormLabel>Name</FormLabel>
                      <Input
                        placeholder="Name"
                        name="name"
                        defaultValue={messageInfo?.name}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box>
                      <FormLabel>Email</FormLabel>
                      <Input
                        placeholder="Email"
                        type="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$"
                        name="email"
                        required
                        defaultValue={messageInfo?.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  <Box my="1.5rem">
                    <FormLabel>Subject</FormLabel>
                    <Select
                      placeholder="Subject"
                      onChange={handleChange}
                      name="subject"
                    >
                      <option value="collaboration">Collaboration</option>
                      <option value="verify">Get verified blue checkbox</option>
                      <option value="bug">Report a bug</option>
                      <option value="other">Other</option>
                    </Select>
                  </Box>

                  <FormLabel>Email</FormLabel>
                  <Textarea
                    placeholder="What did you want to discuss with us?"
                    rows={6}
                    name="message"
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  size="lg"
                  disabled={!messageInfo.email}
                  type="submit"
                  width="full"
                  colorScheme="yellow"
                  isLoading={isSending}
                >
                  Send message
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Page>
    </>
  );
};

export default ContactUsScreen;
