import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { FingerprintGirlSVG } from "assets/svgs/fingerprintSVG";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { verifyEmail } from "api/queries";

const VerifyEmailScreen = () => {
  const [emailVerified, setEmailVerified] = useState(false);

  const { verifyEmailToken } = useParams();

  const history = useHistory();

  const { error, isLoading } = useQuery(
    ["verifyEmail", verifyEmailToken],
    () => verifyEmail(verifyEmailToken ?? ""),
    {
      enabled: Boolean(verifyEmailToken),
      onSuccess: () => setEmailVerified(true),
    }
  );

  useEffect(() => {
    if (emailVerified) {
      setTimeout(() => {
        history.push("/in/editor");
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerified]);

  return (
    <>
      <Helmet>
        <title>Verify Email | Alfalink</title>
      </Helmet>
      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>

        <Flex
          width="full"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          pt="3rem"
        >
          <Heading as="h2" size="xl">
            Hi!{" "}
            <span role="img" aria-label="sheep">
              👋
            </span>
          </Heading>

          <Box
            width="full"
            maxWidth="552px"
            borderRadius={4}
            bg="white"
            borderWidth={1}
            boxShadow={{ base: "none", md: "sm" }}
            mt="3rem"
            textAlign="center"
          >
            <Heading
              as="h3"
              fontSize={{ base: "md", md: "lg" }}
              my="2rem"
              textAlign="center"
            >
              Thanks for verifying your email. <br /> No one likes bots!
            </Heading>
            <Box fontSize="sm" color="gray.600" py="2rem">
              {
                //@ts-ignore
                error && error?.response.status === 400 && (
                  <Text color="red.700">
                    Verification URL has expired.
                    <br />
                    Please request email verification again.
                    <br />
                  </Text>
                )
              }

              {!error && isLoading && <Text>Verifying your email..</Text>}

              {emailVerified && (
                <Text fontSize="md" fontWeight="bold" color="green.700">
                  Email verified!
                  <br />
                  Redirecting you to Editor page...
                </Text>
              )}
            </Box>
          </Box>
          <FingerprintGirlSVG />
        </Flex>
      </Page>
    </>
  );
};

export default VerifyEmailScreen;
