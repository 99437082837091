import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AlertMessage from "components/AlertMessage";
import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { FingerprintGirlSVG } from "assets/svgs/fingerprintSVG";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
import UilEyeSlash from "@iconscout/react-unicons/icons/uil-eye-slash";
import { getAuthToken } from "utils";
import { useAuth } from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const queryString = require("query-string");

const LoginSchema = yup
  .object({
    username: yup.string().trim().min(1).max(20).required(),
    password: yup.string().min(6).max(32).required(),
  })
  .required();

const LoginScreen = () => {
  const history = useHistory();
  const location = useLocation();

  const { u, p } = queryString.parse(location.search);
  const [showPassword, setShowPassword] = useState(false);

  const { login, error, loading } = useAuth();

  useEffect(() => {
    const token = getAuthToken();

    if (token) {
      history.push("/in/editor");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken()]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(LoginSchema),
  });

  const onSubmitHandler = (data) => {
    const { username, password } = data;

    login({
      username: username,
      password: password,
    });
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <Helmet>
        <title>Login | Alfalink</title>
      </Helmet>
      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>

        <Flex
          width="full"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          py="3rem"
        >
          <Heading as="h2" size="xl">
            Welcome back!{" "}
            <span role="img" aria-label="sheep">
              👋
            </span>
          </Heading>

          <Heading as="h3" size="lg" my="1rem">
            Sign in to your Alfalink account.
          </Heading>

          <Box
            p={{ base: "1rem", md: "1.5rem" }}
            width="full"
            maxWidth="552px"
            borderWidth={{ base: 1, md: 1 }}
            boxShadow="sm"
            borderRadius={4}
            bg="white"
            mt="1rem"
          >
            <>
              <Box my={4} textAlign="left">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  {error && <AlertMessage status="error" message={error} />}

                  <FormControl isInvalid={!!errors.username}>
                    <FormLabel>Username</FormLabel>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <Text
                            pl="4.75rem"
                            pt="8px"
                            fontSize="18px"
                            alignItems="center"
                            verticalAlign="center"
                            size="60px"
                            color="gray.500"
                          >
                            alfalink.co/
                          </Text>
                        }
                      />

                      <Input
                        {...register("username")}
                        pl="6.75rem"
                        placeholder="MichaelScott"
                        size="lg"
                        name="username"
                        defaultValue={u ?? ""}
                      />
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.username?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={6} isInvalid={!!errors.password}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        {...register("password")}
                        type={showPassword ? "text" : "password"}
                        placeholder="*******"
                        size="lg"
                        defaultValue={p ?? ""}
                      />

                      <InputRightElement width="3rem">
                        <Button
                          variant="unstyled"
                          opacity="0.8"
                          top="3px"
                          onClick={handlePasswordVisibility}
                        >
                          {showPassword ? <UilEyeSlash /> : <UilEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    variant="solid"
                    size="lg"
                    type="submit"
                    width="full"
                    my="1.5rem"
                    colorScheme="yellow"
                  >
                    {loading ? (
                      <CircularProgress
                        isIndeterminate
                        size="24px"
                        color="teal"
                      />
                    ) : (
                      "Sign In"
                    )}
                  </Button>

                  <VStack
                    spacing="1rem"
                    mt="1rem"
                    display="flex"
                    justifyContent="center"
                  >
                    <Link color="gray.600" href="/in/forgotpassword">
                      <Text as="u">Forgot password?</Text>
                    </Link>
                    <Divider />

                    <Text color="gray.600">
                      Don't have an account?{" "}
                      <Link href="/in/signup">
                        <Text as="u">Join us!</Text>
                      </Link>
                    </Text>
                  </VStack>
                </form>
              </Box>
            </>
          </Box>
          <FingerprintGirlSVG />
        </Flex>
      </Page>
    </>
  );
};

export default LoginScreen;
