import { Box, SimpleGrid, Text } from "@chakra-ui/layout";

import { CTRIcon } from "assets/svgs/CTRIcon";
import { Tooltip as CTooltip } from "@chakra-ui/react";
import { ClickIcon } from "assets/svgs/clickIcon";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
import UilStopwatch from "@iconscout/react-unicons/icons/uil-stopwatch";
import { secondsToHms } from "utils";

export const TotalCountsCards = ({ data, error }) => {
  if (error) {
    return (
      <div>Something went wrong. Please try again later or contact support</div>
    );
  }

  const { pageViews, linkClicks, timeToClick } = data;

  return (
    <SimpleGrid spacing="0" columns={{ sm: 2, md: 2, lg: 4 }}>
      <Box
        minWidth="224px"
        mt={{ base: "1rem", md: "0.5rem" }}
        bgColor="#fff"
        borderRadius="0.25rem"
        p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
        boxShadow="0 1px 12px -12px #e2e0ff"
        border="1px solid #e2e0ff"
        textAlign="left"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Box>
              <Text
                display="block"
                fontSize="3xl"
                fontWeight="semibold"
                color="#504abe"
              >
                {pageViews.total ?? 0}
              </Text>

              <Text fontWeight="semibold" color="#6d68ca">
                Views
              </Text>
            </Box>
          </Box>

          <Box
            width="48px"
            height="48px"
            borderRadius="50px"
            bg="#6d68ca"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <UilEye size="28px" color="#fff" />
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="left"
          mt={{ base: "1rem", md: "2rem" }}
          color={"#504abe"}
        >
          <Text mr="0.25rem" fontSize="sm">
            {pageViews?.unique
              ? ((pageViews.unique / pageViews.total) * 100).toFixed(1)
              : 0.0}
            %{"  "} unique views
          </Text>

          <CTooltip
            label="Unique visitors are returning visitors to your profile"
            fontSize="xs"
          >
            <i className="uil uil-info-circle" />
          </CTooltip>
        </Box>
      </Box>

      <Box
        minWidth="224px"
        mt={{ base: "1rem", md: "0.5rem" }}
        ml={{ md: "1.5rem" }}
        borderRadius="0.25rem"
        p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
        boxShadow="0 1px 12px -12px #d9ffdf"
        border="1px solid #d9ffdf"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Text
              display="block"
              fontSize="3xl"
              fontWeight="bold"
              color="#39aa68"
            >
              {linkClicks.total ?? 0}
            </Text>
            <Text fontWeight="semibold" color="#45b172">
              Clicks
            </Text>
          </Box>

          <Box>
            <Box
              width="48px"
              height="48px"
              borderRadius="50px"
              bg="#70d098"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ClickIcon />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="left"
          mt={{ base: "1rem", md: "2rem" }}
          color={"#39aa68"}
        >
          <Text mr="0.25rem" fontSize="sm">
            {linkClicks?.unique
              ? ((linkClicks.unique / linkClicks.total) * 100).toFixed(1)
              : 0.0}
            %{"  "} unique clicks
          </Text>

          <CTooltip
            label="Unique clicks are from returning visitors to your profile"
            fontSize="xs"
          >
            <i className="uil uil-info-circle" />
          </CTooltip>
        </Box>
      </Box>

      <Box
        minWidth="224px"
        mt={{ base: "1rem", md: "0.5rem" }}
        ml={{ md: "1.5rem" }}
        borderRadius="0.25rem"
        p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
        boxShadow="0 1px 12px -12px #dcebff"
        border="1px solid #dcebff"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Text
              display="block"
              fontSize="3xl"
              fontWeight="semibold"
              color="#2677e9"
            >
              {pageViews.total
                ? ((linkClicks.total / pageViews.total) * 100).toFixed(1)
                : 0.0}
              %
            </Text>

            <Text fontWeight="semibold" color="#317de7">
              Avg. CTR
            </Text>
          </Box>

          <Box>
            <Box
              width="48px"
              height="48px"
              borderRadius="50px"
              bg="#7babf1"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CTRIcon />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="right"
          mt={{ base: "1rem", md: "2rem" }}
          color="#3583f0"
        >
          <CTooltip
            label="Click-through rate (CTR) is the ratio of visitors who clicked on a link to the total number of visitors"
            fontSize="xs"
          >
            <i className="uil uil-info-circle" />
          </CTooltip>
        </Box>
      </Box>

      <Box
        minWidth="224px"
        mt={{ base: "1rem", md: "0.5rem" }}
        ml={{ md: "1.5rem" }}
        borderRadius="0.25rem"
        p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
        boxShadow="0 1px 12px -12px #ffdbef"
        border="1px solid #ffdbef"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Text
              display="block"
              fontSize="2xl"
              fontWeight="semibold"
              color="#d14b95"
            >
              {timeToClick?.avg ? secondsToHms(timeToClick.avg) : 0}
            </Text>

            <Text
              mt="0.5rem"
              fontSize="sm"
              fontWeight="semibold"
              color="#d65fa1"
            >
              Avg. Time To Click
            </Text>
          </Box>

          <Box ml="1rem">
            <Box
              width="48px"
              height="48px"
              borderRadius="50px"
              bg="#e978b7"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <UilStopwatch size="30px" color="#fff" />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="right"
          mt={{ base: "1rem", md: "2rem" }}
          color="#d14b95"
        >
          <CTooltip
            label="Time between the visitor entering your profile until first link click"
            fontSize="xs"
          >
            <i className="uil uil-info-circle" />
          </CTooltip>
        </Box>
      </Box>
    </SimpleGrid>
  );
};
