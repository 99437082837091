import { QuestionCard } from "./QuestionCard";
import { Stack } from "@chakra-ui/react";

export const SearchList = ({
  filteredQuestions,
}: {
  filteredQuestions: any;
}) => {
  const filtered = filteredQuestions.map((question) => (
    <QuestionCard key={question.title} question={question} />
  ));

  return <Stack spacing="2rem">{filtered}</Stack>;
};

export default SearchList;
