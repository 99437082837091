import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import AlertMessage from "components/AlertMessage";
import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { FingerprintGirlSVG } from "assets/svgs/fingerprintSVG";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import api from "api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPasswordSchema = yup
  .object({
    username: yup.string().trim().min(0).max(20).required(),
  })
  .required();

const ForgotPasswordScreen = () => {
  const toast = useToast();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submittedRequest, setSubmittedRequest] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmitHandler = async (data) => {
    const { username } = data;

    setIsLoading(true);

    try {
      await api.post(`/api/auth/forgotpassword/${username}`);

      setIsLoading(false);

      toast({
        title: "Reset password email sent",
        description:
          "Please follow the instruction in the email to reset your password.",
        status: "success",
        variant: "subtle",
        position: "top",
        isClosable: true,
      });

      setSubmittedRequest(true);
    } catch (error: any) {
      if (error && error.response?.status === 429) {
        console.error("Error, too many requests ", error);

        setIsLoading(false);

        toast({
          title: "Too many server requests",
          description:
            "We have already sent a reset password email. Please check your email or send a new request a bit later",
          status: "error",
          variant: "subtle",
          position: "top",
          isClosable: true,
        });

        setError("Error, too many server requests");

        setSubmittedRequest(true);

        return setTimeout(() => {
          setError("");
        }, 5000);
      }

      if (error && error?.message === "Network Error") {
        return setError(
          "Network error. Please try again later or contact support"
        );
      }

      setError("Username not found.");
      setIsLoading(false);

      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset password | Alfalink</title>
      </Helmet>
      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>

        <Flex
          width="full"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          pt="3rem"
        >
          <Box px="1rem" textAlign="center">
            <Heading as="h3" size="lg" my="1rem">
              Let's get your password back.
            </Heading>
          </Box>

          <Box
            p={{ base: "1rem", md: "2rem" }}
            width="full"
            maxWidth="552px"
            borderRadius={4}
            bg="white"
            borderWidth={{ base: 1, md: 1 }}
            boxShadow="sm"
            mt="1rem"
          >
            <>
              <Box my={4} textAlign="left">
                <Text my="1.25rem">
                  Enter your alfalink username and we will send you a password
                  reset email.
                </Text>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  {error && <AlertMessage status="error" message={error} />}

                  <FormControl isInvalid={!!errors.username}>
                    <FormLabel>Username</FormLabel>

                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <Text
                            pl="4.75rem"
                            pt="8px"
                            fontSize="18px"
                            alignItems="center"
                            verticalAlign="center"
                            size="60px"
                            color="gray.500"
                          >
                            alfalink.co/
                          </Text>
                        }
                      />

                      <Input
                        {...register("username")}
                        pl="6.75rem"
                        type="username"
                        placeholder="MichaelScott"
                        size="lg"
                        required
                        name="username"
                      />
                    </InputGroup>
                  </FormControl>

                  <Button
                    disabled={submittedRequest}
                    variant="solid"
                    size="lg"
                    type="submit"
                    width="full"
                    my="1.5rem"
                    colorScheme="yellow"
                  >
                    {isLoading ? (
                      <CircularProgress
                        isIndeterminate
                        size="24px"
                        color="teal"
                      />
                    ) : (
                      "Reset password"
                    )}
                  </Button>

                  <Divider />

                  <Box mt="2rem" display="flex" justifyContent="center">
                    <Text color="gray.600">
                      Remembered your password?{" "}
                      <Link href="/in/login">
                        <Text as="u">Sign in</Text>
                      </Link>
                    </Text>
                  </Box>
                </form>
              </Box>
            </>
          </Box>
          <FingerprintGirlSVG />
        </Flex>
      </Page>
    </>
  );
};

export default ForgotPasswordScreen;
