import React from "react";

const EtsyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="currentColor"
    >
      <path d="M23.604 94.296c7.581-.33 16.789 0 25.766 0 9.267 0 18.754-.616 26.226 0 3.02.249 5.797 2.056 8.284.456 1.92-2.473.496-5.792.921-9.119.807-6.315 6.631-13.889-1.843-15.5-3.608 3.289-1.176 6.412-3.22 10.486-2.475 4.933-11.342 6.639-19.784 7.293-7.246.562-19.947 1.289-22.547-3.646-2.195-4.164-.922-10.625-.922-15.955 0-6.031-.647-11.804.922-16.414 9.205.672 21.108-3.068 27.606.91 4.348 2.664 1.875 7.877 6.902 9.574 3.46-.929 1.613-5.4 1.379-9.574-.148-2.688-.137-6.468 0-9.574.186-4.192 1.805-9.054-2.762-9.118-3.586 2.787-.891 7.432-4.602 10.029-1.187.832-3.763 1.183-5.519 1.37-6.896.728-17.712.134-23.469-.913-.793-8.402-.725-19.852 0-28.266 3.125-3.104 10.313-3.212 15.185-3.193 8.535.034 21.846.744 24.387 5.016 1.383 2.324.286 7.188 2.762 7.75 4.979 1.137 2.559-7.747 2.762-11.854.15-3.079 1.511-5.062.918-7.295-1.611-2.201-4.012-1.098-5.521-.911-15.656 1.936-39.436.911-56.132.911-1.994 0-5.477-.994-6.901 1.368-.963 5.266 6.226 3.089 8.741 5.927.818.923 2.027 5 2.301 7.751.728 7.307 0 18.467 0 29.18 0 11.318.859 22.736 0 29.635-.3 2.408-1.753 6.289-2.301 6.839-3.135 3.132-11.422-.337-10.584 6.384 2.912 1.958 7.13.624 11.045.453" />
    </svg>
  );
};

export default EtsyIcon;
