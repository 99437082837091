export const AnimationIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.782 8.782a7 7 0 1 0 9.436 9.436 6.953 6.953 0 0 1-2.393.734 5.5 5.5 0 0 1-7.777-7.777c.1-.855.355-1.662.734-2.393z"
        fill="currentColor"
        fillOpacity=".3"
      ></path>
      <circle
        cx="15"
        cy="9"
        r="6.25"
        stroke="currentColor"
        strokeWidth="1.5"
      ></circle>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.782 5.782a7 7 0 1 0 9.436 9.436 6.953 6.953 0 0 1-2.393.734 5.5 5.5 0 0 1-7.777-7.777c.1-.854.355-1.662.734-2.393z"
        fill="currentColor"
        fillOpacity=".6"
      ></path>
    </svg>
  );
};
