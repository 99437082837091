import { Box, Text } from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";

import { BuyMeACoffeeSettings } from "./settings";
import { useLocation } from "react-router-dom";

type ButtonProps = {
  buyMeACoffeeUsername?: string;
};

export const BuyMeACoffee: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const { buyMeACoffeeUsername } = props;

  const renderHelpLabel = () => {
    if (enabled && isEditorView && !buyMeACoffeeUsername) {
      return (
        <Text
          p="1rem"
          my="1rem"
          fontSize="xs"
          textAlign="center"
          textShadow="0 1px 5px black"
          style={{
            color: "#eee",
            cursor: "pointer",
          }}
        >
          <i
            className="uil uil-exclamation-triangle"
            style={{
              color: "#f74949",
            }}
          />{" "}
          BMC username missing
        </Text>
      );
    }

    if (enabled && isEditorView && buyMeACoffeeUsername) {
      return (
        <Text
          py="0.25rem"
          fontSize="xs"
          style={{
            color: "#eee",
            cursor: "pointer",
            textShadow: "0px 0px 10px #000000",
          }}
          textAlign="center"
        >
          Edit BMC username
        </Text>
      );
    }

    return null;
  };

  return (
    <Box ref={(ref) => connect(ref)} textAlign="center" width="100%">
      {buyMeACoffeeUsername && (
        <Box
          borderRadius="8x"
          width="100%"
          pointerEvents={enabled ? "none" : "auto"}
          my="1rem"
        >
          <iframe
            style={{
              borderRadius: "8px",
              border: "none",
              width: "100%",
              display: "block",
              height: "273px",
            }}
            src={`https://www.buymeacoffee.com/widget/page/${buyMeACoffeeUsername}?compact=true&hide_recents=true`}
            frameBorder="0"
            allowTransparency
            allow="encrypted-media"
            title="BuyMeACoffeIframe"
          />
        </Box>
      )}

      {renderHelpLabel()}
    </Box>
  );
};

BuyMeACoffee.craft = {
  displayName: "Buy Me A Coffee",

  props: {
    buyMeACoffeeUsername: "",
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: BuyMeACoffeeSettings,
  },
};
