import {
  Radio,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { SettingsItem } from "components/EditorComponents/SettingsPanel";

export const TextSettings = () => {
  return (
    <Tabs>
      <TabList mb="1em">
        <Tab>Typography</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <SettingsItem
            full
            propKey="fontSize"
            type="slider"
            label="Font Size"
          />
          <SettingsItem propKey="textAlign" type="buttonRadio" label="Align">
            <Radio value="left" label="Left" />
            <Radio value="center" label="Center" />
            <Radio value="right" label="Right" />
          </SettingsItem>
          <SettingsItem propKey="fontWeight" type="radio" label="Weight">
            <Radio value="500" label="Normal" />
            <Radio value="700" label="Bold" />
          </SettingsItem>

          <SettingsItem full={true} propKey="color" type="color" label="Text" />
          <SettingsItem full propKey="shadow" type="slider" label="Shadow" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
