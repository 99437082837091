import {
  Divider,
  Drawer,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { FontWeightRadios, TextAlignRadios } from "../commons";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";
import { useEditor, useNode } from "@craftjs/core";

import Delete from "@iconscout/react-unicons/icons/uil-trash-alt";
import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { FontColorIcon } from "assets/svgs/fontColorIcon";
import { FontFormatIcon } from "assets/svgs/fontFormatIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";

export const BioSettings = () => {
  const { id, propValue } = useNode((node) => ({
    propValue: node.data.props,
  }));

  const { actions } = useEditor();

  const [drawerOpen, setDrawer] = useState("");

  const closeDrawer = () => {
    setDrawer("");
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const MobileEditTextSettingItem = (
    <SettingsItem
      full
      propKey="text"
      type="textArea"
      label="Bio"
      placeholder="Best place to write a short summary about you..."
    />
  );

  const MobileTypographySettingItem = (
    <>
      <SettingsItem
        full
        propKey="fontSize"
        type="slider"
        sliderMin={12}
        sliderMax={24}
        label="Font Size"
      />

      <Divider my="1em" />

      <SettingsItem propKey="textAlign" type="buttonRadio" label="Align text">
        {TextAlignRadios}
      </SettingsItem>

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="fontWeight"
        type="buttonRadio"
        label="Font weight"
      >
        {FontWeightRadios}
      </SettingsItem>
    </>
  );
  const TypographySettingItems = (
    <>
      {MobileEditTextSettingItem}

      <Divider my="1em" />

      {MobileTypographySettingItem}
    </>
  );

  const MobileTextColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Bio color
      </Heading>

      <SettingsItem
        full={true}
        propKey="color"
        type="color"
        useGradient={false}
      />
    </>
  );

  const MobileBackgroundColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Text background
      </Heading>
      <SettingsItem full={true} propKey="textBackground" type="color" />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="textPadding"
        type="slider"
        sliderMin={0}
        sliderMax={16}
        label="Text padding"
      />
      <br />
    </>
  );

  const ColorSettingItem = (
    <>
      {MobileTextColorSettingItem}

      <Divider my="1em" />

      {MobileBackgroundColorSettingItem}
    </>
  );

  return isLargerThan768 ? (
    <React.Fragment>
      <Tabs isFitted>
        <TabList mb="1em">
          <Tab>Typography</Tab>
          <Tab>Color</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{TypographySettingItems}</TabPanel>
          <TabPanel>{ColorSettingItem}</TabPanel>
        </TabPanels>
      </Tabs>
    </React.Fragment>
  ) : (
    <>
      <IconsWrapper
        hidden={
          drawerOpen === "edit" ||
          drawerOpen === "typography" ||
          drawerOpen === "textColor" ||
          drawerOpen === "backgroundColor"
        }
      >
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("typography")}>
          <FontFormatIcon />
          <Text>Typography</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("textColor")}>
          <FontColorIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.color ? propValue.color : "#eee",
            }}
          />

          <Text>Color</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("backgroundColor")}>
          <PaintBucketIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.background ? propValue.background : "#111",
            }}
          />

          <Text>Background</Text>
        </SettingIconButton>

        <SettingIconButton
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            actions.delete(id);
          }}
        >
          <Delete />
          <Text>Delete</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Edit">{MobileEditTextSettingItem}</DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "typography"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Typography">
          {MobileTypographySettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "textColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Text color">
          {MobileTextColorSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "backgroundColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Text background">
          {MobileBackgroundColorSettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
