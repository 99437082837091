import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import AnalyticsScreen from "containers/AnalyticsScreen";
import App from "App";
import { AuthProvider } from "hooks/useAuth";
import ContactUsScreen from "containers/ContactUsScreen";
import Editor from "containers/Editor";
import AdEditor from "containers/AdEditor";
import FeaturesScreen from "containers/Features";
import ForgotPasswordScreen from "containers/ForgotPasswordScreen";
import FunStatsScreen from "containers/FunStatsPage";
import { Helpdesk } from "containers/Helpdesk";
import { JobsScreen } from "containers/JobsScreen";
import LandingScreen from "containers/LandingScreen";
import LoginScreen from "containers/LoginScreen";
import { PricingScreen } from "containers/PricingScreen";
import PrivacyPolicyScreen from "containers/PrivacyPolicyScreen";
import PrivateRoute from "containers/PrivateRoute";
import ProfileScreen from "containers/ProfileScreen";
import { Provider } from "hooks/useTracked";
import ResetPasswordScreen from "containers/ResetPasswordScreen";
import SignUpScreen from "containers/SignUpScreen";
import { TemplatesScreen } from "containers/TemplatesScreen";
import TermsAndConditionScreen from "containers/TermsAndConditionsScreen";
import VerifyEmailScreen from "containers/VerifyEmailScreen";
import ViewScreen from "containers/ViewScreen";
import GuestScreen from "containers/GuestScreen";

const Routes = () => {
  return (
    <Router>
      <Provider>
        <AuthProvider>
          <App>
            <Switch>
              <Route exact path="/" component={LandingScreen} />

              <Route exact path="/in/signup" component={SignUpScreen} />

              <Route exact path="/in/login" component={LoginScreen} />
              <Route exact path="/in/contact" component={ContactUsScreen} />
              <Route exact path="/in/privacy" component={PrivacyPolicyScreen} />
              <Route exact path="/in/features" component={FeaturesScreen} />
              <Route exact path="/in/fun-stats" component={FunStatsScreen} />
              <Route exact path="/in/pricing" component={PricingScreen} />
              <Route exact path="/in/templates" component={TemplatesScreen} />

              <Route
                exact
                path="/in/terms"
                component={TermsAndConditionScreen}
              />

              <Route exact path="/in/jobs" component={JobsScreen} />

              <Route exact path="/in/support" component={Helpdesk} />

              <Route
                exact
                path="/in/forgotpassword"
                component={ForgotPasswordScreen}
              />
              <Route
                exact
                path="/in/passwordreset/:resetToken"
                component={ResetPasswordScreen}
              />

              <Route
                exact
                path="/in/verify-email/:verifyEmailToken"
                component={VerifyEmailScreen}
              />

              <Route
                exact
                path="/in/guest/:guestToken"
                component={GuestScreen}
              />

              <Route exact path="/:username" component={ViewScreen} />
              <PrivateRoute exact path="/in/editor" component={Editor} />
              <PrivateRoute
                exact
                path="/in/analytics"
                component={AnalyticsScreen}
              />

              <PrivateRoute
                exact
                path="/in/profile"
                component={ProfileScreen}
              />

              <PrivateRoute exact path="/in/adeditor/:id" component={AdEditor} />
              <PrivateRoute exact path="/in/editorad/:id" component={AdEditor} />

              <Redirect from="*" to="/" />
            </Switch>
          </App>
        </AuthProvider>
      </Provider>
    </Router>
  );
};

export default Routes;
