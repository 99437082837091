import {
  Drawer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";

export const BuyMeACoffeeSettings = () => {
  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const BuyMeACoffeeSettingItem = (
    <>
      <SettingsItem
        full={true}
        propKey="buyMeACoffeeUsername"
        type="textOnBlur"
        label="Buy Me A Coffee username"
        placeholder="BMC username"
      />
    </>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Info</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{BuyMeACoffeeSettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "edit"}>
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Info" pb="4rem">
          {BuyMeACoffeeSettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
