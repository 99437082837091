import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";

import styled from "styled-components";

export const StyledSpan = styled(Text)<{
  dotcolor: string;
}>`
  ::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 4px;
    margin-bottom: 2px;
    background-color: ${({ dotcolor }) => dotcolor || "green"};
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  display: inline-block;
  padding-top: 0;
  padding: 0;
  margin-left: -28px;

  div svg {
    text-align: center;

    width: auto !important;
    height: auto !important;
  }

  div {
    height: auto !important;
  }
`;

export const LinkDailyCountsGraph = ({ dailyTimeseriesData }) => {
  if (dailyTimeseriesData.length === 0 || !dailyTimeseriesData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p="2rem">
        <Text>No data to display</Text>
      </Box>
    );
  }

  return (
    <Box p={{ base: "0", md: "1.5rem" }}>
      <StyledResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={dailyTimeseriesData}
          barCategoryGap={30}
          barGap={0}
          margin={{
            top: 10,
            right: 0,
            bottom: 10,
            left: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" opacity="0.4" />

          <XAxis
            reversed
            dataKey="date"
            tickFormatter={(str) => {
              const date = parseISO(str);
              if (date && date.getDate() % 7 === 0) {
                return format(date, "MMM, d");
              }
              return date ? format(date, "MMM, d") : "";
            }}
            fontSize={"11px"}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              return active ? (
                <Stack
                  spacing="0.5rem"
                  className="tooltip"
                  style={{
                    fontSize: "13px",
                    padding: "0.825rem",
                  }}
                >
                  <Text color="gray.50">
                    {format(parseISO(label), "eeee, d MMM, yyyy")}
                  </Text>

                  <HStack spacing="1.25rem">
                    <Box>
                      <Text color="gray.400">Clicks:</Text>
                      <Text bold>{payload[0]?.value}</Text>
                    </Box>

                    <Box>
                      <Text color="gray.400">Unique Clicks:</Text>
                      <Text bold>{payload[1]?.value}</Text>
                    </Box>
                  </HStack>
                </Stack>
              ) : null;
            }}
          />
          <YAxis
            tickCount={6}
            fontSize="11px"
            label={{
              value: "Clicks",
              fontSize: "10px",
              position: "insideLeft",
              color: "red",
              opacity: "0.5",
              angle: -90,
              dy: 0,
              dx: 12,
            }}
          />

          <Tooltip />

          <Bar dataKey="linkClickTotal" barSize={15} fill="#38aa65" />
          <Bar dataKey="linkClickUniqueTotal" barSize={15} fill="#84ffb5" />
        </ComposedChart>
      </StyledResponsiveContainer>
      <HStack
        spacing="0.825rem"
        justifyContent="center"
        display="flex"
        alignItems="center"
        fontSize={{ base: "9px", md: "11px" }}
      >
        <StyledSpan dotcolor="#38aa65" color="#3eaf6b">
          Total clicks
        </StyledSpan>

        <StyledSpan dotcolor="#84ffb5" color="#5bdb8e">
          Unique clicks
        </StyledSpan>
      </HStack>
    </Box>
  );
};
