import "polyfill-object.fromentries";
import "./index.css";
import "focus-visible/dist/focus-visible";

import * as Sentry from "@sentry/react";

import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

import { ChakraProvider } from "@chakra-ui/react";
import ErrorBoundary from "containers/ErrorBoundary";
import { ErrorContent } from "api/types";
import { GTM_ID } from "utils/constants";
import GlobalStyle from "style/global";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";
import { theme } from "style/theme";

const tagManagerArgs = {
  gtmId: GTM_ID,
};

if (process.env.NODE_ENV === "production") {
  TagManager.initialize(tagManagerArgs);

  Sentry.init({
    dsn: "https://ba84c3d6cd1a427490b7f971e6adee9a@o1087637.ingest.sentry.io/6101152",
    integrations: [new Integrations.BrowserTracing()],
    environment: "production",

    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,

      retry: (count, error) => {
        return (error as ErrorContent)?.status !== 404 && count < 2;
      },
      retryDelay: (attempt) =>
        Math.min(attempt > 1 ? 2 ** attempt * 5000 : 1000, 30 * 1000),
    },
    mutations: {
      retry: 0,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />

        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
