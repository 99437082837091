import {
  Center,
  ListItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";

import UilCheckCircle from "@iconscout/react-unicons/icons/uil-check";
import UilTimesCircle from "@iconscout/react-unicons/icons/uil-times";

const CustomTd = ({ title, description, listItems }) => (
  <Stack spacing="4px" pb="0.5rem">
    <Text fontSize={{ base: "m", md: "lg" }} fontWeight="bold">
      {title}
    </Text>

    <Text as="span" color="gray.6Z00" fontSize={{ base: "xs", md: "sm" }}>
      {description}
    </Text>

    {listItems
      ? listItems.map((x) => (
          <UnorderedList
            key={x}
            pl={{ base: "0.75rem", md: "1rem" }}
            color="gray.600"
            fontSize={{ base: "xx-small", md: "xs" }}
          >
            <ListItem>{x}</ListItem>
          </UnorderedList>
        ))
      : null}
  </Stack>
);

export const PlanCompareTable = ({
  data,
  showHeaders,
  featureType,
}: {
  data?: any;
  showHeaders?: boolean;
  featureType?: string;
}) => {
  return (
    <>
      <TableContainer>
        <Table variant="simple" size="lg" overflow="hidden">
          <Thead>
            <Tr>
              <Th
                p={{ base: "0", md: "4px" }}
                fontSize={{ base: "lg", md: "xl" }}
                width={{ base: "200px", md: "500px", xl: "auto" }}
              >
                {featureType}
              </Th>
              <Th
                transform={{ base: "rotate(270deg)", md: "auto" }}
                padding="0"
                height="80px"
                textAlign={{ base: "center", md: "center" }}
              >
                {showHeaders ? "Free" : ""}
              </Th>

              <Th
                transform={{ base: "rotate(270deg)", md: "auto" }}
                padding="0"
                height="80px"
                textAlign={{ base: "center", md: "center" }}
              >
                {showHeaders ? "Pro" : ""}
              </Th>
              <Th
                transform={{ base: "rotate(270deg)", md: "auto" }}
                padding="0"
                height="40px"
                textAlign={{ base: "center", md: "center" }}
                maxWidth={{ base: "58px", md: "auto" }}
                fontSize={{ base: "12px", md: "sm" }}
              >
                {showHeaders ? "Business" : ""}
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {data.map((x) => (
              <Tr key={x.title}>
                <Td
                  minWidth={{ base: "190px", md: "auto" }}
                  px={{ base: "0", md: "0.5rem" }}
                  whiteSpace="normal"
                >
                  {CustomTd({
                    title: x.title,
                    description: x.description,
                    listItems: x.listItems,
                  })}
                </Td>

                {x.free ? (
                  <Td
                    px={{ base: "0", md: "1rem" }}
                    textAlign={{ base: "center", md: "center" }}
                  >
                    <Center textAlign={{ base: "center", md: "right" }}>
                      <UilCheckCircle color="#d51dce" />
                    </Center>
                  </Td>
                ) : (
                  <Td
                    px={{ base: "0", md: "1rem" }}
                    textAlign={{ base: "center", md: "center" }}
                  >
                    <Center textAlign={{ base: "center", md: "right" }}>
                      <UilTimesCircle color="#aaaaaa" />
                    </Center>
                  </Td>
                )}

                {x.pro ? (
                  <Td
                    px={{ base: "0", md: "1rem" }}
                    textAlign={{ base: "center", md: "center" }}
                  >
                    <Center textAlign={{ base: "center", md: "right" }}>
                      <UilCheckCircle color="#d51dce" />
                    </Center>
                  </Td>
                ) : (
                  <Td
                    px={{ base: "0", md: "1rem" }}
                    textAlign={{ base: "center", md: "center" }}
                  >
                    <Center textAlign={{ base: "center", md: "right" }}>
                      <UilTimesCircle color="#aaaaaa" />
                    </Center>
                  </Td>
                )}

                {x.business ? (
                  <Td
                    px={{ base: "0", md: "1rem" }}
                    textAlign={{ base: "center", md: "center" }}
                  >
                    <Center textAlign={{ base: "center", md: "right" }}>
                      <UilCheckCircle color="#d51dce" />
                    </Center>
                  </Td>
                ) : (
                  <Td
                    px={{ base: "0", md: "1rem" }}
                    textAlign={{ base: "center", md: "center" }}
                  >
                    <Center textAlign={{ base: "center", md: "right" }}>
                      <Center textAlign={{ base: "center", md: "right" }}>
                        <UilTimesCircle color="#aaaaaa" />
                      </Center>
                    </Center>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
