
export const alfalinkURL = (path) => {
	// @todo: make env.depended
	return ('/'+path).split('//').join('/');
};

export const cloudfrontURL = (path) => {
	// return "https://d2fplot30053y9.cloudfront.net" + (('/'+path).split('//').join('/'));
	return "https://di40kvnekqgxk.cloudfront.net" + (('/'+path).split('//').join('/'));
};

export const s3URL = (path) => {
	// @todo: make env.depended
	// return "https://alfalink-media.s3.eu-west-1.amazonaws.com" + (('/'+path).split('//').join('/'));
	return "https://alfalink-media-s3.s3.amazonaws.com" + (('/'+path).split('//').join('/'));
};

export const isS3URL = (url) => {
	// @todo: make env.depended
	if ((''+url).indexOf('https://alfalink-media.s3') !== -1) {
		return true;
	}

	return false;
};