import styled, { keyframes } from "styled-components";

import { Text } from "@chakra-ui/react";

const flow = keyframes`
0% {
background-position: 0 50%;
}

50% {
    background-position: 100% 50%;
}

100% {
    background-position: 0 50%
}

`;

const StyledText = styled(Text)`
  font-size: ${({ fontSize }) => fontSize ?? "1.5rem"};
  background-color: #111;
  background-image: linear-gradient(-45deg, #bb10dd, #8e3ab7, #b036e9, #e936e1);
  background-size: 300%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  animation: ${flow} 6s ease-in-out infinite;
  text-shadow: ${({ shadow }) =>
    shadow ? "0px 0px 4px rgb(0 0 0 / 14%)" : "none"};

  animation-iteration-count: 6;
  font-family: "Lexend" !important;
  font-weight: 700;
`;

const GradientText = ({
  text,
  fontSize,
  shadow,
  onClick,
}: {
  text: string;
  fontSize?: any;
  shadow?: boolean;
  onClick?: any;
}) => {
  return (
    <StyledText
      fontSize={fontSize ? fontSize : { base: "1rem", md: "1.5rem" }}
      onClick={onClick}
      shadow={shadow}
    >
      {text}
    </StyledText>
  );
};

export default GradientText;
