import { Box, Heading, Link, Stack, Text } from "@chakra-ui/layout";

import { Helmet } from "react-helmet";
import Page from "components/Page";

const TermsAndConditionScreen = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Alfalink</title>
      </Helmet>

      <Page>
        <Stack
          spacing="2rem"
          display="flex"
          py={{ base: "1rem", "2xl": "2rem" }}
          px={{ base: "0rem", md: "0rem" }}
        >
          <Box>
            <Heading as="h4" size="md">
              Terms & Conditions
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Last updated Aug 5, 2022
            </Text>
          </Box>

          <Stack
            spacing="2rem"
            pb="2rem"
            color="gray.700"
            fontWeight="light"
            letterSpacing="-0.1px"
            fontSize="12px"
          >
            <Stack>
              <Heading as="h6" size="sm">
                Welcome to alfalink.co!
              </Heading>

              <p>
                These terms and conditions outline the rules and regulations for
                the use of alfalink.co's Website, located at www.alfalink.co.
              </p>

              <p>
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use alfalink.co if you do not
                agree to take all of the terms and conditions stated on this
                page.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Cookies
              </Heading>
              <p>
                We employ the use of cookies. By accessing alfalink.co, you
                agreed to use cookies in agreement with the alfalink.co's{" "}
                <Link textDecoration="underline" href="/in/privacy">
                  Privacy Policy
                </Link>
                .
              </p>

              <p>
                Most interactive websites use cookies to let us retrieve the
                user’s details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                License
              </Heading>

              <p>
                Unless otherwise stated, alfalink.co and/or its licensors own
                the intellectual property rights for all material on
                alfalink.co. All intellectual property rights are reserved. You
                may access this from alfalink.co for your own personal use
                subjected to restrictions set in these terms and conditions.
              </p>

              <p>You must not:</p>
              <Box pl="1rem">
                <ul>
                  <li>Republish material from alfalink.co</li>
                  <li>Sell, rent or sub-license material from alfalink.co</li>
                  <li>
                    Reproduce, duplicate or copy material from alfalink.co
                  </li>
                  <li>Redistribute content from alfalink.co</li>
                </ul>
              </Box>

              <p>
                alfalink.co offer an opportunity for users to share their online
                Links and/or content. alfalink.co does not filter, edit, publish
                or review Links prior to their presence on the website. Links
                shared by users do not reflect the views and opinions of
                alfalink.co, its agents and/or affiliates To the extent
                permitted by applicable laws, alfalink.co shall not be liable
                for the Links or for any liability, damages or expenses caused
                and/or suffered as a result of any use of and/or posting of
                and/or appearance of the Links on this website.
              </p>

              <p>You warrant and represent that:</p>
              <Box pl="1rem">
                <ul>
                  <li>
                    You are entitled to post the Links and/or content on our
                    website and have all necessary licenses and consents to do
                    so;
                  </li>
                  <li>
                    The Links and/or contents shared do not invade any
                    intellectual property right, including without limitation
                    copyright, patent or trademark of any third party;
                  </li>
                  <li>
                    The Links and/content do not contain any defamatory,
                    libelous, offensive, indecent or otherwise unlawful material
                    which is an invasion of privacy
                  </li>
                  <li>
                    The Links and/or content will not be used to solicit or
                    promote business or custom or present unlawful activity.
                  </li>
                </ul>
              </Box>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Your Privacy
              </Heading>

              <p>
                Please read our{" "}
                <Link textDecoration="underline" href="/in/privacy">
                  Privacy Policy
                </Link>
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Limitation of Liability
              </Heading>
              <p>
                In no event shall alfalink.co, nor its directors, employees,
                partners, agents, suppliers, or affiliates, be liable for any
                indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from (i)
                your access to or use of or inability to access or use the
                Service; (ii) any conduct or content of any third party on the
                Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or
                content, whether based on warranty, contract, tort (including
                negligence) or any other legal theory, whether or not we have
                been informed of the possibility of such damage, and even if a
                remedy set forth herein is found to have failed of its essential
                purpose.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Intellectual Property: Ownership
              </Heading>
              <p>
                Content that you create remains Your Content. This means that by
                using our platform no intellectual property rights are
                transferred from you to us. It is your responsibility to make
                sure that Your Content does not infringe intellectual property
                rights of any other party. This means that if you use Content
                that you have not created from scratch yourself, you need to
                make sure that you have sufficient permission to use this
                Content. You usually need (i) a license to use another party’s
                content or work and (ii) if you have obtained a license or an
                open source license applies to the relevant content: You need to
                comply with the terms and conditions of that license. If we are
                held liable for infringement of third party intellectual
                property rights as a result of your actions or omissions, you
                shall indemnify us and hold us harmless. This means that you
                will have to pay all our damages including legal fees.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Payments
              </Heading>

              <p>
                You may use Alfalink to collect payments from your Users. We use{" "}
                <Link
                  textDecoration="underline"
                  href="https://stripe.com"
                  isExternal
                >
                  Stripe
                </Link>{" "}
                (also referred to as "Payment Processors") , the Stripe API,
                Stripe Connect, and in conjunction, your Stripe account to
                process credit and debit card transactions. That includes Apple
                Pay and Google pay, if you or your profile visitors have a
                Digital Wallet active and enabled.
              </p>
              <p>
                Your use of Payment Processors will be subject to such
                applicable Payment Processor’s terms and conditions, privacy
                policy, and all other relevant agreements (collectively, with
                the Stripe Service Agreement, the “Payment Processor
                Agreements”). Specifically, Your use of (i) the Stripe services
                is subject to the Stripe Connect Platform Agreement located at{" "}
                <Link
                  textDecoration="underline"
                  href="https://stripe.com/connect/legal"
                  isExternal
                >
                  https://stripe.com/connect/legal
                </Link>
                , which includes the Stripe Services Agreement and Stripe’s
                Global Privacy Policy located at{" "}
                <Link
                  textDecoration="underline"
                  href="https://stripe.com/legal"
                  isExternal
                >
                  https://stripe.com/legal
                </Link>{" "}
                and{" "}
                <Link
                  textDecoration="underline"
                  href="https://stripe.com/privacy"
                  isExternal
                >
                  https://stripe.com/privacy
                </Link>
                .
              </p>

              <p>
                You expressly understand and agree that Alfalink shall not be
                liable for any payments and monetary transactions that occur
                through Your use of the Service. You expressly understand and
                agree that all payments and monetary transactions are handled by
                Stripe. You agree that Alfalink shall not be liable for any
                issues regarding financial and monetary transactions between You
                and any other party, including Stripe. You are responsible for
                all transactions (one-time, recurring, and refunds) processed
                through the Service and/or Stripe. Alfalink is not liable for
                loss or damage from errant or invalid transactions processed
                with Your Stripe account. This includes transactions that were
                not processed due to a network communication error, or any other
                reason. If You process a transaction, it is Your responsibility
                to verify that the transaction was successfully processed. You
                must not process stolen credit cards, or unauthorized credit
                cards through Stripe.
              </p>

              <Text fontWeight="bold">Fees</Text>
              <p>
                In consideration for the rights granted herein to access and use
                the Alfalink, You agrees to pay Alfalink the monthly
                subscription fee identified during the Monthly Subscription
                Process or at the time of renewal of the Subscription Term, as
                applicable (the “Subscription Fee”) and You also agrees that
                Alfalink will receive a certain percentage of each purchase or
                transaction that is processed through Alfalink or (the “Service
                Fee” and together with the Subscription Fee, the “Fees”) that is
                agreed to by You during the Monthly Subscription Process or at
                the time of renewal of the Subscription Fee. The Service Fee
                will be paid by You to Alfalink via Stripe upon receipt of
                payment for a purchase or transaction. The Subscription Fee will
                be paid by You to Alfalink in accordance with the payment
                schedule that is agreed upon during the Subscription Process or
                at the time of renewal of the Subscription Term. You hereby
                authorizes Alfalink to automatically charge Your credit card,
                commercial debit card or other agreed upon payment method for
                the full amount of such Subscription Fees in advance of the
                start of each such Subscription Term. You represent and warrant
                that any credit or debit card presented to us for payment is
                yours or under your authorization. You understand that this
                authorization will remain in effect until the expiration or
                termination of this Agreement. You will provide true, complete
                and accurate information with respect to any applicable method
                of payment and agrees to promptly contact Alfalink if any such
                information needs to be updated. You will ensure that you have
                sufficient funds or credit (as applicable) associated with any
                selected method of payment. If Alfalink is not able to process
                payment of Fees using your selected payment method, Alfalink may
                make up to two additional attempts to process payment of the
                Fees, as permitted by law. If the final attempt is not
                successful, we may suspend and revoke your access to our
                services. Payment of any Subscription Fees is non-refundable.
              </p>
              <br />

              <Text fontWeight="bold">Free trials and promotional periods</Text>

              <p>
                If a subscription commences with a free trial or a promotional
                period, you will have the right to cancel the subscription prior
                to the end of the trial or period. If we do not provide you with
                an online cancellation mechanism for technical or any other
                reason, then you may contact us directly and we will help you.
                We will email you prior to the end of the free trial or
                promotional period to remind you that the trial or period is
                coming to an end, and to give you an opportunity to cancel
                before the commencement of the paid period. If you do not
                cancel, we will bill you at the end of the free trial or
                promotional period, and your subsequent cancellation rights will
                be in accordance with the terms specific to the subscription.
              </p>
              <br />

              <Text fontWeight="bold">Cancellation</Text>

              <p>
                You can change or cancel your plan at any time. Visit the{" "}
                <Link textDecoration="underline" href="/in/profile">
                  Settings page
                </Link>{" "}
                for details. Please not that we do not offer refunds. If you
                cancel your plan before the next renewal cycle, you will retain
                access to paid features until the end of your subscription
                period. When your subscription plan expires, you will lose
                access to the features on that plan.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                While in Beta
              </Heading>

              <p>
                Beta in this context means that the feature we offer are
                functional, but not complete. Meaning you may encounter bugs and
                issues. If you encounter such cases, please feel free to&nbsp;
                <Link textDecoration="underline" href="/in/contact">
                  contact us
                </Link>
                &nbsp;and we will look into it.
              </p>

              <p>
                During Beta period of alfalink.co, we will do our best to inform
                you about any change we make to our website and alfalink.co
                editor - including change of functionality of an implemented
                feature, adding or removing a feature, changing name of features
                or changing texts and definitions - but we are not obligated to
                inform you directly.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Removal of links from our website
              </Heading>

              <p>
                If you find any link on our Website that is offensive for any
                reason, you are free to{" "}
                <Link textDecoration="underline" href="/in/contact">
                  contact and inform us
                </Link>{" "}
                any moment. We will consider requests to remove links but we are
                not obligated to or so or to respond to you directly.
              </p>

              <p>
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Disclaimer
              </Heading>
              <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>

              <Box pl="1rem">
                <ul>
                  <li>
                    limit or exclude our or your liability for death or personal
                    injury;
                  </li>
                  <li>
                    limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation;
                  </li>
                  <li>
                    limit any of our or your liabilities in any way that is not
                    permitted under applicable law; or
                  </li>
                  <li>
                    exclude any of our or your liabilities that may not be
                    excluded under applicable law.
                  </li>
                </ul>
              </Box>

              <p>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>

              <p>
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Glossary
              </Heading>
              <p>
                Account. Represents your legal relationship with alfalink.co. A
                “User Account” represents an individual User’s authorization to
                log in to and use the Service and serves as a User’s identity.
              </p>
              <p>alfalink.co, We (us or our).</p>
              <p>
                Service. Any applications, software, products, and services
                provided by alfalink.co, including any early versions or
                experiments.
              </p>
              <p>Content. Refers to content featured or displayed</p>
              through the Website, including without limitation code, text,
              data, articles, images, photographs, graphics, software,
              applications, packages, designs, features, and other materials
              that are available through the Service. “Content” also includes
              Services.
              <p>
                User Generated Content. Content, written or otherwise, created
                or uploaded by Users. "Your Content" is Content that you create
                or own.
              </p>
              <p>
                User, You (Your) or your Alfalink profile visitors. Anyone
                interacting with alfalink.co Service. This can be an individual
                person, company, or organization. We consider several types of
                Users: those who view or interact with alfalink.co or
                alfalink.co Embeds via other websites, visitors not signed in
                and users with an alfalink.co Account who are signed in.
              </p>
            </Stack>
          </Stack>
        </Stack>
      </Page>
    </>
  );
};

export default TermsAndConditionScreen;
