import { Box, Button, Link, Text } from "@chakra-ui/react";
import styled, { css } from "styled-components";

import { COOKIES_CONSENT } from "utils/constants";
import Cookies from "js-cookie";
import { useState } from "react";

const StyledBox = styled(Box)<{ hide: boolean }>`
  backdrop-filter: blur(3px);

  //box-shadow: rgb(9 20 66 / 50%) 0px 20px 32px -8px;
  box-shadow: rgb(146 0 145 / 61%) 0px 0px 24px -10px;
  z-index: 9999;
  transition: left 1s ease 0s, bottom 1s ease 0s, right 1s ease 0s;

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      visibility: hidden;
      transition: visibility 300ms, opacity 300ms linear;
    `}
`;

export const CookiesConsent = () => {
  const consent = Cookies.get(COOKIES_CONSENT);

  const [showBanner, setShowBanner] = useState(consent !== "true");

  const handleOnClick = () => {
    Cookies.set(COOKIES_CONSENT, "true", { expires: 1000 });
    setShowBanner(false);
  };

  return showBanner ? (
    <StyledBox
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      hide={!showBanner}
      fontSize="xs"
      position="fixed"
      bottom="10px"
      left="10px"
      p="0.75rem"
      width="224px"
      zIndex="1111"
      borderRadius="10px"
      bg="#ffffff4d"
      color="gray.650"
    >
      <>
        <Text mb="0.4rem">Yet another cookies disclaimer..</Text>

        <Text fontSize="10px">
          Learn more about our{" "}
          <Link
            textDecoration="underline"
            href="/in/privacy"
            aria-label="learn more about cookies"
          >
            Privacy Policy
          </Link>
        </Text>
      </>

      <Button
        variant="outline"
        size="xs"
        colorScheme="purple"
        onClick={handleOnClick}
        mt="0.75rem"
      >
        Got it!
      </Button>
    </StyledBox>
  ) : null;
};
