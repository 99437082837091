import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/layout";

import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { HStack } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import LinkedInIcon from "@iconscout/react-unicons/icons/uil-linkedin";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import { marginX } from "utils/constants";
import { cloudfrontURL } from "utils/urlBuilder.js";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      px={marginX}
      fontSize="xs"
      position="absolute"
      width="100%"
      p={{ base: "1rem", md: "1rem 0" }}
      color={"#203746"}
    >
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={{ base: "3rem", md: "6rem", lg: "8rem", xl: "12rem" }}
        pb="0.5rem"
      >
        <Stack spacing="0.5rem">
          <AnimatedGradientLogo showBadge />

          <Stack spacing="0.5rem">
            <Link href="/in/privacy">Privacy Policies</Link>
            <Link href="/in/terms">Terms and Conditions</Link>
          </Stack>
        </Stack>

        <Box pt="0.25rem">
          <Stack spacing="0.5rem">
            <Heading as="h5" size="sm">
              Product
            </Heading>

            <Link href="/in/pricing">Pricing</Link>
            <Link href="/in/signup">Sign up</Link>
            <Link href="/in/support">Support</Link>
          </Stack>
        </Box>

        <Box pt="0.25rem">
          <Stack spacing="0.5rem">
            <Heading as="h5" size="sm">
              Company
            </Heading>

            <Link href="/in/jobs">Careers</Link>
            <Link href="/in/contact">Contact us</Link>
          </Stack>
        </Box>
      </Grid>

      <Divider borderColor="#ebebeb" my="0.5rem" />
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="xs" color="gray.500">
          &copy; {currentYear} <Link href="/">alfalink.co</Link>. All rights
          reserved.{" "}
        </Text>

        <HStack spacing="0.5rem">
          <Link href="/hey" isExternal cursor="pointer">
            <Image
              opacity="0.85"
              width="16px"
              src={cloudfrontURL("/1636815935152")}
              cursor="pointer"
              alt="alfalink.co"
            />
          </Link>

          <Link
            href="https://www.instagram.com/alfalink.co"
            isExternal
            cursor="pointer"
          >
            <InstagramIcon size="20px" />
          </Link>

          <Link
            href="https://www.linkedin.com/company/alfalinkco"
            isExternal
            cursor="pointer"
          >
            <LinkedInIcon size="20px" />
          </Link>
          <Link
            href="https://twitter.com/alfalinkco"
            isExternal
            cursor="pointer"
          >
            <TwitterIcon size="20px" />
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Footer;
