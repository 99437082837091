import {
  AnalyticsFeatures,
  LinkFeatures,
  PaymentFeatures,
  SupportFeatures,
  coreFeatures,
} from "containers/PricingScreen";
import {
  Box,
  Fade as CFade,
  Center,
  Flex,
  Heading,
  Image,
  SlideFade,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import Fade from "react-reveal/Fade";
import Features2 from "./Features2";
import GetStartedInput from "components/GetStartedInput";
import GradientText from "components/GradientText";
import { PlanCompareTable } from "components/PlanCompareTable";
import styled from "styled-components";
import { useState } from "react";
import { cloudfrontURL } from "utils/urlBuilder.js";

const CardBox = styled(Box)<{ selected?: boolean }>`
  border-radius: 6px;
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  transition: all 400ms ease 0s;

  :hover {
    h4 {
      text-decoration: underline;
    }
  }

  ${({ selected }) =>
    selected &&
    `
    text-decoration: none;
    border-bottom: 2px;
    border-style: solid;
    border-color: #d50d4e;
    box-shadow: #d9d9d9 0px 4px 20px 0px;
    opacity: 1;
    transform: translateY(-7px);

   :active, :focus {
    box-shadow: #d9d9d9 0px 4px 20px 0px !important;

  }
`};
`;

const FeatureList = [
  {
    name: "videoLink",
    title: "Video Link",
    description:
      "Embed and watch videos from YouTube, Twitch, Facebook, Vimeo, DailyMotion, and many more sources",
  },

  {
    name: "spotifyLink",
    title: "Spotify Link",
    description: "Embed and listen to Spotify songs, playlists, and podcasts",
  },
  {
    name: "payment",
    title: "Collect Payments",
    description:
      "Collect tips and supportive donations smoothly with Apple Pay, Google Pay or Credit Card",
  },

  {
    name: "analytics",
    title: "Analytics",
    description:
      "See how many people have visited your profile, how many have clicked on your link and much more in depth analytics about your links",
  },

  {
    name: "backgroundImageAndVideo",
    title: "Video and Image background",
    description:
      "Along with solid and gradient colors, you can add video or image background to your Alfalink",
  },

  {
    name: "linkCutomization",
    title: "Link appearance customization",
    description:
      "Customize button style, font type and size, text color and link background color, add animation, icon or emoji and much more editing freedom",
  },
];
const FeaturesList = () => {
  const [selectedFeat, setSelectedFeat] = useState("videoLink");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const awsFileLinks = {
    videoLink: cloudfrontURL("/video.jpg"),
    spotifyLink: cloudfrontURL("/spotify.jpg"),
    linkCutomization: cloudfrontURL("/linkstyles.jpg"),
    analytics: cloudfrontURL("/analyticspage3.png"),
    payment: cloudfrontURL("/collectpayment21219.png"),
    backgroundImageAndVideo: cloudfrontURL("/videoback.mov"),
    header: cloudfrontURL("/headerlayouts.jpg"),
    socialHandles: cloudfrontURL("/socials.jpg"),
  };

  const featureMap = (feat) => {
    switch (feat) {
      case "videoLink":
        return <Image src={awsFileLinks.videoLink} alt={feat} />;

      case "spotifyLink":
        return <Image src={awsFileLinks.spotifyLink} alt={feat} />;

      case "payment":
        return <Image src={awsFileLinks.payment} alt={feat} />;

      case "linkCutomization":
        return <Image src={awsFileLinks.linkCutomization} alt={feat} />;

      case "analytics":
        return <Image src={awsFileLinks.analytics} alt={feat} />;

      case "backgroundImageAndVideo":
        return (
          <Box>
            <video
              autoPlay
              loop
              muted
              src={awsFileLinks.backgroundImageAndVideo}
            />
          </Box>
        );
      case "header":
        return <Image src={awsFileLinks.header} alt={feat} />;

      case "socialHandles":
        return <Image src={awsFileLinks.socialHandles} alt={feat} />;

      default:
        return <Image src={awsFileLinks.linkCutomization} alt={feat} />;
    }
  };

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        pb="2rem"
        fontSize="2rem"
        position="relative"
      >
        <Box
          lineHeight="1"
          textAlign="center"
          justifyContent={{ base: "left", md: "center" }}
          alignItems="center"
        >
          <GradientText
            fontSize={{ base: "2.125rem", md: "2.75rem" }}
            text="Variety of features"
          />

          <Text
            fontSize={{ base: "0.825rem", md: "1rem" }}
            fontWeight="900"
            color="gray.700"
          >
            to help you get the best out of your links
          </Text>
        </Box>
      </Flex>

      {isLargerThan768 ? (
        <Box display="flex" flexDirection="row" py="2rem">
          <Box
            width="55%"
            height="100%"
            py="0.5rem"
            pr="1rem"
            maxWidth="550px"
            m="auto"
          >
            {FeatureList.map(
              (f) =>
                selectedFeat === f.name && (
                  <SlideFade
                    key={f.name}
                    offsetY="20px"
                    in={selectedFeat === f.name}
                  >
                    {featureMap(selectedFeat)}
                  </SlideFade>
                )
            )}
          </Box>

          <Box width="45%" borderLeft="1px solid #ebebeb">
            <Stack spacing="1rem" p="0.75rem" alignItems="flex-start">
              {FeatureList.map((f) => (
                <CardBox
                  key={f.name}
                  as="button"
                  selected={selectedFeat === f.name}
                  onClick={() => setSelectedFeat(f.name)}
                >
                  <Stack spacing="0.5rem" p="1rem">
                    <Heading as="h4" fontSize="lg">
                      {f.title}
                    </Heading>

                    {selectedFeat === f.name && (
                      <CFade in={selectedFeat === f.name}>
                        <Text fontSize="sm" color="gray.600">
                          {f.description}
                        </Text>
                      </CFade>
                    )}
                  </Stack>
                </CardBox>
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <Stack
          spacing="5rem"
          width={{ sm: "100%", md: "55%" }}
          height="100%"
          minHeight={{ base: "300px", md: "560px" }}
          py={{ base: "0.5rem", md: "0.5rem" }}
          pr={{ base: "0", md: "1rem" }}
        >
          {FeatureList.map((f) => (
            <SlideFade key={f.name} offsetY="20px" in>
              <CFade in>
                <Heading as="h4" fontSize={{ base: "sm", md: "lg" }}>
                  {f.title}
                </Heading>

                <Text fontSize="sm" color="gray.500" p="4px">
                  {f.description}
                </Text>
              </CFade>
              <br />
              {featureMap(f.name)}
            </SlideFade>
          ))}
        </Stack>
      )}

      <Fade bottom>
        <Flex
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          py="5rem"
        >
          <GetStartedInput showInput />
        </Flex>
      </Fade>

      <Fade bottom>
        <Features2 />
      </Fade>

      <Box py="6rem">
        <Heading textAlign="center" size="lg">
          Compare plans & features
        </Heading>

        <Box
          border={{ base: "1px solid #111111", md: "1px solid #111111" }}
          p={{ base: "1rem 6px", md: "1rem 2rem" }}
          borderRadius="8px"
          my="1rem"
        >
          <Stack spacing="2rem">
            <PlanCompareTable
              showHeaders
              featureType="Core features"
              data={coreFeatures}
            />

            <PlanCompareTable
              featureType="Collect payments"
              data={PaymentFeatures}
            />

            <PlanCompareTable
              featureType="Detailed Analytics"
              data={AnalyticsFeatures}
            />

            <PlanCompareTable
              featureType="Links customization"
              data={LinkFeatures}
            />

            <PlanCompareTable featureType="Support" data={SupportFeatures} />
          </Stack>

          <Center
            textAlign="center"
            borderRadius="8px"
            mt="4rem"
            mb="1rem"
            p="1rem"
          >
            <GetStartedInput showInput />
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default FeaturesList;
