import FeaturesList from "components/Features/FeaturesList";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { Stack } from "@chakra-ui/react";

const FeaturesScreen = () => {
  return (
    <>
      <Helmet>
        <title>Features | Alfalink</title>
      </Helmet>
      <Page pt={{ base: "2rem", md: "4rem" }}>
        <Stack spacing={{ base: "1rem", lg: "2rem" }}>
          <FeaturesList />
        </Stack>
      </Page>
    </>
  );
};

export default FeaturesScreen;
