import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react";

import QRCode from "qrcode.react";
import saveSvgAsPng from "save-svg-as-png";
import { useState } from "react";
import { cloudfrontURL } from "utils/urlBuilder.js";

interface QrCodeModalTypes {
  username: string;
  userAlfalink: string;
  showQrCodeModal: boolean;
  onQrCodeModalClose: () => void;
  avatar: string;
}

const imageOptions = {
  scale: 2,
  encoderOptions: 1,
  backgroundColor: "white",
};

export const QrCodeModal = ({
  username,
  userAlfalink,
  showQrCodeModal,
  onQrCodeModalClose,
  avatar,
}: QrCodeModalTypes) => {
  const [useProfileImage, setUseProfileImage] = useState(false);

  const handleSaveQrCode = () => {
    saveSvgAsPng.saveSvgAsPng(
      document.getElementById("qr-svg"),
      `${username}.png`,
      imageOptions
    );
  };

  return (
    <Modal
      isOpen={showQrCodeModal}
      onClose={onQrCodeModalClose}
      isCentered
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>QR Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          mb="2rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="gray.600" fontSize="sm">
            Your unique alfalink QR code. It will take visitors to your alfalink
            when scanned.
          </Text>
          <QRCode
            id={"qr-svg"}
            value={userAlfalink}
            size={184}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            includeMargin={true}
            level="H"
            renderAs={"svg"}
            imageSettings={{
              src:
                avatar && useProfileImage
                  ? avatar
                  : cloudfrontURL("/1636815935152"),
              x: null,
              y: null,
              height: avatar && useProfileImage ? 50 : 32,
              width: avatar && useProfileImage ? 50 : 32,
              excavate: true,
            }}
          />

          {avatar ? (
            <FormControl
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              onChange={(e: any) => setUseProfileImage(e.target.checked)}
              py="1rem"
            >
              <FormLabel htmlFor="useProfileImage" mb="0">
                Use account picture in your QR code
              </FormLabel>
              <Switch id="useProfileImage" isDisabled={!avatar} size="lg" />
            </FormControl>
          ) : null}

          <Button
            mt="2rem"
            variant="solid"
            isFullWidth
            onClick={handleSaveQrCode}
          >
            Save QR code as image
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
