import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  HStack,
  Heading,
  Radio,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";
import { useEditor, useNode } from "@craftjs/core";

import Delete from "@iconscout/react-unicons/icons/uil-trash-alt";
import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { FontColorIcon } from "assets/svgs/fontColorIcon";
import { LinkDecorationsIcon } from "assets/svgs/linkDecorationsIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilImage from "@iconscout/react-unicons/icons/uil-image";
import UilInfo from "@iconscout/react-unicons/icons/uil-info-circle";
import UilLayoutCenter from "@iconscout/react-unicons/icons/uil-document-layout-center";
import UilLayoutLeft from "@iconscout/react-unicons/icons/uil-document-layout-left";
import UilMoreIcon from "@iconscout/react-unicons/icons/uil-ellipsis-h";

export const HeaderContainerSettings = () => {
  const { id, propValue } = useNode((node) => ({
    propValue: node.data.props,
  }));

  const { actions } = useEditor();

  const [drawerOpen, setDrawer] = useState("");

  const closeDrawer = () => {
    setDrawer("");
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const BackgroundImageSettingItem = (
    <>
      <SettingsItem full propKey="useImage" type="switch" label="Use image" />

      <Collapse in={propValue.useImage} animateOpacity>
        <Box>
          <SettingsItem
            full
            propKey="backgroundImageUrl"
            type="imageGallery"
            withUnsplash
          />

          {propValue.backgroundImageUrl && (
            <>
              <Divider my="0.75rem" borderColor="#ececec" />

              <Stack spacing="1rem" mb={{ base: "1rem", md: "2rem" }}>
                <SettingsItem
                  full
                  propKey="positionX"
                  type="slider"
                  subLabel="Horizontal positioning"
                  sliderMin={0}
                  sliderMax={100}
                />
                <SettingsItem
                  full
                  propKey="positionY"
                  type="slider"
                  subLabel="Vertical positioning"
                  sliderMin={0}
                  sliderMax={100}
                />
              </Stack>

              <Divider my="0.75rem" borderColor="#ececec" />

              <SettingsItem
                full
                propKey="backgroundImageUrl"
                type="text"
                placeholder="Image URL address"
                label="Image URL address"
                subLabel="You can paste your own online image URL here."
              />
            </>
          )}
        </Box>
      </Collapse>
    </>
  );

  const MessageIfImageIsActive = propValue.useImage ? (
    <Box
      bg="gray.200"
      p="0.25rem"
      mb={isLargerThan768 ? "0.5rem" : "1rem"}
      borderRadius="4px"
    >
      <Alert
        borderRadius="4px"
        status="info"
        fontSize="xs"
        p="0.25rem"
        mb={isLargerThan768 ? "0.5rem" : "0.5rem"}
      >
        <UilInfo />

        <Text ml="0.5rem">
          An image is already set as background. <br /> Please disable that to
          use color background.
        </Text>
      </Alert>

      <SettingsItem full propKey="useImage" type="switch" label="Image" />
    </Box>
  ) : null;

  const MobileBackgroundColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Background color
      </Heading>

      <SettingsItem full={true} propKey="background" type="gradient" />
    </>
  );

  const MobileTextColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Text color
      </Heading>

      <SettingsItem
        full={true}
        propKey="color"
        type="gradient"
        useGradient={false}
      />
    </>
  );

  const ColorSettingItems = (
    <>
      {!propValue.useImage && MobileBackgroundColorSettingItem}

      <Divider my="1.5em" />

      {MobileTextColorSettingItem}
    </>
  );

  const MobileDecorationSettingItems = (
    <>
      {propValue.useImage ? (
        <>
          <SettingsItem
            full
            propKey="imageBorderLeftRadius"
            type="slider"
            sliderMin={0}
            sliderMax={100}
            label="Image border bottom left radius"
          />

          <Divider my="1em" />

          <SettingsItem
            full
            propKey="imageBorderRightRadius"
            type="slider"
            sliderMin={0}
            sliderMax={100}
            label="Image border bottom right radius"
          />
        </>
      ) : (
        <>
          <SettingsItem
            full
            propKey="borderBottomLeftRadius"
            type="slider"
            sliderMin={0}
            sliderMax={100}
            label="Border bottom left radius"
          />

          <Divider my="1em" />

          <SettingsItem
            full
            propKey="borderBottomRightRadius"
            type="slider"
            sliderMin={0}
            sliderMax={100}
            label="Border bottom right radius"
          />
        </>
      )}

      <Divider my="1em" />

      <SettingsItem full propKey="shadow" type="slider" label="Shadow" />
    </>
  );

  const MobileMoreSettingItems = (
    <>
      <Text color="gray.500" fontSize="xs">
        When active, @username will be hidden from your public profile
      </Text>
      <SettingsItem
        full={true}
        propKey="hideUsername"
        type="switch"
        label="Hide username"
      />

      <Divider my="1em" />

      <Text color="gray.500" fontSize="xs">
        When active, profile image will be hidden from your public profile
      </Text>
      <SettingsItem
        full={true}
        propKey="hideProfileImage"
        type="switch"
        label="Hide profile image"
      />
    </>
  );
  const DecorationSettingItems = (
    <>
      {MobileMoreSettingItems}

      <Divider my="1em" />

      {MobileDecorationSettingItems}
    </>
  );

  const LayoutSettingItems = (
    <>
      <Text color="gray.500" fontSize="xs" mb="2px">
        When active, the header component will stay at top
      </Text>
      <SettingsItem
        full={true}
        propKey="isSticky"
        type="switch"
        label="Sticky header"
      />

      <Divider my="1em" />

      <Heading as="h6" size="xs" pl="2px" my="0.5rem">
        Layout style
      </Heading>

      <SettingsItem full propKey="headerStyle" type="buttonRadio">
        <HStack spacing="2rem" pb="1rem">
          <Radio value="row" cursor="pointer">
            <UilLayoutLeft size="40px" />
          </Radio>

          <Radio value="vertical" cursor="pointer">
            <UilLayoutCenter size="40px" />
          </Radio>

          <Radio value="middle" cursor="pointer">
            <UilLayoutCenter
              size="40px"
              style={{ transform: "rotate(180deg)" }}
            />
          </Radio>
        </HStack>
      </SettingsItem>
    </>
  );

  return isLargerThan768 ? (
    <Tabs isFitted>
      <TabList mb="1em">
        <Tab>Color</Tab>
        <Tab>Image</Tab>
        <Tab>Decoration</Tab>
        <Tab>Layout</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {MessageIfImageIsActive}
          {ColorSettingItems}
        </TabPanel>

        <TabPanel mb="2rem">{BackgroundImageSettingItem}</TabPanel>

        <TabPanel>{DecorationSettingItems}</TabPanel>

        <TabPanel>{LayoutSettingItems}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper
        hidden={
          drawerOpen === "backgroundColor" ||
          drawerOpen === "textColor" ||
          drawerOpen === "decoration" ||
          drawerOpen === "image" ||
          drawerOpen === "layout" ||
          drawerOpen === "more"
        }
      >
        <SettingIconButton onClick={() => setDrawer("layout")}>
          <UilLayoutCenter />
          <Text>Layout</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("backgroundColor")}>
          <PaintBucketIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.background ? propValue.background : "#111",
            }}
          />

          <Text>Background</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("textColor")}>
          <FontColorIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.color ? propValue.color : "#eee",
            }}
          />

          <Text>Color</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("image")}>
          <UilImage />
          <Text>Image</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("decoration")}>
          <LinkDecorationsIcon />
          <Text>Decorations</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("more")}>
          <UilMoreIcon />
          <Text>More</Text>
        </SettingIconButton>

        <SettingIconButton
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            actions.delete(id);
          }}
        >
          <Delete />
          <Text>Delete</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "textColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Text color">
          {MobileTextColorSettingItem}

          <Button
            my="1.5rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("backgroundColor")}
          >
            Change background color <UilArrowRight size="20px" />
          </Button>
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "backgroundColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Background color">
          {MessageIfImageIsActive}

          {!propValue.useImage && (
            <>
              {MobileBackgroundColorSettingItem}

              <Button
                my="1.5rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("textColor")}
              >
                Change text color <UilArrowRight size="20px" />
              </Button>
            </>
          )}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "decoration"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Decorations">
          {MobileDecorationSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "more"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="More">{MobileMoreSettingItems}</DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "image"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Background image">
          {BackgroundImageSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "layout"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="34vh" pt="1rem" title="Layout">
          {LayoutSettingItems}
        </DrawerContent>
      </Drawer>
    </>
  );
};
