import React, { useState } from "react";

import api from "api";
import axios from "axios";
import { useParams } from "react-router-dom";

export const useAdData = (idString?: any): any => {
  let { id } = useParams();

  if (idString) {
    id = idString;
  }

  const [frameData, setFrameData] = React.useState<string>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  // const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);

  React.useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    api
      .get(`api/private/ad/frame-data/${id}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        console.info("Frame data ", data.frameData);
        setFrameData(data.frameData);
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.error("Error  ", err);
        setLoading(false);

        // if (err && err.response && err.response.status === 404) {
        //   return setIsUsernameAvailable(true);
        // }

        setError(err);
        console.log("Network error ", err);

        if (axios.isCancel(err)) {
          console.warn("Api request canceled", err.message);
        }
      });

    return () => source.cancel("FrameData hook cleanup");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { adData: {frameData: frameData, id: id}, loading, error };
};

export default useAdData;
