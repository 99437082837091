import { Box, Heading, Link, Text, useMediaQuery } from "@chakra-ui/react";

import Fade from "react-reveal/Fade";
import Lottie from "react-lottie";
import security from "assets/jsons/security.json";

const defaultOptions = {
  loop: true,
  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: security,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Privacy = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column-reverse", md: "row" }}
      p={{ base: "1rem", md: "1rem 2rem" }}
      my="2rem"
      //background=" #15182a"
      background="linear-gradient(345deg, #15182a 0%, #262b47  100%)"
      borderRadius="10px"
      color="#f0f5ff"
    >
      <Box
        pt={{ base: "1rem", md: "6rem" }}
        width={{ sm: "50%", md: "50%" }}
        pl={{ md: "0rem", l: "2rem", xl: "3rem" }}
        pb={{ base: "2rem", md: "1em" }}
      >
        <Fade bottom>
          <Heading
            mb="0.5rem"
            as="h2"
            fontSize={{ base: "1.5rem", md: "2rem" }}
          >
            Privacy at heart
          </Heading>

          <Heading as="h3" size="sm" mb="1rem">
            You can safely and securely share your alfalink on the internet.
          </Heading>

          <Text fontWeight="bold" py="1rem">
            We will never track or save any personal data or information about
            your visitors, nor will we ever sell your data.
          </Text>

          <Link fontSize="xs" href="/in/privacy">
            <i>Read more about our Privacy Policy</i>
          </Link>
        </Fade>
      </Box>

      <Box margin="auto" pt="0">
        <Lottie
          options={defaultOptions}
          height={isSmallerThan600 ? 300 : 400}
          width={isSmallerThan600 ? 300 : 400}
        />
      </Box>
    </Box>
  );
};

export default Privacy;
