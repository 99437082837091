import { Input } from "@chakra-ui/react";
import { useState } from "react";

export type ToolbarTextInput = {
  isUrlInput?: boolean;
  onChange?: (value: any) => void;
  value?: any;
  placeholder?: string;
};

export const SettingsOnBlurTextInputItem = ({
  onChange,
  value,
  placeholder,
  ...props
}: ToolbarTextInput) => {
  const [def, setDef] = useState(value);

  const handleOnTextBlur = (e: any) => {
    onChange((e.target as any).value);
  };

  const handleOnTextChange = (e: any) => {
    setDef((e.target as any).value);
  };

  return (
    <div>
      <Input
        placeholder={placeholder}
        style={{ margin: 0, width: "100%" }}
        value={def || ""}
        onBlur={handleOnTextBlur}
        onChange={handleOnTextChange}
        {...props}
      />
    </div>
  );
};
