import {
  Box,
  Button,
  Fade,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useEditor, useNode } from "@craftjs/core";

import { BuyMeACoffee } from "../Embeds/BuyMeACoffee";
import { Image } from "../Embeds/Image";
import { ContainerSettings } from "./settings";
import { KoFi } from "../Embeds/Ko-Fi";
import { Label } from "components/Selectors/Label";
import { Link } from "components/Selectors/Link";
import { Music } from "../Embeds/Music";
import { SocialLinks } from "components/Selectors/SocialLinks";
import { Twitter } from "../Embeds/Twitter";
import UilRedo from "@iconscout/react-unicons/icons/uil-redo";
import { getAuthToken } from "utils";
import jwt_decode from "jwt-decode";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { cloudfrontURL } from "utils/urlBuilder.js";

const StyledBox = styled(Box)`
  box-shadow: rgb(239, 239, 239) 0px 0px 12px;
  position: relative;
  margin: 0 auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  background: transparent;

  ::-webkit-scrollbar {
    display: none;
  }

  * {
    font-family: ${({ ownfontfamily }) =>
      ownfontfamily ? ownfontfamily : "unset"};
  }
`;

export type MainContainerProps = {
  background: string;
  children: React.ReactNode;
  backgroundImageUrl?: string;

  useImage?: boolean;
  useVideo?: boolean;
  backgroundVideoUrl?: string;
  fontFamily?: string;
  positionX?: string;
  positionY?: string;
};

const defaultProps = {
  useImage: false,
  useVideo: false,
  backgroundImageUrl: "",
  backgroundVideoUrl: cloudfrontURL("/vidbg1633556650001.mp4"),
  background: "linear-gradient(0deg, #48099b 0%, rgb(105, 79, 246) 100%)",
  width: "20px",
  height: "54px",
  positionX: "auto",
  positionY: "auto",
  fontFamily: "",
};

const ImageContainer = styled(Box)<{
  backgroundimageurl: string;
  positiony: string;
  positionx: string;
}>`
  position: absolute;
  background-image: ${({ backgroundimageurl }) =>
    `url(${backgroundimageurl})`} !important;

  background-size: cover !important;

  background-position-y: ${({ positiony }) => `${positiony}%`} !important;
  background-position-x: ${({ positionx }) => `${positionx}%`} !important;

  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const Video = styled.video<{
  isPublicView?: boolean;
  type?: any;
}>`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const Row = styled(Box)`
  :after {
    content: "";
    display: table;
    clear: both;
  }

  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const Column = styled(HStack)`
  float: left;

  ${({ left }) =>
    left &&
    `
width: 15%

`};

  ${({ middle }) =>
    middle &&
    `

width: 75%;
`};

  ${({ right }) =>
    right &&
    `
width: 10%;

`};
`;

const RoundDot = styled.span<{ background: string }>`
  height: 12px;
  width: 12px;
  background-color: ${({ background }) => background ?? "#bbb"};
  border-radius: 50%;
  display: inline-block;
`;

const Bar = styled(Box)`
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
`;

export const MainContainer = (props: Partial<MainContainerProps>) => {
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showGoProModal, setShowGoProModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("mobile");

  const isMobileView = selectedDevice === "mobile";
  const isDesktopView = selectedDevice === "desktop";

  const isEditorView = location.pathname.includes("/editor");

  props = {
    ...defaultProps,
    ...props,
  };
  const {
    background,
    useImage,
    backgroundImageUrl,
    useVideo,
    backgroundVideoUrl,
    fontFamily,
    positionX,
    positionY,
    children,
  } = props;

  useEffect(() => {
    const token = getAuthToken();

    if (token && isEditorView) {
      const decoded = jwt_decode(token) as any;
      setUsername(decoded?.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken()]);

  const {
    connectors: { connect },
  } = useNode();

  const { enabled, actions, canUndo, canRedo, active } = useEditor(
    (state, query) => ({
      active: state.events.selected,

      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),

      canRedo: query.history.canRedo(),
    })
  );

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const contentMaxWidth = isEditorView && isMobileView ? "375px" : "680px";
  const backgroundMaxWidth = isEditorView && isMobileView ? "375px" : "100%";

  return (
    <>
      {isLargerThan768 && isEditorView ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px="1rem"
          bg="#ffffff8f"
          position="sticky"
          top="0"
          zIndex="3"
          borderBottom="1px solid #eeeeee"
          py="0.25rem"
        >
          <Box></Box>

          <Box textAlign="center" display="flex" alignItems="center">
            <Box
              position="relative"
              display="inline-block"
              cursor="pointer"
              mr="1.5rem"
              as="button"
              onClick={() => setSelectedDevice("mobile")}
              fill={isMobileView && "#1c72e8"}
            >
              <Tooltip
                label="Mobile preview"
                placement="bottom"
                aria-label="Mobile"
              >
                <svg
                  data-v-8c046fa2=""
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20.3 35"
                  width="14"
                  height="28"
                  className="inline"
                >
                  <path
                    data-v-8c046fa2=""
                    d="M18,0H2.4C1.1,0,0,1.1,0,2.4v30.3C0,33.9,1.1,35,2.4,35H18c1.3,0,2.4-1.1,2.4-2.4V2.4C20.3,1.1,19.3,0,18,0z M7.7,1.7h5 c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3h-5C7.5,2.3,7.4,2.1,7.4,2C7.4,1.8,7.5,1.7,7.7,1.7z M10.2,33.8 c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S10.8,33.8,10.2,33.8z M18.7,30.6h-17V3.7h17V30.6z"
                  ></path>
                </svg>
              </Tooltip>
            </Box>
            <Box
              position="relative"
              display="inline-block"
              cursor="pointer"
              as="button"
              onClick={() => setSelectedDevice("desktop")}
              fill={isDesktopView && "#1c72e8"}
            >
              <Tooltip
                label="Desktop preview"
                placement="bottom"
                aria-label="Desktop"
              >
                <svg
                  data-v-8c046fa2=""
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 433.3"
                  width="21"
                  height="28"
                  className="inline"
                >
                  <path
                    data-v-8c046fa2=""
                    d="M492,0H20C9,0,0,9,0,20v314.7c0,11,9,20,20,20h125l-24.9,49.7c-6.6,13.3,3,28.9,17.9,28.9h236c14.8,0,24.5-15.6,17.9-28.9	L367,354.7h125c11,0,20-9,20-20V20C512,9,503,0,492,0z M341.6,393.3H170.4l19.3-38.7h132.6L341.6,393.3z M472,314.7H40V40h432V314.7	z"
                  ></path>
                </svg>
              </Tooltip>
            </Box>
          </Box>

          {enabled && isLargerThan768 && (canUndo || canRedo) ? (
            <Fade in={enabled && isLargerThan768 && (canUndo || canRedo)}>
              <HStack spacing="6px" display="flex" justifyContent="center">
                <Tooltip label="Undo" placement="bottom" aria-label="Undo">
                  <button
                    style={{
                      transform: "scaleX(-1)",
                    }}
                    disabled={!canUndo}
                    onClick={() => actions.history.undo()}
                  >
                    <UilRedo color={!canUndo ? "#afafaf" : "#111"} />
                  </button>
                </Tooltip>

                <Tooltip label="Redo" placement="bottom" aria-label="Redo">
                  <button
                    disabled={!canRedo}
                    onClick={() => actions.history.redo()}
                  >
                    <UilRedo color={!canRedo ? "#afafaf" : "#111"} />
                  </button>
                </Tooltip>
              </HStack>
            </Fade>
          ) : (
            <Box />
          )}
        </Box>
      ) : null}

      <Box
        border={
          !enabled && isEditorView
            ? "3px solid #f1f1f1"
            : !enabled && isEditorView
            ? "3px solid transparent"
            : "none"
        }
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
        maxWidth={backgroundMaxWidth}
        m="0 auto"
        transition="all 0.3s cubic-bezier(0.19, 1, 0.22, 1)"
        pb={
          isEditorView && enabled && active
            ? "6rem"
            : isEditorView && enabled
            ? "6rem"
            : 0
        }
      >
        {!enabled && isEditorView && (
          <Fade in={!enabled && isEditorView}>
            <Row p="8px 12px" bg="#f1f1f1" display="flex">
              <Column left spacing="6px">
                <RoundDot background="#ED594A" />

                <RoundDot background="#FDD800" />

                <RoundDot background="#5AC05A" />
              </Column>
              <Column middle pl="1rem">
                <Input
                  bg="white"
                  color="gray.500"
                  size="xs"
                  defaultValue={`alfalink.co/${username}`}
                  cursor="unset"
                  pointerEvents="none"
                />
              </Column>

              <Column display="block" right>
                <Box float="right">
                  <Bar />

                  <Bar />

                  <Bar />
                </Box>
              </Column>
            </Row>
          </Fade>
        )}

        <StyledBox
          className="main_container_wrapper"
          ref={connect}
          style={{
            background: background,

            display: "flex",
            flexDirection: "column",
            position: "relative",
            paddingBottom: "0px",
            minHeight: !isEditorView && "100vh",

            overflowX: "inherit",
            width: "100%",
            height: "100%",
          }}
          ownfontfamily={fontFamily}
          maxWidth={backgroundMaxWidth}
          transition="all 0.3s cubic-bezier(0.19, 1, 0.22, 1)"
        >
          {useImage ? (
            <ImageContainer
              backgroundimageurl={backgroundImageUrl}
              positionx={positionX}
              positiony={positionY}
            />
          ) : null}

          {useVideo && backgroundVideoUrl ? (
            <Video autoPlay loop playsInline muted>
              <source src={backgroundVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          ) : null}

          <Box
            maxWidth={contentMaxWidth}
            minHeight="calc(100vh - 332px)"
            height="100%"
            m="0 auto"
            width="inherit"
            flex="1 1 0%"
            transition="all 0.3s cubic-bezier(0.19, 1, 0.22, 1)"
            zIndex={1}
          >
            {children}
          </Box>

          <Box
            display="block"
            position="inherit"
            mt="1.5rem"
            mb="1rem"
            textAlign="center"
          >
            {/*
              <AnimatedGradientLogo
                fontSize="1rem"
                shadow
                preventRedirect
                onClick={() =>
                  isEditorView
                    ? setShowGoProModal(true)
                    : setShowSignUpModal(true)
                }
              />
              */}
          </Box>
        </StyledBox>

        <Modal
          isOpen={showGoProModal}
          onClose={() => setShowGoProModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent p="1rem">
            <ModalHeader>Go Pro</ModalHeader>
            <ModalCloseButton />
            <ModalBody m="0 auto" py="2rem" mb="1rem">
              Remove logo by going pro
            </ModalBody>

            <a href="/in/pro">
              <Button variant="solid" isFullWidth>
                What is Pro?
              </Button>
            </a>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={showSignUpModal}
          onClose={() => setShowSignUpModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent p="1rem">
            <ModalHeader>alfalink</ModalHeader>
            <ModalCloseButton />
            <ModalBody m="0 auto" py="2rem" mb="1rem">
              Sign up
            </ModalBody>

            <Button variant="solid" isFullWidth>
              Join us
            </Button>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

MainContainer.craft = {
  displayName: "Main Container",
  props: defaultProps,

  rules: {
    canDrag: () => false,
    canMoveIn: (node: any) =>
      node.data.type === Label ||
      node.data.type === SocialLinks ||
      node.data.type === KoFi ||
      node.data.type === BuyMeACoffee ||
      node.data.type === Music ||
      node.data.type === Video ||
      node.data.type === Image ||
      node.data.type === Link ||
      node.data.type === Twitter,
    canMoveOut: () => true,
  },
  related: {
    settings: ContainerSettings,
  },
};
