import { Box, Flex, Heading, Link, Stack } from "@chakra-ui/layout";

import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import Page from "components/Page";
import genericError from "assets/jsons/error-animation.json";
import networkError from "assets/jsons/network-error.json";
import { useMediaQuery } from "@chakra-ui/react";

const defaultOptions = {
  loop: true,
  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: networkError,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultGenericErrorOptions = {
  loop: true,
  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: genericError,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const ErrorPage = ({ isNetworkError }: { isNetworkError?: boolean }) => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Helmet>
        <title>Error page | Alfalink</title>
      </Helmet>

      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>
        <Flex
          width="full"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          py="1rem"
        >
          <Box my="4rem">
            {isNetworkError ? (
              <Lottie
                options={defaultOptions}
                height={isSmallerThan600 ? 160 : 230}
                width={isSmallerThan600 ? 300 : 460}
              />
            ) : (
              <Lottie
                options={defaultGenericErrorOptions}
                height={isSmallerThan600 ? 160 : 260}
                width={isSmallerThan600 ? 300 : 460}
              />
            )}
          </Box>

          <Stack spacing="1.5rem" textAlign="center" cursor="default">
            <Heading as="h3" size="lg">
              Something went wrong...
            </Heading>

            <Heading as="h4" size={isSmallerThan600 ? "sm" : "md"}>
              Please try again a bit later or{" "}
              <Link textDecoration="underline" href="/in/contact" isExternal>
                contact support
              </Link>{" "}
              &#128591;
            </Heading>
          </Stack>
        </Flex>
      </Page>
    </>
  );
};
