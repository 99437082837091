import {
  Badge,
  Box,
  Center,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import GetStartedInput from "components/GetStartedInput";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { PlanCompareTable } from "components/PlanCompareTable";
import SearchList from "components/HelpDesk/SearchList";
import { StripePricingTable } from "components/StripePricingTable";
import { useUserContext } from "HoCs/UserProvider";

const questionList = [
  {
    title: "Can I start a free trial of paid plans?",
    content: (
      <Box color="gray.500">
        <Text>
          Yes! You can get 2 months of free trial on both Pro and Business plan
          when annually billed, and 1 free month when monthly billed.
          <br />
        </Text>
      </Box>
    ),
  },

  {
    title: "How secure is Alfalink?",
    content: (
      <Box color="gray.500">
        <Text>
          We use{" "}
          <Link textDecoration="underline" href="https://stripe.com" isExternal>
            Stripe
          </Link>{" "}
          to process all payments on our platform. It’s the same payment
          provider used by products such as Twitter, Pinterest, and Lyft. This
          means 1: we do not handle nor save any information about your Credit
          Card information on our systems, 2: Stripe is certified PCI Service
          Provider Level 1 and all card numbers are encrypted at rest with
          AES-256.
        </Text>
        <br />

        <Text>
          Read more about{" "}
          <Link
            textDecoration="underline"
            href="https://stripe.com/docs/security"
            isExternal
          >
            Security at Stripe
          </Link>
          .
        </Text>
      </Box>
    ),
  },

  {
    title: "Which plan is right for me?",
    content: (
      <Box color="gray.500">
        <Text>
          It depends on your audience size and goals.
          <b> The Free plan is designed for individual use</b>
          , for example, to simply bundle your links in one place and have a
          personalized landing page on the internet.
          <br />
          If you think you will have a lot of traffic to your Alfalink profile,
          the Pro plan is for you. With Pro you can analyze your visitor
          traffic, grow your audience and get to know them, and get lower
          transaction fee. It's also a good option if you're going to use
          Alfalink to collect support donation and tips from your fans.{" "}
          <b>
            {" "}
            Pro is best option to get the full power of Alfalink, with 24/7
            support.
          </b>
        </Text>
        <br />
        <Text>
          If you're a business or agency with multiple branch, Business is for
          you.
          <b> You can add and manage multiple Alfalink profiles</b>
          under one account.
        </Text>
      </Box>
    ),
  },

  {
    title: "Can I pay monthly?",
    content: (
      <Box color="gray.500">
        <Text>
          You can pay for Alfalink on a monthly or an annual basis. If you
          decide to pay annually, you can save more than 25%.
        </Text>
      </Box>
    ),
  },
  {
    title: "Can I change my plan?",

    content: (
      <Text color="gray.500">
        You can change your plan at any time. Visit the Settings page for
        details.
      </Text>
    ),
  },

  {
    title: "What happens when my free trial period ends?",

    content: (
      <Text color="gray.500">
        When your free trial period ends, you’ll be automatically downgraded to
        the Free plan unless you decide to subscribe to one of the paid plans.
      </Text>
    ),
  },
  {
    title: "What is your refund policy?",

    content: (
      <Text color="gray.500">
        We do not offer refunds. If you cancel your plan before the next renewal
        cycle, you will retain access to paid features until the end of your
        subscription period. When your subscription plan expires, you will lose
        access to the features on that plan.
      </Text>
    ),
  },

  {
    title: "What currency are the prices in?",

    content: (
      <Text color="gray.500">
        All the prices are listed in USD, and you'll be charged in USD.
      </Text>
    ),
  },
];

export const coreFeatures = [
  {
    title: "Unlimited links",
    free: true,
    pro: true,
    business: true,

    description: "Add all your links and social icons and more",
  },

  {
    title: "Embed content",
    free: true,
    pro: true,
    business: true,

    description: "Embed & preview content from various sources:",
    listItems: [
      "YouTube, TikTok, Twitch, Vimeo, Facebook, and more",
      "Spotify, Apple Music, SoundCloud, and more",
      "Spotify and Apple Podcasts",
      "Twitter post, feed or profile",
      "Embed Buy Me A Coffee panel",
      "Embed Ko-fi panel",
    ],
  },

  {
    title: "Customization",
    free: true,
    pro: true,
    business: true,
    description: "Unlimited customization options and freedom",

    listItems: [
      "Color, Gradients, Image, and Video profile background",
      "Links color, animation, typography, size, border and more",
      "Clone and duplicate links with the same style",
      "Customize links individually or in bulk",
      "Millions of free background images from Unsplash",
      "See more under Links Customization section",
    ],
  },

  {
    title: "Analytics",
    free: true,
    pro: true,
    business: true,
    description: "Data about your profile views and link clicks",
    listItems: [
      "Lifetime profile views, link clicks, CTR, and Time to Click",
      "Daily views and clicks for the last 30 days",
      "Export and save data as CSV",
      "See more under Detailed Analytics section",
    ],
  },

  {
    title: "All-in-one Editor",
    free: true,
    pro: true,
    business: true,
    description: "Responsive Editor with drag&drop support",

    listItems: [
      "Preview live changes in Mobile and Desktop view",
      "Undo/redo change history",
      "Share your Alfalink on mainstream social media platforms",
      "Generate unique QR code to your Alfalink",
    ],
  },
];

export const PaymentFeatures = [
  {
    title: "Collect tips and donations",
    free: true,
    pro: true,
    business: true,

    description: "Collect tips and supportive donations from your visitors",

    listItems: [
      "Collect payments via Credit Card, Apple Pay and Google Pay",
      "See history and details of each donation received",
      "All the transactions are securely encrypted by Stripe.com",
    ],
  },
];

export const SupportFeatures = [
  {
    title: "We are here to help",
    free: true,
    pro: true,
    business: true,

    description: "Email & self-serve support",
  },
  {
    title: "Priority support",
    free: false,
    pro: true,
    business: true,

    description: "Get an answer within 24 hours max",
  },

  {
    title: "Dedicated support",
    free: false,
    pro: false,
    business: true,

    description: "24/7 support. Get an answer within 4 hours max",
  },
];

export const LinkFeatures = [
  {
    title: "Styling freedom",
    free: true,
    pro: true,
    business: true,

    description: "Customize styling of individual link buttons",

    listItems: [
      "Link button color, shape, font, and much more",
      "Add emoji to links to better represent them",
      "Add animation to grab attention to important links",
      "Clone and duplicate links with current styling",
      "Bulk update multiple links styling",
    ],
  },

  {
    title: "Lock and guard links",
    free: true,
    pro: true,
    business: true,

    description: "Protect and guard a link by a password",

    listItems: [
      "Give exclusive access to a link",
      "Only visitors with the correct password can proceed",
    ],
  },

  {
    title: "Mark link for Sensitive Content",
    free: true,
    pro: true,
    business: true,

    description: "Prompt user with a sensitive content message",
    listItems: ["Editable message text"],
  },

  {
    title: "Hide link if not used for now",
    free: true,
    pro: true,
    business: true,

    description: "Ability to hide/show link from your public profile",
  },
];

export const AnalyticsFeatures = [
  {
    title: "Profile views",
    free: true,
    pro: true,
    business: true,

    description: "Plus the core analytics:",

    listItems: [
      "Detailed data about your audience and profile visitors",
      "Where in the world they come from",
      "Which platform they are referred by",
      "What device type they use",
      "See data in table and chart view",
      "Export your data as CSV",
    ],
  },

  {
    title: "Individual links",
    free: false,
    pro: true,
    business: true,
    description: "Detailed analytical data for each link:",

    listItems: [
      "Number of clicks, top country, referrer and device",
      "Detailed stats about top country, top referrer, top device",
      "History of clicks and unique clicks from the last 7 days",
    ],
  },

  {
    title: "Support links",
    free: false,
    pro: true,
    business: true,
    description: "See in details where you collect donations from",

    listItems: ["Same data as Individual Links"],
  },

  {
    title: "Social icons",
    free: false,
    pro: true,
    business: true,
    description: "Detailed analytical data for each social icon",

    listItems: ["Same data as Individual Links"],
  },
];
export const PricingScreen = () => {
  const { userData } = useUserContext();

  const renderStripeSubscriptionDetails = () => {
    if (!userData?.stripeSubscriptionInfo) {
      return null;
    }

    const { productName, planInterval, priceAmount, status } =
      userData && userData?.stripeSubscriptionInfo;

    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color="gray.300"
          mb="3rem"
        >
          <Text fontSize="sm">You have an active plan</Text>
          <Text fontWeight="bold" fontSize="lg">
            {productName} &nbsp;
            <Badge colorScheme="green">{status}</Badge>
          </Text>
          <Text fontSize="xs">
            ${priceAmount / 100} per {planInterval}
          </Text>
        </Box>

        <Link href={"/in/profile"} textDecoration="underline" color="#ffffff">
          <Text fontSize="sm" width="full">
            See details, upgrade or cancel
          </Text>
        </Link>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Pricing | Alfalink</title>
      </Helmet>

      <Page>
        <Box
          mt="1rem"
          mb="4rem"
          py={{ base: "1rem", md: "1rem" }}
          borderRadius="0.5rem"
          backgroundColor="#0a0a0a"
          position="relative"
        >
          <Box
            px={{ base: "0.5rem", md: "2rem" }}
            py={{ base: "2rem", md: "2rem" }}
          >
            <Badge variant="solid" colorScheme="whatsapp">
              While in Beta, Pro plan features are free!
            </Badge>
          </Box>

          <Box textAlign="center">
            {userData?.username ? null : (
              <Link href="/in/signup">
                <Box
                  color="#eee"
                  position="absolute"
                  top={{ base: "10%", lg: "20%", xl: "22%" }}
                  zIndex="999999999"
                  width="100%"
                  height={{
                    base: "1550px",
                    sm: "1550px",
                    md: "1550px",
                    lg: "600px",
                    xl: "600px",
                  }}
                  cursor="pointer"
                ></Box>
              </Link>
            )}

            {userData?.username && userData?.plan !== "free" ? (
              renderStripeSubscriptionDetails()
            ) : (
              <StripePricingTable username={userData?.username} />
            )}
          </Box>

          {userData?.username ? (
            <Box mt="3rem" mb="1rem" />
          ) : (
            <Text
              textAlign="center"
              mt="3rem"
              mb="1rem"
              fontSize="xs"
              color="gray.500"
            >
              <Link href="/in/login">
                Registration or login is required for purchases
              </Link>
            </Text>
          )}
        </Box>

        <Heading textAlign="center" size="lg">
          Compare plans & features
        </Heading>

        <Box
          border={{ base: "1px solid #111111", md: "1px solid #111111" }}
          p={{ base: "1rem 6px", md: "1rem 2rem" }}
          borderRadius="8px"
          my="1rem"
        >
          <Stack spacing="2rem">
            <PlanCompareTable
              showHeaders
              featureType="Core features"
              data={coreFeatures}
            />

            <PlanCompareTable
              featureType="Collect payments"
              data={PaymentFeatures}
            />

            <PlanCompareTable
              featureType="Detailed Analytics"
              data={AnalyticsFeatures}
            />

            <PlanCompareTable
              featureType="Links customization"
              data={LinkFeatures}
            />

            <PlanCompareTable featureType="Support" data={SupportFeatures} />
          </Stack>

          <Center
            textAlign="center"
            borderRadius="8px"
            mt="4rem"
            mb="1rem"
            p="1rem"
          >
            <GetStartedInput showInput />
          </Center>
        </Box>

        <Box pt="2rem" pb="4rem" px={{ base: "0rem", md: "6rem" }}>
          <Box my="2rem" textAlign="center">
            <Heading size="lg">Pricing FAQs</Heading>
          </Box>

          <SearchList filteredQuestions={questionList} />

          <Box my="3rem" textAlign="center">
            <Text fontSize="sm" color="gray.500">
              Can’t find the answer here?
            </Text>

            <Link
              fontSize="sm"
              color="gray.600"
              textDecoration="underline"
              href="/in/contact"
            >
              {" "}
              Contact support
            </Link>
          </Box>
        </Box>
      </Page>
    </>
  );
};
