import { Box, Text, VStack, useMediaQuery } from "@chakra-ui/react";

import React from "react";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import { useEditor } from "@craftjs/core";
export * from "./SettingsItem";
export * from "./SettingsTextInputItem";

export const SettingsPanel = () => {
  const { active, related, enabled } = useEditor((state) => ({
    active: state.events.selected,
    enabled: state.options.enabled,
    selectedNodeId: state.events.selected,

    related:
      state.events.selected && state.nodes[state.events.selected].related,
  }));

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return isLargerThan768 ? (
    <>
      <Box pt="1px">
        {active &&
          related &&
          related.settings &&
          React.createElement(related.settings)}
      </Box>
      {!active ? (
        <Box
          className="settings_panel_wrapper"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          fontSize="18px"
          p="3rem"
          color="gray.500"
        >
          <VStack spacing="40px">
            <Text fontWeight="bold">
              Click on a component in the view to edit it.
            </Text>

            <div>
              Click on
              <Box
                opacity="0.6"
                display="inline-block"
                width="40px"
                height="40px"
                borderRadius="50%"
                background="#fff"
                verticalAlign="middle"
                p="4px"
                mx="10px"
                boxShadow="0 0 3px rgb(0 0 0 / 14%), 0 1px 4px rgb(0 0 0 / 28%)"
              >
                <UilPlus size="32px" />
              </Box>
              icon <strong> in the bottom left corner</strong> to add new Links
              and other components.
              <Text mt="2px">
                You can either click on the component to add it or drag it to
                the desired place in the view.
              </Text>
            </div>
          </VStack>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {enabled && active && related && related.settings && (
        <div className="mobile_settings_panel_wrapper">
          {active &&
            related &&
            related.settings &&
            React.createElement(related.settings)}
        </div>
      )}
    </>
  );
};
