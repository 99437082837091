import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";

import { GraphCTRIcon } from "assets/svgs/graphIconCTR";
import styled from "styled-components";
import { useState } from "react";

export const StyledSpan = styled.button<{
  dotcolor: string;
}>`
  ::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 4px;
    margin-bottom: 2px;
    background-color: ${({ dotcolor }) => dotcolor || "green"};
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  display: inline-block;
  padding-top: 0;
  padding: 0;
  margin-left: -28px;

  div svg {
    text-align: center;

    width: auto !important;
    height: auto !important;
  }

  div {
    height: auto !important;
  }
`;

export const DailyCountsGraph = ({ dailyTimeseriesData }) => {
  const [hideTotalVisits, setHideTotalVisits] = useState(false);
  const [hideUniqueVisits, setHideUniqueVisits] = useState(false);
  const [hideTotalClicks, setHideTotalClicks] = useState(false);
  const [hideUniqueClicks, setHideUniqueClicks] = useState(false);
  const [hideCTR, setHideCTR] = useState(false);

  return (
    <>
      <StyledResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={dailyTimeseriesData}
          barCategoryGap={30}
          barGap={0}
          margin={{
            top: 10,
            right: 0,
            bottom: 10,
            left: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" opacity="0.4" />

          <XAxis
            reversed
            dataKey="date"
            tickFormatter={(str) => {
              const date = parseISO(str);
              if (date && date.getDate() % 7 === 0) {
                return format(date, "MMM, d");
              }
              return date ? format(date, "MMM, d") : "";
            }}
            fontSize={"11px"}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              return active ? (
                <Stack
                  spacing="0.5rem"
                  className="tooltip"
                  style={{
                    fontSize: "13px",
                    padding: "0.825rem",
                  }}
                >
                  <Text color="gray.50">
                    {format(parseISO(label), "eeee, d MMM, yyyy")}
                  </Text>

                  <HStack spacing="1.25rem">
                    {!hideTotalVisits && (
                      <Box>
                        <Text color="gray.400">Visits:</Text>
                        <Text bold>{payload[0]?.value}</Text>
                      </Box>
                    )}

                    {!hideTotalClicks && (
                      <Box>
                        <Text color="gray.400">Clicks:</Text>
                        <Text bold>{payload[2]?.value}</Text>
                      </Box>
                    )}

                    {!hideCTR &&
                      !hideTotalVisits &&
                      !hideUniqueVisits &&
                      !hideTotalClicks &&
                      !hideUniqueClicks && (
                        <Box>
                          <Text color="gray.400">CTR:</Text>

                          <Text bold>
                            {((payload[4]?.value as any) * 100).toFixed(0)}%
                          </Text>
                        </Box>
                      )}
                  </HStack>
                </Stack>
              ) : null;
            }}
          />
          <YAxis
            tickCount={6}
            fontSize="11px"
            label={{
              value: "views / clicks",
              fontSize: "12px",
              position: "insideLeft",
              color: "red",
              opacity: "0.5",
              angle: -90,
              dy: 0,
              dx: 12,
            }}
          />

          <Tooltip />

          {!hideTotalVisits && (
            <Bar dataKey="pageViewTotal" barSize={15} fill="#8e74ff" />
          )}

          {!hideUniqueVisits && (
            <Bar dataKey="pageViewUniqueTotal" barSize={15} fill="#bbacff" />
          )}

          {!hideTotalClicks && (
            <Bar dataKey="linkClickTotal" barSize={15} fill="#38aa65" />
          )}

          {!hideUniqueClicks && (
            <Bar dataKey="linkClickUniqueTotal" barSize={15} fill="#84ffb5" />
          )}

          {!hideCTR && (
            <Line
              type="monotone"
              dataKey="avgCtr"
              stroke="#1566d6"
              activeDot={{ r: 6 }}
              strokeWidth={1.5}
            />
          )}
          {/* <Scatter dataKey="cnt" fill="red" /> */}
        </ComposedChart>
      </StyledResponsiveContainer>

      <HStack
        spacing="0.825rem"
        justifyContent="center"
        display="flex"
        alignItems="center"
        fontSize={{ base: "9px", md: "12px" }}
      >
        <StyledSpan
          dotcolor="#8e74ff"
          color="#8e74ff"
          onClick={() => setHideTotalVisits(!hideTotalVisits)}
        >
          Total visits
        </StyledSpan>

        <StyledSpan
          dotcolor="#bbacff"
          color="#9784ec"
          onClick={() => setHideUniqueVisits(!hideUniqueVisits)}
        >
          Unique visits
        </StyledSpan>

        <StyledSpan
          dotcolor="#38aa65"
          color="#3eaf6b"
          onClick={() => setHideTotalClicks(!hideTotalClicks)}
        >
          Total clicks
        </StyledSpan>

        <StyledSpan
          dotcolor="#84ffb5"
          color="#5bdb8e"
          onClick={() => setHideUniqueClicks(!hideUniqueClicks)}
        >
          Unique clicks
        </StyledSpan>

        <Text
          as="button"
          color="#2974df"
          display="flex"
          alignItems="center"
          onClick={() => setHideCTR(!hideCTR)}
          cursor="pointer"
        >
          <GraphCTRIcon /> CTR
        </Text>
      </HStack>
    </>
  );
};
