import FingerprintGirl from "assets/svgs/fingerprint.svg";
import styled from "styled-components";

export const FingerprintGirlSVG = styled.div`
  background: url("${FingerprintGirl}");
  background-repeat: no-repeat;
  background-size: contain;
  height: 450px;
  width: 450px;
  display: flex;
  position: absolute;
  text-align: center;
  margin: 0;
  top: 72px;
  right: 32px;
  z-index: -1;
  max-width: 100%;

  @media (max-width: 414px) {
    opacity: 0.3;
    filter: blur(1px);
  }
`;
