import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  Heading,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { frameDataIsNotModified, getAuthToken } from "utils";

import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { TEMP_TEMPLATE } from "utils/constants";
import styled from "styled-components";
import { templatesFrameData } from "utils";
import { updateTemplateFrameData } from "api/queries";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useState } from "react";
import useUserData from "hooks/useUserData";

const TemplateWrapper = styled(Box)`
  display: flex;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 344px;
  transition: transform 0.5s;
  min-width: 200px;
  align-items: center;
  curser: pointer;
`;

export const TemplatesScreen = () => {
  const [confirmResetFrameData, setConfirmResetFrameData] = useState(false);
  const [frameData, setFrameData] = useState("");

  const { userData, loading: loadingUserData } = useUserData();

  const history = useHistory();

  const toast = useToast();

  const {
    mutateAsync: triggerUpdateFrameData,
    isLoading: isUpdatingFrameData,
  } = useMutation(
    updateTemplateFrameData,

    {
      onError: () => {
        toast({
          title:
            "Something went wrong saving the template. Please try again later or contact support",
          status: "error",
          variant: "subtle",
          isClosable: true,
          position: "top",
        });
      },
    }
  );

  if (loadingUserData) {
    return (
      <Center my="5rem" py="5rem">
        <Spinner />
      </Center>
    );
  }

  const handleSave = async () => {
    const token = getAuthToken();

    if (!token) {
      Cookies.set(TEMP_TEMPLATE, frameData, { expires: 2 });

      history.push("/in/signup");

      return;
    }

    try {
      await triggerUpdateFrameData({
        frameData: frameData,
      }).then(() => history.push(`/in/editor`));
    } catch (error) {
      console.debug("Err ", error);
    }
  };

  const canUpdateTemplate =
    userData && frameDataIsNotModified({ frameData: userData?.frameData });

  return (
    <>
      <Helmet>
        <title>Templates | Alfalink</title>
      </Helmet>
      <Page>
        <Box pb="4rem">
          <VStack
            py={{ base: "2rem", md: "3rem" }}
            spacing={2}
            textAlign="center"
          >
            <Heading as="h2" size="xl">
              Welcome!{" "}
              <span role="img" aria-label="sheep">
                👋
              </span>
            </Heading>
            <Text fontSize="lg" color={"gray.500"}>
              Let's choose a template and get started!
            </Text>
          </VStack>

          {userData && !canUpdateTemplate && (
            <Box mb="3rem">
              <Alert status="warning" borderRadius={4} my="0.75rem">
                <AlertIcon />
                <AlertDescription fontSize="sm">
                  You have already set and modified your profile. Selecting a
                  new template will reset your profile. All your changes will be
                  gone.
                </AlertDescription>
              </Alert>

              <Checkbox
                size="lg"
                onChange={() =>
                  setConfirmResetFrameData(!confirmResetFrameData)
                }
              >
                <Text fontSize="sm">
                  I understand and want to reset my profile with a new template.
                </Text>
              </Checkbox>
            </Box>
          )}

          <RadioGroup onChange={setFrameData} value={frameData} size="lg">
            <SimpleGrid
              minChildWidth={{ base: "182px", md: "200px" }}
              spacing={{ base: "24px", md: "56px" }}
            >
              {templatesFrameData.map((template) => (
                <TemplateWrapper
                  key={template.name}
                  borderRadius="1rem"
                  cursor="pointer"
                >
                  <Radio
                    value={template.frameData}
                    flexDirection="column-reverse"
                    cursor="pointer"
                    width="100%"
                    defaultValue={null}
                  >
                    <Image
                      width={{ base: "180px", lg: "auto" }}
                      src={template.image}
                      cursor="pointer"
                      alt={template.name}
                    />
                  </Radio>
                  <Text color="gray.500" py="0.5rem">
                    {template.name}
                  </Text>
                </TemplateWrapper>
              ))}
            </SimpleGrid>
          </RadioGroup>

          <Button
            position="sticky"
            my="2rem"
            bottom="1.5rem"
            width="100%"
            size="lg"
            colorScheme="yellow"
            boxShadow="xl"
            onClick={handleSave}
            disabled={!canUpdateTemplate && !confirmResetFrameData && userData}
            isLoading={isUpdatingFrameData}
          >
            Select & continue
          </Button>
        </Box>
      </Page>
    </>
  );
};
