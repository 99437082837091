import { Box, Heading, Link, Stack, Text } from "@chakra-ui/layout";

import { Helmet } from "react-helmet";
import Page from "components/Page";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Alfalink</title>
      </Helmet>
      <Page>
        <Stack
          spacing="1rem"
          py={{ base: "1rem", "2xl": "2rem" }}
          px={{ base: "0rem", md: "0rem" }}
        >
          <Box>
            <Heading as="h4" size="md">
              Privacy Policy for alfalink.co
            </Heading>

            <Text fontSize="sm" color="gray.500">
              Last updated Jan 25, 2022
            </Text>
          </Box>

          <Stack
            spacing="2rem"
            pb="2rem"
            color="gray.700"
            fontWeight="light"
            letterSpacing="-0.1px"
            fontSize="12px"
          >
            <Stack spacing="1rem">
              <p>
                At alfalink.co, accessible from www.alfalink.co, one of our main
                priorities is the privacy of our visitors. This Privacy Policy
                document contains types of information that is collected and
                recorded by alfalink.co and how we use it.
              </p>

              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to{" "}
                <Link textDecoration="underline" href="/in/contact">
                  contact us.
                </Link>
              </p>

              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in alfalink.co. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </p>
            </Stack>

            <Box>
              <Heading as="h6" size="sm">
                Consent
              </Heading>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </Box>

            <Stack spacing="1rem">
              <Heading as="h6" size="sm">
                Information we collect
              </Heading>

              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>

              <p>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, the
                contents of the message and/or attachments you may send us, and
                any other information you may choose to provide.
              </p>

              <p>
                When you register for an Account, we may ask for your contact
                information, including items such as email address.
              </p>
            </Stack>
            <Box>
              <Heading as="h6" size="sm">
                How we use your information
              </Heading>

              <Stack spacing="1rem">
                <p>
                  We use the information we collect in various ways, including
                  to:
                </p>

                <Box pl="1rem">
                  <ul>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>
                      Develop new products, services, features, and
                      functionality
                    </li>
                    <li>
                      Communicate with you, either directly or through one of
                      our partners, including for customer service, to provide
                      you with updates and other information relating to the
                      website, and for marketing and promotional purposes
                    </li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                  </ul>
                </Box>
              </Stack>
            </Box>

            <Stack>
              <Heading as="h6" size="sm">
                Data Processing
              </Heading>

              <p>
                alfalink.co follows a standard procedure of processing browser
                data. We process data that your browser communicates to our
                servers. We process IP addresses to anonymously detect country
                and city for analytics purposes. We do not save IP addresses on
                our servers or databases. We collect the following data provided
                by your browser: browser type; date and time stamp; referring;
                and possibly the number of clicks. These are not linked to any
                information that is personally identifiable. The purpose of the
                information is for analytics.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Cookies and Web Beacons
              </Heading>

              <p>
                Like any other website, alfalink.co uses 'cookies'. These
                cookies are used to store information including visitors'
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimize the
                users' experience by customizing our web page content based on
                visitors' browser type and/or other information.
              </p>

              <p>
                For more general information on cookies, please read more on the{" "}
                <Link
                  textDecoration="underline"
                  isExternal
                  href="https://www.cookieconsent.com/"
                >
                  Cookie Consent website
                </Link>
                .
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                GDPR Data Protection Rights
              </Heading>

              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>

              <Box pl="1rem">
                <ul>
                  <li>
                    The right to access – You have the right to request copies
                    of your personal data. We may charge you a small fee for
                    this service.
                  </li>

                  <li>
                    The right to rectification – You have the right to request
                    that we correct any information you believe is inaccurate.
                    You also have the right to request that we complete the
                    information you believe is incomplete.
                  </li>

                  <li>
                    The right to erasure – You have the right to request that we
                    erase your personal data, under certain conditions.
                  </li>

                  <li>
                    The right to restrict processing – You have the right to
                    request that we restrict the processing of your personal
                    data, under certain conditions.
                  </li>

                  <li>
                    The right to object to processing – You have the right to
                    object to our processing of your personal data, under
                    certain conditions.
                  </li>

                  <li>
                    The right to data portability – You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions.
                  </li>
                  <li>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights,{" "}
                    <Link textDecoration="underline" href="/in/contact">
                      please contact us.
                    </Link>
                  </li>
                </ul>
              </Box>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Children's Information
              </Heading>

              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>

              <p>
                alfalink.co does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to{" "}
                <Link textDecoration="underline" href="/in/contact">
                  contact us
                </Link>{" "}
                immediately and we will do our best efforts to promptly remove
                such information from our records.
              </p>
            </Stack>

            <Stack>
              <Heading as="h6" size="sm">
                Disable cookies
              </Heading>
              <p>
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites. However, in case you disable
                cookies, you may jeopardize the use of the complete range of
                functions or features provided by our website and products.
              </p>
            </Stack>
          </Stack>
        </Stack>
      </Page>
    </>
  );
};

export default PrivacyPolicyScreen;
