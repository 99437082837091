import { UserComponent, useEditor, useNode } from "@craftjs/core";

import { BioSettings } from "./settings";
import { Box } from "@chakra-ui/react";
import { Text } from "../Text";
import styled from "styled-components";

type ButtonProps = {
  background?: string;
  color?: string;
  buttonStyle?: string;
  fontFamily?: string;
  margin?: any[];
  text?: string;
  textComponent?: any;
  buttonType?: string;
  linkUrl?: string;
  borderRadius?: string;
  icon?: any;
  alignIcon?: string;
  showIcon?: boolean;
  fontSize?: string;
  textAlign?: string;
  fontWeight?: string;
  textBackground?: string;
  textPadding?: number;
};

const BioTextContainer = styled(Box)<ButtonProps>`
  width: 100%;
  height: unset;
  padding: 0px;
  color: ${({ color }) => color} !important;
  font-size: ${({ fontSize }) => fontSize} !important;

  div {
    font-family: ${({ fontFamily }) => `${fontFamily}`};
  }

  textarea {
    font-size: 4rem;
    max-height: 140px;
    resize: ${({ canresize }) => (canresize ? "vertical" : "none")};
    text-align: ${({ textAlign }) => textAlign};
    font-weight: ${({ fontWeight }) => fontWeight};
  }
`;

export const BioContainer: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    text,
    textComponent,
    color,
    buttonStyle,
    background,
    linkUrl,
    borderRadius,
    showIcon,
    icon,
    alignIcon,
    fontFamily,
    fontWeight,
    fontSize,
    textAlign,
    textBackground,
    textPadding,
    ...otherProps
  } = props;

  return !enabled && text === "Bio" ? null : (
    <Box px="1rem">
      <BioTextContainer
        ref={(ref) => connect(ref)}
        textAlign={textAlign}
        fontWeight={fontWeight}
        mb="12px"
        fontFamily={fontFamily}
        fontSize={fontSize}
        color={color}
        canresize={enabled ? 1 : 0}
        {...otherProps}
      >
        <Text
          {...textComponent}
          textAlign={textAlign}
          text={text}
          background={textBackground}
          padding={textPadding}
          color={props.color}
          fontSize={fontSize}
          useTextArea
        />
      </BioTextContainer>
    </Box>
  );
};

BioContainer.craft = {
  displayName: "Bio",

  props: {
    background: "",
    color: "",
    text: "Bio",
    fontSize: "14",
    textBackground: "",
    textPadding: 2,
    fontFamily: "",
    textAlign: "center",
    fontWeight: "500",
    textComponent: {
      ...Text.craft.props,
    },
  },
  rules: {
    canDrag: () => false,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: BioSettings,
  },
};
