import { Box, Img, Link, Text, useMediaQuery } from "@chakra-ui/react";

import { Helmet } from "react-helmet";
import Page from "components/Page";
import ReactPlayer from "react-player/lazy";
import { Search } from "components/HelpDesk/Search";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import { cloudfrontURL } from "utils/urlBuilder.js";

export const Helpdesk = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const questionList = [
    {
      title: "What is alfalink.co?",
      content: (
        <>
          <Text>
            alfalink.co is a web application developed to help online users,
            especially content creators, to manage and organize all their links
            and content in one profile which can be shared via one unique and
            personalized link or a QR-code.
          </Text>
          <br />
          <Text>
            The purpose is to make it easier and faster to share content, reach
            and engage with current and new fans and followers, and have one
            customized and personalized profile for all your online links and
            content.
          </Text>

          <br />

          <Text>
            Check out some of the key features here{" "}
            <Link textDecoration="underline" href="/in/features">
              here
            </Link>
            .
          </Text>
        </>
      ),
    },

    {
      title: "How do I sign up?",
      content: (
        <>
          On the{" "}
          <Link textDecoration="underline" href="/in/signup">
            Sign up
          </Link>{" "}
          page, add your email, username (this is going to be a part of your
          Alfalink url) and your password in the respective fields, then
          click/tap Sign Up button and you're in!
        </>
      ),
    },

    {
      title: "Find your way around the Editor",
      content: (
        <Text>
          A video is worth a million words. Here's a walk-through tour of the
          Editor and playing around with some of its properties: <br />
          <Box my="1.5rem">
            <Text textAlign={"center"}>On narrow screens</Text>
            <ReactPlayer
              style={{ margin: "0 auto" }}
              url="https://alfalink.wistia.com/medias/4h1vbhiv4g"
              height={isSmallerThan600 ? "200px" : "400px"}
              width={isSmallerThan600 ? "auto" : "auto"}
              controls
            />
          </Box>
          <Box my="3rem">
            <Text textAlign={"center"}>On wide screens</Text>
            <ReactPlayer
              style={{ margin: "0 auto" }}
              height={isSmallerThan600 ? "224px" : "400px"}
              width={isSmallerThan600 ? "auto" : "auto"}
              url="https://alfalink.wistia.com/medias/1svttv6s0e"
              controls
            />
          </Box>
          <Box my="1rem">
            <Text>
              If you face any issues, encounter bugs or have feedback on how to
              improve the Editor, please{" "}
              <Link textDecoration="underline" href="/in/contact">
                Contact us
              </Link>
              . We're happy to hear from you and improve together.
            </Text>
          </Box>
        </Text>
      ),
    },

    {
      title: "How to add links to your profile",
      content: (
        <Text>
          Once you're logged in, head to{" "}
          <Link textDecoration="underline" href="/in/editor">
            Editor
          </Link>{" "}
          page and you'll find the Add Component icon in the bottom-left corner
          with a plus icon like so:
          <Box
            display="inline-flex"
            verticalAlign="middle"
            mx="0.5rem"
            borderRadius="50px"
            boxShadow="0 0 1px #111"
          >
            <UilPlus size="1.25rem" />
          </Box>
          - Click/tap the icon and you'll see the available component that you
          can add to your profile. Click on Link to add many links as you wish,
          and that is it!
        </Text>
      ),
    },

    {
      title: "Can I change my username?",
      content: (
        <>
          <Text>No, at the moment we don't support changing usernames.</Text>
          <br />
          <Text>
            If you made a typo in your username, you can either contact us and
            we will do our best to help or you can create a new account with the
            new username.
          </Text>
        </>
      ),
    },

    {
      title: "How to copy and share your Alfalink online",
      content: (
        <Text>
          Your Alfalink is{" "}
          <Text as="span" fontStyle="italic">
            alfalink.co/YourUsername
          </Text>
          . In{" "}
          <Link textDecoration="underline" href="/in/editor">
            Editor
          </Link>{" "}
          view there's a shortcut to quickly copy your unique link (see image)
          and use it anywhere online.
          <Img
            mt="0.25rem"
            mb="1rem"
            boxShadow="0 0 1px #111"
            height="50px"
            src={cloudfrontURL("/Screenshot+2022-04-06+at+13.46.14.png")}
          />
          <br />
          There are also a shortcut to view your unique QR-Code and to share
          your Alfalink on major social media channels and platforms, for
          example Instagram, Twitter Github etc bio or in your LinkedIn, Twitch
          etc. profile.
          <Img
            mt="0.25rem"
            mb="1rem"
            boxShadow="0 0 1px #111"
            height={{ base: "auto", md: "50px" }}
            src={cloudfrontURL("/Screenshot+2022-04-06+at+13.50.38.png")}
          />
        </Text>
      ),
    },

    {
      title: "How to reset your password",
      content: (
        <Text>
          If you have forgotten your password, head to
          <Link textDecoration="underline" href="/in/editor">
            Rest Password
          </Link>{" "}
          page. Add your Alfalink username and we will send a reset password
          email to the email address you provided when you signed up.
          <Img
            m="1rem auto"
            boxShadow="0 0 1px #111"
            height={{ base: "auto", md: "300px" }}
            src={cloudfrontURL("/Screenshot+2022-04-06+at+13.56.09.png")}
          />
          <br />
          Don't remember your email either?{" "}
          <Link textDecoration="underline" href="/in/contact">
            Contact us
          </Link>{" "}
          and we will do our best to help you.
          <br />
          <Text mt="1rem">
            If your're logged in and want to change or reset your password, head
            to your{" "}
            <Link
              textDecoration="underline"
              colorScheme="brand"
              href="/in/profile"
            >
              Account setting
            </Link>{" "}
            page and click on 'Reset password'. We'll send you an email with
            instruction on how to reset your password.
          </Text>
        </Text>
      ),
    },

    {
      title: "How to change your email",
      content: (
        <Text>
          Once you're logged in, head to{" "}
          <Link textDecoration="underline" href="/in/profile">
            Account setting
          </Link>{" "}
          page. Under your account info, change the email field and press
          'Update info'.
          <Img
            m="1rem auto"
            boxShadow="0 0 1px #111"
            height="300px"
            src={cloudfrontURL("/Screenshot+2022-04-06+at+14.17.13.png")}
          />
        </Text>
      ),
    },

    {
      title: "How to use Analytics page",
      content: (
        <>
          <Text mt="1rem">
            <Link textDecoration="underline" href="/in/analytics">
              Analytics
            </Link>{" "}
            page is one of our key pages. Here you can find information about
            your Alfalink visitors, including:
          </Text>
          <Box ml="1rem" my="1rem">
            <ul>
              <li>
                <strong>
                  Lifetime stats - Total numbers of statistical values since you
                  created your account.
                </strong>
                <strong>
                  <br />
                </strong>

                <Box ml="1rem">
                  <ul>
                    <li>
                      Views: Total number of people of clicked on your Alfalink
                      and visited your profile. <br />
                      <em>
                        Unique views: these are the returning visitors to your
                        profile. E.g. same person visiting your profile multiple
                        times.
                      </em>
                    </li>
                  </ul>
                </Box>
                <Box ml="1rem">
                  <ul>
                    <li>
                      Clicks: Total number of people who clicked on one of your
                      links
                      <br />
                      <em>
                        Unique clicks: these are the unique clicks from
                        returning visitors to your profile. E.g. same person
                        clicking on your links multiple times.
                      </em>
                    </li>
                  </ul>
                </Box>

                <Box ml="1rem">
                  <ul>
                    <li>
                      Avg. CTR: Average Click-Through-Rate from your visitors.
                      It's calculated from the ratio of number of clicks to the
                      number of visitors on average. i.e. average number of all
                      the daily CTRs.
                      <br />
                    </li>
                  </ul>
                </Box>
                <Box ml="1rem">
                  <ul>
                    <li>
                      Avg. Time To Click: It's the average time it takes your
                      visitors to the first click on one of your links when they
                      visit your Alfalink. It's calculated from the second a
                      visitor lands on your profile until the first click, and
                      this is the average number of all the daily TTCs.
                      <br />
                    </li>
                  </ul>
                </Box>

                <span>
                  <br />
                </span>
              </li>
              <li>
                <strong>Daily stats</strong>
                <span>
                  <br />
                </span>
                <span>
                  Number of visitors, clicks and CTR on daily basis since the
                  last 30 days. Only days with data are shown to make the data
                  more useful.
                </span>
              </li>
              <li>
                <strong>Top countries</strong>
                <strong>
                  <br />
                </strong>
                <span>
                  Statistics about where in the world your visitors come from.
                  Presented via a map and table for convenience.{" "}
                  <em>
                    <span>(Hover over the map for more information) </span>
                  </em>
                  <br />
                  <em>
                    If a visitor has blocked location sharing capabilities,
                    their location will be shown as 'Unknown'.
                  </em>
                </span>
                <span>
                  <br />
                </span>
              </li>
              <li>
                <strong>Top referrers</strong>
                <strong>
                  <br />
                </strong>
                <span>
                  This is statistics regarding the source and referrer of
                  profile visitors. For example if they come from clicking on
                  your Alfalink on Instagram or LinkedIn. Visitors with a direct
                  visit - entering your Alfalink url in browser directly - are
                  labeled as 'Direct visit' (duh!).
                </span>
              </li>

              <li>
                <strong>Top devices</strong>
                <strong>
                  <br />
                </strong>
                <span>
                  This is statistics regarding device the visitor is using when
                  visiting your Alfalink. E.g. mobile or desktop devices.
                </span>
              </li>

              <li>
                <strong>Links Analytics</strong>
                <strong>
                  <br />
                </strong>
                <span>
                  Statistics regarding individual links on your Alfalink.
                </span>
              </li>

              <li>
                <strong>Social handles analytics</strong>
                <strong>
                  <br />
                </strong>
                <span>
                  Statistics regarding individual social handles on your
                  Alfalink.
                </span>
              </li>
            </ul>
          </Box>
          <Box ml="1rem">
            <Text>Glossary:</Text>
            <ul>
              <li>
                Unique: A unique visitor or click is a returning person.
                <em>
                  Same person visiting your Alfalink or clicking on the same
                  link multiple times is counted as unique.
                </em>
                <br />
                <em>
                  A high CTR is a good indication that visitors find your links
                  interesting and relevant.
                </em>
                <br />
              </li>
              <li>
                CTR: Click-Through-Rate is the number of link clicks divided by
                the number visitors. For example, if you had 5 link clicks and
                100 profile visitors, then the CTR would be 5%.
                <br />
                <em>
                  A high CTR is a good indication that visitors find your links
                  interesting and relevant.
                </em>
                <br />
              </li>
              <li>
                TTC: Time-to-Click is the time it takes for a visitor to click
                on a link for the first time when they land on your Alfalink.
                <br />
              </li>
            </ul>
          </Box>

          <Text mt="2rem">Screenshots:</Text>
          <Box>
            <Img
              m="1rem auto"
              boxShadow="0 0 1px #111"
              height={{ base: "auto", md: "300px" }}
              src={cloudfrontURL("/Screenshot+2022-04-06+at+15.49.15.png")}
            />{" "}
            <Img
              m="2rem auto"
              boxShadow="0 0 1px #111"
              height={{ base: "auto", md: "300px" }}
              src={cloudfrontURL("/Screenshot+2022-04-06+at+15.49.32.png")}
            />
            <Img
              m="1rem auto"
              boxShadow="0 0 1px #111"
              height={{ base: "auto", md: "300px" }}
              src={cloudfrontURL("/Screenshot+2022-04-06+at+15.56.30.png")}
            />
          </Box>
        </>
      ),
    },

    {
      title: "How to get verified and get the blue check",
      content: (
        <Text>
          Please
          <Link textDecoration="underline" href="/in/contact">
            {" "}
            Contact us
          </Link>{" "}
          with your information and we'll look into it.
        </Text>
      ),
    },

    {
      title: "How to delete your account",
      content: (
        <>
          <Text>
            We hope you stay with us, but if you wish to delete your account you
            can do so in{" "}
            <Link
              textDecoration="underline"
              colorScheme="brand"
              href="/in/profile"
            >
              Account setting
            </Link>{" "}
            page.
          </Text>
          <br />
          <Text>
            In Account setting page, click on 'Delete account' button and
            confirm that you wish to permanently delete your account.
          </Text>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Support | Alfalink</title>
      </Helmet>
      <Page>
        <Search questions={questionList} />
        <Box mt="4rem" pb={{ base: "4rem", md: "8rem" }} textAlign="center">
          <Text>
            Can't find what you're looking for or have feedback?{" "}
            <Link textDecoration="underline" href="/in/contact">
              {" "}
              Let us know
            </Link>
            .
          </Text>
        </Box>
      </Page>
    </>
  );
};
