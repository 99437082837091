import {
  BioContainer,
  BuyMeACoffee,
  ContainerHeader,
  Divider,
  KoFi,
  Label,
  Link,
  Links,
  MainContainer,
  Music,
  ProfileImage,
  SocialLinks,
  Support,
  Text,
  Twitter,
  Video,
  Image,
  BrandAd,
  MintMobile,
} from "components/Selectors";
import { Box, Skeleton, SkeletonCircle, Stack } from "@chakra-ui/react";
import { EVENT_PAGE_VIEW, EVENT_TIME_SPENT_ON_PAGE } from "utils/events";
import { Editor, Element, Frame } from "@craftjs/core";
import { useEffect, useState } from "react";

import EmptyFrameData from "./EmptyFrameData";
import { ErrorPage } from "./ErrorPage";
import { Helmet } from "react-helmet";
import { TooManyRequestErrorPage } from "components/ErrorPageTooManyRequests";
import UsernameAvailable from "./UsernameAvailable";
import api from "api";
import axios from "axios";
import lz from "lzutf8";
import { registerEvent } from "api/queries";
import { useParams } from "react-router-dom";

const ViewScreen = () => {
  //const encodedUrl = encodeURI(url);
  const { username } = useParams();

  useEffect(() => {
    var startDate = new Date();

    const onbeforeunloadFn = () => {
      var endDate = new Date();

      var secondsTimeSpent = (endDate.getTime() - startDate.getTime()) / 1000;

      registerEvent({
        username: username,
        eventType: EVENT_TIME_SPENT_ON_PAGE,
        timeSpentOnPage: secondsTimeSpent,
      });
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [frameData, setFrameData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);

  const [frameDataExists, setFrameDataExists] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = (await api.get(
          `api/public/frame-data/${username}`
        )) as any;

        const { frameData } = data;

        if (frameData) {
          setFrameDataExists(true);
          const updatedUint8array = lz.decodeBase64(frameData);

          const updatedJson = JSON.parse(lz.decompress(updatedUint8array));

          setLoading(false);

          return setFrameData(updatedJson);
        }

        setFrameData("");
        setFrameDataExists(false);
      } catch (error: any) {
        if (error && error.response && error.response.status === 404) {
          setLoading(false);

          return setIsUsernameAvailable(true);
        }

        if (error && error.response && error.response.status === 429) {
          setLoading(false);

          return setError(429);
        }

        setError(error);
        console.error("error ", error);

        setFrameData("");
        if (axios.isCancel(error)) {
          console.warn("Request canceled", error.message);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [username]);

  useEffect(() => {
    if (username && frameData) {
      registerEvent({
        username: username,
        eventType: EVENT_PAGE_VIEW,
      });
    }
  }, [username, frameData]);

  const LinkSkeleton = (
    <Skeleton
      startColor="gray.50"
      endColor="gray.300"
      height="56px"
      width="100%"
    />
  );

  if (loading) {
    return (
      <Box py="2rem" px="1rem" bg="white">
        <Box display="flex" justifyContent="center">
          <SkeletonCircle
            size="90px"
            startColor="gray.50"
            endColor="gray.300"
          />
        </Box>

        <Stack
          spacing={"2.5rem"}
          mt="3rem"
          px={{
            base: "0.5rem",
            sm: "6rem",
            lg: "14rem",
            xl: "24rem",
            "2xl": "32rem",
          }}
        >
          {LinkSkeleton}

          {LinkSkeleton}

          {LinkSkeleton}

          {LinkSkeleton}
        </Stack>
      </Box>
    );
  }

  if (error === 429) {
    return <TooManyRequestErrorPage />;
  }

  if (error) {
    return <ErrorPage isNetworkError />;
  }

  if (isUsernameAvailable) {
    return <UsernameAvailable username={username ?? ""} />;
  }

  if (!frameDataExists) {
    return <EmptyFrameData username={username} />;
  }

  console.error('frameData', frameData)

  return (
    <>
      <Helmet>
        <title>{`@${username} | Alfalink`}</title>
      </Helmet>

      <Box>
        <Editor
          resolver={{
            MainContainer,
            Text,
            ContainerHeader,
            ProfileImage,
            BioContainer,
            Label,
            Links,
            Link,
            KoFi,
            Twitter,
            BuyMeACoffee,
            Music,
            Video,
            SocialLinks,
            Divider,
            Support,
            Image,
            BrandAd,
            MintMobile,
          }}
          enabled={false}
        >
          <Frame data={frameData}>
            <Element canvas is={MainContainer}>
              <Element canvas is={ContainerHeader}>
                <BioContainer />
              </Element>

              <Element canvas is={Label}></Element>

              <Element canvas is={Links}>
                <Element canvas is={Link}>
                  <Link />
                </Element>
              </Element>

              <Element canvas is={SocialLinks}></Element>
            </Element>
          </Frame>
        </Editor>
      </Box>
    </>
  );
};

export default ViewScreen;
