import { Box, Center, Heading, Text, useMediaQuery } from "@chakra-ui/react";

import Fade from "react-reveal/Fade";
import GetStartedInput from "components/GetStartedInput";
import Lottie from "react-lottie";
import money from "assets/jsons/money-analytics.json";

const defaultOptions = {
  loop: true,
  autoplay: true,

  animationData: money,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Monetize = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column-reverse", md: "row" }}
      p={{ base: "1rem", md: "0rem 2rem" }}
      background="linear-gradient(-345deg, #824eff 0%, #3e11a8 100%)"
      borderRadius="10px"
      color="#f0f5ff"
    >
      <Box
        pt={{ base: "1rem", md: "4rem" }}
        width={{ sm: "50%", md: "50%" }}
        pl={{ md: "0rem", l: "2rem", xl: "3rem" }}
        pb={{ base: "2rem", md: "4em" }}
      >
        <Fade bottom>
          <Heading
            mb="0.25rem"
            as="h2"
            fontSize={{ base: "1.5rem", md: "2rem" }}
          >
            Monetize your passion
          </Heading>

          <Heading as="h3" size="sm" mb="1rem">
            Stay closer to your followers, readers, listeners, and clients
          </Heading>

          <Text py="1rem">
            Best Link-in-Bio tool for Content Creators, YouTubers, Musicians,
            Coaches, Chefs, Authors, Podcasters and other creatives
          </Text>

          <Center textAlign="center" mt="4rem">
            <GetStartedInput showInput />
          </Center>
        </Fade>
      </Box>

      <Box margin="auto" pt="0">
        <Lottie
          options={defaultOptions}
          height={isSmallerThan600 ? 320 : 450}
          width={isSmallerThan600 ? 290 : 450}
        />
      </Box>
    </Box>
  );
};

export default Monetize;
