export const FontFormatIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.443 5H2v14.277h5.342c1.614 0 2.892-.364 3.833-1.093.94-.73 1.41-1.745 1.41-3.047 0-.918-.2-1.654-.6-2.207-.4-.554-1.053-.931-1.958-1.133v-.098a2.4 2.4 0 0 0 1.597-.986c.4-.547.6-1.247.6-2.1 0-1.256-.457-2.173-1.372-2.749C9.938 5.288 8.468 5 6.443 5Zm.315 5.174H5V7h1.592c.866 0 1.499.119 1.9.356.4.238.6.63.6 1.177 0 .586-.184 1.006-.552 1.26-.368.254-.962.38-1.782.38ZM5 16.72V13h1.875c1.693 0 2.54.592 2.54 1.777 0 .658-.2 1.146-.597 1.465-.397.32-1.012.479-1.845.479H5Zm9-9.971a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Zm.75 3.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5ZM14 18.75a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
