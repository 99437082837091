import {
  Alert,
  Divider,
  Drawer,
  HStack,
  Heading,
  Radio,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilAlignCenter from "@iconscout/react-unicons/icons/uil-align-center";
import UilAlignLeft from "@iconscout/react-unicons/icons/uil-align-left";
import UilAlignRight from "@iconscout/react-unicons/icons/uil-align-right";
import UilArrowsMerge from "@iconscout/react-unicons/icons/uil-arrows-merge";
import UilArrowsResizeH from "@iconscout/react-unicons/icons/uil-arrows-resize-h";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";
import UilInfo from "@iconscout/react-unicons/icons/uil-info-circle";
import UilMargins from "@iconscout/react-unicons/icons/uil-line-spacing";
import { useState } from "react";

export const SocialLinksSettings = () => {
  const [drawerOpen, setDrawer] = useState("");

  const closeDrawer = () => {
    setDrawer("");
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const InfoSettingItems = (
    <Stack spacing="24px">
      <SettingsItem
        full={true}
        propKey="email"
        type="text"
        label="Email"
        placeholder="example@domain.com"
      />

      <SettingsItem
        full={true}
        propKey="twitter"
        type="text"
        label="Twitter handle"
        placeholder="Your twitter @handleName"
      />

      <SettingsItem
        full={true}
        propKey="instagram"
        type="text"
        label="Instagram username"
        placeholder="Your Instagram @username"
      />

      <SettingsItem
        full={true}
        propKey="tiktok"
        type="text"
        label="TikTok username"
        placeholder="Your TikTok @username"
      />

      <SettingsItem
        full={true}
        propKey="facebookUrl"
        type="text"
        label="Facebook page URL"
        placeholder="https://facebook.com/facebookpageurl"
      />

      <SettingsItem
        full={true}
        propKey="snapchat"
        type="text"
        label="Snapchat username"
        placeholder="Your Snapchat @username"
      />

      <SettingsItem
        full={true}
        propKey="youtubeUrl"
        type="text"
        label="YouTube channel name"
        placeholder="https://youtube.com/channel/youtubechannelurl"
      />

      <SettingsItem
        full={true}
        propKey="linkedin"
        type="text"
        label="LinkedIn username"
        placeholder="Your LinkedIn @username"
      />

      <SettingsItem
        full={true}
        propKey="linkedinCompany"
        type="text"
        label="LinkedIn company username"
        placeholder="Your LinkedIn company @username"
      />

      <SettingsItem
        full={true}
        propKey="goodreads"
        type="text"
        label="Goodreads User or Author URL"
        placeholder="https://www.goodreads.com/user/show/YourUsername"
      />

      <SettingsItem
        full={true}
        propKey="twitch"
        type="text"
        label="Twitch username"
        placeholder="Your Twitch @username"
      />

      <SettingsItem
        full={true}
        propKey="tellonym"
        type="text"
        label="Tellonym username"
        placeholder="Your Tellonym username"
      />

      <SettingsItem
        full={true}
        propKey="patreon"
        type="text"
        label="Patreon account name"
        placeholder="Your Patreon @AccountName"
      />

      <SettingsItem
        full={true}
        propKey="etsy"
        type="text"
        label="Etsy Shopname"
        placeholder="Your Etsy @shopname"
      />

      <SettingsItem
        full={true}
        propKey="appstoreUrl"
        type="text"
        label="Appstore URL"
        placeholder="https://apps.apple.com/us/yourapp/appid123"
      />

      <SettingsItem
        full={true}
        propKey="whatsapp"
        type="number"
        label="WhatsApp number"
        placeholder="+123123123"
      />
    </Stack>
  );

  const MobileIconAlignmentSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Icon alignment
      </Heading>

      <SettingsItem full={true} propKey="position" type="buttonRadio">
        <HStack spacing="1.25rem">
          <Radio value="left">
            <UilAlignLeft />
          </Radio>
          <Radio value="flex-end">
            <UilAlignRight />
          </Radio>
          <Radio value="center">
            <UilAlignCenter />
          </Radio>
          <Radio value="space-between">
            <UilArrowsResizeH />
          </Radio>
          <Radio value="space-evenly">
            <UilArrowsMerge />
          </Radio>
        </HStack>
      </SettingsItem>
    </>
  );

  const MobileIconSpacingMarginsSettingItem = (
    <>
      <SettingsItem
        full
        propKey="marginTop"
        type="slider"
        sliderMin={0}
        sliderMax={42}
        label="Margin from top"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="marginBottom"
        type="slider"
        sliderMin={0}
        sliderMax={42}
        label="Margin from bottom"
      />
    </>
  );
  const LayoutSettingItems = (
    <Stack spacing="1rem" py="1.25rem">
      {MobileIconAlignmentSettingItem}
      <Divider my="1em" />

      {MobileIconSpacingMarginsSettingItem}
    </Stack>
  );

  const IconColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Icon color
      </Heading>
      <SettingsItem
        full={true}
        propKey="backgroundColor"
        type="gradient"
        useGradient={false}
      />
    </>
  );

  const InfoMessage = (
    <Alert
      borderRadius="4px"
      status="info"
      fontSize="xs"
      p="0.25rem"
      mb={isLargerThan768 ? "1.5rem" : "1rem"}
      mt={isLargerThan768 ? "-1rem" : "-0.5rem"}
    >
      <UilInfo />

      <Text ml="0.5rem">
        Please see the placeholders on how the username or URL should look like.
        <br /> In most cases you need to only provide your username, account
        name, shop name etc.
      </Text>
    </Alert>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Info</Tab>
        <Tab>Layout</Tab>
        <Tab>Color</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {InfoMessage}
          {InfoSettingItems}
        </TabPanel>

        <TabPanel>{LayoutSettingItems}</TabPanel>

        <TabPanel>{IconColorSettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper
        hidden={
          drawerOpen === "edit" ||
          drawerOpen === "alignment" ||
          drawerOpen === "margin" ||
          drawerOpen === "color"
        }
      >
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("alignment")}>
          <UilAlignCenter />
          <Text>Alignment</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("margin")}>
          <UilMargins />
          <Text>Margins</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("color")}>
          <PaintBucketIcon />
          <Text>Color</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Social handles">
          {InfoMessage}

          {InfoSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "alignment"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Icon alignment" maxHeight="17vh" py="3rem">
          {MobileIconAlignmentSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "margin"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Spacing margins" maxHeight="31vh" py="1rem">
          {MobileIconSpacingMarginsSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "color"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Icon color">{IconColorSettingItem}</DrawerContent>
      </Drawer>
    </>
  );
};
