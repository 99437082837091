import { Avatar, Box } from "@chakra-ui/react";

import { ProfileImageSettings } from "./settings";
import React from "react";
import { useNode } from "@craftjs/core";

type ProfileImageTypes = {
  username?: string;
  imageUrl?: string;
  color?: string;
  imageSize?: string;
  borderSize?: string;
  borderColor?: string;
};
const defaultProps = {
  imageUrl: "",
  color: "",
  imageSize: "84px",
  borderSize: "0",
  borderColor: "#111",
};
export const ProfileImage = (props: Partial<ProfileImageTypes>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { imageUrl, imageSize, username, borderSize, borderColor } = props;

  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      mt="32px"
      mb="8px"
      zIndex={111}
    >
      <Avatar
        border={`${borderSize}px solid ${borderColor}`}
        name={username}
        bg={imageUrl ? "transparent" : "#5d35d3 "}
        ref={connect}
        borderRadius="50%"
        boxShadow="0 0 3px -2px #000000"
        boxSize={imageSize}
        src={imageUrl}
      />
    </Box>
  );
};

ProfileImage.craft = {
  displayName: "Profile Image",
  props: defaultProps,
  related: {
    settings: ProfileImageSettings,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => false,
  },
};
