import * as yup from "yup";

import {
  Badge,
  Center,
  Checkbox,
  Flex,
  FormErrorMessage,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { Box, Divider, Heading, Stack, Text } from "@chakra-ui/layout";
import { Button, ButtonGroup } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import {
  deleteAccount,
  disconnectStripeAccount,
  getStripeAccountInfo,
  getStripeBillingPortalSessionUrl,
  sendPasswordResetRequest,
  updateEmailNewsletterSettings,
  updateProfileUserInfo,
  updateUsername,
  verifyAndFetchStripeAccountInfo,
} from "api/queries";
import { format, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { AUTH_TOKEN } from "utils/constants";
import AlertMessage from "components/AlertMessage";
import Cookies from "js-cookie";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { Input } from "@chakra-ui/input";
import Page from "components/Page";
import { TooManyRequestErrorPage } from "components/ErrorPageTooManyRequests";
import UilArrowDown from "@iconscout/react-unicons/icons/uil-arrow-down";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilCheckIcon from "@iconscout/react-unicons/icons/uil-check-circle";
import UilStop from "@iconscout/react-unicons/icons/uil-ban";
import { useForm } from "react-hook-form";
import { useUserContext } from "HoCs/UserProvider";
import { yupResolver } from "@hookform/resolvers/yup";

const queryString = require("query-string");

const columns = [
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    cell: (row) => `${row.amount ? row.amount / 100 : "Unspecified"}$`,
  },
  {
    name: "From email",
    grow: 3,
    selector: (row) => row?.billing_details?.email,

    sortable: true,
  },
  {
    name: "Date",
    compact: true,
    selector: (row) => {
      const result = fromUnixTime(row.created);

      return format(result, "MMMM dd, yyyy");
    },
    sortable: true,
  },
];

const SignupSchema = yup
  .object({
    username: yup
      .string()
      .trim()
      .min(1)
      .max(20)
      .matches(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
        "Username can only contain alphanumeric characters, underscores (_) and non starting, ending or consecutive dots(.)"
      )
      .required(),
  })
  .required();

const ProfileScreen = () => {
  const [localConnectedAccountId, setLocalConnectedAccountId] =
    useState(undefined);
  const [
    localStripeSubscriptionCustomerId,
    setLocalStripeSubscriptionCustomerId,
  ] = useState(undefined);

  const { userData, loadingUserData, userDataError } = useUserContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(SignupSchema),

    defaultValues: {
      username: "",
    },
  });

  useEffect(() => {
    if (userData) {
      setValue("username", userData.username);
      setLocalConnectedAccountId(userData?.stripeIntegration?.accountId);
      setLocalStripeSubscriptionCustomerId(
        userData?.stripeSubscriptionInfo?.customerId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const { username } = watch();

  const stripeClientId =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_CLIENT_ID
      : process.env.REACT_APP_STRIPE_CLIENT_ID_TEST;

  const stripeRedirectUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_REDIRECT_URI
      : process.env.REACT_APP_STRIPE_REDIRECT_URI_TEST;

  const stripeConnectURI = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&redirect_uri=${stripeRedirectUrl}`;

  const location = useLocation();

  const { code } = queryString.parse(location.search);

  const { data: verifyStripeData, error: errorVerifyStripe } = useQuery(
    ["verifyAndFetchStripeAccountInfo", code],
    () => verifyAndFetchStripeAccountInfo({ code: code ?? "" }),

    {
      onSuccess: () => {
        toast({
          title: "Stripe account connected.",
          status: "success",
          variant: "subtle",
          position: "top",
          isClosable: true,
        });
      },

      onError: () => {
        toast({
          title:
            "Failed to connect Stripe account. Please try again later or contact support.",
          status: "error",
          variant: "subtle",
          position: "top",
          isClosable: true,
        });
      },

      enabled: Boolean(code),
    }
  );

  const {
    mutateAsync: triggerDisconnectStripeAccount,
    isLoading: isLoadingDisconnectStripeAccount,
  } = useMutation(disconnectStripeAccount, {
    onError: () => {
      toast({
        title:
          "Failed to disconnect Stripe account. Please try again later or contact support.",
        status: "error",
        variant: "subtle",
        position: "top",
        isClosable: true,
      });
    },
  });

  const {
    mutateAsync: triggerUpdateEmailSettings,
    isLoading: isLoadingUpdateEmailSettings,
  } = useMutation(updateEmailNewsletterSettings);

  const {
    data: stripeAccountInfoData,
    error: errorGetStripeAccountInfo,
    isLoading: isLoadingGetStripeAccountInfo,
    refetch: refetchStripeAccountInfoData,
  } = useQuery(
    ["getStripeAccountInfo", localConnectedAccountId],
    () => getStripeAccountInfo({ connectedAccountId: localConnectedAccountId }),

    {
      enabled: Boolean(localConnectedAccountId),
    }
  );

  const {
    isLoading: isLoadingGetStripeBillingPortalData,
    refetch: fetchGetStripeBillingPortalSessionUrl,
  } = useQuery(
    ["getStripeBillingPortalSessionUrl", localStripeSubscriptionCustomerId],
    () =>
      getStripeBillingPortalSessionUrl({
        stripeSubscriptionCustomerId: localStripeSubscriptionCustomerId,
      }),

    {
      enabled: false,
    }
  );

  const toast = useToast();
  const history = useHistory();

  const [submittedPasswordRequest, setSubmittedPasswordRequest] =
    useState(false);

  const [weeklyProfilePerformanceEmail, setWeeklyProfilePerformanceEmail] =
    useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);

  const [userInfo, setUserInfo] = useState({
    name: userData?.name ?? "",
    email: userData?.email ?? "",
    avatar: userData?.avatar ?? "",
    username: userData?.username ?? "",
    plan: userData?.plan ?? "",
  });

  const {
    mutateAsync: triggerUpdateProfileUserInfo,
    isLoading: isLoadingUpdateUserInfo,
  } = useMutation(updateProfileUserInfo, {
    onSuccess: (data) =>
      setUserInfo({
        ...userInfo,
        name: data.name,
        email: data.email,
      }),
  });

  const {
    mutateAsync: triggerUpdateUsername,
    isLoading: isLoadingUpdateUsername,
  } = useMutation(updateUsername, {
    onSuccess: (data) =>
      setUserInfo({
        ...userInfo,
        username: data.username,
      }),
  });

  const {
    mutateAsync: triggerSendPasswordResetRequest,
    isLoading: isLoadingSendPasswordResetRequest,
  } = useMutation(sendPasswordResetRequest);

  const {
    mutateAsync: triggerDeleteAccount,
    isLoading: isLoadingDeleteAccount,
  } = useMutation(deleteAccount);

  useEffect(() => {
    setUserInfo({
      name: userData?.name ?? "",
      email: userData?.email ?? "",
      avatar: userData?.avatar ?? "",
      username: userData?.username ?? "",
      plan: userData?.plan ?? "",
    });

    setWeeklyProfilePerformanceEmail(
      userData?.emailNewsletterSettings?.weeklyProfilePerformance
    );
  }, [userData]);

  const handleChange = (e) => {
    const value = e.target.value;
    setUserInfo({
      ...userInfo,
      [e.target.name]: value,
    });
  };

  if (loadingUserData || userData === undefined) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        m="0 auto"
        h="calc(100vh - 58px)"
      >
        <Spinner
          display="flex"
          size="xl"
          thickness="4px"
          justifyContent="center"
          alignItems="center"
          margin="1rem auto"
          emptyColor="gray.200"
          colorScheme="brand"
        />
      </Flex>
    );
  }

  if (userDataError === 429) {
    return <TooManyRequestErrorPage />;
  }

  if (userDataError) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        m="1rem auto"
      >
        <AlertMessage
          status="error"
          message="An error has occurred fetching some data of this page. Please check in again later or contact Support."
        />
      </Flex>
    );
  }

  const handleInfoUpdateSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const data = await triggerUpdateProfileUserInfo({
        name: userInfo.name,
        email: userInfo.email,
      });

      toast({
        title: "Information updated successfully",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      if (data?.token) {
        Cookies.set(AUTH_TOKEN, data.token, { expires: 7 });
      }
    } catch (error) {
      console.warn("Error ", error);

      toast({
        title:
          "There was an error updating the information. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleEmailSettingsUpdateSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      await triggerUpdateEmailSettings({
        weeklyProfilePerformance: weeklyProfilePerformanceEmail,
      });

      toast({
        title: "Email preferences updated successfully",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.warn("Error ", error);

      toast({
        title:
          "There was an error updating the email preferences. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleUpdateUsernameSubmit = async (formData) => {
    const { username } = formData;

    try {
      const data = await triggerUpdateUsername({
        username: username.trim(),
      });

      toast({
        title: "Username updated successfully",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      if (data?.token) {
        Cookies.set(AUTH_TOKEN, data.token, { expires: 7 });
      }
    } catch (error: any) {
      console.warn("Error ", error);

      if (error && error.response && error.response.status === 403) {
        toast({
          title: "Username already taken, please choose a different username.",
          status: "error",
          variant: "subtle",
          isClosable: true,
          position: "top",
        });
        return;
      }

      toast({
        title:
          "There was an error updating the information. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDisconnectStripeAccount = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      await triggerDisconnectStripeAccount({
        accountId: localConnectedAccountId ?? "",
      });

      refetchStripeAccountInfoData();

      toast({
        title: "Stripe account disconnected.",
        status: "success",
        variant: "subtle",
        position: "top",
        isClosable: true,
      });

      setShowStripeModal(false);
    } catch (error) {
      console.warn("Error ", error);

      toast({
        title: "Something went wrong. Try again later or contact support",
        status: "error",
        variant: "subtle",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleForgotPassword = async () => {
    try {
      await triggerSendPasswordResetRequest();

      toast({
        title: "Reset password email sent",
        description:
          "Please follow the instruction in the email to reset your password.",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      setSubmittedPasswordRequest(true);
    } catch (error: any) {
      console.warn("Error", error);

      if (error && error.response?.status === 429) {
        setSubmittedPasswordRequest(true);

        return toast({
          title: "Too many server requests",
          description:
            "We have already sent a reset password email. Please check your email or send a new request a bit later",
          status: "error",
          variant: "subtle",
          position: "top",
          isClosable: true,
        });
      }

      toast({
        title:
          "Failed to send password reset email. Please try again in a moment or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await triggerDeleteAccount();
      toast({
        title: "Account removed 😔",
        description: "Hope to see you again, take care for now.",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      Cookies.remove(AUTH_TOKEN);

      setShowModal(false);
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (error) {
      console.warn("Error ", error);
      setShowModal(false);

      toast({
        title:
          "Failed to delete account. Please try again later or contact Support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleGetStripeBillingUrl = async () => {
    try {
      const { data } = await fetchGetStripeBillingPortalSessionUrl();
      const stripeBillingUrl = data?.data?.url;

      window.open(stripeBillingUrl, "_blank");
    } catch (error) {
      console.warn("Error ", error);

      toast({
        title:
          "There was an error connecting to Stripe billing portal, please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const customStyles = {
    table: {
      style: {
        padding: "0.5px",
        border: `1px solid #f1f1f1`,
        borderRadius: "6px",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
      },
    },
    tableWrapper: {
      style: {
        display: "table",
      },
    },
    rows: {
      style: {
        minHeight: "50px",
        border: "none",
        borderBottomWidth: "0 !important",
      },
      denseStyle: {
        minHeight: "42px",
      },
      stripedStyle: {
        backgroundColor: "#f7f7f7",
      },
    },

    headCells: {
      style: {
        minHeight: "48px",
        fontSize: "12px",
        color: "#111",
      },
    },

    pagination: {
      style: {
        fontSize: "11px",
        border: `1px solid #f1f1f1`,
        color: "#777777",
        borderRadius: "6px",
        marginTop: "0px",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
      },
    },
  };

  const renderStripeSubscriptionDetails = () => {
    if (!userData?.stripeSubscriptionInfo) {
      return null;
    }

    const {
      productName,
      // currency,
      currentPeriodEnd,
      planInterval,
      priceAmount,
      status,
    } = userData && userData?.stripeSubscriptionInfo;

    const planEndsAt = fromUnixTime(currentPeriodEnd);

    return (
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="2rem">
        <Box>
          <Text fontSize="sm">Current Plan</Text>
          <Text fontWeight="bold" fontSize="lg">
            {productName} &nbsp;
            <Badge colorScheme="green">{status}</Badge>
          </Text>
          <Text fontSize="xs">
            ${priceAmount / 100} per {planInterval}
          </Text>
        </Box>

        <Box fontSize="sm">
          <Text>Billing period</Text>
          <Text fontWeight="bold">{planInterval}ly</Text>
          <Text fontSize="xs">
            {status === "trialing" ? "Trial ends on" : "Next renewal"}:{" "}
            {format(planEndsAt, "MMMM dd, yyyy")}
          </Text>
        </Box>
      </SimpleGrid>
    );
  };

  return (
    <>
      <Helmet>
        <title>Settings | Alfalink</title>
      </Helmet>
      <Page>
        <Box mx={{ base: "0rem", md: "9rem" }} pb="6rem">
          <Text
            fontSize="xl"
            fontWeight="bold"
            textAlign="left"
            my="1.5rem"
            color="blackAlpha.800"
          >
            Settings
            <Divider my="0.5rem" />
          </Text>

          <Box
            boxShadow="0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)"
            borderRadius="8px"
            p={{ base: "1.5rem 0.75rem", md: "1.5rem" }}
            backgroundColor="#ffffff"
          >
            <Stack spacing="2rem">
              <Heading size="sm">Billing</Heading>

              {userData.plan === "free" ? (
                <>
                  <Box>
                    <Text fontSize="sm">Current Plan</Text>
                    <Text fontWeight="bold" fontSize="lg">
                      Free &nbsp;
                      <Badge colorScheme="green">Active</Badge>
                    </Text>
                    <Text fontSize="xs">Free forever</Text>
                  </Box>

                  <Stack spacing="1rem" pb="1rem">
                    <Box
                      p={{ base: "2rem 1rem", md: "2rem" }}
                      color="#ffffff"
                      background="linear-gradient(344deg, #4f3cc9 0%, rgb(133 20 207) 100%)"
                      borderRadius="0.5rem"
                    >
                      <Heading>Go Pro ✨</Heading>
                      <Text fontWeight="300" fontSize="sm">
                        Upgrade your account to unlock more powerful features.{" "}
                      </Text>

                      <List
                        spacing={{ base: "1rem", md: "0.5rem" }}
                        fontSize="sm"
                        pt="1rem"
                      >
                        <ListItem
                          display="flex"
                          alignItems={{ base: "flex-start", md: "center" }}
                        >
                          <UilCheckIcon size="20px" />
                          &nbsp;Detailed analytical data about your profile
                          visitors and individual link clicks
                        </ListItem>

                        <ListItem display="flex" alignContent="center">
                          <UilCheckIcon size="20px" />
                          &nbsp;Ability to change username
                        </ListItem>

                        <ListItem display="flex" alignContent="center">
                          <UilCheckIcon size="20px" />
                          &nbsp;Lower transaction fee on payments
                        </ListItem>

                        <ListItem display="flex" alignContent="center">
                          <UilCheckIcon size="20px" />
                          &nbsp;Whitelisted and branded page
                        </ListItem>
                      </List>
                    </Box>

                    <Link
                      href={"/in/pricing"}
                      textAlign="center"
                      textDecoration="underline"
                    >
                      <Text fontSize="sm" width="full">
                        Compare plans
                      </Text>
                    </Link>
                  </Stack>
                </>
              ) : (
                <>
                  {renderStripeSubscriptionDetails()}
                  <Stack spacing="1rem" py="1rem">
                    {isLoadingGetStripeBillingPortalData ? (
                      <Center py="0.5rem">
                        <Spinner />
                      </Center>
                    ) : (
                      <Button width="full" onClick={handleGetStripeBillingUrl}>
                        Change plan
                      </Button>
                    )}

                    <Link
                      href={"/in/pricing"}
                      width="full"
                      textAlign="center"
                      textDecoration="underline"
                    >
                      <Text fontSize="xs" width="full">
                        Compare plans
                      </Text>
                    </Link>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>

          <Divider my="2rem" />

          <>
            <Stack
              spacing="2rem"
              boxShadow={{
                base: "none",
                md: "0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 7%), 0 1px 1px 0 rgb(0 0 0 / 7%)",
              }}
              borderRadius="8px"
              p={{ base: "0", md: "1.5rem" }}
            >
              <Stack spacing="0.25rem">
                <Heading size="sm">Connected payment methods</Heading>

                <Text color="gray.500" fontSize="sm">
                  Connect your merchant account with Alfalink to collect
                  payments from your fans and profile visitors
                </Text>
              </Stack>

              <Divider />

              <Box
                display={{ base: "block", md: "flex" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="bold">
                    Stripe{" "}
                    {(localConnectedAccountId && !errorGetStripeAccountInfo) ||
                    (verifyStripeData && !errorVerifyStripe) ? (
                      <Badge colorScheme="green">Connected</Badge>
                    ) : (
                      <Badge colorScheme="red">Not connected</Badge>
                    )}
                  </Text>
                  <Text color="gray.500" fontSize="sm">
                    Allows you to accept credit card payments, including Apple
                    Pay and Google Pay.
                  </Text>
                </Box>

                <Box textAlign="center" py="1.75rem">
                  {(localConnectedAccountId && !errorGetStripeAccountInfo) ||
                  (verifyStripeData && !errorVerifyStripe) ? (
                    <Button
                      onClick={() => setShowStripeModal(true)}
                      leftIcon={<UilStop />}
                      colorScheme="red"
                      variant="outline"
                    >
                      Disconnect
                    </Button>
                  ) : (
                    <>
                      <Link href={stripeConnectURI}>
                        <Button
                          rightIcon={<UilArrowRight />}
                          variant="outline"
                          colorScheme="blue"
                        >
                          Connect
                        </Button>
                      </Link>
                    </>
                  )}
                </Box>
              </Box>

              {isLoadingGetStripeAccountInfo && localConnectedAccountId ? (
                <Center py="5rem">
                  <Spinner centered />
                </Center>
              ) : null}

              {!errorGetStripeAccountInfo &&
              stripeAccountInfoData?.data?.charges?.data.length ? (
                <>
                  <Divider my="2rem" />
                  <Stack spacing="0.5rem">
                    <Text fontSize="sm" color="gray.600" ml="0.25rem">
                      Last 10 payments from your Stripe account
                    </Text>

                    <DataTable
                      customStyles={customStyles}
                      striped
                      // @ts-ignore
                      columns={columns}
                      data={stripeAccountInfoData?.data?.charges?.data ?? []}
                      sortIcon={<UilArrowDown />}
                      pagination={false}
                    />
                  </Stack>
                </>
              ) : null}

              {errorVerifyStripe &&
                // @ts-ignore
                errorVerifyStripe?.response?.status === 400 && (
                  <AlertMessage
                    status="error"
                    message="Stripe Authorization code expired. Please try to connect
              your Stripe account again"
                  />
                )}

              {verifyStripeData && !errorVerifyStripe && (
                <AlertMessage
                  status="success"
                  message="Stripe account connected"
                />
              )}
            </Stack>

            <Divider my="2rem" />
          </>

          <Box
            boxShadow="0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)"
            borderRadius="8px"
            p={{ base: "1.5rem 0.75rem", md: "1.5rem" }}
          >
            <Heading size="sm">Change username</Heading>

            <form onSubmit={handleSubmit(handleUpdateUsernameSubmit)}>
              <Stack spacing="1rem" mt="2rem" mb="1rem">
                <FormControl isInvalid={!!errors.username} mb="1.25rem">
                  <FormLabel fontSize="sm" color="gray.500">
                    Username
                  </FormLabel>

                  <Input
                    {...register("username")}
                    placeholder="Username"
                    size="lg"
                    name="username"
                    disabled={userData?.plan !== "business"}
                  />
                  <FormErrorMessage>
                    {errors.username?.message}
                  </FormErrorMessage>
                </FormControl>

                {userData?.plan === "business" ? (
                  <Button
                    size="lg"
                    isLoading={isLoadingUpdateUsername}
                    disabled={
                      userData?.plan !== "business" ||
                      !!errors.username ||
                      username === userData?.username
                    }
                    type="submit"
                    width="full"
                  >
                    Change username
                  </Button>
                ) : (
                  <AlertMessage
                    status="info"
                    message="Only Business accounts can change and update username."
                  />
                )}

                {username !== userData?.username && (
                  <>
                    <Text fontSize="sm" color="gray.700" py="1rem">
                      Your new Alfalink URL will be:
                      <Link
                        href={`https://www.alfalink.co/${username}`}
                        isExternal
                        textDecoration="underline"
                        fontWeight="bold"
                      >
                        <br /> {`alfalink.co/${username}`}
                      </Link>
                    </Text>

                    <AlertMessage
                      status="warning"
                      message="Username change can be performed once every 60 days."
                    />

                    <AlertMessage
                      status="warning"
                      message="Username change will take effect immediately and will affect how your friends and profile visitors find you."
                    />

                    <AlertMessage
                      status="warning"
                      message="Your current username will be available to take."
                    />
                  </>
                )}
              </Stack>
            </form>
          </Box>

          <Divider my="2.5rem" />

          <Box
            boxShadow="0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)"
            borderRadius="8px"
            p={{ base: "1.5rem 0.75rem", md: "1.5rem" }}
          >
            <Heading size="sm">Account information</Heading>

            <form onSubmit={handleInfoUpdateSubmit}>
              <Stack spacing="2rem" mt="2rem" mb="1rem">
                <FormControl>
                  <FormLabel fontSize="sm" color="gray.500">
                    Account name
                  </FormLabel>
                  <Input
                    placeholder="Name"
                    size="lg"
                    name="name"
                    maxLength={25}
                    defaultValue={userInfo?.name}
                    onChange={handleChange}
                    mb="1.25rem"
                  />

                  <FormLabel color="gray.500" fontSize="sm">
                    Email
                  </FormLabel>
                  <Input
                    placeholder="Email"
                    size="lg"
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    name="email"
                    defaultValue={userInfo?.email}
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  isLoading={isLoadingUpdateUserInfo}
                  disabled={
                    userInfo.email === "" ||
                    (userInfo.email === userData?.email &&
                      userInfo.name === userData?.name)
                  }
                  type="submit"
                  width="full"
                >
                  Update info
                </Button>
              </Stack>
            </form>
          </Box>

          <Divider my="2.5rem" />

          <Box
            boxShadow="0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)"
            borderRadius="8px"
            p={{ base: "1.5rem 0.75rem", md: "1.5rem" }}
          >
            <Heading size="sm">Email preferences</Heading>

            <form onSubmit={handleEmailSettingsUpdateSubmit}>
              <Stack spacing="2rem" mt="2rem" mb="1rem">
                {!userData?.emailVerified && (
                  <AlertMessage
                    status="warning"
                    message="Please verify your email to receive weekly emails"
                  />
                )}

                <FormControl>
                  <Checkbox
                    size="lg"
                    defaultChecked={weeklyProfilePerformanceEmail}
                    onChange={() =>
                      setWeeklyProfilePerformanceEmail(
                        !weeklyProfilePerformanceEmail
                      )
                    }
                  >
                    <Text fontSize="sm">
                      Receive weekly profile performance report
                    </Text>
                  </Checkbox>
                </FormControl>

                <Button
                  isLoading={isLoadingUpdateEmailSettings}
                  type="submit"
                  width="full"
                >
                  Update settings
                </Button>
              </Stack>
            </form>
          </Box>

          <Divider my="2.5rem" />

          <Stack
            spacing="2rem"
            boxShadow="0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)"
            borderRadius="8px"
            p={{ base: "1.5rem 0.75rem", md: "1.5rem" }}
          >
            <Heading size="sm">Reset password</Heading>

            <Button
              mt="0.5rem"
              onClick={handleForgotPassword}
              colorScheme="brand"
              isLoading={isLoadingSendPasswordResetRequest}
              disabled={submittedPasswordRequest}
            >
              Reset password for '{userData?.username}'
            </Button>
          </Stack>

          <Divider my="4rem" />

          <Stack
            spacing="1rem"
            boxShadow="0 0 0 1px rgb(50 50 93 / 5%), 0 2px 5px 0 rgb(50 50 93 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)"
            borderRadius="8px"
            p={{ base: "1.5rem 0.75rem", md: "1.5rem" }}
          >
            <Heading as="h4" size="md">
              Danger zone
            </Heading>

            <Button
              onClick={() => setShowModal(true)}
              width="full"
              colorScheme="red"
              mb="3rem"
              mt="0.5rem"
            >
              Delete account
            </Button>
          </Stack>

          <Modal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent p="1rem">
              <ModalHeader>Delete account</ModalHeader>
              <ModalCloseButton />
              <ModalBody m="0 auto" pb="2rem">
                Are you sure you want to delete your alfalink account?
                <Text color="red" fontWeight="bold">
                  This action cannot be undone.
                </Text>
              </ModalBody>

              <ButtonGroup justifyContent="right" spacing="1rem">
                <Button variant="solid" onClick={() => setShowModal(false)}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={handleDeleteAccount}
                  isLoading={isLoadingDeleteAccount}
                >
                  Confirm
                </Button>
              </ButtonGroup>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={showStripeModal}
            onClose={() => setShowStripeModal(false)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent p="1rem">
              <ModalHeader>Disconnect Stripe</ModalHeader>
              <ModalCloseButton />
              <ModalBody m="0 auto" pb="2rem">
                <Text fontSize="sm">
                  Are you sure you want to disconnect your Stripe account?
                  <Text
                    fontSize="xs"
                    color="red.600"
                    fontWeight="bold"
                    mt="0.5rem"
                  >
                    You will not be able to collect payments anymore.
                  </Text>
                </Text>
              </ModalBody>

              <ButtonGroup justifyContent="right" spacing="1rem">
                <Button
                  variant="solid"
                  onClick={() => setShowStripeModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={handleDisconnectStripeAccount}
                  isLoading={isLoadingDisconnectStripeAccount}
                >
                  Confirm
                </Button>
              </ButtonGroup>
            </ModalContent>
          </Modal>
        </Box>
      </Page>
    </>
  );
};

export default ProfileScreen;
