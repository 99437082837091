import React from "react";

interface LineProps {
  primary: string;
  secondary: string;
}

const Line = ({ primary, secondary }: LineProps) => {
  return (
    <svg viewBox="0 0 1440 190" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1440" height="190" fill="none" />

      <g strokeWidth="3">
        <g stroke={secondary || "#EfF2F7"}>
          <path d="M0 95.4893C650 -14.5107 790 245.489 1440 95.4893" />
          <path d="M0 95.4893C650 -174.511 790 405.489 1440 95.4893" />
        </g>
        <path
          d="M0 95.4893C650 -94.511 790 325.489 1440 95.4893"
          stroke={primary || "#FF005B"}
        />
      </g>
    </svg>
  );
};

export default Line;
