import "./styles.css";

import { Box, Divider, Text } from "@chakra-ui/layout";
import {
  Button,
  ButtonGroup,
  Tooltip as CTooltip,
  Flex,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {
  getDailyTimeseries,
  getTopTotals,
  getTotalLifetimeCounts,
} from "api/queries";

import AlertMessage from "components/AlertMessage";
import { DailyCountsGraph } from "components/AnalyticsComponents/DailyCountsGraph";
import { ErrorPage } from "containers/ViewScreen/ErrorPage";
import { GlobeMapIcon } from "assets/svgs/globeMapIcon";
import { Helmet } from "react-helmet";
import { LinkStats } from "components/AnalyticsComponents/LinkStats";
import Page from "components/Page";
import { ReferrerIcon } from "assets/svgs/referrerIcon";
import { TopCountsPieChart } from "components/AnalyticsComponents/TopReferrersPieChart";
import { TopCountsTable } from "components/AnalyticsComponents/TopCountsTable";
import { TopDevicesIcon } from "assets/svgs/TopDevicesIcon";
import { TopReferrerTable } from "components/AnalyticsComponents/TopReferrerCountTable";
import { TotalCountsCards } from "components/AnalyticsComponents/TotalCountsCard";
import { WorldMapCounts } from "components/AnalyticsComponents/WorldMapSVG";
import { useQuery } from "react-query";
import { useState } from "react";
import { useUserContext } from "HoCs/UserProvider";

const AnalyticsScreen = () => {
  const { userData, loadingUserData, userDataError } = useUserContext();

  const [timeseries, setTimeseries] = useState([]);
  const [initialTimeseries, setInitialTimeseries] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("7");

  const {
    data: totalLifetimeCounts,
    error: totalLifetimeCountsError,
    isLoading: isLoadingTotalLifetimeCounts,
  } = useQuery("getTotalLifetimeCounts", getTotalLifetimeCounts);

  const {
    data: topTotals,
    error: topTotalsError,
    isLoading: isLoadingTopTotals,
  } = useQuery("getTopTotals", getTopTotals);

  const { error: timeseriesError, isLoading: isLoadingTimeseriesData } =
    useQuery("getDailyTimeseries", getDailyTimeseries, {
      onSuccess: (data) => {
        setInitialTimeseries(data?.data);

        setTimeseries(data?.data ? data?.data.slice(0, 7) : undefined);
      },
      onError: () => {
        setTimeseries(undefined);
      },
    });

  if (timeseriesError || userDataError || topTotalsError) {
    return <ErrorPage />;
  }

  const handleResetTimeseries = () => {
    setSelectedFilter("all");
    setTimeseries(initialTimeseries);
  };

  const handleFilterLast15Days = () => {
    setSelectedFilter("15");

    const last15days = initialTimeseries.slice(0, 15);
    setTimeseries(last15days);
  };

  const handleFilterLast7Days = () => {
    setSelectedFilter("7");
    const last7days = initialTimeseries.slice(0, 7);
    setTimeseries(last7days);
  };

  const TotalNumberSkeletonBlock = (
    <Skeleton
      height="176px"
      startColor="gray.50"
      endColor="gray.300"
      width={{ base: "100%", md: "250px" }}
      ml={{ base: "0rem", md: "1.5rem" }}
      mt={{ base: "1rem", md: "0" }}
    />
  );

  return (
    <>
      <Helmet>
        <title>Analytics | Alfalink</title>
      </Helmet>

      <Page py={{ base: "1rem", md: "1rem" }}>
        <Box>
          <Text color="gray.700" mb="0.5rem" fontWeight="bold" fontSize="lg">
            Lifetime statistics{" "}
            <CTooltip label="Total numbers since you joined" fontSize="xs">
              <i className="uil uil-info-circle" />
            </CTooltip>
          </Text>
        </Box>

        <Box textAlign="left">
          {isLoadingTotalLifetimeCounts || totalLifetimeCounts === undefined ? (
            <Box display={{ md: "flex" }}>
              {TotalNumberSkeletonBlock}

              {TotalNumberSkeletonBlock}

              {TotalNumberSkeletonBlock}

              {TotalNumberSkeletonBlock}
            </Box>
          ) : (
            <TotalCountsCards
              error={totalLifetimeCountsError}
              data={totalLifetimeCounts.data}
            />
          )}

          <Divider my="1.5rem" />

          {isLoadingTimeseriesData ? (
            <Skeleton
              height="420px"
              width="100%"
              startColor="gray.50"
              endColor="gray.300"
            />
          ) : (
            <>
              {timeseries && timeseries.length > 0 ? (
                <>
                  <Box>
                    <Text
                      color="gray.700"
                      mb="0.5rem"
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      Daily stats{" "}
                      <CTooltip
                        label="Only the days with data are shown"
                        fontSize="xs"
                      >
                        <i className="uil uil-info-circle" />
                      </CTooltip>
                    </Text>
                  </Box>
                  <Flex justifyContent="space-around">
                    <ButtonGroup mb={{ base: "0.5rem", md: "0.5rem" }}>
                      <Button
                        colorScheme={
                          selectedFilter === "all" ? "purple" : "gray"
                        }
                        size="xs"
                        onClick={handleResetTimeseries}
                      >
                        Last 30 days
                      </Button>
                      <Button
                        colorScheme={
                          selectedFilter === "15" ? "purple" : "gray"
                        }
                        size="xs"
                        onClick={handleFilterLast15Days}
                      >
                        Last 15 days
                      </Button>
                      <Button
                        colorScheme={selectedFilter === "7" ? "purple" : "gray"}
                        size="xs"
                        onClick={handleFilterLast7Days}
                      >
                        Last 7 days
                      </Button>
                    </ButtonGroup>
                  </Flex>

                  <Box>
                    <DailyCountsGraph dailyTimeseriesData={timeseries} />
                  </Box>
                </>
              ) : (
                <Box mt="1.5rem">
                  <AlertMessage
                    status="info"
                    message=" No daily visitor data found. Share your Alfalink with your
                followers to get visitors data."
                  />
                </Box>
              )}
            </>
          )}
        </Box>

        <Divider my="2rem" />

        {isLoadingTopTotals ? (
          <Skeleton
            height={{ base: "300px", md: "500px" }}
            startColor="gray.50"
            endColor="gray.300"
            width={{ base: "100%", md: "100%" }}
            ml={{ base: "0rem", md: "1rem" }}
          />
        ) : (
          <Box width="100%" minHeight={{ base: "300px", md: "400px" }}>
            <Box display="flex" alignItems="center" ml="2px">
              <GlobeMapIcon />

              <Text fontSize="lg" fontWeight="semibold" ml="0.25rem">
                Top countries
              </Text>
            </Box>

            <Tabs variant="soft-rounded" mt="0.75rem">
              <TabList>
                <Tab>Map</Tab>
                <Tab>Table</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <WorldMapCounts data={topTotals?.data?.topCountries} />
                </TabPanel>
                <TabPanel p="0">
                  <TopCountsTable data={topTotals.data.topCountries} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}

        <Divider my="2rem" />

        {isLoadingTopTotals ? (
          <Skeleton
            height="232px"
            width="100%"
            startColor="gray.50"
            endColor="gray.300"
            mt="1rem"
          />
        ) : (
          <Box
            display="flex"
            flexDirection={{ base: "column", lg: "row" }}
            flexWrap={{ md: "wrap", lg: "nowrap" }}
            width={"100%"}
          >
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="100%"
              flex="1"
              minHeight="400px"
              pr={{ base: "0", lg: "10px" }}
            >
              <Box display="flex" alignItems="center">
                <ReferrerIcon />
                <Text fontSize="lg" fontWeight="semibold">
                  Top referrers
                </Text>
              </Box>

              <Tabs variant="soft-rounded">
                <TabList>
                  <Tab>Chart</Tab>
                  <Tab>Table</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p="0">
                    <TopCountsPieChart
                      data={topTotals.data.topReferrers}
                      isReferrerPie
                    />
                  </TabPanel>
                  <TabPanel p="0">
                    <TopReferrerTable data={topTotals.data.topReferrers} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              flexBasis="100%"
              flex={1}
              pl={{ base: "0", lg: "10px" }}
              borderLeft={{ base: "0", lg: "1px solid #eee" }}
              mt={{ base: "2rem", md: "0" }}
              minHeight="400px"
            >
              <Box display="flex" alignItems="center">
                <TopDevicesIcon />
                <Text fontSize="lg" fontWeight="semibold">
                  Top devices
                </Text>
              </Box>

              <Tabs variant="soft-rounded">
                <TabList>
                  <Tab>Chart</Tab>
                  <Tab>Table</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p="0">
                    <TopCountsPieChart data={topTotals.data.topDevices} />
                  </TabPanel>

                  <TabPanel p="0">
                    <TopCountsTable data={topTotals.data.topDevices} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        )}

        {loadingUserData ? (
          <>
            <Skeleton
              height="80px"
              width="100%"
              startColor="gray.50"
              endColor="gray.300"
              mt="1rem"
            />

            <Skeleton
              height="80px"
              width="100%"
              startColor="gray.50"
              endColor="gray.300"
              mt="1rem"
            />

            <Skeleton
              height="80px"
              width="100%"
              startColor="gray.50"
              endColor="gray.300"
              mt="1rem"
            />
          </>
        ) : (
          <>
            {userData?.linksInfo?.length > 0 && (
              <Box>
                <Divider my="2rem" />

                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  textAlign="center"
                  my="2rem"
                >
                  {" "}
                  Individual component statistics
                </Text>

                <>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    textAlign="left"
                    mt="2rem"
                    mb="0.5rem"
                  >
                    Links
                  </Text>
                  <Stack spacing="1rem">
                    {userData?.linksInfo.map((link) => (
                      <LinkStats
                        linkId={link.linkId}
                        linkUrl={link.linkUrl}
                        name={link.name}
                        hidden={link.hidden}
                        type="link"
                      />
                    ))}
                  </Stack>
                </>
              </Box>
            )}

            {userData?.supportButtonsInfo?.length > 0 && (
              <>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  textAlign="left"
                  mt="3rem"
                  mb="0.5rem"
                >
                  Support buttons
                </Text>
                <Stack spacing="1rem">
                  {userData?.supportButtonsInfo.map((button) => (
                    <LinkStats
                      type="support"
                      linkId={button.linkId}
                      name={button.name}
                    />
                  ))}
                </Stack>
              </>
            )}

            {userData?.socialHandlesInfo?.length > 0 && (
              <>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  textAlign="left"
                  mt="3rem"
                  mb="0.5rem"
                >
                  Social handle icons
                </Text>
                <Stack spacing="1rem">
                  {userData?.socialHandlesInfo.map((link) => (
                    <LinkStats
                      linkId={link.channel}
                      linkUrl={link.handle}
                      name={link.channel}
                      type="social"
                    />
                  ))}
                </Stack>
              </>
            )}
          </>
        )}

        <br />
        <br />
      </Page>
    </>
  );
};

export default AnalyticsScreen;
