import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { UNSPLASH_CLIENT_ID } from "utils/constants";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import axios from "axios";
import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";

const Img = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.1s ease-out;
  backface-visibility: hidden;
  height: 100%;
  object-fit: cover;
`;

const Container = styled(Box)`
  position: relative;
  transition: 0.3s ease-out;

  background: linear-gradient(
    180deg,
    rgba(212, 212, 212, 0.74) 50%,
    rgba(192, 192, 192, 0.795) 60%,
    rgba(117, 117, 117, 0.774) 70%,
    rgb(82, 82, 82) 80%,
    rgb(77, 77, 77) 85%,
    rgb(44, 44, 44) 90%,
    rgb(0, 0, 0) 95%,
    rgb(0, 0, 0) 100%
  );

  :hover {
    > div {
      opacity: 1;
    }

    img {
      opacity: 0.7;
      transition: all 0.1s ease-in;
      cursor: pointer;
    }
  }
`;

const Middle = styled(Box)`
  width: 90%;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

const UnsplashImages = ({ handleImageSelect }: any) => {
  const [take, setTake] = useState(10);
  const [imageQuery, setImageQuery] = useState("");

  const [defaultImages, setDefaultImages] = useState(undefined);
  const [result, setResult] = useState([]);

  var myArray = [
    "clouds",
    "space",
    "desert",
    "green",
    "gradient",
    "abstract",
    "pattern",
  ];

  var randomItem = myArray[Math.floor(Math.random() * myArray.length)];

  useEffect(() => {
    const url =
      "https://api.unsplash.com/search/photos?page=1&query=" +
      randomItem +
      "&client_id=" +
      UNSPLASH_CLIENT_ID;

    if (!defaultImages) {
      axios.get(url).then((response) => {
        setDefaultImages(response.data.results);
      });
    }
  }, [defaultImages, randomItem]);

  const handleChange = (event) => {
    setImageQuery(event.target.value);
    setTake(20);
    const url =
      "https://api.unsplash.com/search/photos?page=1&query=" +
      event.target.value +
      "&client_id=" +
      UNSPLASH_CLIENT_ID;

    axios.get(url).then((response) => {
      setResult(response.data.results);
    });
  };

  const onImageSelect = (image) => {
    handleImageSelect(image);
    sendUnsplashTrackCall(image.links.download_location);
  };

  const handleSubmit = () => {
    setTake(take + 10);
    const url =
      "https://api.unsplash.com/search/photos?page=1&per_page=" +
      take +
      "&query=" +
      imageQuery +
      "&client_id=" +
      UNSPLASH_CLIENT_ID;

    axios.get(url).then((response) => {
      setResult(response.data.results);
    });
  };

  const sendUnsplashTrackCall = async (url: string) => {
    try {
      await axios.get(url + "&client_id=" + UNSPLASH_CLIENT_ID);
    } catch (err) {
      console.error("Error calling sendUnsplashTrackCall ", err);
    }
  };

  if (!defaultImages) {
    return (
      <Flex justifyContent="center" my="0.5rem">
        <Spinner />
      </Flex>
    );
  }
  const images = imageQuery ? result : defaultImages;

  return (
    <>
      <Text fontSize="0.875rem" mb="0.75rem">
        Images from
        <Link
          ml="0.25rem"
          m=""
          textDecoration="underline"
          href="https://unsplash.com/"
          isExternal
        >
          Unsplash:
        </Link>
      </Text>

      <Input
        mb="0.5rem"
        onChange={handleChange}
        type="text"
        name="image"
        placeholder="Try 'cat or 'texture'..."
      />

      <Grid
        templateColumns={images.length !== 0 && "repeat(5, 1fr)"}
        gridAutoRows="min-content"
        gap={4}
      >
        {images.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            Looks like we don't have the photo you're looking for :(
            <br />
            <br />
            Try 'ambient' or 'space' maybe?
          </Box>
        ) : (
          images?.map((image: any) => (
            <Container key={image.id}>
              <Img
                src={image.urls.thumb}
                alt="Avatar"
                style={{ width: "100%" }}
                onClick={() => onImageSelect(image)}
              />

              <Middle>
                <Link
                  fontSize="9px"
                  color="#fff"
                  opacity="0.75"
                  style={{ textShadow: "0px 0px 2px  #7e7e7e" }}
                  href={`https://unsplash.com/@${image.user.username}?utm_source=alfalink.co&utm_medium=referral`}
                  isExternal
                >
                  {image?.user?.name?.substring(0, 8)}
                </Link>
              </Middle>
            </Container>
          ))
        )}
      </Grid>
      {imageQuery && (
        <Button
          width="100%"
          my="12px"
          variant="outline"
          color="GrayText"
          onClick={handleSubmit}
          type="submit"
          disabled={take === 40}
        >
          <UilPlus />
          Load more
        </Button>
      )}
    </>
  );
};

export default UnsplashImages;
