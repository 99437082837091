import {
  // Radio,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Divider,
  Tabs,
} from "@chakra-ui/react";

import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import { useNode } from "@craftjs/core";

export const TextSettings = () => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    actions: { setProp },
    // text1,
    // text2,
    // text3,
    // buttonText,
    title,
    description,
  } = useNode((node) => {
    return {
      text1: node.data.props['text1'],
      text2: node.data.props['text2'],
      text3: node.data.props['text3'],
      buttonText: node.data.props['buttonText'],
      title: node.data.props['title'],
      description: node.data.props['description'],
    };
  });

  return (
    <Tabs>
      <TabList mb="1em">
        <Tab>Brand Settings</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <div
            style={{
              marginBottom: '16px',
            }}>
            { title }
          </div>

          <div
            style={{
              marginBottom: '24px',
            }}
            dangerouslySetInnerHTML={{ __html: description }}
            >
          </div>

          <Divider my="1em" />
{/*
          <SettingsItem
            full
            propKey="fontSize"
            type="slider"
            label="Font Size"
          />*/}

          <SettingsItem
            full={true}
            propKey="text1"
            type="textOnBlur"
            label="Text Line 1"
          />

          <SettingsItem
            full={true}
            propKey="text2"
            type="textOnBlur"
            label="Text Line 2"
          />

          <SettingsItem
            full={true}
            propKey="text3"
            type="textOnBlur"
            label="Text Line 3"
          />

          <SettingsItem
            full={true}
            propKey="buttonText"
            type="textOnBlur"
            label="Button Text"
          />
{/*          <SettingsItem propKey="textAlign" type="buttonRadio" label="Align">
            <Radio value="left" label="Left" />
            <Radio value="center" label="Center" />
            <Radio value="right" label="Right" />
          </SettingsItem>
          <SettingsItem propKey="fontWeight" type="radio" label="Weight">
            <Radio value="500" label="Normal" />
            <Radio value="700" label="Bold" />
          </SettingsItem>

          <SettingsItem full={true} propKey="color" type="color" label="Text" />
          <SettingsItem full propKey="shadow" type="slider" label="Shadow" />*/}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
