import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";

import { Carousel } from "react-responsive-carousel";
import Fade from "react-reveal/Fade";
import { cloudfrontURL } from "utils/urlBuilder.js";

const IntroOneLink = () => {
  return (
    <Box
      mt={{ base: "1rem", md: "0" }}
      display="flex"
      flexDirection={{ base: "column-reverse", md: "row" }}
      pt={{ base: "2rem", md: "2rem" }}
      textAlign={{ base: "center", lg: "left" }}
    >
      <Stack
        spacing={{ base: "2rem", md: "4rem" }}
        pt={{ base: "1rem", md: "2rem", lg: "3rem" }}
        pb={{ base: "1rem", md: "1rem" }}
        width={{ sm: "100%", md: "50%" }}
        maxWidth={{ base: "auto", md: "550px" }}
        pl={{ md: "0em", lg: "1em", xl: "2em" }}
      >
        <Fade bottom>
          <Box>
            <Heading
              as="h2"
              fontSize={{ base: "1.5rem", md: "2.75rem" }}
              mb="0.5rem"
              letterSpacing="-1.25px"
            >
              All your links in one place
            </Heading>

            <Text color="#203746" fontSize="sm" mb="0.5rem">
              Manage and organize all your online links and content in one
              personalized profile and share it with one unique link or QR-code
            </Text>
          </Box>
        </Fade>

        <Fade bottom>
          <Box>
            <Heading
              as="h2"
              fontSize={{ base: "1.5rem", md: "2.75rem" }}
              mb="0.5rem"
              letterSpacing="-1px"
            >
              Increase traffic, reach larger audience
            </Heading>

            <Text color="#203746" fontSize="sm" mb="0.5rem">
              Share your social media channels, video, music, online store,
              portfolio, book, podcast and everything in between via one link
            </Text>
          </Box>
        </Fade>
      </Stack>

      <Box
        m={{ base: "0", sm: "0 auto", md: "0 auto" }}
        pl={{ base: "0", xl: "6rem" }}
        maxWidth={{ base: "350px", lg: "450px" }}
      >
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          autoPlay={true}
          stopOnHover={false}
          infiniteLoop
          animationHandler="fade"
          transitionTime={500}
          interval={5000}
          swipeable={false}
        >
          <Box width={240} m="0 auto">
            <Image
              src={ cloudfrontURL("/introMob.png") }
              alt="Demo page 1"
            />
          </Box>

          <Box width={240} m="0 auto">
            <Image
              src={ cloudfrontURL("/introMob2.png") }
              alt="Demo page 2"
            />
          </Box>

          <Box width={240} m="0 auto">
            <Image
              src={ cloudfrontURL("/introMob3.png") }
              alt="Demo page 3"
            />
          </Box>
        </Carousel>
      </Box>
    </Box>
  );
};

export default IntroOneLink;
