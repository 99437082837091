import { Box, HStack } from "@chakra-ui/layout";

import { SlideFade } from "@chakra-ui/react";
import styled from "styled-components";

const HideBoxScrollbar = styled(Box)`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const IconsWrapper = ({ children, hidden }: any) => {
  return (
    <SlideFade in={!hidden} offsetY="20px" reverse>
      <Box
        position="relative"
        flex="1"
        display="flex"
        zIndex="1399"
        pointerEvents="auto"
      >
        <HStack
          className="mobile_settings_wrapper"
          position="fixed"
          bottom="0"
          width="100%"
          height="90px"
          pointerEvents="auto"
          display="flex"
          paddingLeft="4.75rem"
          alignItems="center"
          justifyContent="space-between"
          overflowX="auto"
          //spacing="0.5rem"
          color="#111"
          backgroundColor="#eee"
          borderTopLeftRadius="6px"
          borderTopRightRadius="6px"
          boxShadow="0 0 0 1px rgb(64 87 109 / 10%), 0 2px 16px rgb(53 71 90 / 25%)"
        >
          <HideBoxScrollbar
            display="grid"
            gridAutoFlow="column"
            flexGrow={1}
            gridGap="2rem"
            justifyContent="flex-start"
            overflowX="auto"
            pointerEvents="auto"
            style={{
              gap: "2rem",
              scrollbarWidth: "none",
              marginBottom: "18px",
            }}
          >
            {children}
          </HideBoxScrollbar>
        </HStack>
      </Box>
    </SlideFade>
  );
};

export const SettingIconButton = styled.button`
  color: #111;

  :first-child {
    padding-left: 1rem;
  }

  :last-child {
    padding-right: 2rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;

  p {
    padding-top: 6px;
    width: max-content;
    max-width: 70px;
  }

  svg {
    font-size: 26px;
    width: 26px;
    height: 26px;
  }
`;
