import {
  BioContainer,
  BuyMeACoffee,
  ContainerHeader,
  Divider,
  KoFi,
  Label,
  Link,
  Music,
  SocialLinks,
  Support,
  Twitter,
  Video,
  Image,
  BrandAd,
  MintMobile,
} from "components/Selectors";
import {
  Box,
  Button as CButton,
  Fade,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  ChildButton,
  FloatingMenu,
  MainButton,
} from "react-floating-button-menu";

import { EmbedIcon } from "assets/svgs/embedIcon";
import HeaderIcon from "assets/svgs/headerIcon";
import { SupportComponentIcon } from "assets/svgs/supportIcon";
import UiLLabel from "@iconscout/react-unicons/icons/uil-text-fields";
import UiLSocials from "@iconscout/react-unicons/icons/uil-channel-add";
import UiLUserSquare from "@iconscout/react-unicons/icons/uil-user-square";
import UiLinkAdd from "@iconscout/react-unicons/icons/uil-link-add";
import UsdCircle from "@iconscout/react-unicons/icons/uil-usd-circle";
import UilDivider from "@iconscout/react-unicons/icons/uil-grip-horizontal-line";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import UilX from "@iconscout/react-unicons/icons/uil-multiply";
import styled from "styled-components";
import { useEditor } from "@craftjs/core";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useTracked } from "hooks/useTracked";
import { useUserContext } from "HoCs/UserProvider";

import { useAvailableAds } from "hooks/useAvailableAds";

import { cloudfrontURL } from "utils/urlBuilder.js";

const Item = styled.a<{ disabled?: boolean; move?: boolean }>`
  svg {
    width: 22px;
    height: 22px;
    fill: #444444;
  }

  ${(props) =>
    props.move &&
    `
    cursor: move;
  `}
  ${(props) =>
    props.disabled &&
    `
    opacity:1;
    cursor: not-allowed;
  `}
`;

const StyledFloatingMenu = styled(FloatingMenu)<{
  open?: boolean;
}>`
  align-items: flex-start;
  position: absolute;
  opacity: 0;
  bottom: 8rem;
  visibility: hidden;
  transition: all 250ms ease-in;

  ${({ open }) =>
    open &&
    `
    bottom: 4.25rem;
    opacity: 1;
    visibility: visible;


    @media (max-width: 600px) {
      bottom: 4rem;


  }

    `};
`;

const StyledMainButton = styled(MainButton)`
  background-color: #ffffff;
  box-shadow: 0 0 5px rgb(70 0 164 / 37%), 0 4px 6px rgb(92 0 154 / 44%);

  :hover {
    transform: scale(1.05, 1.05);
  }
  transition: all 0.2s ease;

  & i,
  svg {
    fill: #444444;
  }
`;

const StyledChildButton = styled(ChildButton)`
  width: unset;
  height: auto;
  background: transparent;
  box-shadow: none;
  border-radius: unset;

  & i,
  svg {
    fill: #444444;
  }
`;

export const AddComponents = ({ activeComponents }: any) => {
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userData } = useUserContext();
  const isEditorView = location.pathname.includes("/editor");
  const isAdEditorView = location.pathname.includes("/editorad");
  const { availableAds } = useAvailableAds();

  const {
    active,
    enabled,
    actions,
    query,
    connectors: { create },
  } = useEditor((state) => ({
    active: state.events.selected,
    enabled: state.options.enabled,
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useTracked();

  const [isOpen, setIsOpen] = useState(false);
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
  const [isBrandAdModalOpen, setIsBrandAdModalOpen] = useState(false);

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return enabled && active && isSmallerThan600 && isEditorView ? (
    <Box
      display="flex"
      style={{
        position: isLargerThan768 ? "absolute" : "fixed",
        float: enabled && !active ? "left" : "left",
        bottom: isLargerThan768 ? "1rem" : "1.75rem",
        left: isLargerThan768 ? "1.5rem" : "0.75rem",

        justifyContent: "center",

        alignItems: "center",
        zIndex: 1403, // larger than IconsWrapper
      }}
    >
      <Box
        style={{
          padding: isLargerThan768 ? "0" : "0",
          margin: isLargerThan768 ? "1rem 0" : "0rem 0",
          backgroundColor: "#fff",
          fontSize: "0.55rem",
          borderRadius: "50px",
          width: "54px",
          height: "54px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          boxShadow: "0 2px 6px rgb(0 0 0 / 42%)",
          zIndex: 1400, // larger than IconsWrapper
        }}
      >
        <Fade in={true}>
          <button
            onClick={() => null}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <UilEye size="22px" />
            Preview
          </button>
        </Fade>
      </Box>
      <Box height="2.25rem" borderRight="1px solid #c8ced3" mx="0.5rem"></Box>
    </Box>
  ) : (
    <Tooltip
      hidden={!isEditorView}
      label={isSmallerThan600 ? "" : "Add component"}
      aria-label="Add component"
    >
      <Fade
        hidden={!enabled}
        in={enabled}
        className="add_component_button"
        style={{
          position: isLargerThan768 ? "absolute" : "fixed",
          float: enabled && !active ? "left" : "left",
          bottom: isLargerThan768 ? "1rem" : "0.5rem",
          left: isLargerThan768 ? "1.5rem" : "0.825rem",
          padding: isLargerThan768 ? "0" : "0",
          margin: isLargerThan768 ? "1rem 0" : "1rem 0",
          zIndex: 1001,
        }}
      >
        <Modal
          isOpen={isBrandAdModalOpen}
          onClose={() => setIsBrandAdModalOpen(false)}
          isCentered
          size="3xl"
        >
          <ModalOverlay />
          <ModalContent height={{ base: "inherit", md: "auto" }}>
            <ModalHeader>Brand Ads</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflow="auto">
              <Stack spacing="2rem">
                <Box>

                    {!isAdEditorView ? (
                      <>
                      {availableAds.map(availableAd => (
                          <CButton
                            variant="unstyled"
                            minHeight="unset"
                            maxHeight="unset"
                            height="auto"
                            whiteSpace="normal"
                            fontSize="xs"
                            color="gray.600"
                            ref={(ref) => create(ref, <BrandAd brandAdId={availableAd.id} title={availableAd.title} description={availableAd.description} />)}
                            onClick={() => {
                              setIsBrandAdModalOpen(false);

                              const nodeTree = query
                                .parseReactElement(<BrandAd brandAdId={availableAd.id} title={availableAd.title} description={availableAd.description} />)
                                .toNodeTree();

                              const childNodes = query
                                .node(state.linksContainerId)
                                .childNodes();

                              actions.addNodeTree(
                                nodeTree,
                                state.linksContainerId,
                                childNodes.length
                              );
                            }}
                          >
                            <Stack
                              spacing="0.5rem"
                              bgColor="#fff"
                              borderRadius="1rem"
                              p={{ base: "1rem 1.75rem", md: "1rem" }}
                              border="1px solid #eee"
                              textAlign="left"
                            >
                              <Box alignItems="center">
                                <Text fontSize="xl" fontWeight="semibold">
                                  <i className="uil uil-usd-circle" /> {availableAd.title}
                                </Text>
                              </Box>

                              <Stack fontSize="xs" color="gray.600">
                                <Text fontSize="xs" color="gray.600" as="span">
                                  {availableAd.title}
                                </Text>
                              </Stack>
                            </Stack>
                          </CButton>
                        ))}
                        </>
                      ) : (
                        <></>
                      )}

                      {isAdEditorView ? (

                        <CButton
                          variant="unstyled"
                          minHeight="unset"
                          maxHeight="unset"
                          height="auto"
                          whiteSpace="normal"
                          fontSize="xs"
                          color="gray.600"
                          ref={(ref) => create(ref, <MintMobile />)}
                          onClick={() => {
                            setIsBrandAdModalOpen(false);

                            const nodeTree = query
                              .parseReactElement(<MintMobile />)
                              .toNodeTree();

                            const childNodes = query
                              .node(state.linksContainerId)
                              .childNodes();

                            actions.addNodeTree(
                              nodeTree,
                              state.linksContainerId,
                              childNodes.length
                            );
                          }}
                        >
                          <Stack
                            spacing="0.5rem"
                            bgColor="#fff"
                            borderRadius="1rem"
                            p={{ base: "1rem 1.75rem", md: "1rem" }}
                            border="1px solid #eee"
                            textAlign="left"
                          >
                            <Box alignItems="center">
                              <Text fontSize="xl" fontWeight="semibold">
                                <i className="uil uil-usd-circle" /> Mint Mobile Block
                              </Text>
                            </Box>

                            <Stack fontSize="xs" color="gray.600">
                              <Text fontSize="xs" color="gray.600" as="span">
                                 Mint Mobile Block
                              </Text>
                            </Stack>
                          </Stack>
                        </CButton>

                      ) : (
                        <></>
                      )}

                </Box>

                <Box>
                  <Text
                    py="0.5rem"
                    textAlign="center"
                    fontWeight="semibold"
                    color="gray.800"
                  >
                    &nbsp;
                  </Text>
                </Box>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>



        <Modal
          isOpen={isEmbedModalOpen}
          onClose={() => setIsEmbedModalOpen(false)}
          isCentered
          size="3xl"
        >
          <ModalOverlay />
          <ModalContent height={{ base: "inherit", md: "auto" }}>
            <ModalHeader>Embeds & Integrations</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflow="auto">
              <Stack spacing="2rem">
                <Box>
                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="1rem">
                    <CButton
                      variant="unstyled"
                      minHeight="unset"
                      maxHeight="unset"
                      height="auto"
                      whiteSpace="normal"
                      fontSize="xs"
                      color="gray.600"
                      ref={(ref) => create(ref, <Video />)}
                      onClick={() => {
                        setIsEmbedModalOpen(false);

                        const nodeTree = query
                          .parseReactElement(<Video />)
                          .toNodeTree();

                        const childNodes = query
                          .node(state.linksContainerId)
                          .childNodes();

                        actions.addNodeTree(
                          nodeTree,
                          state.linksContainerId,
                          childNodes.length
                        );
                      }}
                    >
                      <Stack
                        spacing="0.5rem"
                        bgColor="#fff"
                        borderRadius="1rem"
                        p={{ base: "1rem 1.75rem", md: "1rem" }}
                        border="1px solid #eee"
                        textAlign="left"
                      >
                        <Box alignItems="center">
                          <Text fontSize="xl" fontWeight="semibold">
                            <i className="uil uil-video" /> Video
                          </Text>
                        </Box>

                        <Stack fontSize="xs" color="gray.600">
                          <Box>
                            <Img
                              height="34px"
                              width="auto"
                              src={ cloudfrontURL("/videoEmbeds.png") }
                            />
                          </Box>
                          <Text fontSize="xs" color="gray.600" as="span">
                            Embed videos from YouTube, TikTok, Twitch, Facebook,
                            Vimeo, DailyMotion, Wistia, Kultura or Vidyard.
                          </Text>
                        </Stack>
                      </Stack>
                    </CButton>






                    <CButton
                      variant="unstyled"
                      minHeight="unset"
                      maxHeight="unset"
                      height="auto"
                      whiteSpace="normal"
                      ref={(ref) => create(ref, <Music />)}
                      onClick={() => {
                        setIsEmbedModalOpen(false);

                        const nodeTree = query
                          .parseReactElement(<Music />)
                          .toNodeTree();

                        const childNodes = query
                          .node(state.linksContainerId)
                          .childNodes();

                        actions.addNodeTree(
                          nodeTree,
                          state.linksContainerId,
                          childNodes.length
                        );
                      }}
                    >
                      <Stack
                        spacing="0.5rem"
                        bgColor="#fff"
                        borderRadius="1rem"
                        p={{ base: "1rem 1.75rem", md: "1rem" }}
                        border="1px solid #eee"
                        textAlign="left"
                      >
                        <Box alignItems="center">
                          <Text fontSize="xl" fontWeight="semibold">
                            <i className="uil uil-music" /> Music
                          </Text>
                        </Box>

                        <Stack>
                          <Box>
                            <Img
                              height="34px"
                              width="auto"
                              src={ cloudfrontURL("/musicEmbedshq.png") }
                            />
                          </Box>
                          <Text fontSize="xs" color="gray.600">
                            Embed music from Spotify, Apple Music, SoundCloud,
                            Bandcamp or Mixcloud.
                          </Text>
                        </Stack>
                      </Stack>
                    </CButton>


                    <CButton
                      variant="unstyled"
                      minHeight="unset"
                      maxHeight="unset"
                      height="auto"
                      whiteSpace="normal"
                      fontSize="xs"
                      color="gray.600"
                      ref={(ref) => create(ref, <Image />)}
                      onClick={() => {
                        setIsEmbedModalOpen(false);

                        const nodeTree = query
                          .parseReactElement(<Image />)
                          .toNodeTree();

                        const childNodes = query
                          .node(state.linksContainerId)
                          .childNodes();

                        actions.addNodeTree(
                          nodeTree,
                          state.linksContainerId,
                          childNodes.length
                        );
                      }}
                    >
                      <Stack
                        spacing="0.5rem"
                        bgColor="#fff"
                        borderRadius="1rem"
                        p={{ base: "1rem 1.75rem", md: "1rem" }}
                        border="1px solid #eee"
                        textAlign="left"
                      >
                        <Box alignItems="center">
                          <Text fontSize="xl" fontWeight="semibold">
                            <i className="uil uil-image" /> Image
                          </Text>
                        </Box>

                        <Stack fontSize="xs" color="gray.600">
                          <Text fontSize="xs" color="gray.600" as="span">
                            Upload and embed images
                          </Text>
                        </Stack>
                      </Stack>
                    </CButton>


                    <CButton
                      variant="unstyled"
                      minHeight="unset"
                      maxHeight="unset"
                      height="auto"
                      whiteSpace="normal"
                      ref={(ref) => create(ref, <Twitter />)}
                      onClick={() => {
                        setIsEmbedModalOpen(false);

                        const nodeTree = query
                          .parseReactElement(<Twitter />)
                          .toNodeTree();

                        const childNodes = query
                          .node(state.linksContainerId)
                          .childNodes();

                        actions.addNodeTree(
                          nodeTree,
                          state.linksContainerId,
                          childNodes.length
                        );
                      }}
                    >
                      <Stack
                        spacing="0.75rem"
                        bgColor="#fff"
                        borderRadius="1rem"
                        p={{ base: "1rem 1.75rem", md: "1rem" }}
                        border="1px solid #eee"
                        textAlign="left"
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mr="0.5rem"
                          >
                            <Img
                              width="36px"
                              height="36px"
                              src={ cloudfrontURL("/twitter_iconn.png") }
                            />
                          </Box>

                          <Text fontWeight="semibold">Twitter</Text>
                        </Box>

                        <Text fontSize="xs" color="gray.600">
                          Embed a Tweet or Twitter profile.
                        </Text>
                      </Stack>
                    </CButton>
                  </SimpleGrid>
                </Box>

                <Box>
                  <Text
                    py="0.5rem"
                    textAlign="center"
                    fontWeight="semibold"
                    color="gray.800"
                  >
                    Integrations
                  </Text>

                  <SimpleGrid
                    columns={{ sm: 1, md: 2 }}
                    spacing="1rem"
                    pb="2rem"
                  >
                    <CButton
                      variant="unstyled"
                      height="auto"
                      whiteSpace="normal"
                      ref={(ref) => create(ref, <BuyMeACoffee />)}
                      onClick={() => {
                        setIsEmbedModalOpen(false);

                        const nodeTree = query
                          .parseReactElement(<BuyMeACoffee />)
                          .toNodeTree();

                        const childNodes = query
                          .node(state.linksContainerId)
                          .childNodes();

                        actions.addNodeTree(
                          nodeTree,
                          state.linksContainerId,
                          childNodes.length
                        );
                      }}
                    >
                      <Stack
                        spacing="1rem"
                        //minWidth="300px"
                        bgColor="#fff"
                        borderRadius="1rem"
                        p={{ base: "1rem 1.75rem", md: "1rem" }}
                        border="1px solid #eee"
                        textAlign="left"
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mr="0.5rem"
                          >
                            <Img
                              width="34px"
                              height="34px"
                              src={ cloudfrontURL("/bmc-logo.png") }
                            />
                          </Box>

                          <Text fontWeight="semibold">Buy Me A Coffee</Text>
                        </Box>

                        <Box>
                          <Text fontSize="12px">
                            Embed your BMC donation panel and collect BMC
                            donations right in your Alfalink page.
                          </Text>
                        </Box>
                      </Stack>
                    </CButton>

                    <CButton
                      variant="unstyled"
                      height="auto"
                      whiteSpace="normal"
                      ref={(ref) => create(ref, <KoFi />)}
                      onClick={() => {
                        setIsEmbedModalOpen(false);

                        const nodeTree = query
                          .parseReactElement(<KoFi />)
                          .toNodeTree();

                        const childNodes = query
                          .node(state.linksContainerId)
                          .childNodes();

                        actions.addNodeTree(
                          nodeTree,
                          state.linksContainerId,
                          childNodes.length
                        );
                      }}
                    >
                      <Stack
                        spacing="0.75rem"
                        //minWidth="300px"
                        bgColor="#fff"
                        borderRadius="1rem"
                        p={{ base: "1rem 1.75rem", md: "1rem" }}
                        border="1px solid #eee"
                        textAlign="left"
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mr="0.5rem"
                          >
                            <Img
                              width="34px"
                              height="34px"
                              src={ cloudfrontURL("/kofilogo_cir.png") }
                            />
                          </Box>

                          <Text fontWeight="semibold">Ko-fi</Text>
                        </Box>

                        <Box>
                          <Text fontSize="12px">
                            Embed your Ko-fi panel and collect donations right
                            in your Alfalink page.
                          </Text>
                        </Box>
                      </Stack>
                    </CButton>
                  </SimpleGrid>
                </Box>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>





        <StyledMainButton
          iconResting={<UilPlus size={isLargerThan768 ? "36px" : "32px"} />}
          iconActive={<UilX />}
          background="#ffffff"
          onClick={() => setIsOpen(!isOpen)}
          size={isLargerThan768 ? 70 : 64}
        />

        <StyledFloatingMenu
          open={isOpen}
          slideSpeed={250}
          direction={"up" as any}
          spacing={isLargerThan768 ? 12 : 8}
          isOpen={isOpen}
        >
          {!activeComponents?.includes("ContainerHeader") ? (
            <StyledChildButton
              icon={
                <Box ref={(ref) => create(ref, <ContainerHeader />)}>
                  <CButton
                    size="lg"
                    leftIcon={<HeaderIcon />}
                    cursor={
                      !activeComponents?.includes("ContainerHeader")
                        ? "pointer"
                        : "not-allowed"
                    }
                    disabled={activeComponents?.includes("ContainerHeader")}
                    onClick={() => {
                      setIsOpen(false);
                      if (activeComponents?.includes("ContainerHeader")) {
                        return null;
                      }

                      const nodeTree = query
                        .parseReactElement(<ContainerHeader />)
                        .toNodeTree();

                      actions.addNodeTree(nodeTree, "ROOT", 0);
                    }}
                  >
                    Header
                  </CButton>
                </Box>
              }
              background={"white"}
              size={40}
            />
          ) : (
            <></>
          )}

          {!activeComponents?.includes("BioContainer") &&
          activeComponents?.includes("ContainerHeader") ? (
            <StyledChildButton
              icon={
                <Box ref={(ref) => create(ref, <BioContainer />)}>
                  <CButton
                    size="lg"
                    leftIcon={<UiLUserSquare style={{ fill: "#002c4b" }} />}
                    cursor={
                      !activeComponents?.includes("BioContainer")
                        ? "pointer"
                        : "not-allowed"
                    }
                    disabled={activeComponents?.includes("BioContainer")}
                    onClick={() => {
                      setIsOpen(false);
                      if (activeComponents?.includes("BioContainer")) {
                        return null;
                      }

                      const nodeTree = query
                        .parseReactElement(<BioContainer />)
                        .toNodeTree();

                      actions.addNodeTree(nodeTree, state.headerContainerId, 1);
                    }}
                  >
                    Bio
                  </CButton>
                </Box>
              }
              background={"white"}
              size={40}
            />
          ) : (
            <></>
          )}

          <StyledChildButton
            icon={
              <Box ref={(ref) => create(ref, <Label />)}>
                <Item move>
                  <CButton
                    size="lg"
                    leftIcon={<UiLLabel style={{ fill: "#002c4b" }} />}
                    onClick={() => {
                      setIsOpen(false);

                      const nodeTree = query
                        .parseReactElement(<Label />)
                        .toNodeTree();

                      const childNodes = query
                        .node(state.linksContainerId)
                        .childNodes();

                      actions.addNodeTree(
                        nodeTree,
                        state.linksContainerId,
                        childNodes.length - 1
                      );
                    }}
                  >
                    Label
                  </CButton>
                </Item>
              </Box>
            }
            background={"white"}
            size={40}
          />

          {(availableAds.length || isAdEditorView) ? (
            <StyledChildButton
              icon={
                <Box>
                  <Item move>
                    <CButton
                      size="lg"
                      leftIcon={<UsdCircle />}
                      onClick={() => {
                        setIsOpen(false);
                        setIsBrandAdModalOpen(true);
                      }}
                    >
                      Embed Brand Ad
                    </CButton>
                  </Item>
                </Box>
              }
              background={"white"}
              size={40}
            />
          ) : (
            <></>
          )}

          <StyledChildButton
            icon={
              <Box>
                <Item move>
                  <CButton
                    size="lg"
                    leftIcon={<EmbedIcon />}
                    onClick={() => {
                      setIsOpen(false);
                      setIsEmbedModalOpen(true);
                    }}
                  >
                    Embed
                  </CButton>
                </Item>
              </Box>
            }
            background={"white"}
            size={40}
          />

          {!activeComponents?.includes("SocialLinks") ? (
            <StyledChildButton
              icon={
                <Box ref={(ref) => create(ref, <SocialLinks />)}>
                  <Item move>
                    <CButton
                      size="lg"
                      leftIcon={<UiLSocials style={{ fill: "#002c4b" }} />}
                      onClick={() => {
                        setIsOpen(false);

                        const nodeTree = query
                          .parseReactElement(<SocialLinks />)
                          .toNodeTree();

                        actions.addNodeTree(
                          nodeTree,
                          "ROOT",
                          activeComponents.length - 1
                        );
                      }}
                    >
                      Socials
                    </CButton>
                  </Item>
                </Box>
              }
              background={"white"}
              size={40}
            />
          ) : (
            <></>
          )}

          <StyledChildButton
            icon={
              <Box ref={(ref) => create(ref, <Support />)}>
                <Item move>
                  <CButton
                    size="lg"
                    leftIcon={<SupportComponentIcon />}
                    onClick={() => {
                      setIsOpen(false);

                      const nodeTree = query
                        .parseReactElement(<Support />)
                        .toNodeTree();

                      const childNodes = query
                        .node(state.linksContainerId)
                        .childNodes();

                      actions.addNodeTree(
                        nodeTree,
                        state.linksContainerId,
                        childNodes.length - 1
                      );
                    }}
                  >
                    Support
                  </CButton>
                </Item>
              </Box>
            }
            background={"white"}
            size={40}
          />

          <StyledChildButton
            icon={
              <Box ref={(ref) => create(ref, <Divider />)}>
                <Item move>
                  <CButton
                    size="lg"
                    leftIcon={<UilDivider style={{ fill: "#002c4b" }} />}
                    onClick={() => {
                      const nodeTree = query
                        .parseReactElement(<Divider />)
                        .toNodeTree();

                      const childNodes = query
                        .node(state.linksContainerId)
                        .childNodes();

                      actions.addNodeTree(
                        nodeTree,
                        state.linksContainerId,
                        childNodes.length
                      );

                      setIsOpen(false);
                    }}
                  >
                    Divider
                  </CButton>
                </Item>
              </Box>
            }
            background={"white"}
            size={40}
          />

          <StyledChildButton
            icon={
              <Box ref={(ref) => create(ref, <Link />)}>
                <Item move>
                  <CButton
                    size="lg"
                    leftIcon={<UiLinkAdd style={{ fill: "#002c4b" }} />}
                    onClick={() => {
                      const nodeTree = query
                        .parseReactElement(<Link />)
                        .toNodeTree();

                      const childNodes = query
                        .node(state.linksContainerId)
                        .childNodes();

                      actions.addNodeTree(
                        nodeTree,
                        state.linksContainerId,
                        childNodes.length
                      );
                    }}
                  >
                    Link
                  </CButton>
                </Item>
              </Box>
            }
            background={"white"}
            size={40}
          />





        </StyledFloatingMenu>
      </Fade>
    </Tooltip>
  );
};
