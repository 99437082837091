import { Redirect, Route } from "react-router-dom";

import { getAuthToken } from "utils";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authToken = getAuthToken();

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? <Component {...props} /> : <Redirect to="/in/login" />
      }
    />
  );
};

export default PrivateRoute;
