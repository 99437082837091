import { EVENT_LINK_CLICK, EVENT_TIME_SPENT_ON_PAGE } from "utils/events";
import { ErrorContent, TimeseriesTypes } from "./types";
import { PROFILE_VISITOR_ID, VISITOR_SESSION_ID } from "utils/constants";
import { getAuthToken, getDeviceType } from "utils";

import Cookies from "js-cookie";
import api from "api";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

type TokenType = {
  username: string;
  token: string;
};

export const handleError = (res: ErrorContent) => {
  const error = res;

  if (res.status === 401 || res.status === 403) {
    console.log("unauthorized ", res.status);
    window.location.href = "/in/login";
  }

  console.error(error);
  throw error;
};

const getHeaders = () => {
  const token = getAuthToken();

  try {
    const decoded = jwt_decode(token) as TokenType;

    return {
      username: decoded?.username,
      config: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    };
  } catch (error) {
    console.log("Invalid token");
  }
};

export const sendVerifyEmailRequest = async (): Promise<void> => {
  const headers = getHeaders();

  return await api
    .post(`/api/auth/send-verify-email/${headers.username}`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const verifyEmail = async (verifyEmailToken: string): Promise<any> => {
  return await api
    .put(
      `/api/auth/verify-email/${verifyEmailToken}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getFrameData = async (username: string): Promise<any> => {
  return await api
    .get(`/api/public/frame-data/${username}`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const updateTemplateFrameData = async ({
  frameData,
}: {
  frameData: string;
}): Promise<void> => {
  const headers = getHeaders();

  return await api
    .put(
      `/api/private/frame-data/${headers.username}`,
      {
        frameData,
      },
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getIsAccountVerified = async (username: string): Promise<any> => {
  return await api
    .get(`/api/public/account-verified/${username}`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getPublicFunStats = async (): Promise<any> => {
  return await api
    .get(`/api/public/fun-stats`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getTimeseriesData = async (): Promise<TimeseriesTypes> => {
  const headers = getHeaders();

  return await api
    .get(
      `/api/private/statistics/timeseries/${headers?.username}`,
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getTotalLifetimeCounts = async (): Promise<any> => {
  const headers = getHeaders();

  return await api
    .get(`/api/private/stats/totals/${headers?.username}`, headers.config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getDailyTimeseries = async (): Promise<any> => {
  const headers = getHeaders();

  return await api
    .get(`/api/private/stats/timeseries/${headers?.username}`, headers.config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getTopTotals = async (): Promise<any> => {
  const headers = getHeaders();

  return await api
    .get(`/api/private/stats/toptotals/${headers?.username}`, headers.config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

// INDIVIDUAL LINK

export const getDailyLinkTimeseries = async (linkId: string): Promise<any> => {
  const headers = getHeaders();

  return await api
    .get(
      `/api/private/stats/timeseries/link/${headers?.username}/${linkId}`,
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getLinkTopTotals = async (linkId: string): Promise<any> => {
  const headers = getHeaders();

  return await api
    .get(
      `/api/private/stats/toptotals/link/${headers?.username}/${linkId}`,
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getTikTokEmbed = async (url: string): Promise<any> => {
  return await api

    .post(
      `/api/public/get-tiktok-vid`,
      {
        url: encodeURI(url),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    .then((response) => {
      return response.data;
    })

    .catch(handleError);
};

export const registerEvent = async ({
  username,
  eventType,
  linkInfo,
  timeToClick,
  timeSpentOnPage,
}: {
  username: string;
  eventType: string;
  linkInfo?: any;
  timeToClick?: number;
  timeSpentOnPage?: number;
}): Promise<void> => {
  const cookieSessionId = Cookies.get(VISITOR_SESSION_ID);
  const cookieVisitorId = Cookies.get(PROFILE_VISITOR_ID);

  const uuidSessionId = uuidv4();
  const uuidVisitorId = uuidv4();

  if (!cookieSessionId) {
    Cookies.set(VISITOR_SESSION_ID, uuidSessionId); // session cookie
  }

  if (!cookieVisitorId) {
    Cookies.set(
      PROFILE_VISITOR_ID,
      uuidVisitorId,
      { expires: 365 } // yearly visitor cookie
    );
  }

  const eventPayload = {
    sessionId: cookieSessionId ?? uuidSessionId,
    visitorId: cookieVisitorId ?? uuidVisitorId,

    context: {
      device: getDeviceType(),
      language: navigator.language,
      referrer: document.referrer,
      url: window.location.href,
      userAgent: navigator.userAgent,
    },

    eventType: eventType,

    ...(eventType === EVENT_LINK_CLICK && {
      linkInfo: linkInfo ?? null,
      timeToClick: timeToClick,
    }),

    ...(eventType === EVENT_TIME_SPENT_ON_PAGE && {
      timeSpentOnPage: timeSpentOnPage,
    }),
  };

  const date = new Date();

  return await api
    .post(
      `/api/public/event/${username}`,
      {
        createdAt: date.toISOString(),
        eventPayload: eventPayload,
        eventType,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })

    .catch(handleError);
};

export const updateProfileUserInfo = async ({
  name,
  email,
}: {
  name: string;
  email: string;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .put(
      `/api/private/user-data/${headers.username}`,
      {
        name,
        email,
      },
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const updateUsername = async ({
  username,
}: {
  username: string;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .put(
      `/api/private/update-username/${headers.username}`,
      {
        username,
      },
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const sendPasswordResetRequest = async (): Promise<void> => {
  const headers = getHeaders();

  return await api
    .post(`/api/auth/forgotpassword/${headers.username}`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};


export const getAvailableAds = async (): Promise<any> => {
  const headers = getHeaders();

  return await api
    .post(
      `/api/private/ad/available`,
      {},
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const uploadProfileAvatar = async ({
  avatar,
}: {
  avatar: any;
}): Promise<{ avatar: string }> => {
  const headers = getHeaders();

  return await api
    .post(
      `/api/private/upload-avatar/${headers.username}`,
      avatar,
      headers.config
    )
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getStripeIntegrationData = async ({
  username,
}: {
  username: string;
}): Promise<any> => {
  return await api
    .get(`/api/public/stripe-integration/${username}`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const verifyAndFetchStripeAccountInfo = async ({
  code,
}: {
  code: string;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .post(
      `/api/private/verify-stripe/${headers.username}`,
      { code },
      headers.config
    )

    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const disconnectStripeAccount = async ({
  accountId,
}: {
  accountId: string;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .post(
      `/api/private/disconnect-stripe/${headers.username}`,
      { accountId },
      headers.config
    )

    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getStripeAccountInfo = async ({
  connectedAccountId,
}: {
  connectedAccountId: string;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .post(
      `/api/private/get-stripe-account-info/${headers.username}`,
      { connectedAccountId },
      headers.config
    )

    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const createStripeChargeIntent = async ({
  username,
  connectedAccountId,
  amount,
  donorEmail,
  donorName,
  donorMessage,
}: {
  username: string;
  connectedAccountId: string;
  amount: number;
  donorEmail: string;
  donorName: string;
  donorMessage: string;
}): Promise<any> => {
  return await api
    .post(
      "/api/public/create-stripe-charge-intent",
      {
        username,
        connectedAccountId,
        amount,
        donorEmail,
        donorName,
        donorMessage,
      },

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const getStripeBillingPortalSessionUrl = async ({
  stripeSubscriptionCustomerId,
}: {
  stripeSubscriptionCustomerId: string;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .post(
      `/api/private/stripe-billing-portal-url`,
      { stripeSubscriptionCustomerId },
      headers.config
    )

    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const updateEmailNewsletterSettings = async ({
  weeklyProfilePerformance,
}: {
  weeklyProfilePerformance: boolean;
}): Promise<any> => {
  const headers = getHeaders();

  return await api
    .put(
      `/api/private/update-email-newsletter-settings/${headers.username}`,
      { weeklyProfilePerformance },
      headers.config
    )

    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const deleteProfileAvatar = async ({
  imageUrl,
}: {
  imageUrl: any;
}): Promise<{ imageUrl: string }> => {
  const headers = getHeaders();

  return await api
    .delete(`/api/private/delete-avatar/${headers.username}`, {
      headers: headers.config.headers,

      data: { imageUrl },
    })
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

export const deleteAccount = async (): Promise<void> => {
  const headers = getHeaders();

  return await api
    .delete(`/api/private/delete-account/${headers.username}`, headers.config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};
