import {
  FormControl,
  FormLabel,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
} from "@chakra-ui/react";

export const UsernameInput = ({
  onChange,
  username,
  register,
  isInvalid,
  error,
}: {
  onChange?: any;
  username?: string;
  register?: any;
  isInvalid?: boolean;
  error?: string;
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>Username</FormLabel>

      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={
            <Text
              pl="4.75rem"
              pt="8px"
              fontSize="18px"
              alignItems="center"
              verticalAlign="center"
              size="60px"
              color="gray.500"
            >
              alfalink.co/
            </Text>
          }
        />

        <Input
          {...register("username")}
          pl="6.75rem"
          type="username"
          placeholder="MichaelScott"
          size="lg"
          name="username"
          defaultValue={username ?? ""}
          onChange={onChange}
        />
      </InputGroup>

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
