import { Box, Text } from "@chakra-ui/react";
import styled, { keyframes } from "styled-components";

const flow = keyframes`
0% {
background-position: 0 50%;
}

50% {
    background-position: 100% 50%;
}

100% {
    background-position: 0 50%
}

`;

const GradientText = styled(Text)`
  font-size: ${({ fontSize }) => fontSize ?? "1.5rem"};
  background-color: #fffbaa;
  background-image: linear-gradient(-45deg, #db2de1, #8e3ab7, #d40ace, #e936e1);
  background-size: 300%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  animation: ${flow} 8s ease-in-out infinite;
  text-shadow: ${({ shadow }) =>
    shadow ? "0px 0px 4px rgb(0 0 0 / 14%)" : "none"};

  animation-iteration-count: 2;
  font-family: "Lexend" !important;
  font-weight: 700;
  cursor: pointer !important;
`;

const Badge = styled(Box)`
  position: absolute;
  left: 84px;
  bottom: 26px;
  font-size: 8px;
  background: red;
  background-image: linear-gradient(-45deg, #c982df, #d272f5, #ec69b6);
  padding: 0 3px;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
`;

const AnimatedGradientLogo = ({
  fontSize,
  shadow,
  preventRedirect,
  onClick,
  showBadge = false,
}: {
  fontSize?: string;
  shadow?: boolean;
  preventRedirect?: boolean;
  onClick?: any;
  showBadge?: boolean;
}) => {
  return (
    <Box position="relative">
      {showBadge && (
        <Badge>
          <span>Beta</span>
        </Badge>
      )}
      <GradientText
        fontSize={fontSize}
        onClick={preventRedirect ? onClick : null}
        as="a"
        href={preventRedirect ? null : "/"}
        shadow={shadow}
        cursor="pointer"
      >
        alfalink
      </GradientText>
    </Box>
  );
};

export default AnimatedGradientLogo;
