import { UserComponent, useEditor, useNode } from "@craftjs/core";

import { Box } from "@chakra-ui/react";
import { Text } from "../Text";
import { TextSettings } from "./settings";
import styled from "styled-components";

type ButtonProps = {
  background?: string;
  color?: string;
  buttonStyle?: string;
  fontFamily?: string;
  text?: string;
  textComponent?: any;
  buttonType?: string;
  linkUrl?: string;
  borderRadius?: string;
  icon?: any;
  alignIcon?: string;
  showIcon?: boolean;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
  labelBackground?: string;
  textAlign?: string;
  labelPadding?: string;
};

const LabelContainer = styled(Box)<ButtonProps>`
  width: 100%;
  height: unset;

  color: ${({ color }) => color} !important;
  font-size: ${({ fontSize }) => fontSize} !important;

  cursor: "default";
  div,
  span {
    font-family: ${({ fontFamily }) => `${fontFamily}`} !important;
  }
`;

export const Label: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    text,
    textComponent,
    color,
    buttonStyle,
    background,
    linkUrl,
    borderRadius,
    showIcon,
    icon,
    alignIcon,
    fontFamily,
    fontWeight,
    fontSize,
    marginTop,
    marginBottom,
    labelBackground,
    labelPadding,
    textAlign,
    ...otherProps
  } = props;

  // hide label in public mode if not set
  return !enabled && text === "Example Label" ? null : (
    <Box
      width="100%"
      background={labelBackground ?? "transparent"}
      borderRadius="0.5rem"
      mt={`${marginTop}px !important`}
      mb={`${marginBottom}px !important`}
    >
      <LabelContainer
        p={`${labelPadding}px !important`}
        className="label_component_wrapper"
        textAlign={textAlign}
        position="relative"
        ref={(ref) => connect(ref)}
        fontFamily={fontFamily}
        fontSize={fontSize}
        color={color}
        cursor={enabled ? "text" : "default"}
        {...otherProps}
      >
        <Text
          {...textComponent}
          text={text}
          color={props.color}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textAlign={textAlign}
        />
      </LabelContainer>
    </Box>
  );
};

Label.craft = {
  displayName: "Label",

  props: {
    background: "",
    color: "#fff",
    text: "Example Label",
    fontSize: "14px",
    labelBackground: "",
    labelPadding: "8",
    fontFamily: "",
    marginTop: "12",
    marginBottom: "12",
    textAlign: "center",
    textComponent: {
      ...Text.craft.props,
      textAlign: "center",
    },
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: TextSettings,
  },
};
