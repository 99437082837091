import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import api from "api";
import { getAuthToken } from "utils";
import jwt_decode from "jwt-decode";
import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import { useUserContext } from "HoCs/UserProvider";

const StyledInputFile = styled.input`
  display: none;
`;

const StyledLabel = styled.label<{ disabled: boolean }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: #002c4b;
  background-color: #dce6f1;
  height: 40px;
  padding: 0 1rem;
  margin: 0.25rem 0;
  border-radius: 0.375rem;
  transition: all 100ms ease-out;

  ${({ disabled }) =>
    disabled &&
    `
        opacity: 0.4;
    `};

  :hover {
    cursor: pointer;
    background-color: #e2e8f0;

    ${({ disabled }) =>
      disabled &&
      `
        cursor: not-allowed;
    `};
  }
`;

const Img = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  transition: all 0.1s ease-out;
  backface-visibility: hidden;
  height: 100%;
  object-fit: cover;
`;

const Container = styled(Box)`
  position: relative;
  transition: 0.3s ease-out;

  background: linear-gradient(
    180deg,
    rgba(212, 212, 212, 0.74) 50%,
    rgba(192, 192, 192, 0.795) 60%,
    rgba(117, 117, 117, 0.774) 70%,
    rgb(73, 73, 73) 80%,
    rgb(58, 58, 58) 85%,
    rgb(37, 37, 37) 90%,
    rgb(0, 0, 0) 95%,
    rgb(0, 0, 0) 100%
  );

  :hover {
    > div {
      opacity: 1;
    }

    img {
      opacity: 0.7;
      transition: all 0.1s ease-in;
      cursor: pointer;
    }
  }
`;

const Middle = styled(Box)`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

const ImageGallery = ({ handleImageSelect }: any) => {
  const authToken = getAuthToken();

  const toast = useToast();

  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const [galleryImages, setGalleryImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const { userData } = useUserContext();

  const handleImageUpload = async (image) => {
    const imageUrl = new FormData();
    imageUrl.append("imageUrl", image, image.name);

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const decoded = jwt_decode(authToken) as any;

    setIsUploadingImage(true);
    try {
      const { data } = await api.post(
        `/api/private/upload-gallery-photo/${decoded.username}`,
        imageUrl,
        {
          headers: headers,
        }
      );

      toast({
        title: "Image uploaded",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      setIsUploadingImage(false);
      handleImageSelect(data.addedImageUrl);
      setGalleryImages([data.addedImageUrl, ...galleryImages]);
    } catch (error) {
      console.error("Err ", error);
      setIsUploadingImage(false);

      // @ts-ignore
      if (error.response.status === 413) {
        return toast({
          title: "Image size is too large, you can upload files up to 250 MB",
          status: "error",
          variant: "subtle",
          isClosable: true,
          position: "top",
        });
      }

      toast({
        title:
          "Something went wrong uploading the image. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleImageUpload(selectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    setGalleryImages(userData?.galleryImages);
  }, [userData]);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const handleDeletePhoto = async () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const decoded = jwt_decode(authToken) as any;

    try {
      const { data } = await api.delete(
        `/api/private/delete-gallery-photo/${decoded.username}`,
        {
          headers: headers,
          data: {
            imageUrl: selectedImageUrl,
          },
        }
      );

      setGalleryImages(
        galleryImages.filter((imgUrl) => imgUrl !== data.removedImageUrl)
      );

      handleImageSelect("");
      setShowModal(false);

      toast({
        title: "Image removed",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Err ", error);

      toast({
        title: "There was an error removing the image",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const onDeleteButtonClick = (imageUrl) => {
    setShowModal(true);
    setSelectedImageUrl(imageUrl);
  };

  const uploadLimitReached = galleryImages?.length >= 8;

  return (
    <div>
      <Text fontSize="0.875rem" color="gray.600" my="0.75rem">
        Uploaded image gallery
      </Text>

      <Grid
        templateColumns={galleryImages?.length !== 0 && "repeat(4, 1fr)"}
        gridAutoRows="min-content"
        gap={4}
        //minHeight="280px"
      >
        {galleryImages?.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            color="gray.500"
            p="1rem"
            borderRadius="0.25rem"
            bg="gray.100"
          >
            Looks like you haven't uploaded any images
            <br />
          </Box>
        ) : (
          galleryImages?.map((image: any) => (
            <Container key={image}>
              <Img
                src={image ?? ""}
                alt="Avatar"
                style={{ width: "100%" }}
                onClick={() => handleImageSelect(image)}
              />

              <Middle>
                <Text
                  fontSize="11px"
                  style={{ textShadow: "0px 0px 2px  #202020" }}
                >
                  <Button
                    variant="outline"
                    size="xs"
                    color="#fff"
                    _hover={{ color: "#111", backgroundColor: "#eee" }}
                    onClick={() => onDeleteButtonClick(image)}
                  >
                    Delete
                  </Button>
                </Text>
              </Middle>
            </Container>
          ))
        )}
      </Grid>

      <Flex justifyContent="center" my="0.5rem">
        {isUploadingImage ? (
          <Spinner />
        ) : (
          <StyledLabel
            htmlFor="upload-gallery-image-input"
            disabled={uploadLimitReached}
          >
            <StyledInputFile
              disabled={uploadLimitReached}
              id="upload-gallery-image-input"
              type="file"
              accept="image/*"
              placeholder="Image URL"
              onChange={onFileChange}
            />
            <UilPlus /> Upload image
          </StyledLabel>
        )}
      </Flex>

      <Text fontSize="xs" color="gray.400" mt="0.5rem">
        Supported formats: jpg/jpeg, png and gif.
      </Text>

      {uploadLimitReached && (
        <Text color="orange.500">
          Upload limit reached. Please remove a photo or two before uploading a
          new image.
        </Text>
      )}

      <Modal isOpen={showModal} onClose={() => setShowModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent p="1rem">
          <ModalHeader>Delete image</ModalHeader>
          <ModalCloseButton />
          <ModalBody m="0 auto" pb="2rem">
            Are you sure you want to delete this image?
            <Text color="red" fontWeight="bold">
              This action cannot be undone.
            </Text>
          </ModalBody>

          <ButtonGroup justifyContent="right" spacing="1rem">
            <Button variant="solid" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={handleDeletePhoto}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ImageGallery;
