import { Box, Link, Text } from "@chakra-ui/react";
import { showFooter, showNavbar } from "utils";

import { CookiesConsent } from "components/CookiesConsent";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import React from "react";
import { UserProvider } from "HoCs/UserProvider";
import { useLocation } from "react-router-dom";

const ToTemplates = () => {
  return (
    <Text
      fontSize="0.825rem"
      p="1rem 0.5rem"
      width="100%"
      zIndex="1116"
      bg="#6920f0"
      color="#fff"
      textAlign="center"
      fontWeight="600"
      position="sticky"
      top="-12px"
    >
      <Text as="span" fontSize="1rem">
        🚀
      </Text>
      &nbsp; NEW! Start with a{" "}
      <Link
        fontWeight="700"
        textDecoration="underline"
        href="/in/templates"
        aria-label="templates"
      >
        Template
      </Link>
      <Text as="span" fontSize="1rem">
        &nbsp; 🚀
      </Text>
    </Text>
  );
};

function App({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const shouldShowNavbar = showNavbar(location);
  const shouldShowFooter = showFooter(location);
  const isEditorView = location.pathname.includes("/editor");

  return (
    <UserProvider>
      <Box>
        {window.location.pathname === "/" && <ToTemplates />}

        {shouldShowNavbar ? (
          <>
            <Box borderBottom={isEditorView ? "1px solid #111" : "none"}>
              <NavBar isEditorView={isEditorView} />
            </Box>
            {!isEditorView && <CookiesConsent />}
          </>
        ) : null}

        <Box>{children}</Box>

        {shouldShowFooter && !isEditorView ? (
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            borderTop={"1px solid #111"}
          >
            <Footer />
          </Box>
        ) : null}
      </Box>
    </UserProvider>
  );
}

export default App;
