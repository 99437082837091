import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import api from "api";
import { getAuthToken } from "utils";
import styled from "styled-components";
import { isS3URL } from "utils/urlBuilder.js";

const StyledInputFile = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75rem;
  color: #002c4b;

  background-color: #edf2f7;
  height: 40px;
  padding: 0 1rem;
  border-radius: 0.375rem;
  transition: all 100ms ease-out;

  :hover {
    cursor: pointer;
    background-color: #e2e8f0;
  }
`;

const ProfileImageSelector = ({ handleImageSelect, currentImageUrl }) => {
  const authToken = getAuthToken();

  const toast = useToast();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleImageUpload = async (image) => {
    const imageUrl = new FormData();
    imageUrl.append("imageUrl", image, image.name);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const { data } = await api.post(
        `/api/private/upload-photo`,
        imageUrl,
        config
      );

      handleImageSelect(data.imageUrl);
    } catch (err: any) {
      if (err.response.status === 413) {
        return toast({
          title: "Image size is too large, you can upload files up to 250 MB",
          status: "error",
          variant: "subtle",
          isClosable: true,
          position: "top",
        });
      }

      toast({
        title:
          "Something went wrong uploading the image. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleImageUpload(selectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const handleDeletePhoto = async () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      await api.delete(`/api/private/delete-photo`, {
        headers: headers,
        data: {
          imageUrl: currentImageUrl,
        },
      });

      toast({
        title: "Image removed ",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      handleImageSelect("");
    } catch (error) {
      console.error("Err ", error);

      toast({
        title:
          "Something went wrong deleting the image. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledLabel htmlFor="upload-input">
          <StyledInputFile
            id="upload-input"
            type="file"
            onChange={onFileChange}
          />
          {currentImageUrl ? "Change" : "Add"} profile image
        </StyledLabel>

        {currentImageUrl ? (
          isS3URL(currentImageUrl) ? (
            <Button
              fontSize="0.75rem"
              variant="solid"
              onClick={handleDeletePhoto}
            >
              Delete photo
            </Button>
          ) : (
            <Button
              fontSize="0.75rem"
              variant="outline"
              onClick={() => handleImageSelect("")}
            >
              Reset
            </Button>
          )
        ) : null}
      </Flex>

      <Text fontSize="xs" color="gray.400" mt="0.5rem">
        Supported formats: jpg/jpeg, png and gif.
      </Text>
    </>
  );
};

export default ProfileImageSelector;
