import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";

import WorldMap from "react-svg-worldmap";
import countries from "utils/countries";

export const WorldMapCounts = ({ data }: { data?: any }) => {
  const [type, setType] = useState("pageView");
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");

  if (data.length === 0 || !data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p="2rem">
        <span>No data to display</span>
      </Box>
    );
  }

  const formattedData = data.map((c) => {
    return {
      country: countries[c.name] ? countries[c.name] : "Unknown",
      value: type === "pageView" ? c.pageView : c.linkClick,
    };
  });

  const defaultNumbers = Object.values(countries).map((c: string) => {
    return {
      country: c ? c : "Unknown",
      value: 0,
    };
  });

  const mapData = [...defaultNumbers, ...formattedData];

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      pt="1rem"
    >
      <WorldMap
        color={type === "pageView" ? "#5f42d9" : "#38aa68"}
        tooltipBgColor="#111"
        valuePrefix={type === "pageView" ? "- Views: " : "- Clicks: "}
        size={isSmallerThan768 ? "lg" : "xl"}
        data={mapData}
        frame
      />

      <Flex justifyContent="space-around" mt="1.5rem">
        <ButtonGroup>
          <Button
            colorScheme={type === "pageView" ? "purple" : "gray"}
            size="sm"
            onClick={() => setType("pageView")}
          >
            Views
          </Button>
          <Button
            colorScheme={type === "linkClick" ? "green" : "gray"}
            size="sm"
            onClick={() => setType("linkClick")}
          >
            Clicks
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
