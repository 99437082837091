import { Box, Heading, Link, SimpleGrid } from "@chakra-ui/react";

import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { cloudfrontURL } from "utils/urlBuilder.js";

const StyledBox = styled(Box)`
  background-image: ${cloudfrontURL('/allineonesocialicons3241.png')};
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #000000;
  border-radius: 12px;

  @media (max-width: 414px) {
    background-position-x: -8px;
  }
`;

const TempImageBox = styled(Box)<{ url: string }>`
  background-image: ${({ url }) => `url(${url})`} !important;

  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border-radius: 12px;

  :hover {
    transform: scale(1.01, 1.02);
  }
  transition: all 0.2s ease;

  @media (max-width: 414px) {
    background-position-x: 40%;
  } ;;
`;

const StartWithTemplate = () => {
  return (
    <Box
      mt={{ base: "2rem", md: "1rem" }}
      borderRadius="10px"
      position="relative"
    >
      <StyledBox
        pt={{ base: "1.25rem", md: "6rem" }}
        pl={{ md: "0rem", l: "2rem", xl: "3rem" }}
        pb={{ base: "2rem", md: "1em" }}
        mb={{ base: "2rem", md: "8rem" }}
        textAlign="center"
        color="#eeeeee"
        height={{ base: "300px", md: " 800px" }}
      >
        <Fade bottom>
          <Heading
            mb="0.5rem"
            as="h2"
            color="#ab01fa"
            fontSize={{ base: "1.5rem", md: "3.5rem" }}
          >
            SHARE. GROW.
          </Heading>
          <Heading
            mb="0.5rem"
            as="h2"
            color="#ab01fa"
            fontSize={{ base: "1.5rem", md: "4rem" }}
          >
            MONETIZE.
          </Heading>

          <Link fontSize={{ base: "xx-small", md: "sm" }} href="/in/privacy">
            <i>Start with a template ✨</i>
          </Link>
        </Fade>
      </StyledBox>

      <SimpleGrid
        left="0px"
        //top="455px"
        top={{ base: "140px", md: "455px" }}
        position="absolute"
        width="full"
        spacing={{ base: "0", md: "0.75rem" }}
        columns={{ base: 3, md: 3 }}
        //p="1rem"
        m="3rem 0"
      >
        <Link href="/in/templates">
          <TempImageBox
            height={{ base: "200px", md: "450px" }}
            //minWidth="224px"
            maxHeight="450px"
            //background="rgb(63, 140, 239)"
            //boxShadow="rgb(0 0 0 / 12%) 0px 7.4833px 39.2873px -11.2249px"
            url={cloudfrontURL("/blurTemplate003.png")}
          />
        </Link>

        <Link href="/in/templates">
          <TempImageBox
            height={{ base: "200px", md: "450px" }}
            maxHeight="522px"
            url={cloudfrontURL("/spiralgreentemplate10.png")}
          />
        </Link>

        <Link href="/in/templates">
          <TempImageBox
            height={{ base: "200px", md: "450px" }}
            maxHeight="522px"
            url={cloudfrontURL("/neonbluelighttemaplate11.png")}
          />
        </Link>
      </SimpleGrid>
    </Box>
  );
};

export default StartWithTemplate;
