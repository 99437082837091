import { Button, Flex, Spinner, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import api from "api";
import { getAuthToken } from "utils";
import styled from "styled-components";
import { isS3URL, cloudfrontURL } from "utils/urlBuilder.js";

const defaultVideoUrl = cloudfrontURL("/vidbg1633556650001.mp4");

const StyledInputFile = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: #002c4b;

  background-color: #edf2f7;
  height: 40px;
  padding: 0 1rem;
  border-radius: 0.375rem;
  transition: all 100ms ease-out;

  :hover {
    cursor: pointer;
    background-color: #e2e8f0;
  }
`;

const VideoGallery = ({ handleVideoSelect, currentVideoUrl }) => {
  const authToken = getAuthToken();

  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  const toast = useToast();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleVideoUpload = async (video) => {
    const videoUrl = new FormData();
    videoUrl.append("videoUrl", video, video.name);

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    // if own vid already exists, delete it and upload new one -  check for default video
    if (
      currentVideoUrl &&
      currentVideoUrl !== defaultVideoUrl &&
      isS3URL(currentVideoUrl)
    ) {
      try {
        await api.delete(`/api/private/delete-video`, {
          headers: headers,
          data: {
            videoUrl: currentVideoUrl,
          },
        });
        console.info("deleted current one");
      } catch (error) {
        console.error("Err ", error);
      }
    }

    handleVideoSelect("");
    setIsUploadingVideo(true);
    try {
      const { data } = await api.post(`/api/private/upload-video`, videoUrl, {
        headers: headers,
      });

      console.info("uploaded new video ", data.videoUrl);

      const newVideoUrl = data.videoUrl;

      toast({
        title: "Video uploaded",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      handleVideoSelect(newVideoUrl);
      setIsUploadingVideo(false);
    } catch (err) {
      setIsUploadingVideo(false);

      // @ts-ignore

      if (err.response.status === 413) {
        return toast({
          title: "Video size is too large, you can upload files up to 250 MB",
          status: "error",
          variant: "subtle",
          isClosable: true,
          position: "top",
        });
      }

      toast({
        title:
          "Something went wrong uploading the video. Please try again later or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleVideoUpload(selectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const handleDeleteVideo = async () => {
    if (currentVideoUrl === defaultVideoUrl) {
      toast({
        title:
          "Can't remove default video. Disable video background or upload your own video",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
      return;
    }

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const { data } = await api.delete(`/api/private/delete-video`, {
        headers: headers,
        data: {
          videoUrl: currentVideoUrl,
        },
      });

      console.info("deleted video ", data);

      toast({
        title: "Video removed",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      handleVideoSelect("");
    } catch (error) {
      console.error("Err ", error);

      toast({
        title: "Error removing video",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {isUploadingVideo ? (
        <Spinner />
      ) : (
        <StyledLabel htmlFor="upload-video-input">
          <StyledInputFile
            id="upload-video-input"
            type="file"
            accept="video/*"
            placeholder="Video URL"
            onChange={onFileChange}
          />
          <UilPlus />
          { isS3URL(currentVideoUrl)
            ? "Replace"
            : "Upload"}{" "}
          video
        </StyledLabel>
      )}

      {currentVideoUrl ? (
        isS3URL(currentVideoUrl) &&
        currentVideoUrl !== defaultVideoUrl ? (
          <Button variant="solid" onClick={handleDeleteVideo}>
            Delete video
          </Button>
        ) : (
          <Button variant="outline" onClick={() => handleVideoSelect("")}>
            Reset video
          </Button>
        )
      ) : null}
    </Flex>
  );
};

export default VideoGallery;
