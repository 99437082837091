import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`



* {
    box-sizing: border-box;
  }

  html, body {
    font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.5;
    min-height: 100vh;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  body {
    color: #002c4b;
    background-color: #ffffff;
    margin: 0;
   
  }

  code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.chakra-modal__content-container, .chakra-slide, .chakra-modal__content {
  /*
  z-index: 1800 
   */
  z-index: 1800 !important /* 999999 */
}
`;

export default GlobalStyle;
