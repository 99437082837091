import { useEditor, useNode } from "@craftjs/core";

import ContentEditable from "react-contenteditable";
import { TextSettings } from "./settings";
import TextareaAutosize from "react-textarea-autosize";
import { useMediaQuery } from "@chakra-ui/react";

export type TextTypes = {
  fontSize: string;
  textAlign: string;
  fontWeight: string;
  color: string;
  shadow: number;
  text: string;
  fontFamily?: string;
  useTextArea?: boolean;
  background?: string;
  padding?: number;
};

export const Text = ({
  fontSize,
  textAlign,
  fontWeight,
  fontFamily,
  color,
  shadow,
  text,
  useTextArea,
  background,
  padding,
}: Partial<TextTypes>) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const handleOnTextChange = (e: any) => {
    setProp((prop) => (prop.text = e.target.value));
  };

  return useTextArea ? (
    <>
      <TextareaAutosize
        maxRows={5}
        maxLength={120}
        ref={connect}
        readOnly={!enabled || isSmallerThan600}
        value={text || ""}
        onChange={handleOnTextChange}
        style={{
          width: "100%",
          background: background ? background : "transparent",
          wordBreak: "break-word",
          color: color ?? "red",
          fontSize: `${fontSize}px`,
          textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
          fontFamily: fontFamily,
          border: "none",
          height: 100,
          maxLines: 4,
          textAlign: textAlign as any,
          borderRadius: "3px",
          fontWeight: fontWeight as any,
          padding: padding ? `${padding}px` : "0px",
          cursor: enabled ? "text" : "default",
        }}
      />
    </>
  ) : (
    <ContentEditable
      innerRef={connect}
      html={text ?? ""}
      disabled={!enabled || isSmallerThan600}
      defaultValue={text || ""}
      onChange={handleOnTextChange}
      tagName={background ? "span" : "div"}
      style={{
        background: background ?? "transparent",
        width: "100%",
        wordBreak: "break-word",
        whiteSpace: "normal",
        borderRadius: "3px",
        color: color ?? "red",
        fontSize: `${fontSize}px`,
        textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
        fontWeight,
        textAlign,
        fontFamily: fontFamily,
        paddingTop: "1px",
        padding: padding ? `${padding}px` : "0px",
      }}
    />
  );
};

Text.craft = {
  displayName: "Text",
  props: {
    fontSize: "20",
    textAlign: "center",
    fontWeight: "",
    fontFamily: "",
    color: "",
    background: "transparent",
    shadow: 0,
    text: "Text",
  },
  related: {
    settings: TextSettings,
  },
};
