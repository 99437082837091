import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ErrorPage } from "containers/ViewScreen/ErrorPage";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { getPublicFunStats } from "api/queries";
import { useQuery } from "react-query";

const FunStatsScreen = () => {
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery("getPublicFunStats", getPublicFunStats);

  if (isLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        m="0 auto"
        h="calc(100vh - 58px)"
      >
        <Spinner
          display="flex"
          size="xl"
          thickness="4px"
          justifyContent="center"
          alignItems="center"
          margin="1rem auto"
          emptyColor="gray.200"
          colorScheme="brand"
        />
      </Flex>
    );
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Helmet>
        <title>Fun Stats | Alfalink</title>
      </Helmet>
      <Page pt={{ base: "2rem", md: "4rem" }}>
        <Heading as="h2" size="xl" textAlign="center" mb="2rem">
          Fun & fast stats
        </Heading>
        <Stack
          spacing={{ base: "4rem", lg: "5rem" }}
          textAlign="center"
          pb="5rem"
          fontWeight="bold"
        >
          <Box>
            <Heading as="h3" size="lg">
              Sign ups
            </Heading>
            <Box
              minWidth="224px"
              mt={{ base: "1rem", md: "0.5rem" }}
              bgColor="#fff"
              borderRadius="0.25rem"
              p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
              boxShadow="0 1px 12px -12px #baeffa"
              border="1px solid #baeffa"
            >
              <Text>Total number of users</Text>
              <Text fontSize="3xl">{stats.totalNumberOfUsers}</Text>
            </Box>

            <SimpleGrid
              spacing={{ base: "0", md: "1rem" }}
              columns={{ sm: 2, md: 3 }}
              mt="0.5rem"
            >
              <Box
                minWidth="224px"
                mt={{ base: "1rem", md: "0.5rem" }}
                bgColor="#fff"
                borderRadius="0.25rem"
                p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
                boxShadow="0 1px 12px -12px #baeffa"
                border="1px solid #baeffa"
              >
                <Text>Since last week</Text>
                <Text fontSize="3xl">{stats.usersSinceLastWeek}</Text>
              </Box>

              <Box
                minWidth="224px"
                mt={{ base: "1rem", md: "0.5rem" }}
                bgColor="#fff"
                borderRadius="0.25rem"
                p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
                boxShadow="0 1px 12px -12px #baeffa"
                border="1px solid #baeffa"
              >
                <Text>Since last month</Text>
                <Text fontSize="3xl">{stats.usersSinceLastMonth}</Text>
              </Box>

              <Box
                minWidth="224px"
                mt={{ base: "1rem", md: "0.5rem" }}
                bgColor="#fff"
                borderRadius="0.25rem"
                p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
                boxShadow="0 1px 12px -12px #baeffa"
                border="1px solid #baeffa"
              >
                <Text>Since open beta</Text>
                <Text fontSize="3xl">{stats.usersSinceOpenBeta}</Text>
              </Box>
            </SimpleGrid>
          </Box>

          <Box>
            <Heading as="h3" size="lg">
              User engagements
            </Heading>
            <Box
              mt={{ base: "1rem", md: "0.5rem" }}
              bgColor="#fff"
              borderRadius="0.25rem"
              p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
              boxShadow="0 1px 12px -12px #e7c4ff"
              border="1px solid #e7c4ff"
            >
              <Text>Total number of shared links</Text>
              <Text fontSize="3xl">{stats.activeLinks?.total}</Text>
            </Box>

            <SimpleGrid
              spacing={{ base: "0", md: "1rem" }}
              columns={{ sm: 2, md: 2 }}
              mt="0.5rem"
            >
              <Box
                minWidth="224px"
                mt={{ base: "1rem", md: "0.5rem" }}
                bgColor="#fff"
                borderRadius="0.25rem"
                p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
                boxShadow="0 1px 12px -12px #e7c4ff"
                border="1px solid #e7c4ff"
              >
                <Text>Total number of profile visits</Text>
                <Text fontSize="3xl">{stats.hits?.pageViews.total}</Text>
              </Box>

              <Box
                minWidth="224px"
                mt={{ base: "1rem", md: "0.5rem" }}
                bgColor="#fff"
                borderRadius="0.25rem"
                p={{ base: "1rem 1.75rem", md: "1rem 1.25rem" }}
                boxShadow="0 1px 12px -12px #e7c4ff"
                border="1px solid #e7c4ff"
              >
                <Text>Total number of link clicks</Text>
                <Text fontSize="3xl">{stats.hits?.linkClicks.total}</Text>
              </Box>
            </SimpleGrid>
          </Box>
        </Stack>
      </Page>
    </>
  );
};

export default FunStatsScreen;
