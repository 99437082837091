import { Box, Collapse, Text } from "@chakra-ui/react";

import UilArrowDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilArrowUp from "@iconscout/react-unicons/icons/uil-angle-up";
import { useState } from "react";

export const QuestionCard = ({ question }: { question: any }) => {
  const [toggled, setToggled] = useState(false);
  const toggleContent = () => {
    setToggled(!toggled);
  };

  return (
    <Box borderBottom="1px solid #dddddd" fontSize="0.825rem">
      <Box
        py="0.5rem"
        px={{ base: "1rem", md: "1rem" }}
        onClick={toggleContent}
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        cursor="pointer"
      >
        <Text wordBreak="break-word">{question.title}</Text>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          {toggled ? <UilArrowUp /> : <UilArrowDown />}
        </Box>
      </Box>

      <Collapse in={toggled} animateOpacity>
        <Box
          py={{ base: "0.5rem", md: "0.75rem" }}
          px={{ base: "1rem", md: "1rem " }}
        >
          {question.content}
        </Box>
      </Collapse>
    </Box>
  );
};
