export const TiktokIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 333333 333333"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "48px",
        height: "48px",
      }}
    >
      <path
        d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm33461 70641c337 2877 825 5661 1461 8341l6304 2c1170 9991 4006 19119 8465 26697 7282 6745 16796 10904 28280 11641v9207c2131 444 4350 746 6659 894v29689c-14847 1462-27841-3426-42981-12531l2324 50846c0 16398 61 23892-8738 38976-20546 35222-58194 36676-82176 18322-12269-4256-23069-12466-29881-23611-19875-32516-1959-85512 55687-90966l-94 7835v1970c3105-646 6365-1144 9794-1468v31311c-12483 2057-20412 5890-24119 12980-7424 14197-4049 26526 3716 34309 16276 2796 34400-8481 31672-43351V70640h33628z"
        fill="#1a121f"
      />
      <path
        d="M200127 70641c3093 26406 18915 45038 44509 46681v25046l-165 15v-21275c-25595-1642-40311-17390-43404-43796l-27114-1v111094c3912 50004-35050 51490-49954 32531 17482 10933 45867 3826 42500-39202V70640h33628zm-72854 184164c-15319-3153-29249-12306-37430-25689-19875-32516-1959-85512 55687-90966l-94 7835c-53443 8512-58809 65920-44009 89801 5707 9209 15076 15686 25846 19019z"
        fill="#26f4ee"
      />
      <path
        d="M207892 78984c1761 15036 7293 28119 16454 36903-12866-6655-20630-19315-23062-36905l6609 2zm36580 47511c2181 463 4456 777 6824 929v29689c-14847 1462-27841-3426-42981-12531l2324 50846c0 16398 61 23892-8738 38976-21443 36760-61517 36743-85239 15810 30930 17765 84927 3857 84828-56453v-55495c15141 9105 28134 13993 42981 12530v-24302zm-99035 21460c3105-646 6365-1144 9794-1468v31311c-12483 2057-20412 5890-24119 12980-10441 19964 474 36238 14923 41365-18075-649-36010-19214-23555-43031 3707-7089 10474-10923 22957-12980v-28176z"
        fill="#fb2c53"
      />
      <path
        d="M201068 77312c3093 26406 17809 42154 43404 43796v29689c-14847 1462-27841-3425-42981-12530v55495c119 72433-77801 77944-100063 42024-14800-23881-9435-81289 44009-89801v30146c-12483 2057-19250 5891-22957 12980-22909 43808 56997 69872 51475-706V77311l27114 1z"
        fill="#fefefe"
      />
    </svg>
  );
};
