import {
  Box,
  Button,
  ButtonGroup,
  Fade,
  HStack,
  Link,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { getAuthToken } from "utils";

import { QrCodeModal } from "components/QrCodeModal";
import { ShareLinkModal } from "components/ShareLinkModal";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import UilEdit from "@iconscout/react-unicons/icons/uil-edit-alt";
import UilFloppy from "@iconscout/react-unicons/icons/uil-save";
import UilQrCode from "@iconscout/react-unicons/icons/uil-qrcode-scan";
import UilRedo from "@iconscout/react-unicons/icons/uil-redo";
// import UilSocialShare from "@iconscout/react-unicons/icons/uil-share-alt";
import api from "api";
import copy from "copy-to-clipboard";
import lz from "lzutf8";
import { marginX } from "utils/constants";
import styled from "styled-components";
import { useEditor } from "@craftjs/core";
// import { useHistory } from "react-router-dom";
import { useState } from "react";

const StyledSaveButtonContainerMobile = styled(Box)`
  transition: all 0.3s ease-out;

  top: ${({ isEditing }) => (isEditing ? "3.5rem" : "8.5rem")};
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  padding: 0;
`;

export const AdEditorHeader = ({ adData }) => {
  const authToken = getAuthToken();

  // const routeHistory = useHistory();

  const [showSocialShareModal, setShowSocialShareModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  const [isSavingFrameData, setIsSavingFrameData] = useState(false);

  const { actions, canUndo, canRedo, query, enabled } = useEditor(
    (state, query) => ({
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo(),
    })
  );

  const toast = useToast();
  const id = "u-toast";

  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const onShareLinkModalClose = () => {
    setShowSocialShareModal(false);
  };

  const onQrCodeModalClose = () => {
    setShowQrCodeModal(false);
  };

  const handleUpdateFrameData = async () => {
    actions.setOptions((options) => (options.enabled = !enabled));

    if (enabled) {
      const json = query.serialize();

      const parsedNodes = query?.getState()?.nodes;

      const links = parsedNodes
        ? Object.keys(parsedNodes)
            .map((k) => parsedNodes[k])
            .filter((k) => k?.data?.name === "Link")
            .filter((l) => l?.data?.props.linkUrl !== "")
        : undefined;

      const socialLinks = parsedNodes
        ? Object.keys(parsedNodes)
            .map((k) => parsedNodes[k])
            .filter((k) => k?.data?.name === "SocialLinks")
        : undefined;

      const supportLinks = parsedNodes
        ? Object.keys(parsedNodes)
            .map((k) => parsedNodes[k])
            .filter((k) => k?.data?.name === "Support")
        : undefined;

      const socialLinkKeys = socialLinks[0]?.data?.props ?? undefined;

      const toDBLinksInfo = links
        ? links.map(({ id: linkId, data }) => {
            return {
              linkId: linkId,
              name: data?.props.text,
              linkUrl: data?.props.linkUrl,
              hidden: data?.props.hideLink,
            };
          })
        : adData?.linksInfo;

      const toDBSupportButtons = supportLinks
        ? supportLinks.map(({ id, data }) => {
            return {
              linkId: id,
              name: data?.props?.buttonText ?? "Support Button",
            };
          })
        : adData?.supportButtonsInfo;

      const toDBSocialHandlesInfo = socialLinkKeys
        ? Object.keys(socialLinkKeys)
            .filter(
              (k) =>
                socialLinkKeys[k] !== "" &&
                k !== "marginBottom" &&
                k !== "marginTop" &&
                k !== "position"
            )
            .map((k) => ({
              channel: k,
              handle: socialLinkKeys[k],
            }))
        : adData?.socialHandlesInfo;

      const frameData = lz.encodeBase64(lz.compress(json));

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      setIsSavingFrameData(true);

      try {
        await api.put(
          `/api/private/ad/frame-data/${adData.id}`,
          {
            frameData,
            linksInfo: toDBLinksInfo,
            supportButtonsInfo: toDBSupportButtons,
            socialHandlesInfo: toDBSocialHandlesInfo,
          },
          config
        );

        setIsSavingFrameData(false);

        toast({
          title: "Changes saved and published!",
          status: "success",
          variant: "subtle",
          position: "top",
          isClosable: true,
        });
      } catch (error: any) {
        console.error("error saving changes ", error);

        toast({
          title:
            "Something went wrong saving the new changes. Please try again later or contact support",
          status: "error",
          variant: "subtle",
          isClosable: true,
          position: "top",
        });
      }

      setIsSavingFrameData(false);
    }
  };

  const userAlfalink = `https://${document.domain}/${adData.username}`;
  const shortUserAlfalink = `${document.domain}/${adData.username}`;

  const LinkInfo = (
    <Box
      display="flex"
      height="52px"
      alignItems="center"
      justifyContent={{ base: "space-between", md: "normal" }}
      width="100%"
    >
    </Box>
  );


  return (
    <>
      <Box
        borderBottom={isLargerThan768 ? "1px solid #eee" : ""}
        px={{ base: "1rem", md: "0rem" }}
      >
        <Box
          mx={marginX}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {isSmallerThan768 && !enabled
            ? LinkInfo
            : isLargerThan768
            ? LinkInfo
            : null}

          {isLargerThan768 ||
            (isSmallerThan480 && !enabled) ||
            (isLargerThan768 && (
              <Box
                display="flex"
                height="52px"
                alignItems="center"
                justifyContent={{ base: "space-between", md: "normal" }}
                width="100%"
              >
                <Text size="md" display="flex" alignItems="center">
                  {isSmallerThan480 ? "" : "Your link:"}
                  <StyledLink
                    href={userAlfalink}
                    isExternal
                    ml={{ base: "0 ", md: "0.5rem" }}
                  >
                    {shortUserAlfalink}
                  </StyledLink>
                </Text>

                <ButtonGroup
                  variant="outline"
                  spacing="0.75rem"
                  size="sm"
                  ml="1rem"
                >
                  <Button
                    onClick={() => {
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          position: "top",
                          status: "success",
                          variant: "subtle",
                          isClosable: true,
                          title: "Link copied",
                        });
                        copy(`alfalink.co/${adData.username}`);
                      }
                    }}
                  >
                    <UilCopy size="1rem" />
                  </Button>
                  <Button onClick={() => setShowQrCodeModal(true)}>
                    <UilQrCode size="1rem" />
                  </Button>
                </ButtonGroup>
              </Box>
            ))}

          <QrCodeModal
            avatar={adData?.avatar}
            onQrCodeModalClose={onQrCodeModalClose}
            showQrCodeModal={showQrCodeModal}
            userAlfalink={userAlfalink}
            username={adData.username}
          />

          <ShareLinkModal
            onShareLinkModalClose={onShareLinkModalClose}
            showSocialShareModal={showSocialShareModal}
            userAlfalink={shortUserAlfalink}
            username={adData.username}
          />

          {isLargerThan768 ? (
            <ButtonGroup spacing="0.5rem" alignItems="center">
              <Button
                className="desktop_publish_button_wrapper"
                onClick={handleUpdateFrameData}
                variant="solid"
                colorScheme="brand"
                isLoading={isSavingFrameData}
                leftIcon={enabled ? <UilFloppy /> : <UilEdit />}
              >
                {enabled ? "Save & Publish" : "Edit"}
              </Button>

              {/*

              <Button
                className="copy-state-btn"
                onClick={() => {
                  const json = query.serialize();
                  copy(lz.encodeBase64(lz.compress(json)));
                }}
                style={{ marginRight: "10px" }}
              >
                Copy current stateeee
              </Button>
                */}
            </ButtonGroup>
          ) : null}
        </Box>
      </Box>

      {isSmallerThan768 ? (
        <>
          <StyledSaveButtonContainerMobile
            position="absolute"
            mx="1rem"
            isEditing={enabled}
            zIndex={111}
            left="0"
            right="0"
            pointerEvents="none"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Fade in={enabled && isSmallerThan768 && (canUndo || canRedo)}>
                <HStack spacing="6px" display="flex">
                  <Tooltip label="Undo" placement="bottom" aria-label="Undo">
                    <button
                      style={{
                        transform: "scaleX(-1)",
                        pointerEvents: "auto",
                      }}
                      disabled={!canUndo}
                      onClick={() => actions.history.undo()}
                    >
                      <UilRedo color={!canUndo ? "#afafaf" : "#111"} />
                    </button>
                  </Tooltip>

                  <Tooltip label="Redo" placement="bottom" aria-label="Redo">
                    <button
                      style={{
                        pointerEvents: "auto",
                      }}
                      disabled={!canRedo}
                      onClick={() => actions.history.redo()}
                    >
                      <UilRedo color={!canRedo ? "#afafaf" : "#111"} />
                    </button>
                  </Tooltip>
                </HStack>
              </Fade>

              <ButtonGroup spacing="0.125rem" alignItems="center">
                <Button
                  className="mobile_publish_button_wrapper"
                  pointerEvents="auto"
                  onClick={handleUpdateFrameData}
                  variant="solid"
                  colorScheme="brand"
                  boxShadow="0 1px 2px 0px #424242"
                  isLoading={isSavingFrameData}
                  size="sm"
                  leftIcon={
                    enabled ? (
                      <UilFloppy size="1.25rem" />
                    ) : (
                      <UilEdit size="1.25rem" />
                    )
                  }
                >
                  {enabled ? "Publish" : "Edit"}
                </Button>

              </ButtonGroup>
            </Box>
          </StyledSaveButtonContainerMobile>
        </>
      ) : null}
    </>
  );
};
