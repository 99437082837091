import {
  Drawer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilMargins from "@iconscout/react-unicons/icons/uil-line-spacing";
import { useState } from "react";

export const DividerSettings = () => {
  const [drawerOpen, setDrawer] = useState("");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const closeDrawer = () => {
    setDrawer("");
  };

  const MobileDividerSettingItem = (
    <Stack spacing="2rem">
      <Text fontSize="xs" color="gray.600">
        Add some empty vertical space between components
      </Text>
      <SettingsItem
        full
        propKey="margin"
        type="slider"
        sliderMin={8}
        sliderMax={60}
        label="Spacing margin"
      />

      <SettingsItem
        full
        propKey="thickness"
        type="slider"
        sliderMin={0}
        sliderMax={6}
        label="Thickness"
      />
    </Stack>
  );

  const MobileDividerColor = (
    <SettingsItem full propKey="color" type="gradient" useGradient={false} />
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Edit</Tab>
        <Tab>Color</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{MobileDividerSettingItem}</TabPanel>
        <TabPanel>{MobileDividerColor}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "edit" || drawerOpen === "color"}>
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilMargins />
          <Text>Edit</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("color")}>
          <PaintBucketIcon />
          <Text>Color</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Edit">{MobileDividerSettingItem}</DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "color"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Color">{MobileDividerColor}</DrawerContent>
      </Drawer>
    </>
  );
};
