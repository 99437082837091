import React from "react";

const PatreonIcon = () => {
  return (
    <svg
      viewBox="0 0 412 492"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-brand-appstore"
      width="64"
      height="64"
      stroke="currentColor"
      strokeWidth="2"
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      focusable="false"
    >
      <g>
        <path
          d="M328.2,10.5c-101.7,0-184.4,82.7-184.4,184.4c0,101.3,82.7,183.8,184.4,183.8c101.3,0,183.8-82.4,183.8-183.8
		C512,93.2,429.6,10.5,328.2,10.5z M0,501.5h90v-491H0V501.5z"
        />
      </g>
    </svg>
  );
};

export default PatreonIcon;
