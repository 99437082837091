import { Alert, Flex, Spinner } from "@chakra-ui/react";
import {
  BioContainer,
  BuyMeACoffee,
  ContainerHeader,
  Divider,
  KoFi,
  Label,
  Link,
  Links,
  MainContainer,
  Music,
  SocialLinks,
  Support,
  Twitter,
  Video,
  Image,
  BrandAd,
  MintMobile,
} from "components/Selectors";
import { memo, useState } from "react";

import { Editor } from "@craftjs/core";
import AdEditorContent from "./AdEditorContent";
import { Helmet } from "react-helmet";
import { ProfileImage } from "components/Selectors/ProfileImage";
import { RenderNode } from "components/EditorComponents";
import { Text } from "components/Selectors";
import { TooManyRequestErrorPage } from "components/ErrorPageTooManyRequests";
import UilExclamation from "@iconscout/react-unicons/icons/uil-exclamation-octagon";
import useAdData from "hooks/useAdData";

const EditorScreen = () => {
  const [activeComponents, setActiveComponents] = useState([]);

  const {
    adData,
    loading: loadingUserData,
    error: userDataError,
  } = useAdData();

  if (loadingUserData || adData === undefined) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        m="0 auto"
        h="calc(100vh - 58px)"
      >
        <Spinner
          display="flex"
          size="xl"
          thickness="4px"
          justifyContent="center"
          alignItems="center"
          margin="1rem auto"
          emptyColor="gray.200"
          colorScheme="brand"
        />
      </Flex>
    );
  }

  if (userDataError === 429) {
    return <TooManyRequestErrorPage />;
  }

  if (userDataError) {
    return (
      <Alert status="error" textAlign="center">
        <UilExclamation style={{ marginRight: "0.5rem" }} />
        There was an error fetching profile data. Try again in a bit or contact
        support.
      </Alert>
    );
  }

  const findAllByKey = (obj, keyToFind) => {
    if (obj === null) {
      return null;
    }

    return Object.entries(obj)?.reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(value)
          : typeof value === "object"
          ? acc.concat(findAllByKey(value, keyToFind))
          : acc,
      []
    );
  };

  return (
    <>
      <Helmet>
        <title>Editor | Alfalink</title>
      </Helmet>
      <Editor
        resolver={{
          MainContainer,
          ContainerHeader,
          BioContainer,
          Text,
          Label,
          ProfileImage,
          Links,
          Link,
          KoFi,
          Twitter,
          Music,
          BuyMeACoffee,
          Video,
          Image,
          SocialLinks,
          Divider,
          Support,
          BrandAd,
          MintMobile,
        }}
        onNodesChange={(query) => {
          setTimeout(() => {
            setActiveComponents(
              findAllByKey(query.getSerializedNodes(), "resolvedName")
            );
          }, 500);
        }}
        enabled={true}
        onRender={RenderNode}
      >
        <AdEditorContent
          activeComponents={activeComponents}
          adData={adData}
        />
      </Editor>
    </>
  );
};

export default memo(EditorScreen);
