import { HStack, Heading, Radio, Stack, Text } from "@chakra-ui/react";

import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilAlignCenter from "@iconscout/react-unicons/icons/uil-align-center";
import UilAlignLeft from "@iconscout/react-unicons/icons/uil-align-left";
import UilAlignRight from "@iconscout/react-unicons/icons/uil-align-right";

export const TextAlignRadios = (
  <HStack spacing="2rem" py="0.5rem">
    <Radio value="left">
      <UilAlignLeft />
    </Radio>
    <Radio value="center">
      <UilAlignCenter />
    </Radio>
    <Radio value="right">
      <UilAlignRight />
    </Radio>
  </HStack>
);

export const FontWeightRadios = (
  <HStack spacing="2rem">
    <Radio value="500">Normal</Radio>
    <Radio value="700">
      <strong>Bold</strong>
    </Radio>
  </HStack>
);

export const FontSizeItem = (
  <SettingsItem
    full
    propKey="fontSize"
    type="slider"
    sliderMin={12}
    sliderMax={48}
    label="Font Size"
  />
);

export const SelectFontItem = (
  <SettingsItem
    full={true}
    propKey="fontFamily"
    type="selectfont"
    label="Font family"
  />
);

export const LinkStylesItem = ({
  buttonStyle,
  background,
}: {
  buttonStyle: string;
  background: string;
}) => (
  <SettingsItem propKey="buttonStyle" type="buttonRadio">
    <Heading display={{ base: "none", md: "block" }} as="h6" size="xs" pl="2px">
      Link styles
    </Heading>

    <Stack spacing="1.5rem" py="1rem">
      <Radio value="solid" cursor="pointer" alignItems="baseline">
        <Text
          display="inline-flex"
          alignItems="center"
          px="1rem"
          height="2.5rem"
          background="gray.100"
          fontWeight="600"
          borderRadius="0.375rem"
          cursor="pointer"
          transition="all 0.1s ease-out"
          _hover={{
            boxShadow: "0 4px 5px -2px #e9e9e9",
          }}
        >
          Solid
        </Text>
        {buttonStyle === "solid" && !background && (
          <Text
            width={{ base: "124px", md: "90px" }}
            color="gray.500"
            fontSize="0.7rem"
            pt="2px"
          >
            add background color
          </Text>
        )}
      </Radio>

      <Radio value="outline" cursor="pointer">
        <Text
          display="inline-flex"
          alignItems="center"
          px="1rem"
          height="2.5rem"
          background="transparent"
          fontWeight="600"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="0.375rem"
          cursor="pointer"
          transition="all 0.1s ease-out"
          _hover={{
            boxShadow: "0 4px 5px -2px #e9e9e9",
          }}
        >
          Outline
        </Text>
      </Radio>

      <Radio value="ghost" cursor="pointer">
        <Text
          display="inline-flex"
          alignItems="center"
          px="1rem"
          height="2.5rem"
          background="transparent"
          fontWeight="600"
          borderRadius="0.375rem"
          cursor="pointer"
          transition="all 0.1s ease-out"
          _hover={{
            boxShadow: "0 4px 5px -2px #e9e9e9",
          }}
        >
          Ghost
        </Text>
      </Radio>

      <Radio value="shadow" cursor="pointer" id="html">
        <Text
          display="inline-flex"
          alignItems="center"
          px="1rem"
          height="2.5rem"
          background="gray.100"
          fontWeight="600"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="0.375rem"
          cursor="pointer"
          boxShadow="8px 10px #111"
          transition="all 0.1s ease-out"
          _hover={{
            background: "none",
          }}
        >
          Shadow
        </Text>
      </Radio>
    </Stack>
  </SettingsItem>
);
