import { Alert, AlertDescription, AlertIcon, Box } from "@chakra-ui/react";

import React from "react";

interface AlertMessageInterface {
  status: "error" | "info" | "warning" | "success";
  message: string;
}
export default function AlertMessage({
  message,
  status,
}: AlertMessageInterface) {
  return (
    <Box my={4}>
      <Alert status={status} borderRadius={4} fontSize="sm">
        <AlertIcon />
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    </Box>
  );
}
