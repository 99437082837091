import {
  Divider,
  Drawer,
  HStack,
  Heading,
  Radio,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilCircle from "@iconscout/react-unicons/icons/uil-circle";
import UilImage from "@iconscout/react-unicons/icons/uil-image";
import UilImageSize from "@iconscout/react-unicons/icons/uil-image-resize-landscape";
import UilInfo from "@iconscout/react-unicons/icons/uil-info-circle";

export const ProfileImageSettings = () => {
  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const MobileBorderSettingItem = (
    <>
      <SettingsItem
        full={true}
        propKey="borderSize"
        type="slider"
        sliderMin={0}
        sliderMax={7}
        label="Thickness"
      />

      <br />

      <SettingsItem
        full={true}
        propKey="borderColor"
        type="gradient"
        useGradient={false}
        label="Color"
      />
    </>
  );
  const MobileReplaceImageSettingItem = (
    <>
      <SettingsItem full={true} propKey="imageUrl" type="profileImageUrl" />

      <br />

      <SettingsItem
        full={true}
        propKey="imageUrl"
        type="text"
        label="Image URL"
        placeholder="Image URL address"
      />

      <Text
        color="gray.400"
        fontSize="xs"
        mt="1.5rem"
        display="flex"
        alignItems="center"
      >
        <UilInfo size="1rem" /> You can also use/paste your own online image
        URL.
      </Text>
    </>
  );

  const MobileImageSizeSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Image size
      </Heading>

      <SettingsItem propKey="imageSize" type="buttonRadio">
        <HStack spacing="1.25rem">
          <Radio value="74px" cursor="pointer">
            <div>Small</div>
          </Radio>

          <Radio value="88px" cursor="pointer">
            <div>Medium</div>
          </Radio>

          <Radio value="100px" cursor="pointer">
            <div>Large</div>
          </Radio>
        </HStack>
      </SettingsItem>
    </>
  );

  const ProfileImageSettingItem = (
    <>
      {MobileReplaceImageSettingItem}

      <Divider my="1rem" />

      {MobileImageSizeSettingItem}
    </>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Edit</Tab>
        <Tab>Border</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{ProfileImageSettingItem}</TabPanel>
        <TabPanel>{MobileBorderSettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "replace" || drawerOpen === "size"}>
        <SettingIconButton onClick={() => setDrawer("replace")}>
          <UilImage />
          <Text>Replace</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("size")}>
          <UilImageSize />
          <Text>Size</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("border")}>
          <UilCircle />
          <Text>Border</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "replace"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Profile image">
          {MobileReplaceImageSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "size"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="15vh" title="Image size">
          {MobileImageSizeSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "border"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="15vh" title="Border">
          {MobileBorderSettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
