export const LinkShieldIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="mdi-shield-link-variant-outline"
      width="24"
      height="26"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.28,9.73C14.86,10.31 15.18,11.08 15.18,11.9C15.18,12.73 14.86,13.5 14.28,14.08L12.26,16.1C11.66,16.7 10.87,17 10.08,17C9.29,17 8.5,16.7 7.9,16.1C6.7,14.9 6.7,12.95 7.9,11.74L9.15,10.5L9.14,11.06C9.14,11.5 9.21,11.95 9.36,12.36L9.4,12.5L9.04,12.87C8.75,13.15 8.6,13.5 8.6,13.92C8.6,14.31 8.75,14.68 9.03,14.96C9.6,15.53 10.57,15.53 11.13,14.97L13.14,12.95C13.43,12.67 13.58,12.3 13.58,11.91C13.58,11.5 13.43,11.14 13.14,10.86C13,10.71 12.9,10.5 12.9,10.29C12.9,10.08 13,9.87 13.14,9.72C13.45,9.42 14,9.42 14.28,9.73M18,9.08C18,9.9 17.68,10.68 17.1,11.26L15.85,12.5L15.86,11.94C15.86,11.5 15.79,11.06 15.64,10.64L15.59,10.5L15.96,10.13C16.25,9.85 16.4,9.5 16.4,9.08C16.4,8.69 16.25,8.32 15.96,8.04C15.4,7.47 14.43,7.47 13.87,8.03L11.86,10.05C11.57,10.33 11.42,10.7 11.42,11.1C11.42,11.5 11.57,11.86 11.85,12.14C12,12.29 12.1,12.5 12.1,12.71C12.1,12.93 12,13.13 11.85,13.28C11.7,13.43 11.5,13.5 11.29,13.5C11.09,13.5 10.88,13.43 10.72,13.28C9.5,12.07 9.5,10.12 10.72,8.92L12.74,6.9C13.95,5.7 15.9,5.7 17.1,6.9C17.68,7.5 18,8.26 18,9.08Z" />
    </svg>
  );
};
