import {
  Drawer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";

import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";
import { useNode } from "@craftjs/core";

export const VideoSettings = () => {
  const { propValue } = useNode((node) => ({
    propValue: node.data.props,
    parent: node.data.parent,
  }));
  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const closeDrawer = () => {
    setDrawer("");
  };

  const VideoDisplayOptionsSettingItem = (
    <>
      <SettingsItem
        full={true}
        propKey="url"
        type="text"
        label="Video URL or Embed code"
        subLabel="Embed vide from YouTube, Twitch, Facebook, Vimeo, DailyMotion, Wistia,Kaltura, and Vidyard"
        placeholder="Paste the URL or Embed Code"
      />

      {(propValue && propValue.url && propValue.url.startsWith("<")) ||
      (propValue &&
        propValue.url &&
        propValue.url.includes("tiktok.com")) ? null : (
        <>
          {" "}
          <Text fontSize="sm" pl="2px" mt="1.5rem" mb="0.5rem">
            Preview options
          </Text>
          <Stack spacing="0.75rem">
            <SettingsItem
              full
              propKey="autoplayVideo"
              type="switch"
              label="Auto play video on page load"
            />

            <SettingsItem
              full
              propKey="muteVideo"
              type="switch"
              label="Mute video"
            />
          </Stack>
        </>
      )}
    </>
  );

  return isLargerThan768 ? (
    <Tabs>
      <TabList mb="1em">
        <Tab>Info</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{VideoDisplayOptionsSettingItem}</TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper hidden={drawerOpen === "edit"}>
        <SettingIconButton onClick={() => setDrawer("edit")}>
          <UilEditKeyboard />
          <Text>Edit</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "edit"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="15vh" title="Info" pb="6rem">
          {VideoDisplayOptionsSettingItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
