import { Box } from "@chakra-ui/layout";
import Picker from "emoji-picker-react";

const EmojiSelectorSettingsItem = ({ handleEmojiSelect }) => {
  const onEmojiClick = (e, emoji) => {
    handleEmojiSelect(emoji.emoji);
  };

  return (
    <Box>
      <Picker onEmojiClick={onEmojiClick} pickerStyle={{ width: "100%" }} />
    </Box>
  );
};

export default EmojiSelectorSettingsItem;
