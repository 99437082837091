export const GraphCTRIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="14"
      height="14"
      viewBox="0 0 485 485"
      fill="#1566d6"
      style={{ marginTop: "2px", marginRight: "2px" }}
    >
      <g>
        <path
          d="M430,102.509c-30.327,0-55,24.673-55,55c0,9.724,2.547,18.859,6.993,26.794l-61.444,61.445
           c-7.934-4.446-17.07-6.993-26.794-6.993c-9.724,0-18.86,2.547-26.794,6.993l-27.707-27.707c4.446-7.934,6.993-17.07,6.993-26.794
           c0-30.327-24.673-55-55-55s-55,24.673-55,55c0,9.724,2.547,18.859,6.993,26.794l-61.445,61.444
           c-7.935-4.446-17.071-6.993-26.795-6.993c-30.327,0-55,24.673-55,55s24.673,55,55,55s55-24.673,55-55
           c0-9.724-2.547-18.859-6.993-26.793l61.445-61.444c7.934,4.446,17.07,6.993,26.794,6.993c9.724,0,18.859-2.547,26.794-6.993
           l27.707,27.707c-4.446,7.934-6.993,17.07-6.993,26.794c0,30.327,24.673,55,55,55s55-24.673,55-55c0-9.724-2.547-18.86-6.993-26.794
           l61.444-61.444c7.935,4.446,17.07,6.993,26.794,6.993c30.327,0,55-24.673,55-55S460.327,102.509,430,102.509z M55,352.491
           c-13.785,0-25-11.215-25-25s11.215-25,25-25s25,11.215,25,25S68.785,352.491,55,352.491z M191.247,216.247
           c-13.785,0-25-11.215-25-25s11.215-25,25-25s25,11.215,25,25S205.032,216.247,191.247,216.247z M293.755,318.754
           c-13.785,0-25-11.215-25-25s11.215-25,25-25s25,11.215,25,25S307.54,318.754,293.755,318.754z M430,182.509
           c-13.785,0-25-11.215-25-25s11.215-25,25-25s25,11.215,25,25S443.785,182.509,430,182.509z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
