import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Cookies from "js-cookie";
import { EVENT_LINK_CLICK } from "utils/events";
import { HAS_ALREADY_REGISTERED_TTC } from "utils/constants";
import { StripeApplePay } from "components/StripeApplePay";
import { SupportSettings } from "./settings";
import { Text } from "../Text";
import { registerEvent } from "api/queries";
import styled from "styled-components";

const TextareaContainer = styled(Box)<{ canresize: boolean }>`
  textarea {
    resize: ${({ canresize }) => (canresize ? "vertical" : "none")};
  }
`;

type SupportProps = {
  description?: string;
  backgroundColor?: string;
  color?: string;
  fontFamily?: string;
  buttonText?: string;
  textComponent?: any;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
  textAlign?: string;
  hasDescription?: boolean;
};

export const Support: UserComponent<SupportProps> = (props: any) => {
  const {
    nodeId,
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
  }));

  const { username } = useParams();

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const [pageLoadedDate, setPageLoadedDate] = useState(undefined);

  const {
    isOpen: isPaymentModalOpen,
    onOpen: onPaymentModalOpen,
    onClose: onPaymentModalClose,
  } = useDisclosure();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    buttonText,
    description,
    backgroundColor,
    textComponent,
    color,
    buttonStyle,
    alignIcon,
    fontFamily,
    fontWeight,
    fontSize,
    marginTop,
    marginBottom,
    textAlign,
    hasDescription,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...otherProps
  } = props;

  useEffect(() => {
    if (username) {
      setPageLoadedDate(new Date());
    }
  }, [username]);

  const handleOnClick = async () => {
    onPaymentModalOpen();

    if (username) {
      try {
        await handleRegisterLinkClickEvent();
      } catch (error: any) {
        console.error("Error ", error);
      }
    }
  };

  const handleRegisterLinkClickEvent = async () => {
    const cookieHasAlreadyRegisteredLinkTTC = Cookies.get(
      HAS_ALREADY_REGISTERED_TTC
    );

    let secondsTimeToClick = 0;

    if (
      !cookieHasAlreadyRegisteredLinkTTC ||
      cookieHasAlreadyRegisteredLinkTTC === "false"
    ) {
      let clickDate = new Date();

      secondsTimeToClick =
        (clickDate.getTime() - pageLoadedDate.getTime()) / 1000;
    }

    try {
      await registerEvent({
        username,
        eventType: EVENT_LINK_CLICK,
        linkInfo: {
          linkId: nodeId ?? "",
          name: buttonText,
        },
        timeToClick:
          cookieHasAlreadyRegisteredLinkTTC === "true" ? 0 : secondsTimeToClick,
      });

      Cookies.set(HAS_ALREADY_REGISTERED_TTC, "true"); // session cookie
    } catch (error) {
      console.log("Err event ", error);
    }
  };

  return (
    <Box width="100%" ref={(ref) => connect(ref)}>
      <Box
        width="100%"
        my="0.5rem"
        backgroundColor={backgroundColor ?? "transparent"}
        p="0.5rem"
        borderRadius="0.5rem"
        mt={`${marginTop}px !important`}
        mb={`${marginBottom}px !important`}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          pb={backgroundColor ? "0.75rem" : "0.25rem"}
        >
          {hasDescription && description !== "" && (
            <TextareaContainer
              canresize={enabled ? 1 : 0}
              p="0.5rem"
              fontFamily={`${fontFamily} !important`}
              width="100%"
            >
              <Text
                {...textComponent}
                text={description}
                color={props.color}
                fontSize={fontSize}
                fontWeight={fontWeight}
                textAlign={textAlign}
                useTextArea
              />
            </TextareaContainer>
          )}

          <Box
            p={{
              base: "0rem",
              md: `0rem ${isEditorView ? "0.25rem" : "3rem"}`,
            }}
            fontFamily={`${fontFamily} !important`}
            width="100%"
          >
            <Button
              fontFamily={`${fontFamily} !important`}
              width="100%"
              height={"48px"}
              fontWeight={fontWeight}
              colorScheme="blackAlpha"
              onClick={!enabled ? handleOnClick : () => {}}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>

        <Modal
          onClose={onPaymentModalClose}
          isOpen={isPaymentModalOpen}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            bg="#16151a"
            color="#fbfbfe"
            height={{ base: "inherit", md: "auto" }}
          >
            <ModalCloseButton />
            <ModalHeader fontSize="lg">{`Support ${
              username ?? ""
            }`}</ModalHeader>

            <ModalBody
              textAlign="left"
              display="flex"
              justifyContent="left"
              flexDirection="column"
              overflow="auto"
            >
              {isPaymentModalOpen && (
                <StripeApplePay closeModal={onPaymentModalClose} />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

Support.craft = {
  displayName: "Support",
  props: {
    color: "#fff",
    backgroundColor: "",
    buttonText: "Buy me a coffee ☕️",
    description: "Support my work..",
    fontSize: "14px",
    hasDescription: false,
    fontFamily: "",
    marginTop: "4",
    marginBottom: "4",
    textAlign: "center",
    textComponent: {
      ...Text.craft.props,
      textAlign: "center",
    },
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: SupportSettings,
  },
};
