export const TwitchIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "48px",
        height: "48px",
      }}
    >
      <circle cx="256" cy="256" fill="#6441A5" id="ellipse" r="256" />
      <path
        d="M256,189.5V273h-27.9v-83.5H256z M332.5,189.5V273h-27.9v-83.5H332.5z M332.5,335.6l48.7-48.8  V133.9H151.6v201.7h62.7v41.7l41.7-41.7L332.5,335.6L332.5,335.6z M409.1,106v194.8l-83.5,83.5h-62.7L221.2,426h-41.7v-41.7H103  V161.6l21-55.6C124,106,409.1,106,409.1,106z"
        fill="#FFFFFF"
        id="logo"
      />
    </svg>
  );
};
