export const VerifiedBadge = () => {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 3868.4 2500"
      fill="#1DA1F2"
    >
      <path
        d="M3184.2,1434.6c0,87.4-21,168.5-63,242.7c-42,74.2-98.1,132.3-168.9,172.9c2,13.2,2.9,33.7,2.9,61.5
       c0,132.3-44.4,244.6-132.3,337.4c-88.4,93.3-194.8,139.6-319.3,139.6c-55.7,0-108.9-10.3-159.2-30.8
       c-39.1,80.1-95.2,144.5-168.9,193.8c-73.2,49.8-153.8,74.2-241.2,74.2c-89.4,0-170.4-23.9-242.7-72.8
       c-72.8-48.3-128.4-113.3-167.5-195.3c-50.3,20.5-103,30.8-159.2,30.8c-124.5,0-231.4-46.4-320.8-139.6
       c-89.4-92.8-133.8-205.6-133.8-337.4c0-14.6,2-35.2,5.4-61.5c-70.8-41-127-98.6-168.9-172.9c-41.5-74.2-62.5-155.3-62.5-242.7
       c0-92.8,23.4-178.2,69.8-255.4s108.9-134.3,187-171.4c-20.5-55.7-30.8-111.8-30.8-167.5c0-131.8,44.4-244.6,133.8-337.4
       s196.3-139.6,320.8-139.6c55.7,0,108.9,10.3,159.2,30.8c39.1-80.1,95.2-144.5,168.9-193.8c73.2-49.3,153.8-74.2,241.2-74.2
       c87.4,0,168,24.9,241.2,73.7c73.2,49.3,129.9,113.8,168.9,193.8c50.3-20.5,103-30.8,159.2-30.8c124.5,0,231,46.4,319.3,139.6
       s132.3,205.6,132.3,337.4c0,61.5-9.3,117.2-27.8,167.5c78.1,37.1,140.6,94.2,187,171.4C3160.8,1256.4,3184.2,1341.8,3184.2,1434.6
       L3184.2,1434.6z M1881,1811.1l516.1-772.9c13.2-20.5,17.1-43,12.7-66.9c-4.9-23.9-17.1-43-37.6-55.7c-20.5-13.2-43-17.6-66.9-14.2
       c-24.4,3.9-43.9,15.6-58.6,36.1l-454.6,683.6l-209.5-209c-18.6-18.6-40-27.3-64-26.4c-24.4,1-45.4,9.8-64,26.4
       c-16.6,16.6-24.9,37.6-24.9,63c0,24.9,8.3,45.9,24.9,63l287.6,287.6l14.2,11.2c16.6,11.2,33.7,16.6,50.3,16.6
       C1839.5,1853.1,1864.4,1839.4,1881,1811.1L1881,1811.1z"
      />
    </svg>
  );
};
