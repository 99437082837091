import { Box, Spinner, Text, Link } from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";

// import ReactPlayer from "react-player";
import { ImageSettings } from "./settings";
// import { getTikTokEmbed } from "api/queries";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
// import { useQuery } from "react-query";
// import { useState } from "react";

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { EVENT_LINK_CLICK } from "utils/events";
import { HAS_ALREADY_REGISTERED_TTC } from "utils/constants";
import { registerEvent } from "api/queries";

const StyledImageBox = styled(Box)<{ url: string }>`
  background-image: ${({ url }) => `url(${url})`} !important;

  display: block;
  position: relative;
  width: 100%;
  height: 33vh;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border-radius: 12px;

  :hover {
    transform: scale(1.01, 1.02);
  }
  transition: all 0.2s ease;

  @media (max-width: 414px) {
    background-position-x: 40%;
  } ;;
`;

const StyledImageURLBox = styled(Link)<{ url: string }>`
  background-image: ${({ url }) => `url(${url})`} !important;

  display: block;
  position: relative;
  width: 100%;
  height: 33vh;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border-radius: 12px;

  :hover {
    transform: scale(1.01, 1.02);
  }
  transition: all 0.2s ease;

  @media (max-width: 414px) {
    background-position-x: 40%;
  } ;;
`;


type ButtonProps = {
  url?: any;
  linkUrl?: any,
  autoplayVideo?: boolean;
  muteVideo?: boolean;
};

export const Image: UserComponent<ButtonProps> = (props: any) => {
  const { username } = useParams();

  const [pageLoadedDate, setPageLoadedDate] = useState(undefined);
  useEffect(() => {
    if (username) {
      setPageLoadedDate(new Date());
    }
  }, [username]);

  useEffect(() => {
    const onbeforeunloadFn = () => {
      Cookies.remove(HAS_ALREADY_REGISTERED_TTC);
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
  }, []);

  const {
    nodeId,
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
  }));

  // const { url, autoplayVideo, muteVideo, linkUrl } = props;
  const { url, linkUrl } = props;

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor") || location.pathname.includes("/adeditor");

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const handleOnClick = async() => {

    if (username) {
      try {
        await handleRegisterLinkClickEvent();
      } catch (error: any) {
        console.error("Error ", error);
      }
    }
  };

  const handleRegisterLinkClickEvent = async () => {
    if (!linkUrl) {
      return false;
    }

    const cookieHasAlreadyRegisteredLinkTTC = Cookies.get(
      HAS_ALREADY_REGISTERED_TTC
    );

    let secondsTimeToClick = 0;
    if (
      !cookieHasAlreadyRegisteredLinkTTC ||
      cookieHasAlreadyRegisteredLinkTTC === "false"
    ) {
      let clickDate = new Date();

      secondsTimeToClick =
        (clickDate.getTime() - pageLoadedDate.getTime()) / 1000;
    }

    try {
      await registerEvent({
        username,
        eventType: EVENT_LINK_CLICK,
        linkInfo: {
          linkId: nodeId ?? "",
          name: linkUrl,
          linkUrl: linkUrl,
        },
        timeToClick:
          cookieHasAlreadyRegisteredLinkTTC === "true" ? 0 : secondsTimeToClick,
      });

      Cookies.set(HAS_ALREADY_REGISTERED_TTC, "true"); // session cookie
    } catch (error) {
      console.log("Err event ", error);
    }
  };

  const renderComponent = () => {
    let isImageUrl = true;

    if (isImageUrl) {
      if (linkUrl) {
        return (
          <StyledImageURLBox
            url={url}
            href={linkUrl}
            onClick={handleOnClick}
            isExternal={true}
            fallback={<Spinner />}
          />
        );
      }

      return (
        <StyledImageBox
          url={url}
          fallback={<Spinner />}
        />
      );
    }

    return enabled ? (
      <Text
        style={{
          fontSize: "1rem",
          color: "#f74949",
          cursor: "pointer",
          textShadow: "0px 0px 10px #1a1a1a",
        }}
        textAlign="center"
      >
        <i className="uil uil-exclamation-triangle" /> Invalid image URL
      </Text>
    ) : null;
  };

  const renderHelpLabel = () => {
    if (enabled && isEditorView && !url) {
      return (
        <Text
          p="1rem"
          my="1rem"
          fontSize="xs"
          textAlign="center"
          textShadow="0 1px 5px black"
          style={{
            color: "#eee",
            cursor: "pointer",
          }}
        >
          <i
            className="uil uil-exclamation-triangle"
            style={{
              color: "#f74949",
            }}
          />{" "}
          Image URL missing
        </Text>
      );
    }

    if (enabled && isEditorView && url) {
      return (
        <Text
          py="0.25rem"
          fontSize="xs"
          style={{
            color: "#eee",
            cursor: "pointer",
            textShadow: "0px 0px 10px #000000",
          }}
          textAlign="center"
        >
          Edit image URL
        </Text>
      );
    }

    return null;
  };

  return (
    <Box ref={(ref) => connect(ref)} textAlign="center" width="100%">
      {url && (
        <Box
          borderRadius="8x"
          width="100%"
          pointerEvents={enabled ? "none" : "auto"}
          //px="1.5rem"
          mb="0.75rem"
        >
          {renderComponent()}
        </Box>
      )}

      {renderHelpLabel()}
    </Box>
  );
};

Image.craft = {
  displayName: "Image",

  props: {
    url: "",

    autoplayVideo: false,
    muteVideo: true,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: ImageSettings,
  },
};
