import { Box, Heading, Text } from "@chakra-ui/react";

import { Helmet } from "react-helmet";
import Page from "components/Page";
import useScript from "hooks/useScript";

export const JobsScreen = () => {
  useScript(
    "https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImVuIiwiam9ic1BlclBhZ2UiOjI1fSwiam9icyI6e30sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsImZpbHRlckJvcmRlciI6IiNENEQ0RDgiLCJwYWdpbmF0aW9uIjoiIzI1NjNFQiJ9LCJqb2JDYXJkIjp7InNoYWRvdyI6IiNENEQ0RDgiLCJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsInByaW1hcnlUZXh0IjoiIzNGM0Y0NiIsInNlY29uZGFyeVRleHQiOiIjNTI1MjVCIn19fSwidmVyc2lvbiI6MiwiY29tcGFueVB1YmxpY0lkIjoiMzg0MDQzYThiMDM2OWMyNzdhMjc1NGZhMzA4ZmZlMzUiLCJpYXQiOjE2NTEwNjgzNjYsImp0aSI6IjJiN2VlOTdhLThkNGUtNDlmOC04NzA5LTdiMTdiZDYzOTU1YSJ9.lDK-0tFUe_goAXwg3JRxP-9WkF20epEruYK9C4ishaw"
  );

  return (
    <>
      <Helmet>
        <title>Careers | Alfalink</title>
      </Helmet>
      <Page>
        <Box
          p={{ base: "4rem 1rem", md: "6rem 9rem" }}
          my="1rem"
          background="linear-gradient(344deg, #8748f3 0%, #bf61f9 100%)"
          borderRadius="10px"
        >
          <Box>
            <Heading as="h2" size="xl" textAlign="center" color="#ffffff">
              Hi, come work with us!
            </Heading>
          </Box>
        </Box>
        <div id="join-widget" />

        <Box
          p={{ base: "3rem 1rem", md: "5rem 9rem" }}
          fontSize="sm"
          color="gray.600"
        >
          <Text textAlign={{ base: "left", md: "center" }} fontWeight="medium">
            Can't find the job you're looking for?
          </Text>

          <Text fontSize="xs">
            We are still happy to work with you if you are interested in
            connecting people, available at least 10 hours a week, and are at
            least 18 years old.
          </Text>
          <br />
          <Text fontSize="xs">
            Feel free to drop us an email at{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:aras@alfalink.co"
            >
              aras@alfalink.co
            </a>{" "}
            describing what you would like to work with.
          </Text>
        </Box>
      </Page>
    </>
  );
};
