import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import {
  Box,
  Button,
  Fade as CFade,
  Flex,
  Heading,
  Image,
  Link,
  SlideFade,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import GradientText from "components/GradientText";
import UilAngleRight from "@iconscout/react-unicons/icons/uil-angle-right";
import styled from "styled-components";
import { cloudfrontURL } from "utils/urlBuilder.js";

const CardBox = styled(Box)<{ selected?: boolean }>`
  border-radius: 6px;
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  transition: all 400ms ease 0s;
  min-width: 200px;

  :last-child {
    margin-right: 2rem;
  }

  :hover {
    h4 {
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  :hover {
    svg {
      transition: all 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translateX(3px);
    }
  }
`;

const FeatureList = [
  {
    name: "customization",
    title: "Customization Freedom",
    description:
      "Freely customize and personalize your links and profile to best represent you",
  },
  {
    name: "analytics",
    title: "Insights & Analytics",
    description:
      "Get insights on profile visitors and performance of your links",
  },

  {
    name: "donation",
    title: "Collect Payments",
    description:
      "Collect tips and supportive donations smoothly with Apple Pay, Google Pay or Credit Card",
  },

  {
    name: "embed",
    title: "Embed Content",
    description:
      "Place playable video, music, or podcast links in your profile",
  },

  {
    name: "linkGuards",
    title: "Link Guards",
    description:
      "Password protect your links or alert visitors about link content",
  },
];

const awsFileLinks = {
  customizationP1: cloudfrontURL("/customization1.png"),
  customizationP2: cloudfrontURL("/customization2.png"),
  customizationP3: cloudfrontURL("/customization3.png"),

  donation: cloudfrontURL("/collectpayment21219.png"),

  embed: cloudfrontURL("/embed65875.png"),

  analytics: cloudfrontURL("/analyticspage3.png"),

  linkGuards1: cloudfrontURL("/linkGuards1.png"),
  linkGuards2: cloudfrontURL("/linkGuards2.png"),
};

const customizationImages = [
  "customization",
  "embed",
  "linkGuards",
  "analytics",
];

const Features = () => {
  const [selectedFeat, setSelectedFeat] = useState("customization");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasUserClicked, setHasUserClicked] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === customizationImages.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  useEffect(() => {
    if (!hasUserClicked) {
      setSelectedFeat(customizationImages[currentIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const handleFeatureItemClick = (feature: string) => {
    setSelectedFeat(feature);
    setHasUserClicked(true);
  };
  const featureMap = () => {
    switch (selectedFeat) {
      case "customization":
        return (
          <>
            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              autoPlay={true}
              stopOnHover={false}
              infiniteLoop
              animationHandler="fade"
              transitionTime={400}
              interval={2400}
              swipeable={false}
            >
              <div>
                <Image
                  src={awsFileLinks.customizationP1}
                  alt="Customization demo page 1"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.customizationP2}
                  alt="Customization demo page 2"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.customizationP3}
                  alt="Customization demo page 3"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.linkGuards1}
                  alt="Customization demo page 4"
                />
              </div>
            </Carousel>
            <Box textAlign="center" fontStyle="italic" fontSize="xs">
              <Link href="/demo1" isExternal>
                Visit this demo profile →
              </Link>
            </Box>
          </>
        );

      case "embed":
        return (
          <>
            <Image src={awsFileLinks.embed} alt="Embed demo page 1" />
            <Box textAlign="center" fontStyle="italic" fontSize="xs">
              <Link href="/demo2" isExternal>
                Visit this demo profile →
              </Link>
            </Box>
          </>
        );

      case "analytics":
        return (
          <Image src={awsFileLinks.analytics} alt="Analytics demo page 1" />
        );

      case "donation":
        return <Image src={awsFileLinks.donation} alt="Donation demo page 1" />;

      case "linkGuards":
        return (
          <>
            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              autoPlay={true}
              stopOnHover={false}
              infiniteLoop
              animationHandler="fade"
              transitionTime={400}
              interval={3000}
              swipeable={false}
            >
              <div>
                <Image
                  src={awsFileLinks.linkGuards1}
                  alt="Link guard demo page 1"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.linkGuards2}
                  alt="Link guard demo page 2"
                />
              </div>
            </Carousel>

            <Box textAlign="center" fontStyle="italic" fontSize="xs">
              <Link href="/demo3" isExternal>
                Visit this demo profile →
              </Link>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        fontSize="2rem"
        my="4rem"
      >
        <Box
          lineHeight="1"
          textAlign="center"
          justifyContent={{ base: "left", md: "center" }}
          alignItems="center"
        >
          <GradientText
            fontSize={{ base: "2rem", md: "2.5rem" }}
            text=" Powerful features"
          />

          <Text
            fontSize={{ base: "0.825rem", md: "1rem" }}
            fontWeight="900"
            color="gray.700"
          >
            to help you get the best out of your links
          </Text>
        </Box>
      </Flex>

      {isLargerThan768 ? (
        <Box display="flex" flexDirection="row" mt="2.5rem">
          <Stack
            spacing="1rem"
            height="160px"
            display="flex"
            flexDirection="column"
            pr="1rem"
            alignItems="flex-start"
            minHeight="350px"
            width="40%"
          >
            {FeatureList.map((f) => (
              <CardBox
                key={f.name}
                as="button"
                selected={selectedFeat === f.name}
                onClick={() => handleFeatureItemClick(f.name)}
              >
                <Stack spacing="0.5rem" p="1rem 0.5rem">
                  <Box display="flex" alignItems="center">
                    {selectedFeat !== f.name ? (
                      <Box
                        width="6px"
                        height="6px"
                        bg="#da34d3"
                        mr="1.5rem"
                        borderRadius="50px"
                        opacity="0.6"
                      />
                    ) : (
                      <Box
                        width="6px"
                        height="34px"
                        bg="#da34d3"
                        mr="1.5rem"
                        borderRadius="6px"
                        transition="all 300ms ease-in-out"
                      />
                    )}
                    <Heading as="h4" fontSize="xl">
                      {f.title}
                    </Heading>
                  </Box>

                  {selectedFeat === f.name && (
                    <CFade in={selectedFeat === f.name}>
                      <Text fontSize="sm" color="gray.500" ml="2rem">
                        {f.description}
                      </Text>
                    </CFade>
                  )}
                </Stack>
              </CardBox>
            ))}
          </Stack>

          <Box width="60%" height="100%" minHeight="400px" py="0rem" pr="1rem">
            {FeatureList.map(
              (f) =>
                selectedFeat === f.name && (
                  <SlideFade
                    key={f.name}
                    offsetY="20px"
                    in={selectedFeat === f.name}
                  >
                    {featureMap()}
                  </SlideFade>
                )
            )}
          </Box>
        </Box>
      ) : (
        <VStack spacing="5rem">
          <Box m="0 auto">
            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              autoPlay={true}
              stopOnHover={false}
              infiniteLoop
              animationHandler="fade"
              transitionTime={400}
              interval={2400}
              swipeable={false}
            >
              <div>
                <Image
                  src={awsFileLinks.customizationP1}
                  alt="Customization Demo page 1"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.customizationP2}
                  alt="Customization Demo page 2"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.customizationP3}
                  alt="Customization Demo page 3"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.linkGuards1}
                  alt="Customization Demo page 4"
                />
              </div>
            </Carousel>
            <Box
              textAlign="center"
              fontStyle="italic"
              fontSize="xs"
              my="0.5rem"
            >
              <Link href="/demo1" isExternal>
                Visit this demo profile →
              </Link>
            </Box>

            <Box py="0.5rem">
              <Heading as="h2" fontSize="1.5rem" letterSpacing="-0.5px">
                Customization Freedom
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Freely customize and personalize your links and profile to stand
                out
              </Text>
            </Box>
          </Box>

          <Box>
            <Image src={awsFileLinks.analytics} alt="Analytics feature" />

            <Box py="0.5rem">
              <Heading as="h2" fontSize="1.5rem" letterSpacing="-0.5px">
                Insights & Analytics
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Get insights on profile visitors and performance of your links
              </Text>
            </Box>
          </Box>

          <Box>
            <Image src={awsFileLinks.donation} alt="Donation feature" />

            <Box py="0.5rem">
              <Heading as="h2" fontSize="1.5rem" letterSpacing="-0.5px">
                Collect Payments
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Collect tips and supportive donations smoothly with Apple Pay,
                Google Pay or Credit Card
              </Text>
            </Box>
          </Box>

          <Box>
            <Image src={awsFileLinks.embed} alt="Embed content feature" />
            <Box
              textAlign="center"
              fontStyle="italic"
              fontSize="xs"
              my="0.5rem"
            >
              <Link href="/demo2" isExternal>
                Visit this demo profile →
              </Link>
            </Box>

            <Box py="0.5rem">
              <Heading as="h2" fontSize="1.5rem" letterSpacing="-0.5px">
                Embed content
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Place playable video, music, or podcast links in your profile
              </Text>
            </Box>
          </Box>

          <Box>
            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              autoPlay={true}
              stopOnHover={false}
              infiniteLoop
              animationHandler="fade"
              transitionTime={400}
              interval={3000}
              swipeable={false}
            >
              <div>
                <Image
                  src={awsFileLinks.linkGuards1}
                  alt="Link guard demo page 1"
                />
              </div>
              <div>
                <Image
                  src={awsFileLinks.linkGuards2}
                  alt="Link guard demo page 2"
                />
              </div>
            </Carousel>

            <Box
              textAlign="center"
              fontStyle="italic"
              fontSize="xs"
              my="0.5rem"
            >
              <Link href="/demo3" isExternal>
                Visit this demo profile →
              </Link>
            </Box>

            <Box py="0.5rem">
              <Heading as="h2" fontSize="1.5rem" letterSpacing="-0.5px">
                Link Guards
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Password protect your links or alert visitors about link content
              </Text>
            </Box>
          </Box>
        </VStack>
      )}

      <Box my="3rem" textAlign="center">
        <StyledButton
          size="lg"
          type="submit"
          variant="outline"
          rounded="full"
          p="1.5rem"
        >
          <a href="/in/features">See more features</a>
          <UilAngleRight />
        </StyledButton>
      </Box>
    </>
  );
};

export default Features;
