import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import styled, { keyframes } from "styled-components";

import GetStartedInput from "components/GetStartedInput";
import Lottie from "react-lottie";
import arrow from "assets/jsons/arrow.json";
import arrowUp from "assets/jsons/arrowUp.json";

const flow = keyframes`
0% {
background-position: 0 50%;
}

50% {
    background-position: 100% 50%;
}

100% {
    background-position: 0 50%
}

`;

const StyledText = styled(Text)`
  background-color: #111;
  background-image: linear-gradient(-45deg, #c30ad4, #e200ad, #c50786, #c705c0);
  background-size: 400%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  animation: ${flow} 8s ease-in-out infinite;

  text-shadow: 0px 0px 4px rgb(0 0 0 / 4%);

  animation-iteration-count: 6;
  font-family: "Lexend" !important;
  font-weight: 900;
`;

const defaultOptions = {
  loop: true,

  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: arrow,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultArrowUpOptions = {
  loop: true,

  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: arrowUp,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Hero = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");

  return (
    <Box textAlign="center">
      <Box
        position="relative"
        letterSpacing="-6px"
        fontWeight="900"
        fontSize={{
          base: "4rem",
          md: "5rem",
          lg: "6rem",
        }}
        color="#111"
        mb={{ base: "1rem", md: "2rem" }}
      >
        <StyledText
          as="h1"
          fontSize={{ base: "4.25rem", md: "4.75rem", lg: "5.5rem" }}
        >
          One Link,
        </StyledText>
        <Text
          fontSize={{
            base: "3.58rem",
            sm: "4rem",
            md: "5rem",
            lg: "6rem",
          }}
        >
          for everything
        </Text>

        <Text>you have online.</Text>
      </Box>

      {isSmallerThan600 ? (
        <>
          <GetStartedInput showInput />

          <Box mt="0.75rem" pl="1.75rem">
            <Lottie options={defaultArrowUpOptions} height={70} width={120} />
          </Box>
        </>
      ) : (
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            margin="auto"
            position="absolute"
            pr={{ base: "20rem", sm: "24rem", md: "29rem" }}
            pt={{ base: "2rem", sm: "3rem", md: "2.5rem" }}
          >
            <Lottie
              options={defaultOptions}
              height={isSmallerThan768 ? 90 : 130}
              width={isSmallerThan768 ? 180 : 250}
            />
          </Box>
          <GetStartedInput showInput />
        </Box>
      )}
    </Box>
  );
};

export default Hero;
