import { createContext, useContext } from "react";

import useUserData from "hooks/useUserData";

const defaultState = null;

const UserContext = createContext(defaultState);

export const UserProvider = ({ children }) => {
  const {
    userData,
    loading: loadingUserData,
    error: userDataError,
  } = useUserData();

  const contextValues = {
    // Memoize values in an ideal case to avoid re-rendering.
    userData,
    loadingUserData,
    userDataError,
  };

  return (
    <UserContext.Provider value={contextValues}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined || context === null) {
    throw new Error(`useUserContext must be called within UserProvider`);
  }
  return context;
};
