import { Input, Text, Textarea } from "@chakra-ui/react";

import { useState } from "react";

export type ToolbarTextInput = {
  isUrlInput?: boolean;
  label?: string;
  type: string;
  onChange?: (value: any) => void;
  value?: any;
  required?: boolean;
  placeholder?: string;
  textAreaLength?: number;
  inputMaxLength?: number;
};
export const SettingsTextInputItem = ({
  onChange,
  textAreaLength,
  inputMaxLength,
  value,
  isUrlInput,
  label,
  placeholder,
  type,
  required,
  ...props
}: ToolbarTextInput) => {
  const [isUrlInvalid, setIsUrlInvalid] = useState(undefined);

  const checkUrlValidation = () => {
    var pattern =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    return !!pattern.test(value)
      ? setIsUrlInvalid(false)
      : setIsUrlInvalid(true);
  };

  const handleOnTextChange = (e: any) => {
    onChange((e.target as any).value);
  };

  return (
    <div>
      {type === "textArea" ? (
        <>
          <Text mb="4px" color="gray.500">
            {label}
          </Text>

          <Textarea
            maxLength={textAreaLength}
            resize="none"
            style={{ margin: 0, width: "100%" }}
            value={value || ""}
            required={required}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onChange((e.target as any).value);
              }
            }}
            onChange={handleOnTextChange}
            {...props}
          />

          <Text
            textAlign="right"
            fontSize="0.75rem"
            py="0.5rem"
            color="gray.500"
          >
            Characters remaining: {textAreaLength - value.length}
          </Text>
        </>
      ) : (
        <>
          <Text mb="4px" color="gray.500">
            {label}
          </Text>

          <Input
            label={label}
            placeholder={placeholder}
            style={{ margin: 0, width: "100%" }}
            value={value || ""}
            type={type}
            step="0.1"
            maxLength={inputMaxLength}
            min="1"
            max="999"
            onBlur={isUrlInput ? checkUrlValidation : null}
            required={required}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onChange((e.target as any).value);
              }
            }}
            onChange={handleOnTextChange}
            {...props}
          />

          {isUrlInput && value !== "" && !!isUrlInvalid && (
            <Text m="4px" fontSize="xs" color="red.500">
              hmm, the link URL doesn't seem to be valid, please double check.
            </Text>
          )}
        </>
      )}
    </div>
  );
};
