export const FacebookIcon = () => {
  return (
    <svg
      viewBox="0 0 60 60"
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
      className="style-scope yt-icon"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "48px",
        height: "48px",
      }}
    >
      <g className="style-scope yt-icon">
        <g fill="none" fillRule="evenodd" className="style-scope yt-icon">
          <path
            d="M28.4863253 59.9692983c-6.6364044-.569063-11.5630204-2.3269561-16.3219736-5.8239327C4.44376366 48.4721168 3e-7 39.6467924 3e-7 29.9869344c0-14.8753747 10.506778-27.18854591 25.2744118-29.61975392 6.0281072-.9924119 12.7038532.04926445 18.2879399 2.85362966C57.1386273 10.0389054 63.3436516 25.7618627 58.2050229 40.3239688 54.677067 50.3216743 45.4153135 57.9417536 34.81395 59.5689067c-2.0856252.3201125-5.0651487.5086456-6.3276247.4003916z"
            fill="#3B5998"
            fillRule="nonzero"
            className="style-scope yt-icon"
          ></path>
          <path
            d="M25.7305108 45h5.4583577V30.0073333h4.0947673l.8098295-4.6846666h-4.9045968V21.928c0-1.0943333.7076019-2.2433333 1.7188899-2.2433333h2.7874519V15h-3.4161354v.021c-5.3451414.194-6.4433395 3.2896667-6.5385744 6.5413333h-.0099897v3.7603334H23v4.6846666h2.7305108V45z"
            fill="#FFF"
            className="style-scope yt-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
};
