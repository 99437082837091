import {
  Box,
  Flex,
  HStack,
  Link,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import { getTotalLifetimeCounts } from "api/queries";
import { marginX } from "utils/constants";
import styled from "styled-components";
import { useQuery } from "react-query";

export const StyledSpan = styled(Text)<{
  dotcolor: string;
  counterStyle?: boolean;
}>`
  ::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    background-color: ${({ dotcolor }) => dotcolor || "green"};
  }

  & b {
    ${({ counterStyle }) =>
      counterStyle &&
      `
        text-align: center;
        display: block;
      `};
  }
`;

export const AnalyticsHeader = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const {
    data: totalLifetimeCounts,
    error: totalLifetimeCountsError,
    isLoading: isLoadingTotalLifetimeCounts,
  } = useQuery("getTotalLifetimeCounts", getTotalLifetimeCounts);

  if (isLoadingTotalLifetimeCounts || totalLifetimeCounts === undefined) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        borderBottom="1px solid #eee"
        height={{ base: "auto", md: "2.5rem" }}
      >
        <Spinner colorScheme="brand" size="sm" />
      </Flex>
    );
  }

  if (totalLifetimeCountsError) {
    return null;
  }

  return (
    <Box borderBottom="1px solid #eee">
      <Box
        height={{ base: "auto", md: "2.5rem" }}
        mx={marginX}
        px={{ base: "1rem", md: "0rem" }}
        display="flex"
        //flexDirection={isSmallerThan600 ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          height={{ base: "24px", md: "52px" }}
          alignItems="center"
          fontSize={{ base: "0.75rem", md: "0.875rem" }}
        >
          {!isSmallerThan600 && <Text mr="0.75rem">Analytics overview:</Text>}
          <HStack spacing="1rem">
            <StyledSpan dotcolor="#1c72e8">
              Total {isSmallerThan600 ? "" : "profile"} views:{" "}
              <b>
                {totalLifetimeCounts.data.pageViews.total ??
                  totalLifetimeCounts.data.pageViews}
              </b>
            </StyledSpan>
            <StyledSpan dotcolor="#33a852">
              Total link clicks:{" "}
              <b>
                {totalLifetimeCounts.data.linkClicks.total ??
                  totalLifetimeCounts.data.linkClicks}
              </b>
            </StyledSpan>
          </HStack>
        </Box>

        {
          <Link href="/in/analytics">
            <UilArrowRight size="1.5rem" />
          </Link>
        }
      </Box>
    </Box>
  );
};
