import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AlertMessage from "components/AlertMessage";
import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import Cookies from "js-cookie";
import { FingerprintGirlSVG } from "assets/svgs/fingerprintSVG";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { TEMP_TEMPLATE } from "utils/constants";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
import UilEyeSlash from "@iconscout/react-unicons/icons/uil-eye-slash";
import { UsernameInput } from "components/UsernameInput";
import { getAuthToken } from "utils";
import { useAuth } from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { useUserContext } from "HoCs/UserProvider";
import { yupResolver } from "@hookform/resolvers/yup";

const queryString = require("query-string");

const SignupSchema = yup
  .object({
    email: yup.string().email("Please enter a valid email.").required(),
    username: yup
      .string()
      .trim()
      .min(1)
      .max(20)
      .matches(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
        "Username can only contain alphanumeric characters, underscores (_) and non starting, ending or consecutive dots(.)"
      )
      .required(),
    password: yup.string().min(6).max(32).required(),
  })
  .required();

const SignUpScreen = () => {
  const history = useHistory();
  const location = useLocation();

  const { userData } = useUserContext();

  const { username } = queryString.parse(location.search);

  const { signUp, error, loading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(SignupSchema),
  });

  const onSubmitHandler = (data) => {
    const { email, username, password } = data;

    const temp_template = Cookies.get(TEMP_TEMPLATE);

    signUp({
      email: email,
      username: username,
      password: password,
      frameData: temp_template ? temp_template : null,
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const token = getAuthToken();

    if (token && userData?.frameData) {
      history.push("/in/editor");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken(), userData]);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <Helmet>
        <title>Sign up | Alfalink</title>
      </Helmet>

      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>

        <Flex
          width="full"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          pt={{ base: "1rem", md: "3rem" }}
        >
          <Box px="0.5rem" textAlign="center">
            <Heading as="h2" size="xl">
              Hi!{" "}
              <span role="img" aria-label="sheep">
                👋
              </span>
            </Heading>
            <Heading as="h3" fontSize={{ base: "lg", md: "3xl" }} my="1rem">
              Create your Alfalink account.
            </Heading>
          </Box>

          <Box
            p={{ base: "1rem", md: "1.5rem" }}
            width="full"
            maxWidth="552px"
            borderRadius={4}
            bg="white"
            borderWidth={{ base: 1, md: 1 }}
            boxShadow="sm"
            mt="1rem"
          >
            <>
              <Box my={4} textAlign="left">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  {error && <AlertMessage status="error" message={error} />}

                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      {...register("email")}
                      type="email"
                      placeholder="example@email.com"
                      size="lg"
                      name="email"
                      required
                    />

                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>

                  <Box mt={6}>
                    <UsernameInput
                      register={register}
                      isInvalid={!!errors.username}
                      error={errors.username?.message}
                      username={username}
                    />
                  </Box>

                  <FormControl mt={6} isInvalid={!!errors.password}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        {...register("password")}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="*******"
                        size="lg"
                        required
                      />
                      <InputRightElement>
                        <Button
                          variant="unstyled"
                          opacity="0.8"
                          top="3px"
                          onClick={handlePasswordVisibility}
                        >
                          {showPassword ? <UilEyeSlash /> : <UilEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Box mt="2rem" color="gray.500">
                    <Text fontSize="0.75rem">
                      By tapping Sign up & Accept, you acknowledge that you have
                      read the{" "}
                      <Link href="/in/privacy" textDecoration="underline">
                        Privacy Policy
                      </Link>{" "}
                      and agree to the{" "}
                      <Link href="/in/terms" textDecoration="underline">
                        Terms of Service.
                      </Link>
                    </Text>
                    <Button
                      variant="solid"
                      size="lg"
                      colorScheme="yellow"
                      type="submit"
                      width="full"
                      my="1.5rem"
                    >
                      {loading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color="teal"
                        />
                      ) : (
                        "Sign up & accept"
                      )}
                    </Button>
                  </Box>

                  <Divider />

                  <Box mt="2rem" display="flex" justifyContent="center">
                    <Text color="gray.600">
                      Already have an account?{" "}
                      <Link href="/in/login">
                        <Text as="u">Sign in</Text>
                      </Link>
                    </Text>
                  </Box>
                </form>
              </Box>
            </>
          </Box>
          <FingerprintGirlSVG />
        </Flex>
      </Page>
    </>
  );
};

export default SignUpScreen;
