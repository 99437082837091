import { Box, Heading, Img, Link, Stack, Text } from "@chakra-ui/react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import React, { useState } from "react";

import Cookies from "js-cookie";
import { EDITOR_TOUR_DESKTOP } from "utils/constants";
import UilArrowMoveDown from "@iconscout/react-unicons/icons/uil-arrow-down";
import UilArrowMoveUp from "@iconscout/react-unicons/icons/uil-arrow-up";
import UilArrowUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilQuestionMark from "@iconscout/react-unicons/icons/uil-question-circle";

import { cloudfrontURL } from "utils/urlBuilder.js";

export const EditorTourGuide = ({ runTour, setRunTour }: any) => {
  interface State {
    steps: Step[];
  }

  const [steps] = useState<State>({
    steps: [
      {
        content: (
          <div style={{ height: "auto", overflow: "auto" }}>
            <div>
              <div style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>
                &#127881;
              </div>

              <Heading as="h4" size="md" color="#111" mb="0.5rem">
                Welcome!
              </Heading>
            </div>

            <Stack
              spacing="2rem"
              textAlign="left"
              color="gray.600"
              fontSize="sm"
            >
              <Text textAlign="center">
                To get a feel of the place, take our on-screen tour by clicking
                'Next'.
              </Text>
            </Stack>
          </div>
        ),

        locale: { skip: <strong aria-label="skip">Skip</strong> },
        placement: "center",
        styles: {
          options: {
            width: "600px",
          },
        },
        hideCloseButton: true,
        disableBeacon: true,

        target: "body",
      },

      {
        content: (
          <>
            <Heading as="h5" size="sm" color="#111">
              Main Container
            </Heading>

            <Stack spacing="0.5rem" mt="0.5rem" textAlign="left">
              <Text color="gray.500" fontSize="xs">
                This is where the components live. Click on a component and
                start customizing it.
                <br />
                You can add & edit background color, image or video background.
                You can also change the font style of all other text elements
                here.
              </Text>

              <Box>
                <Img src={ cloudfrontURL("/Intro+to+alfalink.co.png") } />
              </Box>
            </Stack>

            <Text textAlign="left" color="gray.400" fontSize="xs" mt="1.5rem">
              &#128161; Click on{" "}
              <UilArrowMoveUp
                style={{
                  background: "#111",
                  color: "#eee",
                  display: "inline-block",
                  borderRadius: "2px",
                }}
                size="1rem"
              />{" "}
              <UilArrowMoveDown
                style={{
                  background: "#111",
                  color: "#eee",
                  display: "inline-block",
                  borderRadius: "2px",
                }}
                size="1rem"
              />{" "}
              icons to quickly move the selected component up or down in the
              order.
            </Text>

            <Text textAlign="left" color="gray.400" fontSize="xs" mt="1.5rem">
              &#128161; Click on{" "}
              <UilArrowUp
                style={{
                  background: "#111",
                  color: "#eee",
                  display: "inline-block",
                  borderRadius: "2px",
                }}
                size="1rem"
              />{" "}
              icon to quickly select the container of the selected component.
            </Text>
          </>
        ),

        placement: "auto",
        target: ".main_container_wrapper",
        styles: {
          options: {
            width: "464px",
          },
        },
        hideCloseButton: true,
        disableBeacon: true,
      },

      {
        content: (
          <>
            <Heading as="h5" size="sm" color="#111">
              Settings panel
            </Heading>

            <Stack spacing="0.75rem" mt="1rem" textAlign="left">
              <Text color="gray.500" fontSize="sm">
                This is where you set and customize selected components.
              </Text>

              <Text color="gray.500" fontSize="sm">
                Each component has multiple cutomizable elements. Select a
                component and start editing!
              </Text>
            </Stack>
          </>
        ),
        placement: "right",
        disableScrolling: true,

        styles: {
          options: {
            width: "400px",
          },
        },
        target: ".settings_panel_wrapper",
        hideCloseButton: true,
        disableBeacon: true,
      },

      {
        content: (
          <>
            <Heading as="h5" size="sm" color="#111">
              Add components
            </Heading>

            <Stack spacing="1rem" mt="1rem" textAlign="left">
              <Text color="gray.500" fontSize="sm">
                This is where you can add new links and other components.
              </Text>
              <Text color="gray.400" fontSize="xs">
                &#128161; you can add multiple links at once and style them all
                together from the Links Container.
              </Text>
            </Stack>
          </>
        ),
        styles: {
          options: {
            width: "400px",
          },
        },
        placement: "top-end",
        target: ".add_component_button",
        hideCloseButton: true,
        disableBeacon: true,
      },

      {
        content: (
          <>
            <Heading as="h5" size="sm" color="#111">
              Save & publish button
            </Heading>

            <Text mt="1rem" textAlign="left" color="gray.500" fontSize="sm">
              Once you're done adding links and customizing your profile, click
              here to save & publish your changes for the Internet people to
              see.
            </Text>
          </>
        ),
        styles: {
          options: {
            width: "400px",
          },
        },
        placement: "auto",
        target: ".desktop_publish_button_wrapper",
        hideCloseButton: true,
        disableBeacon: true,
      },

      {
        content: (
          <>
            <div>
              <div style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>
                &#127881;
              </div>

              <Heading as="h4" size="md" color="#d40ace" mb="0.25rem">
                You made it!
              </Heading>
            </div>

            <br />
            <Stack
              spacing="1rem"
              textAlign="left"
              color="gray.500"
              fontSize="sm"
            >
              <Text>
                You can always run this tour again by clicking on the{" "}
                <UilQuestionMark
                  style={{
                    background: "#002c4b",
                    display: "inline-block",
                    borderRadius: "50px",
                    padding: "2px",
                  }}
                  size="1.5rem"
                  color="#d8d8d8"
                />{" "}
                icon down in the right corner.
              </Text>
              <Text color="gray.500" fontSize="sm">
                Let us know about your experience by answering the{" "}
                <strong>Feedback</strong> questionnaire.
                <br />
                Check out our{" "}
                <Link textDecoration="underline" isExternal href="/in/support">
                  Support
                </Link>{" "}
                for further assistance.
              </Text>
            </Stack>

            <Text color="gray.500" fontSize="sm" mt="0.75rem">
              All the best &#9829;
            </Text>
          </>
        ),

        locale: { last: "Finish tour" },
        placement: "center",
        styles: {
          options: {
            width: "auto",
          },
        },
        target: "body",
        hideCloseButton: true,
        disableBeacon: true,
      },
    ],
  });

  const handleTourEnd = () => {
    setRunTour(false);
    Cookies.set(EDITOR_TOUR_DESKTOP, "true", { expires: 1000 });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      handleTourEnd();
    }
  };

  return (
    <>
      <Joyride
        callback={(data) => handleJoyrideCallback(data)}
        continuous={true}
        run={runTour}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps.steps}
        spotlightPadding={0}
        styles={{
          options: {
            zIndex: 20000,
            primaryColor: "#111",
            backgroundColor: "#ffffff",
          },
        }}
      />
    </>
  );
};
