import {
  Alert,
  Box,
  Divider,
  HStack,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";

import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { HelpDropdown } from "components/HelpDropdown";
import UilArrowGrowth from "@iconscout/react-unicons/icons/uil-arrow-growth";
import UilCog from "@iconscout/react-unicons/icons/uil-cog";
import UilExclamation from "@iconscout/react-unicons/icons/uil-exclamation-octagon";
import UilLogout from "@iconscout/react-unicons/icons/uil-sign-out-alt";
import UilPalette from "@iconscout/react-unicons/icons/uil-palette";
import UilUser from "@iconscout/react-unicons/icons/uil-user-circle";
import { marginX } from "utils/constants";
import { sendVerifyEmailRequest } from "api/queries";
import styled from "styled-components";
import { useAuth } from "hooks/useAuth";
import { useMutation } from "react-query";
import { useState } from "react";
import { useUserContext } from "HoCs/UserProvider";

const StyledLink = styled(Link)<{ active?: boolean }>`
  display: flex;
  align-items: center;

  ${({ active }) =>
    active &&
    `
  background: #eef5ff;
  padding: 6px 8px;
  border-radius: 50px;

  `};

  :hover {
    text-decoration: none;
  }
`;

const NavBar = ({ isEditorView }: { isEditorView?: boolean }) => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const [showVerifyEmailBanner, setShowVerifyEmailBanner] = useState(true);
  const { isAuthenticated, logout } = useAuth();
  const toast = useToast();

  const { userData, loadingUserData, userDataError } = useUserContext();

  const { mutateAsync: triggerSendVerifyEmailRequest } = useMutation(
    sendVerifyEmailRequest
  );

  const handleSendEmailVerification = async () => {
    try {
      await triggerSendVerifyEmailRequest();

      toast({
        title: "Email verification request sent",
        description:
          "Please follow the instruction in the email to verify your email.",
        status: "success",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });

      setShowVerifyEmailBanner(false);
    } catch (error) {
      setShowVerifyEmailBanner(false);
      toast({
        title:
          "Failed to send verification request email. Please try again in a moment or contact support",
        status: "error",
        variant: "subtle",
        isClosable: true,
        position: "top",
      });
    }
  };

  const removeAuthTokenAndLogout = () => {
    logout();
  };

  if (loadingUserData) {
    return (
      <Spinner
        emptyColor="gray.100"
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="1rem auto"
      />
    );
  }

  if (userDataError) {
    return (
      <Alert status="error" textAlign="center">
        <UilExclamation style={{ marginRight: "0.5rem" }} />
        There was an error fetching profile info. Please try again in a bit or
        contact support.
      </Alert>
    );
  }

  return (
    <>
      {isAuthenticated &&
        userData &&
        !userData?.emailVerified &&
        !isEditorView &&
        showVerifyEmailBanner && (
          <Box
            textAlign="center"
            py="0.75rem"
            px={{ base: "0.5rem", md: "1rem" }}
            backgroundColor="orange.300"
            fontSize="sm"
          >
            <i className="uil uil-bell is-size-3" />
            Your email is not verified. Please check your inbox or{" "}
            <button
              style={{ textDecoration: "underline" }}
              onClick={handleSendEmailVerification}
            >
              {" "}
              resend verification link
            </button>
            .
          </Box>
        )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={
          isEditorView
            ? { base: "0.5rem 1rem", md: "0.625rem 0rem" }
            : { base: "0.5rem 1rem", md: "0.625rem 0rem" }
        }
        mx={isEditorView ? marginX : marginX}
        borderBottom={isEditorView ? "none" : "1px solid #111"}
        position={{ base: "relative", md: "unset" }}
        backgroundColor="#ffffff"
        zIndex={1115}
      >
        <AnimatedGradientLogo
          fontSize={isSmallerThan600 ? "1.25rem" : "1.5rem"}
        />
        {isAuthenticated && (
          <HelpDropdown
            isEditorView={isEditorView}
            isSmallerThan600={isSmallerThan600}
          />
        )}

        {isAuthenticated && userData ? (
          <HStack spacing={{ base: "0.25rem", md: "1rem" }}>
            <StyledLink
              href={`/in/analytics`}
              fontSize="sm"
              _hover={{
                textShadow: "0 0 4px 0px #dfdfdf",

                color: "#1c72e8 ",
              }}
              active={window.location.pathname === "/in/analytics"}
            >
              <UilArrowGrowth style={{ marginRight: "4px" }} size="18px" />
              Analytics
            </StyledLink>

            <Divider
              height="32px"
              width="2px"
              orientation="vertical"
              background="gray.100"
              border="0"
              borderColor="gray.100"
              style={{ margin: "0 0.5rem" }}
            />

            {!isEditorView && (
              <>
                <StyledLink
                  fontSize="sm"
                  mx="1rem"
                  href={`/in/editor`}
                  _hover={{
                    textShadow: "0 0 4px 0px #dfdfdf",

                    color: "#1c72e8",
                  }}
                >
                  <UilPalette style={{ marginRight: "4px" }} size="18px" />
                  Editor
                </StyledLink>

                <Divider
                  height="32px"
                  width="2px"
                  orientation="vertical"
                  background="gray.100"
                  border="0"
                  borderColor="gray.100"
                  style={{ margin: "0 0.5rem" }}
                />
              </>
            )}

            <Menu autoSelect={false} placement="bottom">
              <MenuButton
                _hover={{
                  textShadow: "0 0 4px 0px #dfdfdf",

                  color: "#1c72e8",
                }}
              >
                <HStack spacing="0.25rem" cursor="pointer">
                  <UilCog />
                </HStack>
              </MenuButton>
              <MenuList zIndex={9999} p="0.5rem" mr="6px" fontSize="sm">
                <Box p="0.5rem">
                  <StyledLink href={`/in/profile`}>
                    <Box>
                      <Heading size="sm">
                        {userData?.name
                          ? userData?.name
                          : `@${userData?.username}`}
                      </Heading>
                      <Text fontSize="xs" color="gray.500" mt="0.25rem">
                        {userData?.email}
                      </Text>
                    </Box>
                  </StyledLink>

                  {/*
                  <Avatar
                    size="sm"
                    bg={userData?.avatar ? "transparent" : "#5d35d3"}
                    borderRadius="50%"
                    border="1px solid #eee"
                    src={userData?.avatar}
                  />
  */}
                </Box>

                <MenuDivider />

                <StyledLink href={`/in/profile`}>
                  <MenuItem icon={<UilUser size="20px" />}>Settings</MenuItem>
                </StyledLink>

                <MenuDivider />

                <MenuItem
                  icon={<UilLogout size="20px" />}
                  fontSize="sm"
                  onClick={removeAuthTokenAndLogout}
                >
                  Logout{" "}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        ) : (
          <>
            <HStack spacing="1rem">
              <StyledLink
                fontSize="0.875rem"
                href={"/in/login"}
                _hover={{
                  textShadow: "0 0 4px 0px #dfdfdf",

                  color: "#6e0234",
                }}
              >
                Sign in
              </StyledLink>

              <StyledLink
                display="flex"
                alignItems="center"
                h="34px"
                borderRadius="50px"
                px="12px"
                color="#fff"
                fontSize="0.875rem"
                variant="solid"
                bgImage="radial-gradient(circle at 0% 0%, #eb59d1, #f536f0 50%, #fc42f5)"
                href={"/in/signup"}
                _hover={{
                  transition: "all 100ms ease",
                  boxShadow: "0 0 4px 0px #dfdfdf",
                }}
              >
                <span>Sign up</span>
              </StyledLink>
            </HStack>
          </>
        )}
      </Box>
    </>
  );
};

export default NavBar;
