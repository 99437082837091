import { Box, Grid, Text, useMediaQuery } from "@chakra-ui/react";
import styled from "styled-components";

const Container = styled(Box)<{ selected: boolean }>`
  display: grid;
  position: relative;
  transition: 0.3s ease-out;
  text-align: center;
  padding: 10px;
  border: 1px solid ${({ selected }) => (selected ? "#68a7ff" : "#e4e4e4")};
  border-radius: 4px;
  cursor: pointer;

  :hover {
    transform: scale(1.05, 1.05);
  }
`;

const FontSelectToolbarItem = ({ handleFontSelect, selectedFont }: any) => {
  const myFonts = [
    "Lexend",
    "Architects Daughter",
    "Lato",
    "Chakra Petch",
    "Comfortaa",
    "Lobster",
    "Atkinson Hyperlegible",
    "Dosis",
    "Gloria Hallelujah",
    "Jua",
    "Kaushan Script",
    "Monoton",
    "Montserrat",
    "Nanum Gothic",
    "Orbitron",
    "Oswald",
    "Palette Mosaic",
    "Patrick Hand",
    "Permanent Marker",
    `'Press Start 2P', cursive`,
    "Rajdhani",
    "Roboto",
    "Rubik",
    "Shadows Into Light",
    "Sigmar One",
    "Style Script",
    "Teko",
    "Tourney",
    "Ubuntu",
    "Yomogi",
  ];

  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Box>
        <Grid
          templateColumns={`repeat(${isSmallerThan600 ? "3" : "5"}, 1fr)`}
          gap={4}
        >
          {myFonts.map((font) => (
            <Container
              key={font}
              selected={font === selectedFont}
              onClick={() => handleFontSelect(font)}
            >
              <Text fontSize={{ base: "1.5rem", md: "2rem" }} fontFamily={font}>
                Aa
              </Text>
              <Text fontSize="10px">{font}</Text>
            </Container>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default FontSelectToolbarItem;
