import {
  Alert,
  Button,
  Divider,
  Drawer,
  Heading,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  FontSizeItem,
  FontWeightRadios,
  LinkStylesItem,
  SelectFontItem,
  TextAlignRadios,
} from "../commons";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useState } from "react";
import { TabHeaderButton, TabList } from "components/TabList";

import { BackgroundBlurIcon } from "assets/svgs/blurIcon";
import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { FontColorIcon } from "assets/svgs/fontColorIcon";
import { FontFormatIcon } from "assets/svgs/fontFormatIcon";
import { FontTypeIcon } from "assets/svgs/fontTypeIcon";
import { LinkDecorationsIcon } from "assets/svgs/linkDecorationsIcon";
import { LinkStylesIcon } from "assets/svgs/linkStylesIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilInfo from "@iconscout/react-unicons/icons/uil-info-circle";
import UilMargins from "@iconscout/react-unicons/icons/uil-line-spacing";
import { useNode } from "@craftjs/core";

export const LinkContainerSettings = () => {
  const [drawerOpen, setDrawer] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const closeDrawer = () => {
    setDrawer("");
  };

  const { propValue } = useNode((node) => ({
    propValue: node.data.props,
  }));

  const MobileButtonStylesSettingItems = (
    <>
      {LinkStylesItem({
        buttonStyle: propValue.buttonStyle,
        background: propValue.background,
      })}
    </>
  );

  const InfoMessageOnChangesHere = (
    <Alert
      borderRadius="4px"
      status="info"
      fontSize="xs"
      p="0.25rem"
      mb={isLargerThan768 ? "1.5rem" : "1.5rem"}
      mt={isLargerThan768 ? "-1rem" : "-0.5rem"}
    >
      <UilInfo size="16px" />

      <Text ml="0.5rem">Changes will be applied to all unstyled links.</Text>
    </Alert>
  );

  const MobileLinkSpacingSettingItems = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
        mb="0.5rem"
      >
        Spacing
      </Heading>
      <SettingsItem full propKey="spacing" type="slider" />
    </>
  );

  const MobileDecorationSettingItems = (
    <>
      <SettingsItem
        full
        propKey="borderRadius"
        type="slider"
        sliderMin={0}
        sliderMax={60}
        label="Corner smoothing"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="shadow"
        type="slider"
        sliderMin={0}
        sliderMax={20}
        label="Shadow"
      />
    </>
  );

  const MobileBackgroundColorSettingItem = (
    <>
      {propValue.buttonStyle === "ghost" ? (
        <>
          <Heading as="h6" size="xs" pl="2px">
            Cannot add background color to 'Ghost' style links. It's called
            'Ghost' for a reason &#128521;
          </Heading>

          <Button
            mt="1.5rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("styles")}
          >
            Change link style <UilArrowRight size="20px" />
          </Button>
        </>
      ) : (
        <>
          <Heading
            display={{ base: "none", md: "block" }}
            as="h6"
            size="xs"
            pl="2px"
          >
            Background color
          </Heading>

          <SettingsItem
            full
            propKey="background"
            type="gradient"
            useGradient={propValue.buttonStyle === "solid"}
          />
        </>
      )}
    </>
  );

  const MobileTextColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Text color
      </Heading>

      <SettingsItem full propKey="color" type="gradient" useGradient={false} />
    </>
  );

  const MobileBackgroundBlurSettingItem = (
    <>
      <Heading as="h5" size="sm" pl="2px">
        Blur Container background
      </Heading>

      <Text color="gray.400" fontSize="sm" mb="0.25rem">
        (best visible with image or video background)
      </Text>

      <SettingsItem propKey="isBlurred" type="switch" />
    </>
  );

  const DecorationSettingItems = (
    <>
      {MobileBackgroundBlurSettingItem}

      <Divider my="1.5em" />

      {InfoMessageOnChangesHere}

      {MobileButtonStylesSettingItems}

      <Divider my="1.25em" />

      {MobileLinkSpacingSettingItems}

      <Divider my="1.25em" />

      {MobileDecorationSettingItems}

      <Divider my="1.25em" />

      {MobileBackgroundColorSettingItem}

      <Divider my="1.25em" />

      {MobileTextColorSettingItem}
    </>
  );

  const TypographySettingItems = (
    <>
      {FontSizeItem}

      <Divider my="1em" />
      <SettingsItem
        full
        propKey="textAlign"
        type="buttonRadio"
        label="Align text"
      >
        {TextAlignRadios}
      </SettingsItem>

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="fontWeight"
        type="buttonRadio"
        label="Font weight"
      >
        {FontWeightRadios}
      </SettingsItem>
    </>
  );

  return isLargerThan768 ? (
    <Tabs isFitted>
      <TabList mb="1em">
        <TabHeaderButton>Decoration</TabHeaderButton>
        <TabHeaderButton>Typography</TabHeaderButton>
        <TabHeaderButton>Font style</TabHeaderButton>
      </TabList>
      <TabPanels>
        <TabPanel>{DecorationSettingItems}</TabPanel>

        <TabPanel>
          {InfoMessageOnChangesHere}
          {TypographySettingItems}
        </TabPanel>

        <TabPanel>
          {InfoMessageOnChangesHere}
          {SelectFontItem}
        </TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <>
      <IconsWrapper
        hidden={
          drawerOpen === "decoration" ||
          drawerOpen === "blur" ||
          drawerOpen === "styles" ||
          drawerOpen === "spacing" ||
          drawerOpen === "typography" ||
          drawerOpen === "textColor" ||
          drawerOpen === "backgroundColor" ||
          drawerOpen === "font"
        }
      >
        <SettingIconButton onClick={() => setDrawer("spacing")}>
          <UilMargins />
          <Text>Spacing</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("backgroundColor")}>
          <PaintBucketIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.background ? propValue.background : "#111",
            }}
          />

          <Text>Background</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("blur")}>
          <BackgroundBlurIcon />

          <Text>Blur</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("decoration")}>
          <LinkDecorationsIcon />
          <Text>Decorations</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("textColor")}>
          <FontColorIcon />

          <div
            style={{
              width: "32px",
              height: "3px",
              borderRadius: "50px",
              marginBottom: "1px",
              background: propValue.color ? propValue.color : "#eee",
            }}
          />

          <Text>Color</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("styles")}>
          <LinkStylesIcon />
          <Text>Styles</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("typography")}>
          <FontFormatIcon />
          <Text>Typography</Text>
        </SettingIconButton>

        <SettingIconButton onClick={() => setDrawer("font")}>
          <FontTypeIcon />
          <Text>Font</Text>
        </SettingIconButton>
      </IconsWrapper>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "styles"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Links style">
          {InfoMessageOnChangesHere}

          {MobileButtonStylesSettingItems}

          <Button
            mt="1rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("backgroundColor")}
          >
            Change background color <UilArrowRight size="20px" />
          </Button>
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "spacing"}
        blockScrollOnMount={false}
      >
        <DrawerContent py="3rem" maxHeight="16vh" title="Links spacing">
          {MobileLinkSpacingSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "decoration"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="34vh" title="Links decorations">
          {InfoMessageOnChangesHere}

          {MobileDecorationSettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "blur"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="17vh" title="Blur">
          {MobileBackgroundBlurSettingItem}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "textColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Links text color">
          {InfoMessageOnChangesHere}

          {MobileTextColorSettingItem}

          <Button
            mt="1rem"
            variant="outline"
            size="sm"
            onClick={() => setDrawer("backgroundColor")}
          >
            Change background color <UilArrowRight size="20px" />
          </Button>
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        size="xs"
        onClose={closeDrawer}
        isOpen={drawerOpen === "backgroundColor"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Links background color">
          {propValue.buttonStyle === "" ? (
            <>
              <Heading as="h6" size="xs" pl="2px">
                Select a link style before changing background color.
              </Heading>
              <Button
                mt="1rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("styles")}
              >
                Change Link style
              </Button>
            </>
          ) : (
            <>
              {InfoMessageOnChangesHere}

              {MobileBackgroundColorSettingItem}

              <Button
                mt="1rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("textColor")}
              >
                Change text color <UilArrowRight size="20px" />
              </Button>
            </>
          )}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "typography"}
        blockScrollOnMount={false}
      >
        <DrawerContent maxHeight="42vh" title="Links typography">
          {InfoMessageOnChangesHere}

          {TypographySettingItems}
        </DrawerContent>
      </Drawer>

      <Drawer
        placement="bottom"
        onClose={closeDrawer}
        isOpen={drawerOpen === "font"}
        blockScrollOnMount={false}
      >
        <DrawerContent title="Links font">
          {InfoMessageOnChangesHere}

          {SelectFontItem}
        </DrawerContent>
      </Drawer>
    </>
  );
};
