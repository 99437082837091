import {
  Box,
  Button,
  Divider,
  Drawer,
  Fade,
  Heading,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { FontWeightRadios, SelectFontItem, TextAlignRadios } from "../commons";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useCallback, useState } from "react";
import { useEditor, useNode } from "@craftjs/core";

import { CloneIcon } from "assets/svgs/cloneIcon";
import Delete from "@iconscout/react-unicons/icons/uil-trash-alt";
import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { FontColorIcon } from "assets/svgs/fontColorIcon";
import { FontFormatIcon } from "assets/svgs/fontFormatIcon";
import { FontTypeIcon } from "assets/svgs/fontTypeIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";
import UilMargins from "@iconscout/react-unicons/icons/uil-line-spacing";
import { getRandomId } from "@craftjs/utils";
import { useUserContext } from "HoCs/UserProvider";

export const SupportSettings = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [drawerOpen, setDrawer] = useState("");

  const { id, propValue, parent } = useNode((node) => ({
    propValue: node.data.props,
    parent: node.data.parent,
  }));

  const { userData } = useUserContext();

  const connectedAccountId = userData?.stripeIntegration?.accountId;

  const toast = useToast();

  const { actions, query } = useEditor();

  const getCloneTree = useCallback((idToClone) => {
    const tree = query.node(idToClone).toNodeTree();
    const newNodes = {};

    const changeNodeId = (node, newParentId) => {
      const newRandomNodeId = getRandomId();

      const childNodes = node.data.nodes.map((childId) =>
        changeNodeId(tree.nodes[childId], newRandomNodeId)
      );
      const linkedNodes = Object.keys(node.data.linkedNodes).reduce(
        (accum, id) => {
          const newNodeId = changeNodeId(
            tree.nodes[node.data.linkedNodes[id]],
            newRandomNodeId
          );
          return {
            ...accum,
            [id]: newNodeId,
          };
        },
        {}
      );

      let tmpNode = {
        ...node,
        id: newRandomNodeId,
        data: {
          ...node.data,
          parent: newParentId || node.data.parent,
          nodes: childNodes,
          linkedNodes,
        },
      };
      let freshnode = query.parseFreshNode(tmpNode).toNode();
      newNodes[newRandomNodeId] = freshnode;
      return newRandomNodeId;
    };

    const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId], parent);
    return {
      rootNodeId,
      nodes: newNodes,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClone = (e: any) => {
    e.preventDefault();
    const theNode = query.node(id).get();
    const parentNode = query.node(theNode.data.parent).get();
    const indexToAdd = parentNode.data.nodes.indexOf(id);
    const tree = getCloneTree(id);
    actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);

    toast({
      title: "Support cloned",
      status: "success",
      variant: "subtle",
      isClosable: true,
      position: "top",
    });

    actions.clearEvents();
  };

  const closeDrawer = () => {
    setDrawer("");
  };

  const MobileTextColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Text color
      </Heading>
      <SettingsItem
        full={true}
        propKey="color"
        type="color"
        useGradient={false}
      />
    </>
  );

  const MobileTypographySettingItem = (
    <>
      <SettingsItem
        full
        propKey="fontSize"
        type="slider"
        sliderMin={10}
        sliderMax={42}
        label="Font Size"
      />

      <br />

      <SettingsItem
        full
        propKey="textAlign"
        type="buttonRadio"
        label="Align text"
      >
        {TextAlignRadios}
      </SettingsItem>

      <br />

      <SettingsItem
        full
        propKey="fontWeight"
        type="buttonRadio"
        label="Font weight"
      >
        {FontWeightRadios}
      </SettingsItem>
    </>
  );

  const MobileEditTitleSettingItem = (
    <>
      <SettingsItem
        full
        propKey="buttonText"
        type="text"
        label="Button text "
        placeholder="Buy me a coffee ☕️"
      />

      <Divider my="1em" />

      <>
        <Heading as="h6" size="xs" pl="2px" my="0.25rem">
          Add description
        </Heading>

        <SettingsItem full={true} propKey="hasDescription" type="switch" />

        {propValue.hasDescription ? (
          <Fade in={propValue && propValue.hasDescription}>
            <SettingsItem
              full={true}
              propKey="description"
              type="textArea"
              label="Description"
              placeholder="Support my work.."
              textAreaLength={600}
            />

            {MobileTypographySettingItem}
          </Fade>
        ) : null}
      </>
    </>
  );

  const MobileLabelMarginSettingItems = (
    <>
      <SettingsItem
        full
        propKey="marginTop"
        type="slider"
        sliderMin={0}
        sliderMax={44}
        label="Margin from top"
      />

      <br />

      <SettingsItem
        full
        propKey="marginBottom"
        type="slider"
        sliderMin={0}
        sliderMax={44}
        label="Margin from bottom"
      />
    </>
  );

  const EditSettingsItems = (
    <>
      {MobileEditTitleSettingItem}

      <Divider my="1em" />

      {MobileLabelMarginSettingItems}

      {propValue.hasDescription ? (
        <>
          <Divider my="1em" />

          {MobileTextColorSettingItem}
        </>
      ) : null}
    </>
  );

  const MobileBackgroundColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Card background
      </Heading>
      <SettingsItem
        full={true}
        propKey="backgroundColor"
        type="color"
        useGradient={false}
      />

      <Divider my="1em" />
    </>
  );

  const ColorSettingItem = <>{MobileBackgroundColorSettingItem}</>;

  return (
    <React.Fragment>
      {isLargerThan768 && connectedAccountId ? (
        <Tabs isFitted>
          <TabList mb="1em">
            <Tab>Edit</Tab>
            <Tab>Background</Tab>

            <Tab>Font</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div>{EditSettingsItems}</div>

              <Divider my="1rem" />

              <Button mb="1.5rem" variant="outline" onClick={handleClone}>
                <CloneIcon /> <Text pl="0.25rem">Clone</Text>
              </Button>
            </TabPanel>

            <TabPanel>{ColorSettingItem}</TabPanel>

            <TabPanel>{SelectFontItem}</TabPanel>
          </TabPanels>
        </Tabs>
      ) : !isLargerThan768 && connectedAccountId ? (
        <>
          <IconsWrapper
            hidden={
              drawerOpen === "edit" ||
              drawerOpen === "textColor" ||
              drawerOpen === "backgroundColor" ||
              drawerOpen === "typography" ||
              drawerOpen === "margin" ||
              drawerOpen === "font"
            }
          >
            <SettingIconButton onClick={() => setDrawer("edit")}>
              <UilEditKeyboard />
              <Text>Edit</Text>
            </SettingIconButton>

            {propValue.hasDescription ? (
              <SettingIconButton onClick={() => setDrawer("typography")}>
                <FontFormatIcon />
                <Text>Typography</Text>
              </SettingIconButton>
            ) : null}

            <SettingIconButton onClick={() => setDrawer("margin")}>
              <UilMargins />
              <Text>Margins</Text>
            </SettingIconButton>

            <SettingIconButton onClick={() => setDrawer("textColor")}>
              <FontColorIcon />

              <div
                style={{
                  width: "32px",
                  height: "3px",
                  borderRadius: "50px",
                  marginBottom: "1px",
                  background: propValue.color ? propValue.color : "#eee",
                }}
              />

              <Text>Color</Text>
            </SettingIconButton>

            {propValue.hasDescription ? (
              <SettingIconButton onClick={() => setDrawer("backgroundColor")}>
                <PaintBucketIcon />

                <div
                  style={{
                    width: "32px",
                    height: "3px",
                    borderRadius: "50px",
                    marginBottom: "1px",
                    background: propValue.labelBackground
                      ? propValue.labelBackground
                      : "#111",
                  }}
                />

                <Text>Background</Text>
              </SettingIconButton>
            ) : null}

            <SettingIconButton onClick={() => setDrawer("font")}>
              <FontTypeIcon />

              <Text>Font</Text>
            </SettingIconButton>

            <SettingIconButton onClick={handleClone}>
              <CloneIcon />

              <Text>Clone</Text>
            </SettingIconButton>

            <SettingIconButton
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                actions.delete(id);
              }}
            >
              <Delete />
              <Text>Delete</Text>
            </SettingIconButton>
          </IconsWrapper>

          <Drawer
            placement="bottom"
            onClose={closeDrawer}
            isOpen={drawerOpen === "edit"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Edit" pb="4rem">
              {MobileEditTitleSettingItem}
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "margin"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Margins">
              {MobileLabelMarginSettingItems}
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "textColor"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Text color">
              {MobileTextColorSettingItem}

              <Button
                mt="1.5rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("backgroundColor")}
              >
                Change card background color <UilArrowRight size="20px" />
              </Button>
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "backgroundColor"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Card background color">
              {MobileBackgroundColorSettingItem}

              <Button
                mt="1.5rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("textColor")}
              >
                Change text color <UilArrowRight size="20px" />
              </Button>
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            onClose={closeDrawer}
            isOpen={drawerOpen === "typography"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Typography">
              {MobileTypographySettingItem}
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            onClose={closeDrawer}
            isOpen={drawerOpen === "font"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Font style">{SelectFontItem}</DrawerContent>
          </Drawer>
        </>
      ) : (
        <Box
          backgroundColor="#fbfbfb"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          p="3rem 2rem"
          color="gray.600"
        >
          <VStack spacing="1rem">
            <Text fontWeight="bold">
              Please set up Stripe payment method to collect Support donations
            </Text>

            <Link textDecoration="underline" href="/in/profile">
              Connect Stripe account
            </Link>
          </VStack>
        </Box>
      )}
    </React.Fragment>
  );
};
