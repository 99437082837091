import { Box, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { BuyMeACoffee } from "../Embeds/BuyMeACoffee";
import { Divider } from "../Divider";
import { KoFi } from "../Embeds/Ko-Fi";
import { Label } from "../Label";
import { Link } from "components/Selectors/Link";
import { LinkContainerSettings } from "./settings";
import { Music } from "../Embeds/Music";
import { Support } from "../Support";
import { Twitter } from "../Embeds/Twitter";
import { Video } from "../Embeds/Video";
import { Image } from "../Embeds/Image";
import { useNode } from "@craftjs/core";
import { useTracked } from "hooks/useTracked";

type ContainerProps = {
  fontSize: string;
  buttonStyle: string;
  buttonbackground: string;
  color: string;
  textAlign?: string;
  borderRadius: string;
  isblurred: boolean;
};

const handleButtonStyle = (style: string, background: string) => {
  switch (style) {
    case "solid":
      return css`
        background: ${background};
        border: ${background && background.startsWith("linear-gradient")
          ? "none"
          : "2px solid transparent"};
      `;
    case "outline":
      return css`
        background: transparent;
        border: 2px solid ${background};
      `;
    case "ghost":
      return css`
        background: transparent;
        border: 2px solid transparent;
      `;
    case "shadow":
      return css`
        background: ${background};
        border: 2px solid transparent;
        box-shadow: 6px 8px 0
          ${background
            ? background.replace(")", ", 0.5)").replace("rgb", "rgba")
            : "rgba(0, 0, 0, 0.178)"
                .replace(")", ", 0.5)")
                .replace("rgb", "rgba")};
      `;

    default:
      return css`
        background: transparent;
        border: 2px solid transparent;
        border-radius: 4px;
      `;
  }
};

const StyledBox = styled(VStack)<ContainerProps>`
  backdrop-filter: ${({ isblurred }) =>
    isblurred ? `blur(2px)` : "unset"} !important;

  > * {
    white-space: normal;
    font-size: ${({ fontSize }) =>
      fontSize ? `${fontSize}px` : "1em"} !important;
    text-align: left !important;
  }

  > button {
    border-radius: ${({ borderRadius }) => borderRadius ?? "32"}px;
    box-shadow: ${({ shadow }) =>
      shadow === 0 ? "none" : `0px 4px ${shadow}px 2px  rgba(0, 0, 0, 0.178)`};

    ${({ buttonstyle, buttonbackground }) =>
      handleButtonStyle(buttonstyle, buttonbackground)};

    span,
    div {
      color: ${({ color }) => `${color}`};
      text-align: ${({ textAlign }) => textAlign};
      font-weight: ${({ ownfontweight }) => ownfontweight};
      font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "unset")};
    }
  }
`;

export type LinksProps = {
  background: string;
  color: string;
  shadow: number;
  children: React.ReactNode;
  radius: number;
  fontSize: string;
  buttonStyle: string;
  textAlign: string;
  fontWeight?: string;
  borderRadius: string;
  fontFamily?: string;
  spacing?: number;
  isBlurred?: boolean;
};

const defaultProps = {
  color: "#fff",
  shadow: 0,
  fontSize: "1em",
  buttonStyle: "outline",
  textAlign: "center",
  fontWeight: "400",
  spacing: 12,
  isBlurred: false,
};

export const Links = (props: Partial<LinksProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    fontSize,
    buttonStyle,
    textAlign,
    fontWeight,
    background,
    color,
    shadow,
    borderRadius,
    fontFamily,
    spacing,
    isBlurred,
    children,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useTracked();

  const {
    connectors: { connect },
    nodeId,
  } = useNode((node) => ({
    propValue: node.data.props,
    nodeId: node.id,
  }));

  useEffect(() => {
    setState((prev) => ({ ...prev, linksContainerId: nodeId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);

  return (
    <Box px="0.5rem" pb="0.5rem" className="links_container_wrapper">
      <StyledBox
        position="relative"
        fontSize={fontSize}
        buttonstyle={buttonStyle}
        fontFamily={fontFamily}
        buttonbackground={background}
        textAlign={textAlign}
        ownfontweight={fontWeight}
        borderRadius={borderRadius}
        color={color}
        display="flex"
        flexDirection="column"
        p={"0.5rem"}
        mt="14px"
        mb="24px"
        spacing={`${spacing}px`}
        isblurred={isBlurred ? 1 : 0}
        shadow={shadow}
        ref={connect}
        w="100%"
        className="links_container_wrapper_inner"
      >
        {children}
      </StyledBox>
    </Box>
  );
};

Links.craft = {
  displayName: "Links Container",
  name: "LinksContainer",
  props: defaultProps,
  related: {
    settings: LinkContainerSettings,
  },

  rules: {
    canMoveIn: (node: any) =>
      node.data.type === Link ||
      node.data.type === Label ||
      node.data.type === KoFi ||
      node.data.type === BuyMeACoffee ||
      node.data.type === Music ||
      node.data.type === Video ||
      node.data.type === Image ||
      node.data.type === Divider ||
      node.data.type === Support ||
      node.data.type === Twitter,
    canMoveOut: (node: any) => node.data.type === Label,
    /*
      node.data.type === KoFi ||
      node.data.type === BuyMeACoffee ||
      node.data.type === Music ||
      node.data.type === Video ||
      node.data.type === Divider ||
      node.data.type === Twitter,
      */
    canDrag: () => false,
  },
};
