export const YouTubeIcon = () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{
        pointerEvents: "none",
        display: "block",
        width: "48px",
        height: "48px",
      }}
    >
      <g id="YouTube">
        <circle id="back" cx="256" cy="256" r="256" fill="#e52d27" />
        <path
          id="youtube-2"
          data-name="youtube"
          d="M399.36,313.34a41.09,41.09,0,0,1-41,41H153.6a41.08,41.08,0,0,1-41-41V198.66a41.08,41.08,0,0,1,41-41H358.4a41.08,41.08,0,0,1,41,41Zm-173-22.17L303.86,251l-77.47-40.42Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
