import React from "react";
const AppstoreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-brand-appstore"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.75"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      aria-hidden="true"
      focusable="false"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="12" cy="12" r="9"></circle>
      <path d="M8 16l1.106 -1.99m1.4 -2.522l2.494 -4.488"></path>
      <path d="M7 14h5m2.9 0h2.1"></path>
      <path d="M16 16l-2.51 -4.518m-1.487 -2.677l-1.003 -1.805"></path>
    </svg>
  );
};

export default AppstoreIcon;
