import { Tab, TabList as CTabList } from "@chakra-ui/react";
import styled from "styled-components";
/*
export const TabList = styled(({ ...rest }) => (
    <CTabList mb="1em" overflowY={{ base: "auto", md: "hidden" }} {...rest} />
    ))``;
    */

export const TabHeaderButton = styled(({ ...rest }) => (
  <Tab fontSize={{ base: "0.875rem", md: "1rem" }} mb="0" {...rest} />
))``;

export const TabList = styled(({ ...rest }) => (
  <CTabList {...rest} mb="1em" overflowY="auto" />
))`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
