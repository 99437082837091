import { Box, Flex, Heading, Stack } from "@chakra-ui/layout";

import Lottie from "react-lottie";
import Page from "components/Page";
import genericError from "assets/jsons/error-animation.json";
import { useMediaQuery } from "@chakra-ui/react";

const defaultGenericErrorOptions = {
  loop: true,
  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: genericError,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const TooManyRequestErrorPage = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <Page>
      <Flex
        width="full"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py="1rem"
      >
        <Box my="4rem">
          <Lottie
            options={defaultGenericErrorOptions}
            height={isSmallerThan600 ? 160 : 260}
            width={isSmallerThan600 ? 300 : 460}
          />
        </Box>

        <Stack spacing="1.5rem" textAlign="center" cursor="default">
          <Heading as="h4" size="md">
            You sent too many server requests. Please wait a while then try
            again. Thanks!
          </Heading>
        </Stack>
      </Flex>
    </Page>
  );
};
