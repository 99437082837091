import { AnalyticsHeader, Header } from "components/EditorComponents";
import {
  BioContainer,
  ContainerHeader,
  Label,
  Link,
  Links,
  MainContainer,
  SocialLinks,
} from "components/Selectors";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import { Element, Frame, useEditor } from "@craftjs/core";
import { memo, useEffect } from "react";

import Dot from "assets/Dot.png";
import { SettingsPanel } from "components/EditorComponents/SettingsPanel";
import { Viewport } from "components/EditorComponents";
import lz from "lzutf8";

const EditorContent = ({ activeComponents, userData }) => {
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const { enabled, canUndo } = useEditor((state, query) => ({
    active: state.events.selected,
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),

    related:
      state.events.selected && state.nodes[state.events.selected].related,
  }));

  useEffect(() => {
    if (enabled && canUndo) {
      window.addEventListener("beforeunload", alertUser);

      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [enabled, canUndo]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  let frameDataJson = "";

  if (userData?.frameData) {
    const frameDataUint8array = lz.decodeBase64(userData.frameData);
    frameDataJson = JSON.parse(lz.decompress(frameDataUint8array));
  }

  return (
    <Flex flexDirection="column" height="calc(100vh - 57px)" overflow="hidden">
      {isSmallerThan768 && !enabled ? (
        <AnalyticsHeader />
      ) : isLargerThan768 ? (
        <AnalyticsHeader />
      ) : null}

      <Header userData={userData} />
      <Box
        display="flex"
        overflow="hidden"
        flex="1"
        flexDirection={{ base: "column", md: "row" }}
        backgroundImage={Dot}
        backgroundPosition="-22px -24px"
        backgroundSize="28px 28px"
      >
        <Box
          className="viewport"
          flex="1"
          overflow="auto"
          borderRight="1px solid #eeeeee"
          pb="2rem"
        >
          <Viewport activeComponents={activeComponents}>
            <Frame data={frameDataJson}>
              <Element canvas is={MainContainer}>
                <Element canvas is={ContainerHeader}>
                  <BioContainer />
                </Element>

                <Element canvas is={Links}>
                  <Element canvas is={Label}></Element>

                  <Element canvas is={Link}>
                    <Link />
                  </Element>

                  <Element canvas is={Link}>
                    <Link />
                  </Element>
                </Element>

                <Element canvas is={SocialLinks}></Element>
              </Element>
            </Frame>
          </Viewport>
        </Box>

        {isLargerThan768 ? (
          <Box
            bg="#fbfbfb"
            flex={!enabled ? "0" : "1"}
            overflow="auto"
            transition="all 0.4s cubic-bezier(0.19, 1, 0.22, 1)"
          >
            <SettingsPanel />
          </Box>
        ) : (
          <>
            <SettingsPanel />
          </>
        )}
      </Box>
    </Flex>
  );
};

export default memo(EditorContent);
