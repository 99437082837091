import React, { useState } from "react";

import api from "api";
import { getAuthToken } from "utils";
import jwt_decode from "jwt-decode";
import { useAuth } from "./useAuth";

export const useUserData = () => {
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(undefined);

  const { logout } = useAuth();

  React.useEffect(() => {
    const token = getAuthToken();

    const fetchPrivateUserDate = async () => {
      const decoded = jwt_decode(token) as any;

      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const { data } = await api.get(
          `/api/private/${decoded?.username}`,
          config
        );
        setUserData(data);
        setLoading(false);
      } catch (error: any) {
        if (error && error.response?.status === 404) {
          console.error("Error 404  ", error);
          setLoading(false);
          return setError("User not found");
        }

        if (error && error.response?.status === 429) {
          console.error("Error, too many requests ", error);
          setLoading(false);
          return setError(429);
        }

        setLoading(false);
        //setError("tokenExpired");
        logout();
      }
    };

    if (token) {
      fetchPrivateUserDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken()]);

  return { userData, loading, error };
};

export default useUserData;
