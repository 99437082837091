import { Box, Img, Link, Tooltip } from "@chakra-ui/react";
import styled, { css } from "styled-components";
import { useEditor, useNode } from "@craftjs/core";
import { useEffect, useState } from "react";

import AppstoreIcon from "./AppstoreIcon";
import Cookies from "js-cookie";
import { EVENT_LINK_CLICK } from "utils/events";
import EtsyIcon from "./etsyIcon";
import { GoodreadsIcon } from "./GoodreadsIcon";
import { HAS_ALREADY_REGISTERED_TTC } from "utils/constants";
import PatreonIcon from "./patreonIcon";
import { SocialLinksSettings } from "./settings";
import TiktokIcon from "./tiktokIcon";
import TwitchIcon from "./twitchIcon";
import { registerEvent } from "api/queries";
import { useParams } from "react-router-dom";
import { cloudfrontURL } from "utils/urlBuilder.js";

type SocialLinkType = {
  position?: string;
  email?: string;
  twitter?: string;
  instagram?: string;
  tiktok?: string;
  facebookUrl?: string;
  snapchat?: string;
  youtubeUrl?: string;
  linkedin?: string;
  linkedinCompany?: string;
  twitch?: string;
  goodreads?: string;
  patreon?: string;
  etsy?: string;
  appstoreUrl?: string;
  whatsapp?: string;
  marginTop?: string;
  marginBottom?: string;
  tellonym?: string;
  backgroundColor?: string;
};
const defaultProps = {
  position: "center",
  marginTop: "4",
  marginBottom: "4",
  email: "",
  twitter: "",
  instagram: "",
  tiktok: "",
  facebookUrl: "",
  snapchat: "",
  youtubeUrl: "",
  linkedin: "",
  linkedinCompany: "",
  goodreads: "",
  twitch: "",
  patreon: "",
  etsy: "",
  appstoreUrl: "",
  whatsapp: "",
  tellonym: "",
};

const handleIconType = (socialMedia: string) => {
  switch (socialMedia) {
    case "email":
      return css`
        background-color: #286eac;
        color: #ffffff;
      `;

    case "facebookUrl":
      return css`
        background-color: #3b5999;
        color: #ffffff;
      `;

    case "youtubeUrl":
      return css`
        background-color: #ff0000;
        color: #ffffff;
      `;
    case "instagram":
      return css`
        background-color: #e1306c;
        color: #ffffff;
      `;
    case "twitter":
      return css`
        background-color: #209cea;
        color: #ffffff;
      `;

    case "tiktok":
      return css`
        background-color: #07f1ea;
        color: #ffffff;
      `;

    case "twitch":
      return css`
        background-color: #6441a5;
        color: #ffffff;
      `;

    case "snapchat":
      return css`
        background-color: #f7f407;
        color: #ffffff;
      `;

    case "goodreads":
      return css`
        background-color: #e9e5cd;
        color: #ffffff;
      `;

    case "linkedin":
      return css`
        background-color: #0077b5;
        color: #ffffff;
      `;

    case "etsy":
      return css`
        background-color: #f56400;
        color: #ffffff;
      `;

    case "appstoreUrl":
      return css`
        background-color: #1e7cf3;
        color: #ffffff;
      `;

    case "whatsapp":
      return css`
        background-color: #25d366;
        color: #ffffff;
      `;

    case "patreon":
      return css`
        background-color: #ff424d;
        color: #ffffff;
      `;

    case "tellonym":
      return css`
        background-color: #ef0066;
        color: #ffffff;
      `;

    default:
      return "none";
  }
};

const IconsWrapper = styled(Box)<{ position: string }>`
  display: inline-flex;
  flex-wrap: wrap;

  width: 100%;

  justify-content: ${({ position }) => position ?? "center"};

  a {
    margin: 6px;

    :hover {
      text-decoration: none !important;
      outline: none;
      border: none;
    }
    :focus {
      outline: none;
      border: none;
    }
    :active {
      outline: none;
      border: none;
    }
  }
`;

const StyledIcon = styled.i<{ urlType: string; backgroundColor?: string }>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "#fff"};
  border-radius: 50%;
  padding: 12px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.2s ease-in-out;

  :hover {
    ${({ urlType }) => handleIconType(urlType)};
  }
`;
export const SocialLinks = (props: Partial<SocialLinkType>) => {
  const { username } = useParams();
  const [pageLoadedDate, setPageLoadedDate] = useState(undefined);

  props = {
    ...defaultProps,
    ...props,
  };
  const {
    position,
    marginTop,
    marginBottom,
    email,
    twitter,
    instagram,
    tiktok,
    facebookUrl,
    snapchat,
    youtubeUrl,
    twitch,
    linkedin,
    linkedinCompany,
    patreon,
    etsy,
    appstoreUrl,
    whatsapp,
    backgroundColor,
    goodreads,
    tellonym,
  } = props;

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
  } = useNode();

  useEffect(() => {
    if (username) {
      setPageLoadedDate(new Date());
    }
  }, [username]);

  useEffect(() => {
    const onbeforeunloadFn = () => {
      Cookies.remove(HAS_ALREADY_REGISTERED_TTC);
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
  }, []);

  const updateLinkClickStats = async (name, handle) => {
    if (!enabled && username) {
      try {
        await handleRegisterLinkClickEvent({
          username: username,
          linkId: name,
          handle: handle,
        });
      } catch (error: any) {
        console.error("Error linkclick ", error);
      }
    }

    return () => {};
  };

  const handleRegisterLinkClickEvent = async ({ username, linkId, handle }) => {
    const cookieHasAlreadyRegisteredLinkTTC = Cookies.get(
      HAS_ALREADY_REGISTERED_TTC
    );

    let secondsTimeToClick = 0;
    if (
      !cookieHasAlreadyRegisteredLinkTTC ||
      cookieHasAlreadyRegisteredLinkTTC === "false"
    ) {
      let clickDate = new Date();

      secondsTimeToClick =
        (clickDate.getTime() - pageLoadedDate.getTime()) / 1000;
    }

    try {
      await registerEvent({
        username: username,
        eventType: EVENT_LINK_CLICK,
        linkInfo: {
          linkId: linkId,
          name: linkId,
          linkUrl: handle,
        },

        timeToClick:
          cookieHasAlreadyRegisteredLinkTTC === "true" ? 0 : secondsTimeToClick,
      });

      Cookies.set(HAS_ALREADY_REGISTERED_TTC, "true"); // session cookie
    } catch (error) {
      console.log("Err event ", error);
    }
  };

  const extractTwitterHandle = (url: string) => {
    if (!url) return null;
    const match = url.match(
      /^https?:\/\/(www\.)?twitter.com\/@?(?<handle>\w+)/
    );
    return match?.groups?.handle ? `@${match.groups.handle}` : url;
  };

  const extractInstaHandle = (url: string) => {
    if (!url) return null;
    const match = url.match(
      /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?(\w+)\/?$/
    );

    return match?.length > 0 ? match[1] : url;
  };

  return (
    <Box px="1rem" className="socials_component_wrapper">
      <IconsWrapper
        ref={(ref) => connect(drag(ref))}
        px="6px"
        position={position}
        mt={`${marginTop}px !important`}
        mb={`${marginBottom}px !important`}
      >
        {email && (
          <Tooltip hasArrow label={email} fontSize="xs" placement="top">
            <Link
              href={enabled ? null : `mailto:${email}`}
              isExternal
              onClick={() => updateLinkClickStats("email", email)}
            >
              <StyledIcon
                backgroundColor={backgroundColor}
                urlType="email"
                className="uil uil-envelope"
              />
            </Link>
          </Tooltip>
        )}

        {twitter && (
          <Link
            href={
              enabled
                ? null
                : `https://twitter.com/${extractTwitterHandle(twitter)}`
            }
            isExternal
            onClick={() =>
              updateLinkClickStats("twitter", extractTwitterHandle(twitter))
            }
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="twitter"
              className="uil uil-twitter"
            />
          </Link>
        )}

        {instagram && (
          <Link
            href={
              enabled
                ? null
                : `https://instagram.com/${extractInstaHandle(instagram)}`
            }
            isExternal
            onClick={() =>
              updateLinkClickStats("instagram", extractInstaHandle(instagram))
            }
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="instagram"
              className="uil uil-instagram"
            />
          </Link>
        )}

        {tiktok && (
          <Link
            href={enabled ? null : `https://tiktok.com/@${tiktok}`}
            isExternal
            onClick={() => updateLinkClickStats("tiktok", tiktok)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="tiktok">
              <TiktokIcon />
            </StyledIcon>
          </Link>
        )}

        {facebookUrl && (
          <Link
            href={enabled ? null : facebookUrl}
            isExternal
            onClick={() => updateLinkClickStats("facebookUrl", facebookUrl)}
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="facebookUrl"
              className="uil uil-facebook-f"
            />
          </Link>
        )}

        {snapchat && (
          <Link
            href={enabled ? null : `https://snapchat.com/add/${snapchat}`}
            isExternal
            onClick={() => updateLinkClickStats("snapchat", snapchat)}
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="snapchat"
              className="uil uil-snapchat-ghost"
            />
          </Link>
        )}

        {youtubeUrl && (
          <Link
            href={enabled ? null : youtubeUrl}
            isExternal
            onClick={() => updateLinkClickStats("youtubeUrl", youtubeUrl)}
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="youtubeUrl"
              className="uil uil-youtube"
            />
          </Link>
        )}

        {linkedin && (
          <Link
            href={enabled ? null : `https://linkedin.com/in/${linkedin}`}
            isExternal
            onClick={() => updateLinkClickStats("linkedin", linkedin)}
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="linkedin"
              className="uil uil-linkedin"
            />
          </Link>
        )}

        {linkedinCompany && (
          <Link
            href={
              enabled ? null : `https://linkedin.com/company/${linkedinCompany}`
            }
            isExternal
            onClick={() =>
              updateLinkClickStats("linkedinCompany", linkedinCompany)
            }
          >
            <StyledIcon
              backgroundColor={backgroundColor}
              urlType="linkedin"
              className="uil uil-linkedin"
            />
          </Link>
        )}

        {goodreads && (
          <Link
            href={enabled ? null : goodreads}
            isExternal
            onClick={() => updateLinkClickStats("goodreads", goodreads)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="goodreads">
              <GoodreadsIcon />
            </StyledIcon>
          </Link>
        )}

        {twitch && (
          <Link
            href={enabled ? null : `https://twitch.tv/${twitch}`}
            isExternal
            onClick={() => updateLinkClickStats("twitch", twitch)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="twitch">
              <TwitchIcon />
            </StyledIcon>
          </Link>
        )}

        {patreon && (
          <Link
            href={enabled ? null : `https://patreon.com/${patreon}`}
            isExternal
            onClick={() => updateLinkClickStats("patreon", patreon)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="patreon">
              <PatreonIcon />
            </StyledIcon>
          </Link>
        )}

        {etsy && (
          <Link
            href={enabled ? null : `https://www.etsy.com/shop/${etsy}`}
            isExternal
            onClick={() => updateLinkClickStats("etsy", etsy)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="etsy">
              <EtsyIcon />
            </StyledIcon>
          </Link>
        )}

        {appstoreUrl && (
          <Link
            href={enabled ? null : appstoreUrl}
            isExternal
            onClick={() => updateLinkClickStats("appstoreUrl", appstoreUrl)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="appstoreUrl">
              <AppstoreIcon />
            </StyledIcon>
          </Link>
        )}

        {whatsapp && (
          <Tooltip hasArrow label={whatsapp} fontSize="xs" placement="top">
            <Link
              href={enabled ? null : `tel:${whatsapp}`}
              isExternal
              onClick={() => updateLinkClickStats("whatsapp", whatsapp)}
            >
              <StyledIcon
                backgroundColor={backgroundColor}
                urlType="whatsapp"
                className="uil uil-whatsapp"
              />
            </Link>
          </Tooltip>
        )}

        {tellonym && (
          <Link
            href={enabled ? null : `https://tellonym.me/${tellonym}`}
            isExternal
            onClick={() => updateLinkClickStats("tellonym", tellonym)}
          >
            <StyledIcon backgroundColor={backgroundColor} urlType="tellonym">
              <Img
                width="48px"
                src={cloudfrontURL("/newtellano98378272.png")}
              />
            </StyledIcon>
          </Link>
        )}

        {[
          email,
          twitter,
          instagram,
          tiktok,
          facebookUrl,
          snapchat,
          youtubeUrl,
          linkedin,
          linkedinCompany,
          goodreads,
          twitch,
          patreon,
          etsy,
          appstoreUrl,
          whatsapp,
          tellonym,
        ].some((s) => s.length > 0) || !enabled ? null : (
          <Box
            zIndex="0"
            p="1.12rem"
            color="#fff"
            textShadow="0 1px 10px black"
          >
            Social icons placeholder
          </Box>
        )}
      </IconsWrapper>
    </Box>
  );
};

SocialLinks.craft = {
  displayName: "Social handles",
  props: defaultProps,
  related: {
    settings: SocialLinksSettings,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
};
