import {
  Box,
  Collapse,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { getDailyLinkTimeseries, getLinkTopTotals } from "api/queries";

import { FlagIcon } from "react-flag-kit";
import { LinkDailyCountsGraph } from "./LinkDailyCountsGraph";
import { SupportComponentIcon } from "assets/svgs/supportIcon";
import { TopLinkCountsTable } from "./TopLinkCountsTable";
import { TopReferrerTable } from "./TopReferrerCountTable";
import UiLSocials from "@iconscout/react-unicons/icons/uil-channel";
import UilArrowDown from "@iconscout/react-unicons/icons/uil-arrow-down";
import UilArrowUp from "@iconscout/react-unicons/icons/uil-arrow-up";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import countries from "utils/countries";
import { secondsToHms } from "utils";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useState } from "react";

export const StyledTabList = styled(({ ...rest }) => (
  <TabList {...rest} overflowY="auto" />
))`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  button {
    margin-bottom: 0;
    font-size: 14px;
  }
`;

export const LinkStats = ({
  linkId,
  linkUrl,
  name,
  hidden,
  type,
}: {
  linkId: string;
  linkUrl?: string;
  name: string;
  hidden?: boolean;
  type?: string;
}) => {
  const [toggled, setToggled] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);

  const {
    data: topTotals,
    error: topTotalsError,
    isLoading: isLoadingTopTotals,
  } = useQuery(
    ["getLinkTopTotals", linkId],
    () => getLinkTopTotals(linkId ?? ""),
    {
      enabled: Boolean(linkId && toggled && shouldFetch),
      onSuccess: () => setShouldFetch(false),
    }
  );

  const {
    data: dailyLinkTimeseries,
    error: dailyLinkTimeseriesError,
    isLoading: isLoadingDailyLinkTimeseries,
  } = useQuery(
    ["getDailyLinkTimeseries", linkId],
    () => getDailyLinkTimeseries(linkId ?? ""),
    {
      enabled: Boolean(linkId && toggled && shouldFetch),
      onSuccess: () => setShouldFetch(false),
    }
  );

  if (topTotalsError || dailyLinkTimeseriesError) {
    return (
      <div>
        Something isn't right. Please try again later or contact support
      </div>
    );
  }

  const toggleContent = () => {
    setToggled(!toggled);
  };

  const getTypeIcon = () => {
    switch (type) {
      case "social":
        return <UiLSocials size="26px" />;

      case "support":
        return (
          <SupportComponentIcon style={{ height: "26px", width: "26px" }} />
        );

      default:
        return <UilLink size="26px" />;
    }
  };

  return (
    <Box boxShadow="0 1px 4px 0 #f0f0f0, 0 0 0 1px #f0f0f0">
      <Box
        p="1rem"
        onClick={toggleContent}
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        cursor="pointer"
      >
        <Box display="flex" alignItems="center">
          {getTypeIcon()}

          <Box ml="0.75rem">
            <Box>
              <Text fontWeight="semibold" wordBreak="break-word">
                {name}{" "}
                {hidden && (
                  <Tooltip
                    label="Link is hidden from your public profile"
                    fontSize="xs"
                  >
                    <i className="uil uil-info-circle" />
                  </Tooltip>
                )}
              </Text>
            </Box>
            <Text fontSize="10px" color="gray.400" wordBreak="break-word">
              {linkUrl}
            </Text>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          {toggled ? <UilArrowUp size="30px" /> : <UilArrowDown size="30px" />}
        </Box>
      </Box>

      <Collapse in={toggled} animateOpacity>
        {isLoadingTopTotals || topTotals === undefined ? (
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection="column"
            minHeight="400px"
          >
            <Spinner color="gray.500" />
            <Text fontSize="xs" color="gray.500" mt="1rem">
              Loading data..
            </Text>
          </Box>
        ) : (
          <Box minHeight="400px">
            <Tabs variant="line" mt="0.5rem" isFitted>
              <StyledTabList>
                <Tab>Summary</Tab>
                <Tab>Overview</Tab>
                <Tab>Top Countries</Tab>
                <Tab>Top Referrers</Tab>
                <Tab>Top Devices</Tab>
              </StyledTabList>
              <TabPanels p="1rem">
                <TabPanel p="0.25rem">
                  {topTotals?.data?.linkClicks?.total ? (
                    <>
                      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing="2rem">
                        <Box
                          bg="white"
                          borderRadius="6px"
                          p={{ base: "1rem", md: "1.5rem " }}
                          boxShadow="0 0px 4px -1px #999999"
                          textAlign="center"
                        >
                          <Text fontWeight="semibold">Total clicks</Text>

                          <Text
                            display="block"
                            fontSize="3xl"
                            fontWeight="bold"
                          >
                            {topTotals?.data?.linkClicks?.total}
                          </Text>
                        </Box>

                        <Box
                          bg="white"
                          borderRadius="6px"
                          p={{ base: "1rem", md: "1.5rem " }}
                          boxShadow="0 0px 4px -1px #999999"
                          textAlign="center"
                        >
                          <Text fontWeight="semibold">Top country</Text>

                          <Text
                            fontSize="2xl"
                            fontWeight="semibold"
                            display={"flex"}
                            justifyContent="center"
                            alignItems={"center"}
                          >
                            {topTotals.data.topCountries[0].name !== null && (
                              <FlagIcon
                                code={
                                  countries[
                                    topTotals.data?.topCountries[0].name
                                  ] as any
                                }
                                size={32}
                              />
                            )}
                            &nbsp;
                            {topTotals.data.topCountries[0].name === null
                              ? "Unknown"
                              : topTotals.data?.topCountries[0].name}{" "}
                          </Text>
                        </Box>

                        <Box
                          bg="white"
                          borderRadius="6px"
                          p={{ base: "1rem", md: "1.5rem " }}
                          boxShadow="0 0px 4px -1px #999999"
                          textAlign="center"
                        >
                          <Text fontWeight="semibold">Top referrer</Text>

                          <Text fontSize="2xl" fontWeight="semibold">
                            {topTotals.data.topReferrers[0].name === ""
                              ? "Direct visit"
                              : topTotals.data?.topReferrers[0]?.name}
                          </Text>
                        </Box>

                        <Box
                          bg="white"
                          borderRadius="6px"
                          p={{ base: "1rem", md: "1.5rem " }}
                          boxShadow="0 0px 4px -1px #999999"
                          textAlign="center"
                        >
                          <Text fontWeight="semibold">Top device</Text>

                          <Text fontSize="2xl" fontWeight="semibold">
                            {topTotals.data.topDevices[0].name
                              ? topTotals.data.topDevices[0].name
                                  .charAt(0)
                                  .toUpperCase() +
                                topTotals.data.topDevices[0].name.slice(1)
                              : ""}
                          </Text>
                        </Box>

                        <Box
                          bg="white"
                          borderRadius="6px"
                          p={{ base: "1rem", md: "1.5rem " }}
                          boxShadow="0 0px 4px -1px #999999"
                          textAlign="center"
                        >
                          <Text fontWeight="semibold">
                            Average Time to Click
                          </Text>

                          <Text fontSize="2xl" fontWeight="bold">
                            {secondsToHms(topTotals?.data?.timeToClick?.avg)}
                          </Text>
                        </Box>
                      </SimpleGrid>
                    </>
                  ) : (
                    <Box p={{ base: "1rem", md: "2rem " }} textAlign="center">
                      No data to display
                    </Box>
                  )}
                </TabPanel>

                <TabPanel p="0.25rem">
                  {isLoadingDailyLinkTimeseries ? (
                    <Box
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                      p="6rem 3rem"
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    <LinkDailyCountsGraph
                      dailyTimeseriesData={dailyLinkTimeseries?.data}
                    />
                  )}
                </TabPanel>
                <TabPanel p="0.25rem">
                  {topTotals.data.topCountries?.length > 0 ? (
                    <TopLinkCountsTable data={topTotals.data.topCountries} />
                  ) : (
                    <Box p={{ base: "1rem", md: "2rem " }} textAlign="center">
                      No data to display
                    </Box>
                  )}
                </TabPanel>

                <TabPanel p="0.25rem">
                  <TopReferrerTable data={topTotals.data.topReferrers} isLink />
                </TabPanel>

                <TabPanel p="0.25rem">
                  <TopLinkCountsTable data={topTotals.data.topDevices} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
