import { Box, Flex, Heading, Link, Stack, Text } from "@chakra-ui/layout";
import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import Lottie from "react-lottie";
import Page from "components/Page";
import paintBoard from "assets/jsons/paint-board.json";
import { useMediaQuery } from "@chakra-ui/react";

const defaultOptions = {
  loop: true,
  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: paintBoard,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const EmptyFrameData = ({ username }) => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <Page>
      <Box py="1rem" px={{ base: "1rem", md: "0" }}>
        <AnimatedGradientLogo />
      </Box>
      <Flex
        width="full"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py="1rem"
        //h="calc(100vh - 68px)"
      >
        <Box my="2rem">
          <Lottie
            options={defaultOptions}
            height={isSmallerThan600 ? 250 : 320}
            width={isSmallerThan600 ? 500 : 640}
          />
        </Box>

        <Stack
          spacing={{ base: "3rem", md: "3rem" }}
          textAlign="center"
          cursor="default"
        >
          <Heading as="h3" size="lg">
            A profile with username{" "}
            <Text as="span" fontWeight="900" color="#673ab7">
              <i>{username}</i>
            </Text>{" "}
            exists, but it is not yet set.
          </Heading>

          <Heading as="h4" size={isSmallerThan600 ? "sm" : "md"}>
            <Link textDecoration="underline" href={`/in/login?u=${username}`}>
              If you own this profile, login and start adding links.
            </Link>
          </Heading>
        </Stack>
      </Flex>
    </Page>
  );
};

export default EmptyFrameData;
