import { Box, Spinner, Text } from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";

import ReactPlayer from "react-player";
import { VideoSettings } from "./settings";
import { getTikTokEmbed } from "api/queries";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

const StyledReactPlayer = styled(ReactPlayer)<{
  iseditorview?: boolean;
}>`
  z-index: 0 !important;
  max-width: ${({ iseditorview, enabled }) =>
    iseditorview && enabled ? "-webkit-fill-available" : "unset"} !important;

  max-height: ${({ iseditorview, enabled }) =>
    iseditorview && enabled ? "300px" : "unset"} !important;

  margin-top: 0 auto;
  width: 100% !important;
  height: 300px !important;

  //height: ${({ videoHeight }) => `${videoHeight}`}px !important;

  & iframe {
    width: 100% !important;
    height: 300px !important;
    //height: ${({ videoHeight }) => `${videoHeight}`}px !important;

    z-index: 0 !important;

    border-radius: 20px;
  }
`;

const StyledEmbed = styled(Box)<{ iseditorview?: boolean }>`
  z-index: 0 !important;
  max-width: ${({ iseditorview, enabled }) =>
    iseditorview && enabled ? "-webkit-fill-available" : "unset"} !important;

  max-height: ${({ iseditorview, enabled }) =>
    iseditorview && enabled ? "300px" : "unset"} !important;

  width: 100% !important;
  height: 300px !important;

  & iframe {
    z-index: 0 !important;
    margin: 0 auto;
    max-height: 600px;
    border-radius: 20px;
    width: 100%;
    width: 100% !important;
    height: 300px !important;

    @media (max-width: 414px) {
      width: 100%;

      max-height: 280px;
    }
  }
`;

const StyledIframe = styled.iframe`
  display: flex;
  justify-content: center;
  z-index: 0 !important;
  margin: 0 auto;
  max-height: 738px;
  height: 738px;
  border-radius: 6px;
  //width: 100% !important;

  @media (max-width: 414px) {
    //width: 100%;

    max-height: 738px;
  }
`;

type ButtonProps = {
  url?: any;
  autoplayVideo?: boolean;
  muteVideo?: boolean;
};

export const Video: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));
  const { url, autoplayVideo, muteVideo } = props;

  const [tikTokVideoId, setTikTokVideoId] = useState(false);

  const {
    error: errorGettingTikTokVideoId,
    isLoading: isLoadingTikTokVideoId,
  } = useQuery(
    ["getTikTokEmbed", url],
    () => getTikTokEmbed(url ?? ""),

    {
      enabled: Boolean(
        url && url.includes("tiktok.com") && !url.includes("/video/") // is shortened url
      ),
      onSuccess: (data) => {
        setTikTokVideoId(data.data);
      },
    }
  );

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const renderComponent = () => {
    const videoProviders = [
      "dailymotion",
      "facebook",
      "fb.watch",
      "youtu.be",
      "dai.ly",
      "dailymotion.com",
      "vimeo.com",
      "mixcloud",
      "wistia",
      "twitch.tv/videos",
      "twitch.tv",
      "vidyard.com",
      "streamable.com",
      "soundcloud",
      "kaltura.com",
    ];

    // eslint-disable-next-line no-useless-escape
    const regex = /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/;

    const isVideoUrl =
      (url && url.match(regex)) ||
      (url && videoProviders.some((vp) => url.includes(vp)));

    const isEmbedCode = url.startsWith("<iframe");

    const isTikTokVideo = url.includes("tiktok.com");

    const tikTokVideoIdFromUrl =
      url.includes("tiktok.com") && url && url.includes("/video/")
        ? url.match(/video\/(\d+)/)[1]
        : tikTokVideoId;

    if (isEmbedCode) {
      return (
        <StyledEmbed
          enabled={enabled ? 1 : 0}
          iseditorview={isEditorView ? 1 : 0}
          style={{
            pointerEvents: enabled ? "none" : "auto",
          }}
          dangerouslySetInnerHTML={{
            __html: url,
          }}
        ></StyledEmbed>
      );
    }

    if (isTikTokVideo) {
      if (errorGettingTikTokVideoId && isEditorView) {
        return (
          <Text
            style={{
              fontSize: "1rem",
              color: "#f74949",
              cursor: "pointer",
              textShadow: "0px 0px 10px #1a1a1a",
            }}
            textAlign="center"
          >
            <i className="uil uil-exclamation-triangle" /> Error getting TikTok
            video.
          </Text>
        );
      }
      return isLoadingTikTokVideoId ? (
        <Box py="6rem" display="flex" justifyContent="center">
          <Spinner />
        </Box>
      ) : (
        <Box display={"flex"} justifyContent="center">
          <StyledIframe
            src={`https://www.tiktok.com/embed/${tikTokVideoIdFromUrl}`}
            allow="encrypted-media;"
          />
        </Box>
      );
    }

    if (isVideoUrl) {
      return (
        <StyledReactPlayer
          url={url}
          fallback={<Spinner />}
          playing={autoplayVideo}
          muted={muteVideo}
          controls
          enabled={enabled ? 1 : 0}
          iseditorview={isEditorView ? 1 : 0}
          //height={isSmallerThan600 ? "216px" : "300px"}
          //width={isSmallerThan600 ? "auto" : "100%"}
        />
      );
    }

    return enabled ? (
      <Text
        style={{
          fontSize: "1rem",
          color: "#f74949",
          cursor: "pointer",
          textShadow: "0px 0px 10px #1a1a1a",
        }}
        textAlign="center"
      >
        <i className="uil uil-exclamation-triangle" /> Invalid video URL or
        embed code.
      </Text>
    ) : null;
  };

  const renderHelpLabel = () => {
    if (enabled && isEditorView && !url) {
      return (
        <Text
          p="1rem"
          my="1rem"
          fontSize="xs"
          textAlign="center"
          textShadow="0 1px 5px black"
          style={{
            color: "#eee",
            cursor: "pointer",
          }}
        >
          <i
            className="uil uil-exclamation-triangle"
            style={{
              color: "#f74949",
            }}
          />{" "}
          Video URL missing
        </Text>
      );
    }

    if (enabled && isEditorView && url) {
      return (
        <Text
          py="0.25rem"
          fontSize="xs"
          style={{
            color: "#eee",
            cursor: "pointer",
            textShadow: "0px 0px 10px #000000",
          }}
          textAlign="center"
        >
          Edit video URL
        </Text>
      );
    }

    return null;
  };

  return (
    <Box ref={(ref) => connect(ref)} textAlign="center" width="100%">
      {url && (
        <Box
          borderRadius="8x"
          width="100%"
          pointerEvents={enabled ? "none" : "auto"}
          //px="1.5rem"
          mb="0.75rem"
        >
          {renderComponent()}
        </Box>
      )}

      {renderHelpLabel()}
    </Box>
  );
};

Video.craft = {
  displayName: "Video",

  props: {
    url: "",

    autoplayVideo: false,
    muteVideo: true,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: VideoSettings,
  },
};
