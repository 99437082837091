export const CTRIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="28"
      height="28"
      fill="#fff"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <metadata>
        {" "}
        Svg Vector Icons : http://www.onlinewebfonts.com/icon{" "}
      </metadata>
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M5482.3,4981.7c-214.8-74.5-372.7-234.6-447.3-451.6c-30.7-94.3-35.1-342-35.1-2433.6V-231.8l521.8,519.6l519.6,521.8l1666.2,4.4c1659.6,6.6,1666.2,6.6,1769.3,54.8c144.7,65.8,309.1,228,370.5,363.9l52.6,114v1578.5v1578.5l-78.9,142.5c-50.4,92.1-114,171-182,221.4c-208.3,157.8-111.8,151.3-2192.4,151.3C5809,5016.8,5570,5012.4,5482.3,4981.7z M9397.9,4611.2c32.9-24.1,81.1-72.3,105.3-105.2c46-59.2,46-83.3,50.4-1565.4l6.6-1506.2l-50.4-85.5c-26.3-48.2-83.3-107.4-125-131.5c-74.6-46-107.4-46-1775.8-52.6l-1699.1-4.4l-278.4-280.6l-280.6-278.4v1889.8c0,1284.7,6.6,1909.6,24.1,1951.2c30.7,81.1,103,157.8,184.2,195.1c52.6,24.1,407.8,28.5,1922.7,24.1C9319,4657.2,9338.8,4657.2,9397.9,4611.2z" />
          <path d="M6183.9,4282.3c-497.7-179.8-642.4-793.6-274-1159.8c142.5-144.7,274-197.3,493.3-197.3c219.2,0,350.8,52.6,493.3,197.3c144.7,142.5,197.3,274,197.3,493.3c0,219.2-52.6,350.8-197.3,493.3C6716.6,4288.9,6409.7,4363.5,6183.9,4282.3z M6541.2,3935.9c225.8-94.3,280.6-388.1,105.2-563.4c-245.5-247.7-648.9-26.3-585.4,320.1C6100.6,3898.7,6341.7,4021.4,6541.2,3935.9z" />
          <path d="M7297.6,2951.6c-405.6-749.8-745.4-1381.2-754.2-1401c-11-35.1,4.4-39.5,160-39.5c168.8,0,175.4,2.2,201.7,61.4c15.3,32.9,346.4,646.8,736.6,1363.7c390.2,716.9,716.9,1322,723.5,1343.9c13.1,32.9-2.2,37.3-155.7,37.3l-173.2-2.2L7297.6,2951.6z" />
          <path d="M8288.6,2879.2c-497.7-179.8-642.4-793.6-274.1-1159.8c142.5-144.7,274.1-197.3,493.3-197.3s350.8,52.6,493.3,197.3c144.7,142.5,197.3,274.1,197.3,493.3s-52.6,350.8-197.3,493.3C8821.3,2885.8,8514.4,2960.3,8288.6,2879.2z M8645.9,2532.8c225.8-94.3,280.6-388.1,105.2-563.5c-245.5-247.7-648.9-26.3-585.4,320.1C8205.3,2495.5,8446.4,2618.3,8645.9,2532.8z" />
          <path d="M3421.5,3615.9v-701.6h175.4h175.4v701.6v701.6h-175.4h-175.4V3615.9z" />
          <path d="M1601.8,3495.3l-120.6-120.6l499.9-499.9l497.7-497.7l125,127.2l127.2,125l-493.3,493.3c-271.9,271.9-497.7,493.3-504.2,493.3S1667.6,3561,1601.8,3495.3z" />
          <path d="M3432.4,2193c-201.7-52.6-379.3-197.3-471.4-385.9l-54.8-109.6l-6.6-861.6l-6.6-861.6L2794.4,62c-138.1,120.6-278.4,164.4-491.1,151.3c-127.2-6.6-192.9-24.1-265.3-65.8C1121.7-376.5,367.5-825.9,303.9-887.3C260-929,194.3-1016.7,161.4-1080.3L100-1196.4v-844.1c2.2-844.1,2.2-844.1,59.2-1056.7c241.2-881.3,914.2-1506.2,1806.5-1672.8c254.3-48.2,686.2-35.1,951.5,30.7c228,57,620.4,247.7,811.2,394.6c78.9,59.2,710.3,677.4,1403.1,1370.3l1260.6,1262.8v107.4c0,89.9-11,120.6-61.4,173.2c-125,129.4-530.6,267.5-791.5,267.5c-184.1,0-436.3-63.6-627-155.6c-146.9-74.6-228-135.9-399-300.4l-212.7-206.1L4294-65.2l-6.6,1762.7l-74.5,138.1c-83.3,155.7-153.5,221.4-317.9,304.8C3776.7,2201.8,3557.4,2225.9,3432.4,2193z M3789.8,1802.7c30.7-21.9,76.7-70.2,100.8-103c46-59.2,46-83.3,57-1938.1l11-1878.9l50.4-72.4c57-78.9,151.3-135.9,225.8-135.9c32.9,0,149.1,98.7,357.4,302.5c256.5,249.9,339.8,317.9,473.6,385.9c280.6,138.1,589.8,155.6,844.1,48.2l87.7-37.3L4802.7-2810C4145-3461.2,3557.4-4031.2,3498.2-4079.5c-144.7-111.8-420.9-249.9-624.8-306.9c-234.6-67.9-633.6-83.3-879.2-35.1c-710.3,144.7-1260.6,651.1-1482.1,1365.8L453-2862.7l-2.2,800.2c0,798,0,800.2,50.4,870.4c35.1,48.2,289.4,210.5,887.9,559.1c734.5,427.5,850.7,488.9,947.1,495.5c72.3,6.6,129.4-2.2,175.4-28.5c39.5-19.7,217-217,396.8-436.3l326.7-401.2l11,1317.6c8.8,940.5,17.5,1330.8,37.3,1361.5c35.1,61.4,118.4,135.9,182,162.2C3546.4,1872.9,3721.8,1853.2,3789.8,1802.7z" />
          <path d="M790.6,1511.2v-175.4h701.6h701.6v175.4v175.4h-701.6H790.6V1511.2z" />
        </g>
      </g>
    </svg>
  );
};
