import { useState } from "react";
import { createContainer } from "react-tracked";

const useValue = () =>
  useState({
    linksContainerId: "",
    headerContainerId: "",
    bioContainerId: "",
  });

export const { Provider, useTracked } = createContainer(useValue);
