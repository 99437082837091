import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import {
  CardElement,
  Elements,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  createStripeChargeIntent,
  getStripeIntegrationData,
} from "api/queries";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import AlertMessage from "components/AlertMessage";
import UilLock from "@iconscout/react-unicons/icons/uil-lock";
import UilRightArrow from "@iconscout/react-unicons/icons/uil-arrow-right";
import { loadStripe } from "@stripe/stripe-js";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const StripeApplePay = ({
  amount,
  closeModal,
}: {
  amount?: number;
  closeModal: () => void;
}) => {
  const { username } = useParams();
  const {
    data: stripeIntegrationData,
    error: errorGettingStripeIntegrationData,
    isLoading: isLoadingStripeIntegrationData,
  } = useQuery(
    ["getStripeIntegrationData", username],
    () => getStripeIntegrationData({ username }),
    {
      enabled: Boolean(username),
    }
  );

  const connectedAccountId =
    stripeIntegrationData?.data?.stripeIntegration?.accountId; // get it from backend

  const stripePublishableKey =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_PUBLIC_PUBLISHABLE_KEY
      : process.env.REACT_APP_STRIPE_PUBLIC_PUBLISHABLE_KEY_TEST;

  const stripePromise = loadStripe(stripePublishableKey, {
    stripeAccount: connectedAccountId,
  });

  if (isLoadingStripeIntegrationData) {
    return (
      <Center my="6rem" py="6rem">
        <Spinner />
      </Center>
    );
  }

  if (errorGettingStripeIntegrationData) {
    return (
      <AlertMessage
        status="error"
        message="There was an error setting up payment method. Please try again or let the creator know."
      />
    );
  }

  return connectedAccountId ? (
    <Elements stripe={stripePromise}>
      <PaymentFormIn
        amount={amount}
        connectedAccountId={connectedAccountId}
        closeModal={closeModal}
      />
    </Elements>
  ) : (
    <Box textAlign="center" fontSize="sm" color="gray.400" py="3rem">
      No payment method has been set up by the creator.
    </Box>
  );
};

const PaymentFormIn = ({
  amount,
  connectedAccountId,
  closeModal,
}: {
  amount?: number;
  connectedAccountId: string;
  closeModal?: () => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const { username } = useParams();

  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  const [supportAmount, setSupportAmount] = useState("3");

  const [isLoadingWallet, setIsLoadingWallet] = useState(true);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const [showEmailRequiredMessage, setShowEmailRequiredMessage] =
    useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isConfirmingStripePayment, setIsConfirmingStripePayment] =
    useState(false);

  const format = (val) => `$` + val;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
  });

  const { email, name, message } = watch();

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.

      return;
    }

    if (isFormFilled) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Donation - Alfalink",
          amount: parseInt(supportAmount) * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      try {
        pr.canMakePayment().then((result) => {
          setIsLoadingWallet(false);
          if (result) {
            setPaymentRequest(pr);
          }
        });
      } catch (error: any) {
        console.warn("Err ", error);
      }

      pr.on("paymentmethod", async (e) => {
        const { data: clientSecret } = await triggerCreateStripeCharge({
          username: username,
          connectedAccountId: connectedAccountId,
          amount: parseInt(supportAmount),
          donorEmail: email,
          donorName: name,
          donorMessage: message,
        });

        setIsConfirmingStripePayment(true);
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(error);

          setIsConfirmingStripePayment(false);

          toast({
            title: error?.message ?? "Something went wrong",
            status: "error",
            variant: "subtle",
            position: "top",
            isClosable: true,
          });
          e.complete("fail");
          return;
        }

        e.complete("success");
        setIsFormFilled(false);

        if (paymentIntent.status === "succeeded") {
          paymentSuccess();
        }

        if (paymentIntent.status === "requires_action") {
          stripe.confirmCardPayment(clientSecret);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, isFormFilled, isLoadingWallet, supportAmount]);

  const {
    mutateAsync: triggerCreateStripeCharge,
    // error: errorCreateStripeCharge,
    isLoading: isLoadingCreateStripeCharge,
  } = useMutation(createStripeChargeIntent);

  const paymentSuccess = () => {
    setIsConfirmingStripePayment(false);
    toast({
      title: "Payment successful",
      description: "Thank you for your support",
      status: "success",
      variant: "subtle",
      position: "top",
      isClosable: true,
    });
    setIsFormFilled(false);
    closeModal();
  };

  const onSubmitHandler = async (data) => {
    const { email, name, message } = data;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    try {
      const { data: clientSecret } = await triggerCreateStripeCharge({
        username: username,
        connectedAccountId: connectedAccountId,
        amount: parseInt(supportAmount),
        donorEmail: email,
        donorName: name,
        donorMessage: message,
      });

      try {
        setIsConfirmingStripePayment(true);
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: name ?? "",
              email: email ?? "",
            },
          },
        });

        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(result.error);

          setIsConfirmingStripePayment(false);

          toast({
            title: result?.error?.message ?? "Something went wrong",
            status: "error",
            variant: "subtle",
            position: "top",
            isClosable: true,
          });
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === "succeeded") {
            paymentSuccess();

            // check webhook on server
          }
        }
      } catch (error) {
        console.error("Stripe error: ", error);
        toast({
          title:
            "Something went wrong. Please try again later or contact support.",
          status: "error",
          variant: "subtle",
          position: "top",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      console.log("Error getting client secret");
      setIsConfirmingStripePayment(false);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#eeeeee",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  /*
  const updatePrice = () => {
    const amount = parseInt(supportAmount) * 100;

    console.log("Update ", {
      amount: amount,
      parsesd: parseInt(supportAmount) * 100,
      supportAmount,
    });

    paymentRequest.update({
      total: {
        label: "Donation - Alfalink",
        amount: amount,
      },
    });
  };

  */

  const handleProceedToPayment = () => {
    if (email === "" || email === undefined) {
      setShowEmailRequiredMessage(true);
      return;
    }

    setIsFormFilled(true);
  };

  const handleOnBlur = () => {
    if (email) {
      setShowEmailRequiredMessage(false);
      return;
    }
  };

  var ua = navigator.userAgent || navigator.vendor;
  var isInstagram =
    isSmallerThan600 && ua.indexOf("Instagram") > -1 ? true : false;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)} style={{ width: "100%" }}>
        <Collapse in={!isFormFilled} animateOpacity>
          <Stack spacing="1rem" width="100%">
            <RadioGroup
              onChange={setSupportAmount}
              value={supportAmount}
              size="lg"
            >
              <Stack
                direction="row"
                justifyContent="space-evenly"
                fontWeight="bold"
              >
                <Radio value={"3"}>$3</Radio>
                <Radio value={"5"}>$5</Radio>
                <Radio value={"10"}>$10</Radio>
              </Stack>
            </RadioGroup>

            <NumberInput
              onChange={(valueString) => setSupportAmount(valueString)}
              value={format(supportAmount)}
              max={250}
              min={1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Stack>

          <br />

          <Stack spacing="1rem">
            <FormControl isInvalid={!!errors.email} mb="0.25rem">
              <FormLabel fontSize="sm" mb="4px">
                <Text>Email *</Text>
              </FormLabel>
              <Input
                {...register("email")}
                type="email"
                placeholder="example@email.com"
                name="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
                autoFocus
                onBlur={handleOnBlur}
                disabled={isFormFilled}
              />

              {showEmailRequiredMessage && (
                <FormHelperText fontSize="xs" color="orange.500">
                  Please enter your email
                </FormHelperText>
              )}

              <FormHelperText fontSize="xs">
                Your email might be shared with the creator.
              </FormHelperText>

              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm" mb="4px">
                Name
              </FormLabel>
              <Input
                {...register("name")}
                type="text"
                placeholder="Your name"
                name="name"
              />
            </FormControl>

            <FormControl>
              <FormLabel mb="4px" fontSize="sm">
                Message
              </FormLabel>
              <Textarea
                {...register("message")}
                size="sm"
                maxLength={1000}
                resize="none"
                placeholder={`Optional message to ${username}`}
                name="message"
                borderRadius={"0.375rem"}
              />
            </FormControl>
          </Stack>

          <Button
            variant="outline"
            colorScheme="purple"
            onClick={handleProceedToPayment}
            my="2rem"
            isFullWidth
            rightIcon={<UilRightArrow size="1rem" />}
          >
            Proceed to payment{" "}
          </Button>
        </Collapse>

        <Collapse in={isFormFilled} animateOpacity>
          <Box pt="1rem" pb="2rem">
            {isLoadingWallet ? (
              <Center py="0.75rem">
                <Spinner size="sm" />
              </Center>
            ) : null}

            {paymentRequest && !isLoadingWallet && (
              <PaymentRequestButtonElement
                options={{
                  paymentRequest: paymentRequest,
                }}
              />
            )}

            {isInstagram && !paymentRequest && !isLoadingWallet ? (
              <Box textAlign="center" py="2rem">
                <Text pb="2rem" fontSize="sm" color="#ddd">
                  Open link in browser to pay with Apple Pay or Google Pay
                </Text>
              </Box>
            ) : null}

            {!paymentRequest && !isLoadingWallet && !isInstagram ? (
              <Text pb="1rem" fontSize="sm" color="#ddd">
                You don't seem to have Apple Pay or Google Pay active on this
                browser.
              </Text>
            ) : null}

            <Flex align="center">
              <Divider borderColor="#666666" />
              <Text padding="1rem" minWidth="120px" fontSize="xs" color="#ddd">
                Pay with Card
              </Text>
              <Divider borderColor="#666666" />
            </Flex>

            <FormLabel mb="4px" fontSize="sm">
              Card information
            </FormLabel>

            <Box p="1rem" border="1px solid #eee" borderRadius={"0.375rem"}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Box>

            {!username && (
              <Text
                my="0.5rem"
                fontSize="xs"
                textAlign="center"
                color="orange.400"
              >
                Transactions are disabled in Editor view.
              </Text>
            )}

            {elements && stripe && (
              <Button
                disabled={
                  !stripe ||
                  !elements ||
                  !username ||
                  isLoadingCreateStripeCharge ||
                  isConfirmingStripePayment ||
                  parseInt(supportAmount) < 1 ||
                  !supportAmount
                }
                my="2rem"
                type="submit"
                variant="solid"
                colorScheme="purple"
                fontWeight="300"
                isFullWidth
                leftIcon={<UilLock size="1rem" />}
                isLoading={
                  isConfirmingStripePayment || isLoadingCreateStripeCharge
                }
              >
                Pay&nbsp;
                <Text fontWeight="bold">
                  {" "}
                  ${parseInt(supportAmount) ? parseInt(supportAmount) : 0}
                </Text>
              </Button>
            )}

            <Text fontSize="xs" textAlign="center" color="gray.600">
              Payment handled securely by{" "}
              <Link href="https://stripe.com" isExternal>
                Stripe
              </Link>
            </Text>
          </Box>
        </Collapse>
      </form>
    </>
  );
};
