import {
  Button,
  Divider,
  Drawer,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { FontWeightRadios, SelectFontItem, TextAlignRadios } from "../commons";
import {
  IconsWrapper,
  SettingIconButton,
} from "components/MobbileSettingsPanel/IconsWrapper";
import React, { useCallback, useState } from "react";
import { useEditor, useNode } from "@craftjs/core";

import { CloneIcon } from "assets/svgs/cloneIcon";
import Delete from "@iconscout/react-unicons/icons/uil-trash-alt";
import { DrawerContent } from "components/MobbileSettingsPanel/Drawer";
import { FontColorIcon } from "assets/svgs/fontColorIcon";
import { FontFormatIcon } from "assets/svgs/fontFormatIcon";
import { FontTypeIcon } from "assets/svgs/fontTypeIcon";
import { PaintBucketIcon } from "assets/svgs/paintBucket";
import { SettingsItem } from "components/EditorComponents/SettingsPanel";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilEditKeyboard from "@iconscout/react-unicons/icons/uil-keyboard";
import UilMargins from "@iconscout/react-unicons/icons/uil-line-spacing";
import { getRandomId } from "@craftjs/utils";

export const TextSettings = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [drawerOpen, setDrawer] = useState("");

  const { id, propValue, parent } = useNode((node) => ({
    propValue: node.data.props,
    parent: node.data.parent,
  }));

  const toast = useToast();

  const { actions, query } = useEditor();

  const getCloneTree = useCallback((idToClone) => {
    const tree = query.node(idToClone).toNodeTree();
    const newNodes = {};

    const changeNodeId = (node, newParentId) => {
      const newRandomNodeId = getRandomId();

      const childNodes = node.data.nodes.map((childId) =>
        changeNodeId(tree.nodes[childId], newRandomNodeId)
      );
      const linkedNodes = Object.keys(node.data.linkedNodes).reduce(
        (accum, id) => {
          const newNodeId = changeNodeId(
            tree.nodes[node.data.linkedNodes[id]],
            newRandomNodeId
          );
          return {
            ...accum,
            [id]: newNodeId,
          };
        },
        {}
      );

      let tmpNode = {
        ...node,
        id: newRandomNodeId,
        data: {
          ...node.data,
          parent: newParentId || node.data.parent,
          nodes: childNodes,
          linkedNodes,
        },
      };
      let freshnode = query.parseFreshNode(tmpNode).toNode();
      newNodes[newRandomNodeId] = freshnode;
      return newRandomNodeId;
    };

    const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId], parent);
    return {
      rootNodeId,
      nodes: newNodes,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClone = (e: any) => {
    e.preventDefault();
    const theNode = query.node(id).get();
    const parentNode = query.node(theNode.data.parent).get();
    const indexToAdd = parentNode.data.nodes.indexOf(id);
    const tree = getCloneTree(id);
    actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);

    toast({
      title: "Label cloned",
      status: "success",
      variant: "subtle",
      isClosable: true,
      position: "top",
    });

    actions.clearEvents();

    /*
    // uncomment this code block to have the clone
    // function to work properly

    setTimeout(() => {
      actions.deserialize(query.serialize());
      actions.selectNode(tree.rootNodeId);
    }, 150);

    */
  };

  const closeDrawer = () => {
    setDrawer("");
  };

  const MobileEditTextSettingItem = (
    <SettingsItem
      full
      propKey="text"
      type="text"
      label="Text"
      placeholder="placeholder"
    />
  );

  const MobileTypographySettingItem = (
    <>
      <SettingsItem
        full
        propKey="fontSize"
        type="fontSize"
        sliderMin={10}
        sliderMax={42}
        label="Font Size"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="textAlign"
        type="buttonRadio"
        label="Align text"
      >
        {TextAlignRadios}
      </SettingsItem>

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="fontWeight"
        type="buttonRadio"
        label="Font weight"
      >
        {FontWeightRadios}
      </SettingsItem>
    </>
  );

  const MobileLabelMarginSettingItems = (
    <>
      <SettingsItem
        full
        propKey="marginTop"
        type="slider"
        sliderMin={0}
        sliderMax={44}
        label="Margin from top"
      />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="marginBottom"
        type="slider"
        sliderMin={0}
        sliderMax={44}
        label="Margin from bottom"
      />
    </>
  );

  const TypographySettingsItems = (
    <>
      {MobileEditTextSettingItem}

      <Divider my="1em" />

      {MobileTypographySettingItem}

      <Divider my="1.5em" />

      {MobileLabelMarginSettingItems}
    </>
  );

  const MobileTextColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Label color
      </Heading>
      <SettingsItem
        full={true}
        propKey="color"
        type="color"
        useGradient={false}
      />
    </>
  );

  const MobileBackgroundColorSettingItem = (
    <>
      <Heading
        display={{ base: "none", md: "block" }}
        as="h6"
        size="xs"
        pl="2px"
      >
        Label background
      </Heading>
      <SettingsItem full={true} propKey="labelBackground" type="color" />

      <Divider my="1em" />

      <SettingsItem
        full
        propKey="labelPadding"
        type="slider"
        sliderMin={0}
        sliderMax={24}
        label="Label Background padding"
      />
    </>
  );

  const ColorSettingItem = (
    <>
      {MobileTextColorSettingItem}

      <Divider my="1em" />

      {MobileBackgroundColorSettingItem}
    </>
  );

  return (
    <React.Fragment>
      {isLargerThan768 ? (
        <Tabs isFitted>
          <TabList mb="1em">
            <Tab>Typography</Tab>
            <Tab>Color</Tab>
            <Tab>Font</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div>{TypographySettingsItems}</div>

              <Divider my="1rem" />

              <Button mb="1.5rem" variant="outline" onClick={handleClone}>
                <CloneIcon /> <Text pl="0.25rem"> Clone Label</Text>
              </Button>
            </TabPanel>

            <TabPanel>{ColorSettingItem}</TabPanel>
            <TabPanel>{SelectFontItem}</TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <>
          <IconsWrapper
            hidden={
              drawerOpen === "edit" ||
              drawerOpen === "textColor" ||
              drawerOpen === "backgroundColor" ||
              drawerOpen === "typography" ||
              drawerOpen === "margin" ||
              drawerOpen === "font"
            }
          >
            <SettingIconButton onClick={() => setDrawer("edit")}>
              <UilEditKeyboard />
              <Text>Edit</Text>
            </SettingIconButton>

            <SettingIconButton onClick={() => setDrawer("typography")}>
              <FontFormatIcon />
              <Text>Typography</Text>
            </SettingIconButton>

            <SettingIconButton onClick={() => setDrawer("margin")}>
              <UilMargins />
              <Text>Margins</Text>
            </SettingIconButton>

            <SettingIconButton onClick={() => setDrawer("textColor")}>
              <FontColorIcon />

              <div
                style={{
                  width: "32px",
                  height: "3px",
                  borderRadius: "50px",
                  marginBottom: "1px",
                  background: propValue.color ? propValue.color : "#eee",
                }}
              />

              <Text>Color</Text>
            </SettingIconButton>

            <SettingIconButton onClick={() => setDrawer("backgroundColor")}>
              <PaintBucketIcon />

              <div
                style={{
                  width: "32px",
                  height: "3px",
                  borderRadius: "50px",
                  marginBottom: "1px",
                  background: propValue.labelBackground
                    ? propValue.labelBackground
                    : "#111",
                }}
              />

              <Text>Background</Text>
            </SettingIconButton>

            <SettingIconButton onClick={() => setDrawer("font")}>
              <FontTypeIcon />

              <Text>Font</Text>
            </SettingIconButton>

            <SettingIconButton onClick={handleClone}>
              <CloneIcon />

              <Text>Clone</Text>
            </SettingIconButton>

            <SettingIconButton
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                actions.delete(id);
              }}
            >
              <Delete />
              <Text>Delete</Text>
            </SettingIconButton>
          </IconsWrapper>

          <Drawer
            placement="bottom"
            onClose={closeDrawer}
            isOpen={drawerOpen === "edit"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Label" pb="4rem">
              {MobileEditTextSettingItem}
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "typography"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Typography">
              {MobileTypographySettingItem}
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "textColor"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Label color">
              {MobileTextColorSettingItem}

              <Button
                mt="1.5rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("backgroundColor")}
              >
                Change background color <UilArrowRight size="20px" />
              </Button>
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "backgroundColor"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Label background color">
              {MobileBackgroundColorSettingItem}

              <Button
                mt="1.5rem"
                variant="outline"
                size="sm"
                onClick={() => setDrawer("textColor")}
              >
                Change text color <UilArrowRight size="20px" />
              </Button>
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            size="xs"
            onClose={closeDrawer}
            isOpen={drawerOpen === "margin"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Spacing margins">
              {MobileLabelMarginSettingItems}
            </DrawerContent>
          </Drawer>

          <Drawer
            placement="bottom"
            onClose={closeDrawer}
            isOpen={drawerOpen === "font"}
            blockScrollOnMount={false}
          >
            <DrawerContent title="Font style">{SelectFontItem}</DrawerContent>
          </Drawer>
        </>
      )}
    </React.Fragment>
  );
};
