import {
  Button,
  Fade,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";

import UilAngleRight from "@iconscout/react-unicons/icons/uil-angle-right";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const StyledButton = styled(Button)`
  :hover {
    svg {
      transition: all 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translateX(3px);
    }
  }
`;

const usernamePlaceholders = [
  "TonySoprano",
  "WalterWhite",
  "MichaelScott",
  "MotherOfDragons",
  "HomerSimpson",
];

const GetStartedInput = ({ showInput }: { showInput?: boolean }) => {
  const [username, setUsername] = useState("");

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    return history.push(
      username ? `/in/signup?username=${username}` : "/in/signup"
    );
  };

  const randomUsernamePlaceholder =
    usernamePlaceholders[
      Math.floor(Math.random() * usernamePlaceholders.length)
    ];

  return (
    <form onSubmit={handleSubmit}>
      {showInput ? (
        <FormControl
          fontWeight="light"
          maxWidth="352px"
          margin="0 auto"
          autoFocus={false}
        >
          <InputGroup autoFocus={false}>
            <InputLeftElement
              autoFocus={false}
              pointerEvents="none"
              children={
                <Text
                  pl="5.25rem"
                  pt="8px"
                  fontSize="18px"
                  alignItems="center"
                  verticalAlign="center"
                  size="60px"
                  color="gray.500"
                >
                  alfalink.co/
                </Text>
              }
            />

            <Input
              rounded="full"
              pl="6.9rem"
              type="username"
              placeholder={randomUsernamePlaceholder}
              size="lg"
              name="username"
              autoFocus={false}
              defaultValue=""
              backgroundColor="#ffffff"
              onChange={(event) => setUsername(event.currentTarget.value)}
              _focus={{
                borderColor: "#fd8ef0",
              }}
            />
          </InputGroup>
        </FormControl>
      ) : null}

      {
        <Fade in>
          <StyledButton
            mt="1rem"
            colorScheme="brand"
            type="submit"
            variant="outline"
            rounded="full"
          >
            Get started for free <UilAngleRight />
          </StyledButton>
        </Fade>
      }
    </form>
  );
};

export default GetStartedInput;
