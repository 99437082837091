export const LinkStylesIcon = () => {
  return (
    <svg
      version="1.1"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25px"
      height="24px"
      fill="currentColor"
      viewBox="-0.5 0.5 42 42"
    >
      <path
        d="M11.5,4.5c-6.939,0-11,4.47-11,11v11c0,6.971,3.859,11,11,11h18c7.4,0,11-4.029,11-11v-11c0-6.97-4.061-11-11-11H11.5z
        M10.49,7.5H30.5c4.59,0,7,2.52,7,7v13c0,4.62-2.45,7-7,7h-20c-4.54,0-7-2.49-7-7V14.37C3.5,9.99,6.029,7.5,10.49,7.5z"
      />
    </svg>
  );
};
