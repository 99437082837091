import { Box, useMediaQuery } from "@chakra-ui/react";
import { UserComponent, useEditor, useNode } from "@craftjs/core";
// import { useState } from "react";
// import {Link} from "react-router-dom";

import { Settings } from "./settings";
// import { useLocation } from "react-router-dom";
import SmallLogo from "./mintmobile_small.png";
import FoxLogo from "./mintmobile_fox-min.png";
import styled, { css } from "styled-components";
import { ContainerQuery } from 'react-container-query';
import classnames from 'classnames';
import { useParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { EVENT_LINK_CLICK } from "utils/events";
import { HAS_ALREADY_REGISTERED_TTC } from "utils/constants";
import { registerEvent } from "api/queries";

type ButtonProps = {
  text1?: string;
  text2?: string;
  text3?: string;
  url?: string;
  buttonText?: string;
  defaultOpen?: string;
};

const query = {
  'size_is_small': {
    minWidth: 150,
    maxWidth: 399,
  },
  'size_is_large': {
    minWidth: 400,
  },
};

const MintMobileContainer = styled(Box)<ButtonProps>`
  width: 100%;
  height: unset;
  letter-spacing: .05rem;
  font-weight: normal;
  container-type: inline-size;
  container-name: mintcontainer;

  color: ${({ color }) => color} !important;
  font-size: ${({ fontSize }) => fontSize} !important;

  font-family: Oswald !important;


  cursor: "default";
  div,
  span {
    font-family: ${({ fontFamily }) => `${fontFamily}`} !important;
  }

  .mint_mobile_tag {
    text-align: center;
    color: black;
    font-size: 15px;
    user-select: none;
  }

  .mint_mobile_learn_more {
    user-select: none;
    padding: 8px;
    transition: color 0.2s ease-in-out;
    text-align: center;
    cursor: pointer;
    color: #549670;
    &:hover,
    &:focus{
      color: #347650;
    }

    span {
      padding: 0 8px;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
      font-size: 10px;
      top: -2px;
    }
  }

  .mint_mobile_container_view {
    margin-top: 8px;
    max-height: 200px;
    overflow: hidden;
    transition: all 1.5s ease-in-out;
    user-select: none;
  }

  .mint_mobile_container_view_min {
    opacity: 1;
    max-height: 200px;
  }

  .mint_mobile_container_view_max {
    max-height: 0px;
    opacity: 0;
  }


  ${ props => !props.isExpanded && css`

    &.size_is_large {
      .mint_mobile_text1 {
        font-size: 26px;
      }
    }

  `};

  ${ props => props.isExpanded && css`
    .mint_mobile_learn_more {
    }
    .mint_mobile_container_view_min {
      max-height: 0px;
      opacity: 0;
    }
    .mint_mobile_container_view_max {
      max-height: 350px;
      opacity: 1;
    }
    .mint_mobile_text2 {
      color: #000000;
    }
    .mint_mobile_text1.mint_mobile_text1_expanded {
      font-size: 24px;
      background-color: #549670;
      color: white;
      padding: 8px 24px 8px 24px;
      margin: 8px 0 8px 0;
    }
    .mint_mobile_text3 {
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      z-index: 1;
      display: block;
      color: #549670;
      &:before {
          border-top: 2px solid #549670;
          content:"";
          margin: 0 auto; /* this centers the line to the full width specified */
          position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
          top: 50%; left: 0; right: 0; bottom: 0;
          width: 95%;
          z-index: -1;
      }

      span {
          /* to hide the lines from behind the text, you have to set the background color the same as the container */
          background: #e9f3ed;
          padding: 0 15px;
      }
    }
    img {
      margin-top: 0px;
    }

    .mint_mobile_left_block {
      float: left;
      width: 40%;
    }
    .mint_mobile_right_block {
      float: left;
      width: 60%;
    }

    &.size_is_small {
      .mint_mobile_left_block img {
        width: 80%;
      }
      .mint_mobile_left_block {
        width: 100%;
        height: 0px;
        opacity: 0.3;
      }
      .mint_mobile_right_block {
        width: 100%;
        z-index: 999;
        position: relative;
      }
    }
  `};


`;

const Text1Block = styled(Box)<ButtonProps>`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  transition: all 0.5s ease-in-out;

  sub {
    bottom: 0;
  }
  sup {
    top: -4px;
  }
`;


const LogoImageBlock = styled(Box)<ButtonProps>`
  width: 100%;
  margin: 8px 0;

  img {
    text-align: center;
    height: 20px;
    display: block;
    margin: 0 auto;
  }

`;


const MintLink = styled(Box)`
  margin: 10px;

  font-family: Oswald !important;

  > a {
    transition: background-color 0.2s ease-in-out;
    border-radius: 14px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: .1rem;
    display: block;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 8px;
    background-color: #c95100;
    font-family: Oswald !important;
    color: white;
    text-decoration: underine;

    &:hover,
    &:focus{
      color: white;
      background-color: #b94100;
    }
    &:active{
      color: white;
    }
  }
`;

export const MintMobile: UserComponent<ButtonProps> = (props: any) => {
  const {
    nodeId,
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
  }));

  const { username } = useParams();


  const [pageLoadedDate, setPageLoadedDate] = useState(undefined);
  useEffect(() => {
    if (username) {
      setPageLoadedDate(new Date());
    }
  }, [username]);

  useEffect(() => {
    const onbeforeunloadFn = () => {
      Cookies.remove(HAS_ALREADY_REGISTERED_TTC);
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
  }, []);

  const { text1, buttonText, text2, text3, url } = props;
  // const { text1, buttonText, text2, text3, url, defaultOpen } = props;

  // let openOnStart = false;
  // if (defaultOpen && defaultOpen == '1') {
  //   openOnStart = true;
  // }


  const handleOnClick = async() => {

    if (username) {
      try {
        await handleRegisterLinkClickEvent();
      } catch (error: any) {
        console.error("Error ", error);
      }
    }
  };

  const handleRegisterLinkClickEvent = async () => {
    if (!url) {
      return false;
    }

    const cookieHasAlreadyRegisteredLinkTTC = Cookies.get(
      HAS_ALREADY_REGISTERED_TTC
    );

    let secondsTimeToClick = 0;
    if (
      !cookieHasAlreadyRegisteredLinkTTC ||
      cookieHasAlreadyRegisteredLinkTTC === "false"
    ) {
      let clickDate = new Date();

      secondsTimeToClick =
        (clickDate.getTime() - pageLoadedDate.getTime()) / 1000;
    }

    try {
      await registerEvent({
        username,
        eventType: EVENT_LINK_CLICK,
        linkInfo: {
          linkId: nodeId ?? "",
          name: url,
          linkUrl: url,
        },
        timeToClick:
          cookieHasAlreadyRegisteredLinkTTC === "true" ? 0 : secondsTimeToClick,
      });

      Cookies.set(HAS_ALREADY_REGISTERED_TTC, "true"); // session cookie
    } catch (error) {
      console.log("Err event ", error);
    }
  };

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  // const location = useLocation();

  // const isEditorView = location.pathname.includes("/editor");



  let text1html = ''+((text1 && (''+text1).trim()) ? text1 : 'Unlimited Premium Wireless for $15/mo');

  text1html = text1html.split('$').join('<sup>$</sup>');
  text1html = text1html.split('/mo').join('<sub>/mo</sub>');

  // eslint-disable-next-line no-useless-concat
  let text2html = ''+((text2 && (''+text2).trim()) ? text2 : 'Exclusive offer for ${'+'username} followers');

  let text3html = ''+((text3 && (''+text3).trim()) ? text3 : 'Nationwide Coverage For Less');

  // eslint-disable-next-line no-useless-concat
  if (text2html.indexOf('${'+'username}') !== -1 && username) {
    // console.error('username', username);

    // eslint-disable-next-line no-useless-concat
    text2html = text2html.split('${'+'username}').join(username);
  }

// Unlimited Premium Wireless for <sup>$</sup>15<sub>/mo</sub>
  return (
    <Box ref={(ref) => connect(ref)} textAlign="center" width="100%">
    <ContainerQuery query={query}>
      {(params) => (
      <MintMobileContainer
        borderRadius="32px"
        width="100%"
        backgroundColor="#e9f3ed"
        color="#549670"
        fontWeight="bold"
        display="block"
        padding="20px"
        fontFamily="Oswald"
        pointerEvents={enabled ? "none" : "auto"}
        my="1rem"
        className={classnames(params)}
        isLargerThan768={isLargerThan768}
        isExpanded={isExpanded}
      >
        <LogoImageBlock>
          <img src={SmallLogo} alt="mint mobile" />
        </LogoImageBlock>

        <div className="mint_mobile_container_view mint_mobile_container_view_min">

          <Text1Block className="mint_mobile_text1" dangerouslySetInnerHTML={{ __html: text1html }}></Text1Block>

          <MintLink>
            <a href={(url ? url : "https://google.com")} target="_blank" rel="noopener noreferrer"
            onClick={handleOnClick}>
              {(buttonText) ? (<span>{buttonText}</span>) : (<span>Start Saving</span>)}
            </a>
          </MintLink>

          <div className="mint_mobile_tag">
            #MintMobilePartner
          </div>

          <div className="mint_mobile_learn_more" onClick={() => setIsExpanded(!isExpanded)}>
            <span>▼</span> Learn More <span>▼</span>
          </div>

        </div>
        <div className="mint_mobile_container_view mint_mobile_container_view_max">
{/*
          <div className="mint_mobile_learn_more" onClick={() => setIsExpanded(!isExpanded)}>
            <span>▼</span> Learn More <span>▼</span>
          </div>*/}

          <div className="mint_mobile_left_block">
            <img src={FoxLogo} alt="mint mobile" />
          </div>
          <div className="mint_mobile_right_block">
            <div className="mint_mobile_text2" dangerouslySetInnerHTML={{ __html: text2html }}></div>
            <Text1Block className="mint_mobile_text1 mint_mobile_text1_expanded" dangerouslySetInnerHTML={{ __html: text1html }}></Text1Block>

            <div className="mint_mobile_text3"><span dangerouslySetInnerHTML={{ __html: text3html }}></span></div>

            <MintLink>
              <a href={(url ? url : "https://google.com")} target="_blank" rel="noopener noreferrer"
              onClick={handleOnClick}>
                {(buttonText) ? (<span>{buttonText}</span>) : (<span>Start Saving</span>)}
              </a>
            </MintLink>

          </div>
          <div style={{clear: 'both'}}></div>

          <div className="mint_mobile_tag">
            When you purchase services through the link, I may receive compensation from Mint Mobile
          </div>

        </div>

        {/*<img src={FoxLogo} />*/}

      </MintMobileContainer>
      )}
    </ContainerQuery>
    </Box>
  );
};

MintMobile.craft = {
  displayName: "MintMobile",
  props: {
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: Settings,
  },
};
