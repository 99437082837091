import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import AlertMessage from "components/AlertMessage";
import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { FingerprintGirlSVG } from "assets/svgs/fingerprintSVG";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
import UilEyeSlash from "@iconscout/react-unicons/icons/uil-eye-slash";
import api from "api";
import { useAuth } from "hooks/useAuth";
import { useParams } from "react-router-dom";
import { useState } from "react";

const ResetPasswordScreen = () => {
  const toast = useToast();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { login, error: loginError, loading } = useAuth();

  const { resetToken } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const headers = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (password !== confirmPassword) {
      setTimeout(() => {
        setError("");
      }, 5000);
      return setError("Passwords don't match");
    }

    setIsLoading(true);

    try {
      const { data } = await api.put(
        `/api/auth/passwordreset/${resetToken}`,
        { password },
        {
          headers: headers,
        }
      );

      const { username } = data;

      toast({
        title: "Password successfully updated",
        description: "Logging you in...",
        status: "success",
        variant: "subtle",
        position: "top",
        isClosable: true,
      });

      setTimeout(() => {
        login({ username, password });
      }, 2000);

      setIsLoading(false);
    } catch (error: any) {
      if (error && error?.response.status === 400) {
        setError("Token has expired. Please request a new password reset");
        setIsLoading(false);
        return setTimeout(() => {
          setError("");
        }, 5000);
      }

      setIsLoading(false);

      setError(
        "Something went wrong. Please try again later or contact support"
      );
      setTimeout(() => {
        setError("");
      }, 5000);
    }

    setIsLoading(false);
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        minHeight="400px"
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Reset password | Alfalink</title>
      </Helmet>
      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>

        <Flex
          width="full"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          pt="3rem"
        >
          <Heading as="h2" size="xl">
            Hi!{" "}
            <span role="img" aria-label="sheep">
              👋
            </span>
          </Heading>
          <Heading as="h3" size="lg" my="1rem">
            Add your new password and you're all set!
          </Heading>
          <Box
            p={{ base: "1rem", md: "1.5rem" }}
            width="full"
            maxWidth="552px"
            borderRadius={4}
            bg="white"
            borderWidth={{ base: 1, md: 1 }}
            boxShadow="sm"
            mt="1rem"
          >
            <>
              <Box my={4} textAlign="left">
                <form onSubmit={handleSubmit}>
                  {error && <AlertMessage status="error" message={error} />}
                  {loginError && (
                    <AlertMessage status="error" message={loginError} />
                  )}

                  <FormControl mt={6}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="*******"
                        size="lg"
                        required
                        onChange={(event) =>
                          setPassword(event.currentTarget.value)
                        }
                      />
                      <InputRightElement width="3rem">
                        <Button
                          variant="unstyled"
                          opacity="0.8"
                          top="3px"
                          onClick={handlePasswordVisibility}
                        >
                          {showPassword ? <UilEyeSlash /> : <UilEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl mt={6}>
                    <FormLabel>Confirm password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="*******"
                        size="lg"
                        required
                        onChange={(event) =>
                          setConfirmPassword(event.currentTarget.value)
                        }
                      />
                      <InputRightElement width="3rem">
                        <Button
                          variant="unstyled"
                          opacity="0.8"
                          top="3px"
                          onClick={handleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? <UilEyeSlash /> : <UilEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Button
                    variant="solid"
                    size="lg"
                    type="submit"
                    width="full"
                    my="1.5rem"
                    colorScheme="yellow"
                  >
                    {isLoading ? (
                      <CircularProgress
                        isIndeterminate
                        size="24px"
                        color="teal"
                      />
                    ) : (
                      "Set password and login"
                    )}
                  </Button>
                </form>
              </Box>

              <Divider />

              <Box mt="2rem" display="flex" justifyContent="center">
                <Text color="gray.600">
                  <Link href="/in/login">
                    <Text as="u">Go to login page</Text>
                  </Link>
                </Text>
              </Box>
            </>
          </Box>
          <FingerprintGirlSVG />
        </Flex>
      </Page>
    </>
  );
};

export default ResetPasswordScreen;
