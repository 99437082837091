import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

import { EmailIcon } from "assets/svgs/emailIcon";
import { FacebookIcon } from "assets/svgs/facebookIcon";
import { InstagramIcon } from "assets/svgs/instagramIcon";
import { LinkedInIcon } from "assets/svgs/linkedInIcon";
import { RedditIcon } from "assets/svgs/redditIcon";
import { SnapchatIcon } from "assets/svgs/snapchatIcon";
import { TiktokIcon } from "assets/svgs/tiktokIcon";
import { TwitchIcon } from "assets/svgs/twitchIcon";
import { TwitterIcon } from "assets/svgs/twitterIcon";
import { WhatsAppIcon } from "assets/svgs/whataAppIcon";
import { YouTubeIcon } from "assets/svgs/youtubeIcon";
import copy from "copy-to-clipboard";
import styled from "styled-components";

const StyledHStack = styled(HStack)`
  a {
    :hover {
      transform: scale(1.1, 1.1);
    }
  }
`;

interface ShareLinkTypes {
  userAlfalink: string;
  username: string;
  onShareLinkModalClose: () => void;
  showSocialShareModal: boolean;
}

export const ShareLinkModal = ({
  userAlfalink,
  username,
  onShareLinkModalClose,
  showSocialShareModal,
}: ShareLinkTypes) => {
  const toast = useToast();
  const id = "u-toast";

  return (
    <Modal
      isOpen={showSocialShareModal}
      onClose={onShareLinkModalClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent maxWidth="34rem">
        <ModalHeader>Share</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb="2rem">
          <Heading as="h5" size="sm" mb="1.25rem">
            Share your Alfalink on your social media channels to maximize your
            audience reach
          </Heading>

          <Text color="gray.600" fontSize="sm" mb="0.5rem">
            Share on social media
          </Text>

          <StyledHStack
            spacing={{ base: "0.75rem", md: "1.25rem" }}
            gridRowGap="1rem"
            justifyContent="space-evenly"
            flexWrap="wrap"
          >
            <Link
              title="Share via email"
              href={`mailto:?subject=%20Check%20out%20my%20links!%20&body=Hey%2C%20view%20all%20my%20links%20here%20${encodeURI(
                userAlfalink
              )}`}
              rel="noopener"
              isExternal
            >
              <EmailIcon />
            </Link>

            <Link
              title="Share via Twitter"
              href={`https://twitter.com/intent/tweet?url=${encodeURI(
                userAlfalink
              )}%0D&text=Hey%2C%20view%20all%20my%20links%20here&via=alfalinkco
            
        `}
              rel="noopener"
              isExternal
            >
              <TwitterIcon />
            </Link>

            <Link
              title="Share via Reddit"
              href={`https://www.reddit.com/submit?url=${encodeURI(
                userAlfalink
              )}&feature%3Dshare&title=Hey%2C%20view%20all%20my%20links%20here`}
              rel="noopener"
              isExternal
            >
              <RedditIcon />
            </Link>

            <Link
              title="Share via Snapchat"
              href={`https://snapchat.com/scan?attachmentUrl=${encodeURI(
                userAlfalink
              )}`}
              rel="noopener"
              isExternal
            >
              <SnapchatIcon />
            </Link>

            <Link
              title="Share via LinkedIn"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                userAlfalink
              )}%26feature%3Dshare`}
              rel="noopener"
              isExternal
            >
              <LinkedInIcon />
            </Link>

            <Link
              title="Share via WhatsApp"
              href={`https://api.whatsapp.com/send/?phone&text=${encodeURI(
                userAlfalink
              )}`}
              rel="noopener"
              isExternal
            >
              <WhatsAppIcon />
            </Link>

            <Link
              title="Share via Facebook"
              href={`https://www.facebook.com/dialog/share?app_id=87741124305&href=${encodeURI(
                userAlfalink
              )}%26feature%3Dshare&display=popup`}
              rel="noopener"
              isExternal
            >
              <FacebookIcon />
            </Link>
          </StyledHStack>

          <Divider my={{ base: "1.5rem", md: "1.75rem" }} />

          <Box>
            <Text color="gray.600" fontSize="sm">
              Add to your social accounts
            </Text>
            <Text color="gray.400" fontSize="xs" mb="0.75rem">
              Copy and add your alfalink to your bio or profile pages on the
              respective platforms
            </Text>

            <StyledHStack
              spacing={{ base: "0.75rem", md: "1.25rem" }}
              gridRowGap="1.25rem"
              flexWrap="wrap"
              justifyContent="space-evenly"
              mb="1.5rem"
            >
              <Tooltip
                hasArrow
                label="Paste your alfalink to Bio or Website field"
              >
                <Link
                  href={"https://www.instagram.com/accounts/edit/"}
                  rel="noopener"
                  isExternal
                >
                  <InstagramIcon />
                </Link>
              </Tooltip>

              <Tooltip hasArrow label="Paste your alfalink to Website field">
                <Link
                  href="https://twitter.com/settings/profile"
                  rel="noopener"
                  isExternal
                >
                  <TwitterIcon />
                </Link>
              </Tooltip>

              <Tooltip hasArrow label="Paste your alfalink to Website field">
                <Link
                  href="https://www.linkedin.com/in/edit/contact-info"
                  rel="noopener"
                  isExternal
                >
                  <LinkedInIcon />
                </Link>
              </Tooltip>

              <Tooltip
                hasArrow
                label="Paste your alfalink to Links in 'Basic Info' section in Customize Channel page"
              >
                <Link
                  href="https://www.youtube.com/my_profile"
                  rel="noopener"
                  isExternal
                >
                  <YouTubeIcon />
                </Link>
              </Tooltip>

              <Tooltip
                hasArrow
                label="Paste your alfalink to Social Links in 'Edit about & links' on About page"
              >
                <Link
                  href="https://www.twitch.tv/settings/profile"
                  rel="noopener"
                  isExternal
                >
                  <TwitchIcon />
                </Link>
              </Tooltip>

              <Tooltip
                hasArrow
                label="Paste your alfalink to Website section in 'Edit profile' page in Manage Account"
              >
                <Link href="https://www.tiktok.com/" rel="noopener" isExternal>
                  <TiktokIcon />
                </Link>
              </Tooltip>

              <Tooltip
                hasArrow
                label="Paste your alfalink to 'Contact and basic info' in About page on your profile"
              >
                <Link
                  href="https://www.facebook.com/"
                  rel="noopener"
                  isExternal
                >
                  <FacebookIcon />
                </Link>
              </Tooltip>
            </StyledHStack>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={
                  <Text
                    pl="5.25rem"
                    pt="8px"
                    fontSize="18px"
                    alignItems="center"
                    verticalAlign="center"
                    size="60px"
                    color="gray.500"
                  >
                    alfalink.co/
                  </Text>
                }
              />
              <Input
                isReadOnly
                name="password"
                placeholder={username}
                size="lg"
                pl="7rem"
                required
              />
              <InputRightElement>
                <Button
                  variant="ghost"
                  opacity="0.8"
                  px="1.75rem"
                  top="3px"
                  right="0.75rem"
                  onClick={() => {
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        position: "top",
                        status: "success",
                        variant: "subtle",
                        isClosable: true,
                        title: "Link copied",
                      });
                      copy(userAlfalink);
                    }
                  }}
                >
                  Copy
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
