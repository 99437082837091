import { AUTH_TOKEN, TEMP_TEMPLATE } from "utils/constants";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getAuthToken, removeAuthToken } from "utils";

import Cookies from "js-cookie";
import api from "api";
import { useHistory } from "react-router-dom";

type SignupT = {
  username: string;
  email: string;
  password: string;
  frameData: string;
};

type loginT = {
  username: string;
  password: string;
};

interface AuthContextType {
  loading: boolean;
  token: string;
  error?: any;
  login: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => void;
  signUp: ({
    email,
    username,
    password,
    frameData,
  }: {
    email: string;
    username: string;
    password: string;
    frameData: string;
  }) => void;
  logout: () => void;
  isAuthenticated?: boolean;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const token = getAuthToken();

    if (token) {
      setToken(token);
      setIsAuthenticated(true);
    } else {
      setToken(null);
      setIsAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken(), token]);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  const login = async ({ username, password }: loginT): Promise<any> => {
    setLoading(true);

    try {
      const { data } = await api.post("/api/auth/login", {
        username,
        password,
      });

      setIsAuthenticated(true);
      setLoading(false);

      Cookies.set(AUTH_TOKEN, data.token, { expires: 7 });

      if (data?.frameData) {
        history.push(`/in/editor`);
      } else {
        history.push(`/in/templates`);
      }
    } catch (error: any) {
      console.log("Err ", error, error.message);
      setLoading(false);

      if (error && error?.message === "Network Error") {
        setIsAuthenticated(false);
        return setError(
          "Network error. Please try again later or contact support"
        );
      }

      setIsAuthenticated(false);
      setError("Invalid credentials.");
      setTimeout(() => {
        setError("");
      }, 5000);
    }

    setLoading(false);
  };

  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  const signUp = async ({
    email,
    username,
    password,
    frameData,
  }: SignupT): Promise<any> => {
    setLoading(true);

    try {
      const { data } = await api.post(
        "/api/auth/register",

        { email, username, password, frameData }
      );

      if (!data.success) {
        setLoading(false);

        return setError("Username taken, please choose a different username.");
      }

      Cookies.set(AUTH_TOKEN, data.token, { expires: 7 });

      setIsAuthenticated(true);
      setLoading(false);

      if (data?.frameData) {
        Cookies.remove(TEMP_TEMPLATE);

        return history.push("/in/editor");
      }

      return history.push("/in/templates");
    } catch (error: any) {
      console.error("Err ", error);

      if (error && error.response?.status === 400) {
        setIsAuthenticated(false);
        setLoading(false);
        return setError(
          "An account with this email exists. Please use a different email"
        );
      }

      if (error && error?.message === "Network Error") {
        setIsAuthenticated(false);
        setLoading(false);
        return setError(
          "Network error. Please try again later or contact support"
        );
      }

      setError("Something went wrong, please try again in a moment.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
  };

  const logout = () => {
    removeAuthToken();
    setIsAuthenticated(false);
    history.push("/");
  };

  const memoedValue = useMemo(
    () => ({
      loading,
      error,
      token,
      isAuthenticated,
      login,
      signUp,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, error, token]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
