import { Box, Flex, Heading, Link, Stack, Text } from "@chakra-ui/layout";
import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import Lottie from "react-lottie";
import Page from "components/Page";
import looking from "assets/jsons/looking.json";
import { useMediaQuery } from "@chakra-ui/react";

const defaultOptions = {
  loop: true,
  speed: "1",
  direction: "-1",
  autoplay: true,
  animationData: looking,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const UsernameAvailable = ({ username }) => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

  return (
    <Page>
      <Box py="1rem" px={{ base: "1rem", md: "0" }}>
        <AnimatedGradientLogo />
      </Box>
      <Flex
        width="full"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py="1rem"
        //h="calc(100vh - 68px)"
      >
        <Box my="1rem">
          <Lottie
            options={defaultOptions}
            height={isSmallerThan600 ? 160 : 280}
            width={isSmallerThan600 ? 300 : 600}
          />
        </Box>

        <Stack spacing="3rem" textAlign="center" cursor="default">
          <Heading as="h3" size="lg">
            No profile found for username{" "}
            <Text as="span" fontWeight="900" color="#673ab7">
              <i>{username}</i>
            </Text>
          </Heading>

          <Heading as="h4" size={isSmallerThan600 ? "sm" : "md"}>
            <Link
              textDecoration="underline"
              href={`/in/signup?username=${username}`}
            >
              Create your alfalink profile with username{" "}
              <Text as="span" fontWeight="900" color="#673ab7">
                <i>{username}</i>
              </Text>
            </Link>
          </Heading>
        </Stack>
      </Flex>
    </Page>
  );
};

export default UsernameAvailable;
