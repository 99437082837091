import React from "react";
import { useEditor } from "@craftjs/core";

import "./styles.css";
import { Box } from "@chakra-ui/react";

import { AddComponents } from "./AddComponents";

export const Viewport: React.FC<any> = ({ activeComponents, children }) => {
  const { connectors } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  return (
    <Box
      h="100%"
      // @ts-ignore
      ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
    >
      {children}

      <AddComponents activeComponents={activeComponents} />
    </Box>
  );
};
