import { Box, Spinner, Text } from "@chakra-ui/react";
import { TwitterTimelineEmbed, TwitterTweetEmbed } from "react-twitter-embed";
import { UserComponent, useEditor, useNode } from "@craftjs/core";

import { TwitterSettings } from "./settings";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

type ButtonProps = {
  url?: string;
  height?: number;
};

export const Twitter: UserComponent<ButtonProps> = (props: any) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const location = useLocation();

  const isEditorView = location.pathname.includes("/editor");

  const { url, height } = props;

  const tweetIdIdFromUrl =
    url && url.includes("/status/") ? url.match(/status\/(\d+)/)[1] : url;

  const renderHelpLabel = () => {
    if (enabled && isEditorView && !tweetIdIdFromUrl) {
      return (
        <Text
          p="1rem"
          my="1rem"
          fontSize="xs"
          textShadow="0 1px 5px black"
          textAlign="center"
          style={{
            color: "#eee",
            cursor: "pointer",
          }}
        >
          <i
            className="uil uil-exclamation-triangle"
            style={{
              color: "#f74949",
            }}
          />{" "}
          Twitter URL missing
        </Text>
      );
    }

    if (enabled && isEditorView && tweetIdIdFromUrl) {
      return (
        <Text
          py="0.25rem"
          fontSize="xs"
          style={{
            color: "#eee",
            cursor: "pointer",
            textShadow: "0px 0px 10px #000000",
          }}
          textAlign="center"
        >
          Edit Twitter URL
        </Text>
      );
    }

    return null;
  };

  const StyledBox = styled(Box)<{ ownHeight?: number }>`
    & iframe {
      height: ${({ ownHeight }) => ownHeight} !important;
    }
  `;

  return (
    <Box
      ref={(ref) => connect(ref)}
      //px="1rem"
      width={"100%"}
      textAlign="center"
    >
      {tweetIdIdFromUrl && (
        <StyledBox
          borderRadius="8x"
          width="100%"
          pointerEvents={enabled ? "none" : "auto"}
          ownHeight={height}
        >
          {isNaN(parseInt(tweetIdIdFromUrl)) ? (
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={tweetIdIdFromUrl}
              placeholder={
                <div>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    p="8rem 3rem"
                  >
                    <Spinner />
                  </Box>
                </div>
              }
              options={{
                height: height,
              }}
            />
          ) : (
            <TwitterTweetEmbed
              onLoad={function noRefCheck() {}}
              placeholder={
                <div>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    p="8rem 3rem"
                  >
                    <Spinner />
                  </Box>
                </div>
              }
              options={{
                height: height,
              }}
              tweetId={tweetIdIdFromUrl}
            />
          )}
        </StyledBox>
      )}

      {renderHelpLabel()}
    </Box>
  );
};

Twitter.craft = {
  displayName: "Twitter",

  props: {
    url: "",
    height: 300,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
  related: {
    settings: TwitterSettings,
  },
};
