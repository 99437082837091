import { useEffect, useState } from "react";
import { getAuthToken } from "utils";

import { getAvailableAds } from "api/queries";

export const useAvailableAds = (): any => {
  const [availableAds, setAvailableAds] = useState([]);

  useEffect(() => {

    getAvailableAds()
      .then((got)=>{
        if (got && got.items && got.items.length) {
          setAvailableAds(got.items);
        } else {
          setAvailableAds([]);
        }
      })
      .catch(()=>{
        setAvailableAds([]);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthToken()]);

  return { availableAds };
};

export default useAvailableAds;
