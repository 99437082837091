import { EDITOR_TOUR_DESKTOP, EDITOR_TOUR_MOBILE } from "utils/constants";
import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Cookies from "js-cookie";
import { EditorTourGuide } from "components/EditorGuideTour";
import { MobileEditorTourGuide } from "components/MobileEditorGuideTour";
import { PopupButton } from "@typeform/embed-react";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope-alt";
import UilFeedback from "@iconscout/react-unicons/icons/uil-feedback";
import UilQuestionMark from "@iconscout/react-unicons/icons/uil-question-circle";
import UilTour from "@iconscout/react-unicons/icons/uil-sign-left";
import { frameDataIsNotModified } from "utils";
import { useUserContext } from "HoCs/UserProvider";

export const HelpDropdown = ({
  isEditorView,
  isSmallerThan600,
}: {
  isEditorView?: boolean;
  isSmallerThan600?: boolean;
}) => {
  const editorTourDesktop = Cookies.get(EDITOR_TOUR_DESKTOP);

  const { userData } = useUserContext();

  const [runTour, setRunTour] = useState(editorTourDesktop !== "true");

  const editorTourMobile = Cookies.get(EDITOR_TOUR_MOBILE);

  const [runTourMobile, setRunTourMobile] = useState(
    editorTourMobile !== "true"
  );

  const canUpdateTemplate =
    userData && frameDataIsNotModified({ frameData: userData?.frameData });

  return (
    <>
      <Menu>
        <MenuButton
          position={{ base: "absolute", md: "fixed" }}
          top={{ base: isEditorView ? "12px" : "13px", md: "unset" }}
          bottom={{ base: "unset", md: "1rem" }}
          right={{ base: "unset", md: "1rem" }}
          left={{ base: "6.25rem", md: "unset" }}
          size="xs"
          display="inline-flex"
          alignItems="center"
          zIndex={1115}
          as={IconButton}
          p="0"
          aria-label="Options"
          icon={
            <UilQuestionMark
              color="#002c4b"
              size={!isSmallerThan600 && "2rem"}
            />
          }
          variant="unstyled"
        />

        <MenuList zIndex={1115} fontSize="sm">
          {isEditorView && !isSmallerThan600 && canUpdateTemplate ? (
            <MenuItem
              icon={<UilTour size="20px" />}
              onClick={() => setRunTour(true)}
              className="start_editor_tour_desktop"
            >
              Take Editor tour
            </MenuItem>
          ) : null}

          {isEditorView && isSmallerThan600 && canUpdateTemplate ? (
            <MenuItem
              icon={<UilTour size="20px" />}
              onClick={() => setRunTourMobile(true)}
              className="start_editor_tour_mobile"
            >
              Take Editor tour
            </MenuItem>
          ) : null}

          <PopupButton id="biTPyLY6" style={{ width: "100%" }}>
            <MenuItem icon={<UilFeedback size="20px" />}>Feedback</MenuItem>
          </PopupButton>

          <Link href="/in/support" isExternal width="100%">
            <MenuItem icon={<UilQuestionMark size="20px" />}>Support</MenuItem>
          </Link>

          <Link href="/in/contact" isExternal width="100%">
            <MenuItem icon={<UilEnvelope size="20px" />}>Contact us</MenuItem>
          </Link>
        </MenuList>
      </Menu>

      {isEditorView ? (
        <MobileEditorTourGuide
          runTourMobile={runTourMobile && isSmallerThan600 && canUpdateTemplate}
          setRunTourMobile={setRunTourMobile}
        />
      ) : null}

      {isEditorView ? (
        <EditorTourGuide
          runTour={runTour && !isSmallerThan600 && canUpdateTemplate}
          setRunTour={setRunTour}
        />
      ) : null}
    </>
  );
};
