// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as React from "react";
export const StripePricingTable = ({ username }: { username: string }) => {
  const stripePublishableKey =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_PUBLIC_PUBLISHABLE_KEY
      : process.env.REACT_APP_STRIPE_PUBLIC_PUBLISHABLE_KEY_TEST;

  return (
    <>
      <stripe-pricing-table
        pricing-table-id="prctbl_1Lhg79DjPBslCYv4kCFLJHGk"
        publishable-key={stripePublishableKey}
        client-reference-id={username ?? ""}
      ></stripe-pricing-table>
    </>
  );
};
