import { DividerSettings } from "./settings";
import { useNode } from "@craftjs/core";

type DividerType = {
  margin?: string;
  color?: string;
  thickness?: string;
};
const defaultProps = {
  margin: "0",
  color: "#eee",
  thickness: "1",
};
export const Divider = (props: Partial<DividerType>) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const { margin, color, thickness } = props;

  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={(ref) => connect(drag(ref))}
      style={{
        padding: "1rem",
        width: "100%",
      }}
    >
      <div
        style={{
          margin: `${margin}px 0`,
          height: `${thickness}px`,

          borderRadius: "0.5rem",

          backgroundColor: color,
        }}
      />
    </div>
  );
};

Divider.craft = {
  displayName: "Divider",
  props: defaultProps,
  related: {
    settings: DividerSettings,
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false,
    canDrag: () => true,
  },
};
