import { Box } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

import AnimatedGradientLogo from "components/AnimatedGradientLogo";
import { Helmet } from "react-helmet";
import Page from "components/Page";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// import { getFrameData } from "api/queries";
import Cookies from "js-cookie";
import { AUTH_TOKEN } from "utils/constants";

const GuestScreen = () => {
  // const [guestTokenVerified, setGuestTokenVerified] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
      mounted.current = true;
      return () => {
          mounted.current = false;
      };
  }, []);


  // const checkTheReferrer = false;

  const { guestToken } = useParams();

  const history = useHistory();

  // useEffect(() => {
  //   if (guestTokenVerified) {
  //     // setTimeout(() => {
  //     //   history.push("/in/editor");
  //     // }, 400);
  //   }
  // }, [guestTokenVerified]);

  const checkTheToken = async (token) => {
    // if (guestTokenVerified) {
    //   return false;
    // }

    await new Promise((res)=>setTimeout(res, 500));
    // alert(document.referrer);

    const splet = (''+token).split('!---!');
    let isOkToken = true;

    // alert(mounted.current);

    if (!mounted.current) {
      return false;
    }

    if (splet.length !== 3) {
      isOkToken = false;
    }
    if (isOkToken) {
      isOkToken = false;
      try {
        // const p1 = splet[0].trim();
        // const p2 = splet[1].trim();
        // const resp = await getFrameData(p1);
        // if (resp && resp.success && resp.frameData) {
        //   const frameData = resp.frameData;
        //   alert(frameData);

        //   isOkToken = true;
        // }
        isOkToken = true;
      } catch (e) {
        isOkToken = false;
      }
    }

    console.error('isOkToken', isOkToken);
    if (isOkToken) {
      const p3 = splet[2].trim();
      console.error('2__' + AUTH_TOKEN + ' -- ' + p3);
      Cookies.set(AUTH_TOKEN, p3, { expires: 7 });
      // setGuestTokenVerified(true);

      await new Promise((res)=>setTimeout(res, 500));
      history.push("/in/editor");

      // document.location = "/in/editor";
    }
  };

  if (guestToken) {
    checkTheToken(guestToken);
    // setTimeout(()=>{
    // }, 1000);
  }

  return (
    <>
      <Helmet>
        <title>Guest | Alfalink</title>
      </Helmet>
      <Page>
        <Box py="1rem" px={{ base: "1rem", md: "0" }}>
          <AnimatedGradientLogo />
        </Box>

      </Page>
    </>
  );
};

export default GuestScreen;
